import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-estatistica-home',
  templateUrl: './estatistica-home.component.html',
  styleUrls: ['./estatistica-home.component.css']
})
export class EstatisticaHomeComponent implements OnInit {

  tab: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.tab = this.route.snapshot.params['tab'];
    console.log('this.tab', this.tab);
    if (!this.tab) {
      this.tab = 'vendas';
    }
  }

}
