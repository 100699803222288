<div class="pgw--container">
    <div class="ui-g">

        <div class="ui-g-12">
            <button pButton type="button" (click)="onClose()" label="Voltar"
                icon="pi pi-step-backward"></button>
        </div>

        <div class="ui-g-12">
            <h1>Pedido de Origem</h1>
        </div>

        <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Data/Hora Pedido:
            </div>
            <div>
                {{ pedido.dtHr | date:'dd/MM/yyyy - HH:mm:ss' }}
            </div>
        </div>

        <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Número do Pedido:
            </div>
            <div style="min-height: 15px;">
                <span class="pedido-origem" (click)="onPedidoOrigemSelected()">{{ pedido.pedidoNumero }}</span>
            </div>
        </div>

        <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Origem:
            </div>
            <div style="min-height: 15px;">
                {{ pedido.origem }}
            </div>
        </div>

        <div class="ui-g-12 ui-md-4 ui-lg-6">
            <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Comprador:
            </div>
            <div style="min-height: 15px;">
                {{ pedido.compradorNomeCompleto }}
            </div>
        </div>

        <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Status:
            </div>
            <div style="min-height: 15px;" appPagseguroStatus>{{ pedido.pagseguroStatus }}</div>
        </div>

        <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Forma de Pagamento:
            </div>
            <div style="min-height: 15px;">
                {{ pedido.formaPagamento }}
            </div>
        </div>

        <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Moeda:
            </div>
            <div style="min-height: 15px;">
                {{ pedido.moedaLoja }}
            </div>
        </div>

        <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Total do Pedido:
            </div>
            <div *ngIf="pedido.moedaLoja === 'BRL'" style="min-height: 15px;">
                {{ pedido.totalGeral | currency:'BRL':'symbol':'0.2-2':'pt' }}</div>
            <div *ngIf="pedido.moedaLoja === 'USD'" style="min-height: 15px;">
                {{ pedido.totalGeral | currency:'USD':'symbol':'0.2-2':'en' }}</div>
        </div>

    </div>
    
    <div class="ui-g">
        <div class="ui-g-12">
            <h1>Pedidos Dependentes</h1>
        </div>

        <div class="ui-g-12">
            <p-table [value]="pedidos" [responsive]="true" selectionMode="single"
                (onRowSelect)="onRowSelectPedido($event.data)">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 100px;">Data</th>
                        <th style="width: 200px;">Pedido</th>
                        <th class="pgw--text-left">Comprador</th>
                        <th style="width: 80px;">Forma Pgto.</th>
                        <th style="width: 200px;">Situação</th>
                        <th style="width: 120px;" class="pgw--text-right">Total</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-pedidoDTO>
                    <tr [pSelectableRow]="pedidoDTO">
                        <td class="pgw--text-center">{{ pedidoDTO.dtHr | date:'dd/MM/yyyy' }}</td>
                        <td class="pgw--text-center">{{ pedidoDTO.pedidoNumero }}</td>
                        <td class="pgw--text-left">{{ pedidoDTO.compradorNomeCompleto }}</td>
                        <td class="pgw--text-center">{{ pedidoDTO.formaPagamento }}</td>
                        <td class="pgw--text-center" appPagseguroStatus>{{ pedidoDTO.pagseguroStatus }}</td>
                        <td class="pgw--text-right" *ngIf="pedido.moedaLoja == 'BRL'">
                            {{ pedidoDTO.totalPedido | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
                        <td class="pgw--text-right" *ngIf="pedido.moedaLoja == 'USD'">
                            {{ pedidoDTO.totalPedido | currency:'USD':'symbol':'0.2-2':'en' }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <div class="ui-g-12">
            <button pButton type="button" (click)="onClose()" label="Voltar"
                icon="pi pi-step-backward"></button>
        </div>
       

    </div>
</div>

