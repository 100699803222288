export class RecorrenteLink {
  id: string;
  empresaId: string;
  licencaId: string;
  descricao: string;
  valorUnitario: number;
  periodicidade: string;
  diasVenctoBoleto: number;

  ativarBoleto: boolean;
  ativarCartao: boolean;
  ativo: boolean;

  link: string;
  linkReduzido: string;

  tpMulta: string;
  vlrMulta: number;
  tpJuros: string;
  vlrJuros: number;

  constructor(empresaId: string) {
      this.empresaId = empresaId;
      this.valorUnitario = 0.00;
      this.periodicidade = 'MENSAL';
      this.diasVenctoBoleto = 1;
      this.descricao = "";
      this.ativarBoleto = true;
      this.ativarCartao = true;
      this.ativo = true;
      this.tpMulta = 'ISENTO';
      this.vlrMulta = 0.00;
      this.tpJuros = 'ISENTO';
      this.vlrJuros = 0.00;
  }
}
