import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { map } from 'rxjs/operators';
import * as Highcharts from 'highcharts';

import { AuthService } from '../seguranca/auth.service';
import { PedidoService } from '../pedido/pedido.service';
import { TotalGroupDTO } from '../pedido/total-group-dto.model';
import { Observable } from 'rxjs';
import { EmpresaService } from '../empresa/empresa.service';
import { SaqueService } from '../saque/saque.service';
import { SaqueFilter } from '../saque/saque-filter';
import { LicencaService } from '../licenca/licenca.service';
import { Licenca } from '../licenca/licenca.model';

declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  jwtPayload: any;
  moeda: string;

  empresaId: any;
  empresasOptions: any[];

  licencaId: any;
  licencasOptions: any[];
  licencas: Licenca[];

  public lineFluxoOptions: any = {
    title: {
      text: '',
      x: 0 // center
    },
    subtitle: {
      text: '',
      x: 0
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e %b'
      }
    },
    yAxis: {
      title: {
        text: 'Valor'
      },
      min: 0,
      plotLines: [{
        value: 0,
        width: 1,
        color: '#808080'
      }]
    },
    tooltip: {
      valuePrefix: '$'
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      borderWidth: 0
    },
    series: [
      {
        name: 'Pendente',
        data: [],
        pointInterval: 24 * 3600 * 1000 // one day
      },
      {
        name: 'Pago',
        data: [],
        pointInterval: 24 * 3600 * 1000 // one day
      },
      {
        name: 'Disponível',
        data: [],
        pointInterval: 24 * 3600 * 1000 // one day
      },
      {
        name: 'Cancelado',
        data: [],
        pointInterval: 24 * 3600 * 1000 // one day
      },
      {
        name: 'Antecipado',
        data: [],
        pointInterval: 24 * 3600 * 1000 // one day
      }
    ]
  };

  public pieStatusOptions: any = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: false,
        alpha: 35
      },
      spacing: [0, 0, 0, 0]
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    noData: {
      useHTML: true
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        center: ['50%', '50%'],
        size: '60%',
        dataLabels: {
          enabled: true,
          padding: 0,
          style: {
            textOverflow: 'clip'
          }
        }
      }
    },
    series: [{
      name: '$',
      data: []
    }]
  };

  public pieFormaOptions: any = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: false,
        alpha: 35
      },
      spacing: [0, 0, 0, 0]
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    noData: {
      useHTML: true
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        center: ['50%', '50%'],
        size: '60%',
        dataLabels: {
          enabled: true,
          padding: 0,
          style: {
            textOverflow: 'clip'
          }
        }
      }
    },
    series: [{
      name: '$',
      data: []
    }]
  };

  public pieBandeirasOptions: any = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: false,
        alpha: 35
      },
      spacing: [0, 0, 0, 0]
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    noData: {
      useHTML: true
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        center: ['50%', '50%'],
        size: '60%',
        dataLabels: {
          enabled: true,
          padding: 0,
          style: {
            textOverflow: 'clip'
          }
        }
      }
    },
    series: [{
      name: '$',
      data: []
    }]
  };

  public pieDebitoOptions: any = {
    chart: {
      type: 'pie',
      options3d: {
        enabled: false,
        alpha: 35
      },
      spacing: [0, 0, 0, 0]
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    noData: {
      useHTML: true
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        center: ['50%', '50%'],
        size: '60%',
        dataLabels: {
          enabled: true,
          padding: 0,
          style: {
            textOverflow: 'clip'
          }
        }
      }
    },
    series: [{
      name: '$',
      data: []
    }]
  };

  public barDepositosOptions: any = {
    chart: {
      type: 'column'
    },
    title: {
      text: '',
      x: 0
    },
    subtitle: {
      text: '',
      x: 0
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: []
    },
    yAxis: {
      title: {
        text: 'Depósitos'
      },
      min: 0,
      plotLines: [{
        value: 0,
        width: 1,
        color: '#808080'
      }]
    },
    tooltip: {
      valuePrefix: '$'
    },
    legend: {
      enabled: false,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      borderWidth: 0
    },
    series: [{
      name: 'Depósito',
      data: []
    }]
  };

  public barProdutosOptions: any = {
    chart: {
      type: 'column',
      styledMode: false
    },

    title: {
      text: ''
    },

    credits: {
      enabled: false
    },

    legend: {
      enabled: false
    },

    xAxis: {
      categories: []
    },

    yAxis: [{
      className: 'highcharts-color-0',
      title: {
        text: 'Quantidade'
      }
    }, {
      className: 'highcharts-color-1',
      opposite: true,
      title: {
        text: 'Valor'
      }
    }],

    plotOptions: {
      column: {
        borderRadius: 0
      }
    },

    series: [{
      name: 'Quantidade',
      data: [1, 3, 2, 4]
    }, {
      name: 'Valor',
      data: [324, 124, 547, 221],
      yAxis: 1
    }]

  };

  public barClientesOptions: any = {
    chart: {
      type: 'bar'
    },
    title: {
      text: null
    },
    subtitle: {
      text: null
    },
    xAxis: {
      categories: [],
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      labels: {
        overflow: 'justify'
      }
    },
    tooltip: {
      valuePrefix: '$'
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false
        }
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top', x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      shadow: true,
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Total',
      data: []
    }]
  };

  public barReembolsosOptions: any = {
    chart: {
      type: 'column'
    },
    title: {
      text: '',
      x: 0
    },
    subtitle: {
      text: '',
      x: 0
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: []
    },
    yAxis: {
      title: {
        text: 'Reembolsos'
      },
      min: 0,
      plotLines: [{
        value: 0,
        width: 1,
        color: '#808080'
      }]
    },
    tooltip: {
      valuePrefix: '$'
    },
    legend: {
      enabled: false,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      borderWidth: 0
    },
    series: [{
      name: 'Reembolso',
      data: []
    }]
  };

  totalPedidos$: Observable<number>;
  totalDepositos$: Observable<number>;
  totalReembolsos$: Observable<number>;

  @ViewChild('dashDepositos', { static: true }) dashDepositos: ElementRef;
  @ViewChild('dashPedidos', { static: true }) dashPedidos: ElementRef;
  @ViewChild('dashReembolsos', { static: true }) dashReembolsos: ElementRef;

  constructor(
    private authservice: AuthService,
    private saqueService: SaqueService,
    private pedidoService: PedidoService,
    private empresaService: EmpresaService,
    private licencaService: LicencaService
  ) { }

  ngOnInit() {
    this.jwtPayload = this.authservice.getJwtPayload();
    this.empresaId = this.jwtPayload.empresaId;

    this.empresaService.getEmpresas().subscribe(
      result => {
        this.empresasOptions = [];
        result.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );

    Highcharts.setOptions({
      lang: {
        loading: 'Aguarde...',
        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        shortMonths: ['Jan', 'Feb', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      }
    });
  }

  buscarLicencas() {
    this.licencaId = undefined;
    this.licencaService.buscarPorEmpresa(this.empresaId).subscribe(
      licencas => {
        this.licencas = licencas;
        this.licencasOptions = [];
        licencas.forEach(licenca => {
          this.licencasOptions.push(
            { label: `${licenca.contrato} [${licenca.moeda}]`, value: licenca.id, moeda: licenca.moeda }
          );
          if (this.licencaId === undefined) {
            this.licencaId = licenca.id;
            this.moeda = licenca.moeda;
          }
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.paintChart();
      }
    );
  }

  paintChart() {
    this.licencas.forEach(licenca => {
      if (this.licencaId === licenca.id) {
        this.moeda = licenca.moeda;
      }
    });

    this.totalPedidos$ = this.pedidoService.getTotalPedidosPorLicenca(this.licencaId);
    this.totalDepositos$ = this.pedidoService.getTotalDepositosPorLicenca(this.licencaId);
    this.totalReembolsos$ = this.pedidoService.getTotalReembolsosPorEmpresa(this.empresaId);

    this.pedidoService.getEstatisticaFluxo(this.licencaId).subscribe(
      result => {
        console.log('fluxo:', result);
        const dtInit = Date.parse(result[0].dt);
        this.lineFluxoOptions.series[0].pointStart = dtInit;
        this.lineFluxoOptions.series[1].pointStart = dtInit;
        this.lineFluxoOptions.series[2].pointStart = dtInit;
        this.lineFluxoOptions.series[3].pointStart = dtInit;
        this.lineFluxoOptions.series[4].pointStart = dtInit;
        this.lineFluxoOptions.series[0].data = [];
        this.lineFluxoOptions.series[1].data = [];
        this.lineFluxoOptions.series[2].data = [];
        this.lineFluxoOptions.series[3].data = [];
        this.lineFluxoOptions.series[4].data = [];
        this.lineFluxoOptions.tooltip.valuePrefix = this.moeda === 'BRL' ? 'R$ ' : '$';
        for (const value of result) {
          this.lineFluxoOptions.series[0].data.push(value.totalPendente);
          this.lineFluxoOptions.series[1].data.push(value.totalPago);
          this.lineFluxoOptions.series[2].data.push(value.totalDisponivel);
          this.lineFluxoOptions.series[3].data.push(value.totalCancelado);
          this.lineFluxoOptions.series[4].data.push(value.totalLiquidado);
        }
        Highcharts.chart('chartFluxo', this.lineFluxoOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );

    this.pedidoService.getEstatisticaVendas(this.licencaId).pipe(
      map((array: TotalGroupDTO[]) => {
        return array.map((item: TotalGroupDTO) => {
          switch (item.status) {
            case 0:
              return ['Pedido efetuado', item.total];
            case 1:
              return ['Aguardando pagamento', item.total];
            case 2:
              return ['Em análise', item.total];
            case 3:
              return ['Paga', item.total];
            case 4:
              return ['Disponível', item.total];
            case 5:
              return ['Em disputa', item.total];
            case 6:
              return ['Devolvida', item.total];
            case 7:
              return ['Cancelada', item.total];
            case 8:
              return ['Debitado', item.total];
            case 9:
              return ['Retenção temporária', item.total];
            case 10:
              return ['Resgate', item.total];
            case 11:
              return ['Liquidado', item.total];
            case 12:
              return ['Pedido falhou', item.total];
            case 13:
              return ['Antecipado', item.total];
          }
        });
      })
    ).subscribe(
      result => {
        this.pieStatusOptions.series[0].data = result;
        this.pieStatusOptions.series[0].name = this.moeda === 'BRL' ? 'R$ ' : '$';
        Highcharts.chart('chartStatus', this.pieStatusOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );

    this.pedidoService.getEstatisticaForma(this.licencaId).subscribe(
      result => {
        this.pieFormaOptions.series[0].data = [];
        this.pieFormaOptions.series[0].name = this.moeda === 'BRL' ? 'R$ ' : '$';
        for (const value of result) {
          this.pieFormaOptions.series[0].data.push([value.forma, value.total]);
        }
        Highcharts.chart('chartForma', this.pieFormaOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );

    this.pedidoService.getEstatisticaBandeiras(this.licencaId).subscribe(
      result => {
        this.pieBandeirasOptions.series[0].data = [];
        this.pieBandeirasOptions.series[0].name = this.moeda === 'BRL' ? 'R$ ' : '$';
        for (const value of result) {
          this.pieBandeirasOptions.series[0].data.push([value.forma, value.total]);
        }
        Highcharts.chart('chartBandeiras', this.pieBandeirasOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );

    this.pedidoService.getEstatisticaDebito(this.licencaId).subscribe(
      result => {
        this.pieDebitoOptions.series[0].data = [];
        this.pieDebitoOptions.series[0].name = this.moeda === 'BRL' ? 'R$ ' : '$';
        for (const value of result) {
          this.pieDebitoOptions.series[0].data.push([value.forma, value.total]);
        }
        Highcharts.chart('chartDebito', this.pieDebitoOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );

    const saqueFilter = new SaqueFilter(this.empresaId);
    const startDate = new Date(new Date().setDate(new Date().getDate() - 30));
    saqueFilter.rangeDates = [];
    saqueFilter.rangeDates.push(startDate);
    saqueFilter.rangeDates.push(null);
    saqueFilter.rangeType = 'dtHrLiquidacao';
    saqueFilter.sort = 'ASC';
    saqueFilter.moeda = this.moeda;
    this.saqueService.getDepositos(saqueFilter).subscribe(
      result => {
        console.log('Depositos:', result);
        this.barDepositosOptions.xAxis.categories = [];
        this.barDepositosOptions.series[0].data = [];
        this.barDepositosOptions.tooltip.valuePrefix = this.moeda === 'BRL' ? 'R$ ' : '$';
        for (const saque of result) {
          const d = saque.dtHrLiquidacao.substr(8, 2);
          const m = saque.dtHrLiquidacao.substr(5, 2);
          const y = saque.dtHrLiquidacao.substr(0, 4);
          const dtStr = d + '/' + m + '/' + y;
          this.barDepositosOptions.xAxis.categories.push(dtStr);
          this.barDepositosOptions.series[0].data.push(Number(saque.valor));
        }
        Highcharts.chart('barDepositos', this.barDepositosOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );

    this.pedidoService.getEstatisticaProdutos(this.licencaId).subscribe(
      result => {
        this.barProdutosOptions.series[0].data = [];
        this.barProdutosOptions.series[1].data = [];
        this.barProdutosOptions.xAxis.categories = [];
        for (const value of result) {
          this.barProdutosOptions.series[0].data.push([value.forma, value.qtd]);
          this.barProdutosOptions.series[1].data.push([value.forma, value.total]);
          this.barProdutosOptions.xAxis.categories.push(value.forma);
        }
        Highcharts.chart('chartProdutos', this.barProdutosOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );

    this.pedidoService.getDashboardClientes(this.licencaId).subscribe(
      result => {
        this.barClientesOptions.xAxis.categories = [];
        this.barClientesOptions.series[0].data = [];
        this.barClientesOptions.tooltip.valuePrefix = this.moeda === 'BRL' ? 'R$ ' : '$';
        for (const value of result) {
          this.barClientesOptions.xAxis.categories.push(value.forma);
          this.barClientesOptions.series[0].data.push(value.total);
        }
        Highcharts.chart('chartClientes', this.barClientesOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );

    this.pedidoService.getReembolsos(this.empresaId).subscribe(
      result => {
        this.barReembolsosOptions.xAxis.categories = [];
        this.barReembolsosOptions.series[0].data = [];
        this.barReembolsosOptions.tooltip.valuePrefix = this.moeda === 'BRL' ? 'R$ ' : '$';
        for (const obj of result) {
          const d = obj.forma.substr(8, 2);
          const m = obj.forma.substr(5, 2);
          const y = obj.forma.substr(0, 4);
          const dtStr = d + '/' + m + '/' + y;
          this.barReembolsosOptions.xAxis.categories.push(dtStr);
          this.barReembolsosOptions.series[0].data.push(Number(obj.total));
        }
        Highcharts.chart('barReembolsos', this.barReembolsosOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );


    if (this.dashDepositos) {
      this.dashDepositos.nativeElement.parentElement.style.backgroundColor = '#5687b3';    //'#b2d334';
    }
    if (this.dashPedidos) {
      this.dashPedidos.nativeElement.parentElement.style.backgroundColor = '#adb35f';      //'#523297';
    }
    if (this.dashReembolsos) {
      this.dashReembolsos.nativeElement.parentElement.style.backgroundColor = '#4f6e63';   //'#f7d861';
    }
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

}
