<form (ngSubmit)="salvar()">

  <div class="ui-g">

    <div class="ui-g-12">
      <h1>Conta</h1>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="banco">Banco</label>
      <input pInputText id="banco" name="banco" [(ngModel)]="conta.banco"/>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="apelido">Agência</label>
      <input pInputText id="agencia" name="agencia" [(ngModel)]="conta.agencia"/>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="conta">Conta</label>
      <input pInputText id="conta" name="conta" [(ngModel)]="conta.conta"/>
    </div>

    <div class="ui-g-12">
      <p-button type="submit" label="Salvar" icon="pi pi-save" ></p-button>
    </div>

  </div>

</form>
