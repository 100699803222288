<div class="pgw--container">
    <form (ngSubmit)="gerarBoleto()" style="margin-bottom: 40px;">

        <div class="ui-g">

            <div class="ui-g-12 border-botton">
                <h1>Boletos Emissão
                    <a href="javascript:void(0);" (click)="verTutorial()" [hidden]="exibirTutorial">
                        <i class="fas fa-question-circle"></i>ajuda</a>
                </h1>
            </div>

            <div class="ui-g-12 pgw--text-right" *ngIf="exibirTutorial">
                <a href="javascript:void(0);" (click)="fecharTutorial()"><i class="fas fa-times"></i>fechar</a>
                <video width="100%" controls autoplay>
                    <source src="https://app.pgwpay.com.br/repo/video/BoletoTutorial.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div>

            <div class="ui-g-12 ui-g-nopad">
                <div class="ui-g-12 ui-fluid" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
                    <p-dropdown [options]="empresasOptions" [(ngModel)]="empresaId" name="empresaId"
                        (onChange)="buscarLicencas()" filter="true" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
            </div>

            <div class="ui-g-12 ui-md-8 ui-lg-6 ui-fluid">
                <label for="licencaId">Contrato *</label>
                <p-dropdown id="licencaId" name="licencaId" [options]="licencas" [(ngModel)]="boleto.licencaId">
                </p-dropdown>
            </div>

            <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
                <label for="descricaoGeral">Descrição geral do serviço/produto *</label>
                <input pInputText id="descricaoGeral" name="descricaoGeral" [(ngModel)]="boleto.descricaoGeral"
                    maxlength="77" />
            </div>

            <div class="ui-g-12">
                <h4>Vencimentos</h4>
            </div>

            <div class="ui-g-12 ui-md-3 ui-lg-2 ui-fluid">
                <p-calendar name="vencimento" [(ngModel)]="vencimento" dateFormat="dd/mm/yy" [minDate]="minDate"
                    [maxDate]="maxDate" [readonlyInput]="true" [inputStyle]="{'text-align': 'center'}" class="amount"
                    placeholder="dd/mm/aaaa" class="amount"></p-calendar>
            </div>
            <div class="ui-g-12 ui-md-3 ui-lg-2 ui-fluid">
                <input pInputText type="text" class="amount col-right" [pKeyFilter]="regExValor" name="valor"
                    [(ngModel)]="valor" placeholder="0,00" />
            </div>
            <div class="ui-g-12 ui-md-3 ui-lg-5 ui-fluid">
                <input pInputText name="descricaoEspecifica" [(ngModel)]="descricaoEspecifica" maxlength="77"
                    placeholder="Descrição específica" class="amount" />
            </div>
            <div class="ui-g-12 ui-md-3 ui-lg-3 ui-fluid">
                <button pButton type="button" label="Adicionar Vencimento" icon="pi pi-plus" class="ui-button-success"
                    (click)="adicionarVencimento()"></button>
            </div>

            <div class="ui-g-12 ui-fluid">
                <p-table [value]="boleto.vencimentos" [responsive]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 150px;">Vencimento</th>
                            <th style="width: 150px;">Valor</th>
                            <th>Descrição Específica</th>
                            <th style="width: 70px;">
                                Excluir
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-vencimento let-rowIndex="rowIndex">
                        <tr>
                            <td class="col-center">
                                {{ vencimento.vencimento | date:'dd/MM/yyyy' }}
                            </td>
                            <td class="col-center">
                                {{ vencimento.valor | currency:'BRL':'symbol':'0.2-2':'pt' }}
                            </td>
                            <td class="col-center" pEditableColumn>
                                {{ vencimento.descricaoEspecifica }}
                            </td>
                            <td class="col-center">
                                <p-button icon="pi pi-trash" (click)="removerVencimento(rowIndex)"
                                    title="Excluir Vencimento">
                                </p-button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class="ui-g-12">
                <h4>Multa e juros</h4>
            </div>

            <div class="ui-g-12 ui-md-6 ui-lg-3 ui-fluid">
                <label for="tpMulta">Tipo de Multa *</label>
                <p-dropdown id="tpMulta" name="tpMulta" [options]="tpMultaOptions" [(ngModel)]="boleto.tpMulta"
                    (onChange)="selectTpMulta()">
                </p-dropdown>
            </div>

            <div class="ui-g-12 ui-md-6 ui-lg-3 ui-fluid">
                <label for="valorMulta">Valor/Percentual Multa *</label>
                <input pInputText type="text" class="amount col-right" [pKeyFilter]="regExValor" name="valorMulta"
                    [(ngModel)]="valorMulta" [disabled]="boleto.tpMulta === '3'" placeholder="0,00" maxlength="8"
                    onFocus="this.select();" />
            </div>

            <div class="ui-g-12 ui-md-6 ui-lg-3 ui-fluid">
                <label for="tpJuros">Tipo de Juros *</label>
                <p-dropdown id="tpJuros" name="tpJuros" [options]="tpJurosOptions" [(ngModel)]="boleto.tpJuros"
                    (onChange)="selectTpJuros()">
                </p-dropdown>
            </div>

            <div class="ui-g-12 ui-md-6 ui-lg-3 ui-fluid">
                <label for="valorJuros">Valor/Percentual Juros *</label>
                <input pInputText type="text" class="amount col-right" [pKeyFilter]="regExValor" name="valorJuros"
                    [(ngModel)]="valorJuros" [disabled]="boleto.tpJuros === '5'" placeholder="0,00" maxlength="8"
                    onFocus="this.select();" />
            </div>

            <div ngModelGroup="customer">

                <div class="ui-g-12">
                    <h4>Cliente</h4>
                </div>

                <div ngModelGroup="document">

                    <div class="ui-g-5 ui-md-4 ui-lg-3 ui-fluid">
                        <label for="type">Tp.Doc. *</label>
                        <p-dropdown id="type" name="type" [options]="types" [(ngModel)]="boleto.customer.document.type">
                        </p-dropdown>
                    </div>

                    <div class="ui-g-5 ui-md-4 ui-lg-3 ui-fluid">
                        <label for="value">Documento *</label>
                        <input pInputText id="value" name="value" [(ngModel)]="boleto.customer.document.value"
                            [maxlength]="boleto.customer.document.type == 'CPF' ? 11 : 14" pKeyFilter="pint"
                            (blur)='buscarComprador()' />
                    </div>

                    <div class="ui-g-1">
                        <button pButton type="button" icon="pi pi-search" style="margin-top: 18px;"
                            (click)="abrirConsultaComprador()"></button>
                    </div>

                </div>

                <div class="ui-g-12 ui-fluid">
                    <label for="name">Nome *</label>
                    <input pInputText id="name" name="name" [(ngModel)]="boleto.customer.name" maxlength="50" />
                </div>

                <div class="ui-g-12 ui-md-6 ui-lg-8 ui-fluid">
                    <label for="email">E-mail *</label>
                    <input pInputText id="email" name="email" [(ngModel)]="boleto.customer.email" maxlength="80" />
                </div>

                <div ngModelGroup="phone">

                    <div class="ui-g-12 ui-md-2 ui-lg-1 ui-fluid">
                        <label for="ddd">DDD *</label>
                        <input pInputText id="ddd" name="ddd" [(ngModel)]="boleto.customer.phone.areaCode" maxlength="2"
                            pKeyFilter="pint" />
                    </div>

                    <div class="ui-g-12 ui-md-4 ui-lg-3 ui-fluid">
                        <label for="telefone">Celular *</label>
                        <input pInputText id="telefone" name="telefone" [(ngModel)]="boleto.customer.phone.number"
                            maxlength="9" pKeyFilter="pint" />
                    </div>

                </div>
                <div ngModelGroup="address">

                    <div class="ui-g-12">
                        <h4>Endereço</h4>
                    </div>

                    <div class="ui-g-12 ui-g-nopad">
                        <div class="ui-g-12 ui-md-3 ui-lg-2 ui-fluid ui-">
                            <label for="postalCode">CEP *</label>
                            <input pInputText id="postalCode" name="postalCode"
                                [(ngModel)]="boleto.customer.address.postalCode" (blur)="buscarCep()" maxlength="8"
                                pKeyFilter="pint" />
                        </div>
                    </div>

                    <div class="ui-g-12 ui-md-10 ui-lg-11 ui-fluid">
                        <label for="street">Logradouro *</label>
                        <input pInputText id="street" name="street" [(ngModel)]="boleto.customer.address.street"
                            maxlength="160" />
                    </div>

                    <div class="ui-g-12 ui-md-2 ui-lg-1 ui-fluid">
                        <label for="number">Número *</label>
                        <input pInputText id="number" name="number" [(ngModel)]="boleto.customer.address.number" #number
                            maxlength="20" />
                    </div>

                    <div class="ui-g-12 ui-md-4 ui-lg-4 ui-fluid">
                        <label for="district">Complemento</label>
                        <input pInputText id="complement" name="complement"
                            [(ngModel)]="boleto.customer.address.complement" maxlength="40" />
                    </div>

                    <div class="ui-g-12 ui-md-3 ui-lg-4 ui-fluid">
                        <label for="district">Bairro *</label>
                        <input pInputText id="district" name="district" [(ngModel)]="boleto.customer.address.district"
                            maxlength="60" />
                    </div>

                    <div class="ui-g-12 ui-md-3 ui-lg-3 ui-fluid">
                        <label for="city">Cidade *</label>
                        <input pInputText id="city" name="city" [(ngModel)]="boleto.customer.address.city"
                            maxlength="60" />
                    </div>

                    <div class="ui-g-12 ui-md-2 ui-lg-1 ui-fluid">
                        <label for="state">UF *</label>
                        <input pInputText id="state" name="state" [(ngModel)]="boleto.customer.address.state"
                            maxlength="2" pKeyFilter="alpha" />
                    </div>

                </div>

            </div>

            <div class="ui-g-12">
                <p-button type="submit" label="Emitir Boleto" icon="fa fa-barcode"></p-button>
            </div>

        </div>

    </form>

    <nav *ngIf="exibirConsultaComprador">
        <p-sidebar [(visible)]="exibirConsultaComprador" [fullScreen]="true">

            <app-comprador-consulta [empresaId]="empresaId" (compradorSelecionado)="compradorSelecionado($event)"
                (fecharConsultaComprador)="fecharConsultaComprador()"></app-comprador-consulta>

        </p-sidebar>
    </nav>

</div>

<p-blockUI [blocked]="blocked">
    <p-progressSpinner class="spinner-center">
    </p-progressSpinner>
</p-blockUI>
