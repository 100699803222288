import { Component, OnInit } from '@angular/core';

import { MessageService } from 'primeng/api';

import { EmpresaService } from 'src/app/empresa/empresa.service';
import { AuthService } from 'src/app/seguranca/auth.service';
import { LicencaService } from 'src/app/licenca/licenca.service';
import { Licenca } from 'src/app/licenca/licenca.model';
import { PaymentLink } from '../payment-link.model';
import { PaymentLinkFilter } from '../payment-link-filter';
import { PaymentLinkService } from '../payment-link.service';

@Component({
  selector: 'app-link-consulta',
  templateUrl: './link-consulta.component.html',
  styleUrls: ['./link-consulta.component.css']
})
export class LinkConsultaComponent implements OnInit {

  jwtPayload: any;
  moeda: string;
  exibirLink: boolean;

  empresasOptions: any[];

  licencas: Licenca[];
  licencasOptions: any[];

  paymentLinkFilter: PaymentLinkFilter;
  paymentLink: PaymentLink;
  paymentLinks: PaymentLink[];

  exibirTutorial = false;

  constructor(
    private authservice: AuthService,
    private empresaService: EmpresaService,
    private licencaService: LicencaService,
    private paymentLinkService: PaymentLinkService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.jwtPayload = this.authservice.getJwtPayload();
    this.paymentLinkFilter = new PaymentLinkFilter(this.jwtPayload.empresaId);

    this.paymentLinkService.linkEvent.subscribe(
      () => {
        this.buscar();
        this.exibirLink = false;
      }
    );

    this.empresaService.getEmpresas().subscribe(
      result => {
        this.empresasOptions = [];
        result.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );
  }

  buscarLicencas() {
    this.paymentLinkFilter.licencaId = undefined;
    this.licencaService.buscarPorEmpresa(this.paymentLinkFilter.empresaId).subscribe(
      licencas => {
        this.licencas = licencas;
        this.licencasOptions = [];
        licencas.forEach(licenca => {
          if (licenca.moeda === 'BRL') {
            this.licencasOptions.push(
              { label: licenca.contrato, value: licenca.id }
            );
            if (this.paymentLinkFilter.licencaId === undefined) {
              this.paymentLinkFilter.licencaId = licenca.id;
              this.moeda = licenca.moeda;
            }
          }
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.buscar();
      }
    );
  }

  buscar() {
    this.paymentLinkService.buscar(this.paymentLinkFilter).subscribe(
      result => {
        this.paymentLinks = result;
        console.log('PaymentLinks', this.paymentLinks);
      },
      error => {
        console.log('Error:', error);
        this.paymentLinks = [];
      }
    );
  }

  onRowSelect(paymentLink: PaymentLink) {
    this.paymentLink = paymentLink;
    this.exibirLink = true;
  }

  criar() {
    if (!this.licencasOptions || this.licencasOptions.length < 1) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Contrato(s) inativo(s)!',
        detail: 'Empresa sem contrato ativo em Real.'
      });
      return;
    }
    this.exibirLink = true;
    this.paymentLink = new PaymentLink(this.paymentLinkFilter.empresaId);
    this.paymentLink.licencaId = this.paymentLinkFilter.licencaId;
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

  copy(link: string) {
    if (link) {
      let listener = (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', link);
        e.preventDefault();
      };
      document.addEventListener('copy', listener);
      document.execCommand('copy');
      document.removeEventListener('copy', listener);

      this.messageService.add({
        severity: 'success',
        summary: 'Link copiado com sucesso!',
        detail: 'Link copiado para área de transferência.'
      });
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Falha ao copiar!',
        detail: 'Link não encontrado.'
      });
    }
  }

  verTutorial() {
    this.exibirTutorial = true;
  }
  fecharTutorial() {
    this.exibirTutorial = false;
  }

  getMelhorLink(paymentLink: PaymentLink) {
    return paymentLink.linkReduzido ? paymentLink.linkReduzido : paymentLink.link;
  }

}
