import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

import { Pedido } from '../pedido.model';
import { AuthService } from 'src/app/seguranca/auth.service';
import { Licenca } from 'src/app/licenca/licenca.model';
import { PedidoService } from '../pedido.service';

@Component({
  selector: 'app-pedido-detalhe',
  templateUrl: './pedido-detalhe.component.html',
  styleUrls: ['./pedido-detalhe.component.css']
})
export class PedidoDetalheComponent implements OnInit {

  @Input() origem: string;
  @Input() pedido: Pedido;
  @Input() licenca: Licenca;
  @Output() onPedidosDependentes = new EventEmitter();
  @Output() onUpdatePedido = new EventEmitter();

  jwtPayload: any;

  toggle = false;

  nameEdit = false;

  nameValue: string;

  exibirDependentes = false;

  blocked = false;

  vigenciaOptions: any[];

  constructor(
    private messageService: MessageService,
    private authService: AuthService,
    private pedidoService: PedidoService,
    private confirmationService: ConfirmationService
  ) {
  }

  ngOnInit() {
    console.log(' ínit detalhe ');
    this.jwtPayload = this.authService.getJwtPayload();
    this.vigenciaOptions = [
      { label: 'Indeterminada', value: 0 },
      { label: 'Persolnalizada', value: 1 },
      { label: '3 meses', value: 3 },
      { label: '6 meses', value: 6 },
      { label: '12 meses', value: 12 },
      { label: '18 meses', value: 18 },
      { label: '24 meses', value: 24 },
      { label: '30 meses', value: 30 },
      { label: '36 meses', value: 36 }
    ];
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

  getBoletoLink(): string {
    if (this.pedido.boleto.linkReduzido) {
      if (this.pedido.boleto.linkReduzido.startsWith('http')) {
        return this.pedido.boleto.linkReduzido;
      } else {
        return 'https://' + this.pedido.boleto.linkReduzido;
      }
    }
    if (this.pedido.boleto.link) {
      return this.pedido.boleto.link;
    }
    if (this.pedido.paymentLink && this.pedido.paymentLink.length > 4) {
      return this.pedido.paymentLink;
    }
    for (const l of this.pedido.linksBoleto) {
      if (l.situacao === 'ATIVO') {
        return l.link;
      }
    }
    return undefined;
  }

  getBoletoBarCode(): string {
    if (this.pedido.boleto.codigoBarras) {
      return this.pedido.boleto.codigoBarras;
    }
    for (const l of this.pedido.linksBoleto) {
      if (l.situacao === 'ATIVO') {
        return l.barcode;
      }
      return undefined;
    }
  }

  getBoletoDtVencto(): Date {
    if (this.pedido.boleto.dtVencto) {
      return this.pedido.boleto.dtVencto;
    }
    for (const l of this.pedido.linksBoleto) {
      if (l.situacao === 'ATIVO') {
        return l.dtVencto;
      }
      return undefined;
    }
  }
  public getTotal(): number {
    return this.pedido.totalItens + this.pedido.extras;
  }

  public getFee(): number {
    return Math.round(this.getTotal() * 0.035 * 100) / 100;
  }

  public getBaseCambio(): number {
    return this.getTotal() - this.getFee();
  }

  public getValorMoedaExtrangeira(): number {
    return Math.round(this.getBaseCambio() / this.pedido.cambio * 100) / 100;
  }

  public getFeePGW(): number {
    return Math.round(this.pedido.totalWC * 0.035 * 100) / 100;
  }

  public getTotalLiquido() {
    return this.pedido.totalWC - this.getFeePGW();
  }

  clickEvent() {
    this.toggle = !this.toggle;
    this.nameEdit = false;
  }

  nameEditEvent() {
    this.nameEdit = !this.nameEdit && this.toggle;
    this.nameValue = this.pedido.compradorNomeCompleto;
  }

  nameSaveEvent() {
    this.blocked = true;
    console.log(this.nameValue);

    if (this.nameValue == undefined || this.nameValue == null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Nome Inválido!',
        detail: 'Informe um nome.'
      });
      this.blocked = false;
      return;
    }

    this.nameValue = this.nameValue.trim();
    if (this.nameValue.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Nome Inválido!',
        detail: 'Informe um Nome com no mínimo 2 caracteres.'
      });
      this.blocked = false;
      return;
    }

    // Atualiza Nome do Comprador
    this.pedidoService.atualizarNomeComprador(this.pedido.id, this.nameValue).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Nome Atualizado!',
          detail: 'Nome atualizado com sucesso.'
        });
        this.pedido.compradorNomeCompleto = this.nameValue.trim().toUpperCase();
        this.onUpdatePedido.emit(this.pedido);
        this.nameEdit = false;
        this.blocked = false;
      },
      error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Falha ao Atualizar Nome!',
          detail: 'O nome não foi atualizado. Favor tentar novamente.'
        });
        this.blocked = false;
      }
    );
  }

  cancelarAssinatura() {
    console.log('Pedido antes:', this.pedido);
    this.confirmationService.confirm({
      message: 'Confirma o cancelamento da assinatura?',
      header: 'Cancelamento de assinatura',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.pedidoService.cancelarAssinatura(this.pedido.id).subscribe(
          pedido => {
            this.pedido = pedido;
            this.messageService.add({
              severity: 'success',
              summary: 'Assinatura Cancelada!',
              detail: 'Assinatura cancelada com sucesso.'
            });
            console.log('Pedido depois:', this.pedido);
          },
          error => {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro!',
              detail: 'Erro ao cancelar assinatura.'
            });
          });
      },
      reject: () => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Assinatura mantida!',
          detail: 'Assinatura não foi cancelada.'
        });
      },
      key: "positionDialog"
    });
  }

  pedidoOrigem() {
    console.log('Pedido Origem');
    this.pedidoService.getPedido(this.pedido.recorrenteParent).subscribe(
      pedido => {
        this.pedido = pedido;
        this.messageService.add({
          severity: 'success',
          summary: 'Pedido de origem!',
          detail: 'Pedido de origem selecionado com sucesso.'
        });
      },
      error => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erro!',
          detail: 'Erro ao selecionar o pedido de origem.'
        });
      });
  }

  showPedidosDependentes() {
    console.log('Pedidos Dependentes');
    if (this.pedido.recorrenteParent) {
      this.pedidoService.getPedido(this.pedido.recorrenteParent).subscribe(
        pedido => {
          this.pedidoService.getPedidosDependentes(this.pedido.recorrenteParent).subscribe(
            pedidos => {

              this.onPedidosDependentes.emit({ pedido, pedidos });

            },
            error => {
              this.messageService.add({
                severity: 'error',
                summary: 'Erro!',
                detail: 'Erro ao selecionar pedidos dependentes.'
              });
            });
        },
        error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro!',
            detail: 'Erro ao selecionar o pedido de origem.'
          });
        });
    } else {
      this.pedidoService.getPedidosDependentes(this.pedido.id).subscribe(
        pedidos => {
          this.onPedidosDependentes.emit({ pedido: this.pedido, pedidos: pedidos });
        },
        error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro!',
            detail: 'Erro ao selecionar pedidos dependentes.'
          });
        });
    }
  }

  estornar() {
    console.log('Estornar');
    this.confirmationService.confirm({
      message: 'Confirma a solicitação de estorno?',
      header: 'Solicitação de Estorno',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.blocked = true;
        console.log('Estornar SIM');
        this.pedidoService.estornar(this.pedido.id).subscribe(
          () => {
            console.log('Estornar Success');
            this.messageService.add({
              severity: 'success',
              summary: 'Estorno Solicitado!',
              detail: 'Estorno solicitado com sucesso.'
            });
            console.log('Pedido depois:', this.pedido);
            this.blocked = false;
          },
          error => {
            console.log('Estornar Error');
            this.messageService.add({
              severity: 'error',
              summary: 'Erro!',
              detail: 'Erro ao solicitar estorno.'
            });
            this.blocked = false;
          });
      },
      reject: () => {
        console.log('Estornar NÃO');
        this.messageService.add({
          severity: 'warn',
          summary: 'Estorno abortado!',
          detail: 'Estorno não solicitado.'
        });
      },
      key: "positionDialog"
    });
  }


}
