import { AfterContentInit, Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';

import { MessageService } from 'primeng/api';

import { Licenca } from 'src/app/licenca/licenca.model';
import { ExtratoService } from '../extrato.service';
import { TransferDTO } from '../transfer-dto.model';

@Component({
  selector: 'app-extrato-transfer',
  templateUrl: './extrato-transfer.component.html',
  styleUrls: ['./extrato-transfer.component.css']
})
export class ExtratoTransferComponent implements OnInit, AfterContentInit {

  blocked: boolean;
  cambioEnabled: boolean;

  destinoLicencaId: string;

  regExValor: RegExp = /[0123456789,]/;
  valor: string;
  cambio: string;
  data: string;
  pedido: string;

  transferDTO: TransferDTO;

  @Input()
  valorMax: number;

  @Input()
  licencasOptions: any[];

  @Input()
  licencaSelecionada: Licenca;

  @ViewChild('transferValor', { static: true })
  transferValorField: ElementRef;

  @ViewChild('transferCambio', { static: false })
  transferCambioField: ElementRef;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private extratoService: ExtratoService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.blocked = false;
    this.valor = '0,00';
    this.transferDTO = new TransferDTO();
  }

  ngAfterContentInit(): void {
    console.log('this.valor', this.valor);
    this.valor = this.valorMax.toFixed(2).replace('.', ',');

    this.transferDTO.origem = this.licencaSelecionada.id;
    this.transferDTO.destino = this.licencasOptions[0].value;
    this.selecionarDestino();
  }

  selecionarDestino() {
    for (const licencaOptional of this.licencasOptions) {
      if (licencaOptional.value === this.transferDTO.destino) {
        if (this.licencaSelecionada.moeda === licencaOptional.moeda) {
          this.cambioEnabled = false;
          this.changeDetector.detectChanges();
        } else {
          this.cambioEnabled = true;
          this.changeDetector.detectChanges();
        }
      }
    }
  }

  transfer() {
    this.blocked = true;

    // Valida Conta Origem
    console.log('origem', this.transferDTO.origem);
    if (this.transferDTO.origem === undefined || this.transferDTO.origem === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Conta Origem não selecionada.'
      });
      this.blocked = false;
      return;
    }

    // Valida Conta Destino
    console.log('destino', this.transferDTO.destino);
    if (this.transferDTO.destino === undefined || this.transferDTO.destino === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Conta Destino não selecionada.'
      });
      this.blocked = false;
      return;
    }

    // Valida Valor da Transferência
    console.log('valor', this.valor);
    if (this.valor === undefined || this.valor === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço inválido.'
      });
      this.transferValorField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    let valorSplit = this.valor.split(',');
    if (valorSplit.length > 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço inválido.'
      });
      this.transferValorField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (valorSplit.length === 2 && valorSplit[1].length > 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço com mais de 2 digitos decimais.'
      });
      this.valor = `${valorSplit[0]},${valorSplit[1].substring(0, 2)}`;
      this.transferValorField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    this.transferDTO.valor = parseFloat(this.valor.replace(',', '.'));
    console.log('this.transferDTO.valor', this.transferDTO.valor);
    if (this.transferDTO.valor === undefined || this.transferDTO.valor === null || Number.isNaN(this.transferDTO.valor) || this.transferDTO.valor <= 0.00 || this.transferDTO.valor > this.valorMax) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Valor indefinido ou saldo insuficiente.'
      });
      this.transferValorField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    // Valida Câmbio
    if (this.cambioEnabled) {
      console.log('cambio', this.cambio);
      if (this.cambio === undefined || this.cambio === null) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atenção!',
          detail: 'Câmbio inválido.'
        });
        this.transferCambioField.nativeElement.focus();
        this.blocked = false;
        return;
      }
      let cambioSplit = this.cambio.split(',');
      if (cambioSplit.length > 2) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atenção!',
          detail: 'Câmbio inválido.'
        });
        this.transferCambioField.nativeElement.focus();
        this.blocked = false;
        return;
      }
      if (cambioSplit.length === 2 && cambioSplit[1].length > 6) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atenção!',
          detail: 'Câmbio com mais de 6 digitos decimais.'
        });
        this.cambio = `${cambioSplit[0]},${cambioSplit[1].substring(0, 6)}`;
        this.transferCambioField.nativeElement.focus();
        this.blocked = false;
        return;
      }
      this.transferDTO.cambio = parseFloat(this.cambio.replace(',', '.'));
      console.log('this.transferDTO.cambio', this.transferDTO.cambio);
      if (this.transferDTO.cambio === undefined || this.transferDTO.cambio === null || Number.isNaN(this.transferDTO.cambio) || this.transferDTO.cambio <= 0.00) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Atenção!',
          detail: 'Câmbio indefinido.'
        });
        this.transferCambioField.nativeElement.focus();
        this.blocked = false;
        return;
      }

      if (this.licencaSelecionada.moeda == 'BRL') {
        console.log('data', this.transferDTO.data)
        console.log('pedido', this.transferDTO.pedido)

        // Valida Data
        if (!this.transferDTO.data && this.transferDTO.pedido) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Atenção!',
            detail: 'Informe a Data.'
          });
          this.blocked = false;
          return;
        }

        // Valida Pedido
        if (this.transferDTO.data && !this.transferDTO.pedido) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Atenção!',
            detail: 'Informe o Pedido.'
          });
          this.blocked = false;
          return;
        }
      }
    }

    this.extratoService.transfer(this.transferDTO).subscribe(
      transfer => {
        console.log('Success', transfer);
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso!',
          detail: 'Transferência efetuada com sucesso.'
        });
        this.extratoService.transferEvent.emit();
        this.blocked = false;
      },
      error => {
        console.log('Error :', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao efetuar a transerência!',
          detail: error.error.msg
        });
        this.transferValorField.nativeElement.focus();
        this.blocked = false;
      }
    );
  }

}
