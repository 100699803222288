import { Customer } from './customer.model';

export class BoletoAvulso {

  constructor() {
    this.customer = new Customer();
    this.recorrente = false;
    this.recorrenteVigencia = false;
  }

  licencaId: string;
  usuarioId: string;
  reference: string;
  firstDueDate: Date;
  numberOfPayments: number;
  periodicity: string;
  amount: string;
  recorrente: boolean;
  recorrenteVigencia: boolean;
  recorrenteVigenciaMes: number;
  recorrenteVigenciaAno: number;
  instructions: string;
  description: string;
  customer: Customer;

}
