<div class="pgw--container">

  <div class="ui-g">

    <div class="ui-g-12 border-botton">
      <h1>Links de Pagamento
        <a href="javascript:void(0);" (click)="verTutorial()" [hidden]="exibirTutorial">
          <i class="fas fa-question-circle"></i>ajuda</a>
      </h1>
    </div>

    <div class="ui-g-12 pgw--text-right" *ngIf="exibirTutorial">
      <a href="javascript:void(0);" (click)="fecharTutorial()"><i class="fas fa-times"></i>fechar</a>
      <video width="100%" controls autoplay>
        <source src="https://app.pgwpay.com.br/repo/video/LinkTutorial.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>

  </div>

  <div class="ui-g" style="padding: 20px 0px;">

    <div class="ui-g-12 ui-fluid" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
      <p-dropdown [options]="empresasOptions" [(ngModel)]="paymentLinkFilter.empresaId" (onChange)="buscarLicencas()" filter="true" resetFilterOnHide="true">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-fluid">
      <p-dropdown [options]="licencasOptions" [(ngModel)]="paymentLinkFilter.licencaId" pTooltip="Contratos"
        tooltipPosition="top" (onChange)="buscar()">
      </p-dropdown>
    </div>

    <div class="ui-g-12">
      <button pButton label="Criar Link" icon="pi pi-plus" class="ui-button-primary" (click)="criar()"
        *ngIf="hasPermission('LINK_CRIAR')"></button>
    </div>

    <div class="ui-g-12 ui-fluid">
      <p-table [value]="paymentLinks" [responsive]="true" selectionMode="single"
        (onRowSelect)="onRowSelect($event.data)">
        <ng-template pTemplate="header">
          <tr>
            <th>Produto / Serviço</th>
            <th style="width: 120px;">Link</th>
            <th style="width: 70px;">Ativo</th>
            <th style="width: 100px;">Preço</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-paymentLink>
          <tr [pSelectableRow]="paymentLink">
            <td class="pgw--text-left">{{ paymentLink.descricao }}</td>
            <td class="pgw--text-center">
              <button pButton type="button" label="copiar" icon="far fa-copy"
                class="ui-button-rounded ui-button-primary" (click)="copy(getMelhorLink(paymentLink))"></button>
              <a *ngIf="false" href="javascript:void(0);" (click)="copy(getMelhorLink(paymentLink))">copiar link</a>
            </td>
            <td class="pgw--text-center">{{ paymentLink.ativo ? 'SIM' : 'NÃO' }}</td>
            <td class="pgw--text-right">{{ paymentLink.valorUnitario | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
          </tr>
        </ng-template>

      </p-table>
    </div>

  </div>

</div>

<nav *ngIf="exibirLink">
  <p-sidebar [(visible)]="exibirLink" position="left" styleClass="ui-sidebar-md">
    <app-link-cadastro [paymentLink]="paymentLink" [licencasOptions]="licencasOptions"></app-link-cadastro>
  </p-sidebar>
</nav>
