import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Usuario } from './usuario.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  usuarioEvent = new EventEmitter();

  constructor(private http: HttpClient) { }

  criar(usuario: Usuario): Observable<Usuario> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<Usuario>(`${environment.apiUrl}/usuarios`, usuario, { headers });
  }

  atualizar(usuario: Usuario): Observable<Usuario> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<Usuario>(`${environment.apiUrl}/usuarios`, usuario, { headers });
  }

  excluir(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/usuarios/${id}`);
  }

  buscarPorEmpresa(empresaId: string): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(`${environment.apiUrl}/usuarios/empresa/${empresaId}`);
  }

  buscarPorEmail(email: string): Observable<Usuario> {
    return this.http.get<Usuario>(`${environment.apiUrl}/usuarios/email/${email}`);
  }

  buscarPorEmpresaAndGrupo(empresaId: string, grupoId: string): Observable<Usuario[]> {
    return this.http.get<Usuario[]>(`${environment.apiUrl}/usuarios/empresa/grupo/${empresaId}/${grupoId}`);
  }

}
