import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Licenca } from 'src/app/licenca/licenca.model';
import { LicencaService } from 'src/app/licenca/licenca.service';
import { PedidoDTO } from 'src/app/pedido/pedido-dto';
import { PedidoFilter } from 'src/app/pedido/pedido-filter';
import { Pedido } from 'src/app/pedido/pedido.model';
import { PedidoService } from 'src/app/pedido/pedido.service';
import { AuthService } from 'src/app/seguranca/auth.service';
import { RecorrenciaCancelDTO } from '../recorrencia-cancel-dto.model';
import { Recorrencia } from '../recorrencia.model';
import { RecorrenteConsultaService } from '../recorrente-consulta.service';

@Component({
  selector: 'app-recorrente-detalhe',
  templateUrl: './recorrente-detalhe.component.html',
  styleUrls: ['./recorrente-detalhe.component.css']
})
export class RecorrenteDetalheComponent implements OnInit {

  @Input() recorrencia: Recorrencia;
  @Output() onClose = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onShowPedido = new EventEmitter();
  @Output() onRecorrenciaBlocked = new EventEmitter();

  jwtPayload: any;
  pedidosDTO: PedidoDTO[] = [];

  blocked = false;

  constructor(
    private authService: AuthService,
    private pedidoService: PedidoService,
    private licencaService: LicencaService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private recorrenteConsultaService: RecorrenteConsultaService
  ) { }

  ngOnInit(): void {
    this.jwtPayload = this.authService.getJwtPayload();
    const pedidoFilter = new PedidoFilter(this.recorrencia.empresaId);
    pedidoFilter.licencaId = this.recorrencia.licencaId;
    pedidoFilter.pedidoNumero = this.recorrencia.pedidoNumero.toString();
    pedidoFilter.environment = 'production';
    if (this.recorrencia.comprador.dataNascimento === '0/0/0') {
      this.recorrencia.comprador.dataNascimento = '';
    }
    this.pedidoService.getPedidosDTO(pedidoFilter).subscribe(
      pedidosDTO => {
        this.pedidosDTO = pedidosDTO;
        this.onRecorrenciaBlocked.emit(false);
      },
      err => {
        console.log('Error:', err);
        this.onRecorrenciaBlocked.emit(false);
      }
    );
  }

  fechar() {
    this.onClose.emit();
  }

  cancelarRecorrencia() {
    console.log('Cancelar recorrencia');
    this.confirmationService.confirm({
      message: 'Confirma o cancelamento da recorrência?',
      header: 'Solicitação de Cancelamento',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.blocked = true;
        console.log('Cancelar SIM');
        const recorrenciaCancelDTO = new RecorrenciaCancelDTO();
        recorrenciaCancelDTO.recorrenciaId = this.recorrencia.id;
        recorrenciaCancelDTO.usuarioId = this.jwtPayload.usuarioId;
        if (this.jwtPayload.empresaId === '5d5ffff07396f1033f599e0d') {
          recorrenciaCancelDTO.usuarioNome = 'PGW';
        } else {
          recorrenciaCancelDTO.usuarioNome = this.jwtPayload.usuarioNome;
        }
        console.log('recorrenciaCancelDTO', recorrenciaCancelDTO);
        this.recorrenteConsultaService.canelarRecorrencia(recorrenciaCancelDTO).subscribe(
          result => {
            this.recorrencia = result;
            console.log('this.recorrencia', this.recorrencia);
            this.messageService.add({
              severity: 'success',
              summary: 'Recorrência cancelada!',
              detail: 'Recorrência cancelada com sucesso.'
            });
            this.onCancel.emit(this.recorrencia.id);
            this.blocked = false;
          },
          error => {
            console.log('Error:', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Erro ao cancelar!',
              detail: 'Erro ao cancelar a recorrência.'
            });
            this.blocked = false;
          }
        );
      },
      reject: () => {
        console.log('Cancelar NÃO');
        this.messageService.add({
          severity: 'warn',
          summary: 'Cancelamento abortado!',
          detail: 'Cancelamento da recorrência não solicitado.'
        });
      },
      key: "positionDialog"
    });
  }

  onRowSelect(pedidoDTO: PedidoDTO) {
    this.onShowPedido.emit(pedidoDTO);
  }

}
