import { HttpParams } from '@angular/common/http';

export class PedidoFilter {

  empresaId: string;

  moeda: string;
  environment: string;
  origem: string;
  autorizacao: string;
  licencaId: string;
  pedidoNumero: string;
  comprador: string;
  recorrente: boolean;
  recorrenteRoot: boolean;
  recorrenteAtivo: boolean;
  rangeDates: Date[];
  selectedDias: number[] = new Array();
  selectedSituacoes: number[] = new Array();
  selectedFormasPagto: string[] = new Array();
  selectedInstituicoes: string[] = new Array();
  limit: number;

  diasOptions: any[];
  situacoesOptions: any[];
  formasPagtoOptions: any[];
  environmentOptions: any[];
  origemOptions: any[];
  instituicoesOptions: any[];
  recorrenteOptions: any[];
  situacaoOptions: any[];
  topOptions: any[];
  tpPeriodoOptions: any[];

  tpPeriodo: string;

  constructor(empresaId: string) {
    this.empresaId = empresaId;
    this.environment = 'production';
    this.tpPeriodo = 'E';

    this.situacoesOptions = [
      { label: 'Pedido efetuado', value: 0, rgb: 'olive' },
      { label: 'Aguardando pagamento', value: 1, rgb: 'gray' },
      { label: 'Em análise', value: 2, rgb: 'chocolate' },
      { label: 'Paga', value: 3, rgb: '#0f9b0f' },
      { label: 'Disponível', value: 4, rgb: 'blue' },
      { label: 'Em disputa', value: 5, rgb: 'orange' },
      { label: 'Devolvida', value: 6, rgb: 'purple' },
      { label: 'Cancelada', value: 7, rgb: 'red' },
      { label: 'Debitado', value: 8, rgb: 'limegreen' },
      { label: 'Retenção temporária', value: 9, rgb: 'brown' },
      { label: 'Resgate', value: 10, rgb: 'midnightblue' },
      { label: 'Liquidado', value: 11, rgb: '#c55a11' },
      { label: 'Pedido falhou', value: 12, rgb: '#c70039' },
      { label: 'Antecipado', value: 13, rgb: '#541743' }
    ];

    this.formasPagtoOptions = [
      { label: 'Boleto', value: 'BOLETO' },
      { label: 'Cartão', value: 'CARTÃO' },
      { label: 'Débito', value: 'DÉBITO' },
      { label: 'Pix', value: 'PIX' }
    ];

    this.instituicoesOptions = [
      { label: 'Bin', value: 'BIN' },
      { label: 'Bradesco', value: 'BANCO BRADESCO' },
      { label: 'Cielo', value: 'CIELO' },
      { label: 'Getnet', value: 'GETNET' },
      { label: 'Inter', value: 'BANCO INTER' },
      { label: 'Itaú', value: 'BANCO ITAÚ' },
      { label: 'Pagseguro', value: 'PAGSEGURO' },
      { label: 'Rede', value: 'REDE' },
      { label: 'Safra', value: 'SAFRA' },
      { label: 'Santander', value: 'SANTANDER' },
      { label: 'Stone', value: 'STONE' }
    ];

    this.environmentOptions = [
      { label: 'Produção', value: 'production' },
      { label: 'Teste', value: 'sandbox' },
      { label: 'Log', value: 'log' },
      { label: 'Todos', value: undefined }
    ];

    this.origemOptions = [
      { label: 'Todos', value: undefined },
      { label: 'Woo Commerce', value: 'WC' },
      { label: 'PGW', value: 'PGW' },
      { label: 'POS', value: 'POS' }
    ];

    this.recorrenteOptions = [
      { label: 'Convencional', value: false },
      { label: 'Recorrente', value: true },
      { label: 'Todos', value: undefined }
    ];

    this.diasOptions = [
      { label: '01', value: '1' },
      { label: '02', value: '2' },
      { label: '03', value: '3' },
      { label: '04', value: '4' },
      { label: '05', value: '5' },
      { label: '06', value: '6' },
      { label: '07', value: '7' },
      { label: '08', value: '8' },
      { label: '09', value: '9' },
      { label: '10', value: '10' },
      { label: '11', value: '11' },
      { label: '12', value: '12' },
      { label: '13', value: '13' },
      { label: '14', value: '14' },
      { label: '15', value: '15' },
      { label: '16', value: '16' },
      { label: '17', value: '17' },
      { label: '18', value: '18' },
      { label: '19', value: '19' },
      { label: '20', value: '20' },
      { label: '21', value: '21' },
      { label: '22', value: '22' },
      { label: '23', value: '23' },
      { label: '24', value: '24' },
      { label: '25', value: '25' },
      { label: '26', value: '26' },
      { label: '27', value: '27' },
      { label: '28', value: '28' },
      { label: '29', value: '29' },
      { label: '30', value: '30' },
      { label: '31', value: '31' }
    ];

    this.situacaoOptions = [
      { label: 'Ativos', value: true },
      { label: 'Cancelados', value: false },
      { label: 'Todos', value: undefined }
    ];

    this.topOptions = [
      { label: 'Top 5', value: 5 },
      { label: 'Top 10', value: 10 },
      { label: 'Top 20', value: 20 },
      { label: 'Top 50', value: 50 },
      { label: 'Top 100', value: 100 },
      { label: 'Top 200', value: 200 },
      { label: 'Top 500', value: 500 },
      { label: 'Top 1000', value: 1000 },
      { label: 'Todos', value: undefined }
    ];

    this.tpPeriodoOptions = [
      { label: 'Vencimento', value: 'V' },
      { label: 'Pagamento', value: 'P' },
      { label: 'Emissão', value: 'E' }
    ];
  }

  initRangeDates() {
    const startDate = new Date(new Date().setDate(new Date().getDate() - 1));
    this.rangeDates = [];
    this.rangeDates.push(startDate);
    this.rangeDates.push(null);
  }

  initDtVenctos() {
    const startDate = new Date();
    const endDate = new Date(new Date().setDate(new Date().getDate() + 1));
    this.rangeDates = [];
    this.rangeDates.push(startDate);
    this.rangeDates.push(endDate);
    this.tpPeriodo = 'V';
  }

  clear() {
    this.pedidoNumero = undefined;
    this.comprador = undefined;
    this.autorizacao = undefined;
    this.rangeDates = undefined;
    this.limit = undefined;
    this.selectedSituacoes = [];
    this.selectedFormasPagto = [];
    this.selectedInstituicoes = [];
  }

  getSearchParams() {
    let params = new HttpParams();

    if (this.empresaId !== undefined && this.empresaId !== null) {
      params = params.set('empresaId', this.empresaId);
    }

    if (this.moeda !== undefined && this.moeda !== null) {
      params = params.set('moeda', this.moeda);
    }

    if (this.environment !== undefined && this.environment !== null) {
      params = params.set('environment', this.environment);
    }

    if (this.origem !== undefined && this.origem !== null) {
      params = params.set('origem', this.origem);
    }

    if (this.autorizacao !== undefined && this.autorizacao !== null) {
      params = params.set('autorizacao', this.autorizacao);
    }

    if (this.licencaId !== undefined && this.licencaId !== null) {
      params = params.set('licencaId', this.licencaId);
    }

    if (this.pedidoNumero !== undefined && this.pedidoNumero !== undefined) {
      params = params.set('pedidoNumero', this.pedidoNumero);
    }

    if (this.comprador !== undefined && this.comprador !== undefined) {
      params = params.set('comprador', this.comprador);
    }

    if (this.rangeDates !== undefined && this.rangeDates[0] != null) {
      const dtStr = this.rangeDates[0].toISOString().substr(0, 10);
      if (this.tpPeriodo === 'E') {
        params = params.set('dtInicio', dtStr);
      } else if (this.tpPeriodo === 'P') {
        params = params.set('dtPagtoInicio', dtStr);
      } else {
        params = params.set('dtVenctoInicio', dtStr);
      }
    }

    if (this.rangeDates !== undefined && this.rangeDates[1] != null) {
      const dtStr = this.rangeDates[1].toISOString().substr(0, 10);
      if (this.tpPeriodo === 'E') {
        params = params.set('dtFim', dtStr);
      } else if (this.tpPeriodo === 'P') {
        params = params.set('dtPagtoFim', dtStr);
      } else {
        params = params.set('dtVenctoFim', dtStr);
      }
    }

    if (this.selectedFormasPagto !== undefined && this.selectedFormasPagto !== null && this.selectedFormasPagto.length > 0) {
      params = params.set('formasPagto', this.selectedFormasPagto.toString());
    }

    if (this.selectedInstituicoes !== undefined && this.selectedInstituicoes !== null && this.selectedInstituicoes.length > 0) {
      params = params.set('instituicoes', this.selectedInstituicoes.toString());
    }

    if (this.selectedSituacoes !== undefined && this.selectedSituacoes !== null && this.selectedSituacoes.length > 0) {
      params = params.set('situacoes', this.selectedSituacoes.toString());
    }

    if (this.selectedDias !== undefined && this.selectedDias !== null && this.selectedDias.length > 0) {
      params = params.set('recorrenteDias', this.selectedDias.toString());
    }

    if (this.recorrente !== undefined && this.recorrente !== null) {
      params = params.set('recorrente', this.recorrente ? 'true' : 'false');
    }

    if (this.recorrenteRoot !== undefined && this.recorrenteRoot !== null) {
      params = params.set('recorrenteRoot', this.recorrenteRoot ? 'true' : 'false');
    }

    if (this.recorrenteAtivo !== undefined && this.recorrenteAtivo !== null) {
      params = params.set('recorrenteAtivo', this.recorrenteAtivo ? 'true' : 'false');
    }

    if (this.limit !== undefined && this.limit !== null && this.limit > 0) {
      params = params.set('limit', this.limit.toString());
    }

    return params;
  }

  isFilterSelected() {
    console.log('isFilterSelected');
    if (this.pedidoNumero !== undefined && this.pedidoNumero !== null && this.pedidoNumero.trim().length > 0) {
      console.log('this.pedidoNumero');
      return true;
    }
    if (this.rangeDates !== undefined && this.rangeDates !== null && this.rangeDates.length > 0) {
      console.log('this.rangeDates');
      return true;
    }
    if (this.selectedSituacoes !== undefined && this.selectedSituacoes !== null && this.selectedSituacoes.length > 0) {
      console.log('this.selectedSituacoes');
      return true;
    }
    if (this.selectedFormasPagto !== undefined && this.selectedFormasPagto !== null && this.selectedFormasPagto.length > 0) {
      console.log('this.selectedFormasPagto');
      return true;
    }
    if (this.selectedInstituicoes !== undefined && this.selectedInstituicoes !== null && this.selectedInstituicoes.length > 0) {
      console.log('this.selectedInstituicoes');
      return true;
    }
    if (this.comprador !== undefined && this.comprador !== null && this.comprador.trim().length > 0) {
      console.log('this.comprador');
      return true;
    }
    if (this.autorizacao !== undefined && this.autorizacao !== null && this.autorizacao.trim().length > 0) {
      console.log('this.autorizacao');
      return true;
    }
    return false;
  }

}
