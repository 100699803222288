import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';

import { MessageService } from 'primeng/api';

import { PaymentLink } from '../payment-link.model';
import { PaymentLinkService } from '../payment-link.service';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'app-link-cadastro',
  templateUrl: './link-cadastro.component.html',
  styleUrls: ['./link-cadastro.component.css']
})
export class LinkCadastroComponent implements OnInit {

  @Input() paymentLink: PaymentLink;
  @Input() licencasOptions: any[];

  @ViewChild('linkContrato', { static: true }) contratoField: ElementRef;
  @ViewChild('linkDescricao', { static: true }) descricaoField: ElementRef;
  @ViewChild('linkPreco', { static: true }) precoField: ElementRef;
  @ViewChild('textToCopy', { static: true }) textToCopyField: ElementRef;
  @ViewChild('parcelamentoCartaoElRef', { static: true }) parcelamentoCartaoField: Dropdown;


  parcelasOptions: any;
  regExValor: RegExp = /[0123456789,]/;
  valor: string;

  blocked: boolean;

  tpMultaOptions: any[];
  tpJurosOptions: any[];
  valorMulta: string;
  valorJuros: string;

  constructor(
    private paymentLinkService: PaymentLinkService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.blocked = false;
    this.valor = this.paymentLink.valorUnitario.toFixed(2).replace('.', ',');
    this.valorMulta = this.paymentLink.vlrMulta.toFixed(2).replace('.', ',');
    this.valorJuros = this.paymentLink.vlrJuros.toFixed(2).replace('.', ',');
    this.parcelasOptions = [];
    this.changeValor();

    // this.tpMultaOptions = [
    //   { label: 'ISENTO', value: '3' },
    //   { label: 'VALOR', value: '1' },
    //   { label: 'PERCENTUAL', value: '2' }
    // ];

    // this.tpJurosOptions = [
    //   { label: 'ISENTO', value: '5' },
    //   { label: 'VALOR AO DIA', value: '6' },
    //   { label: 'PERCENTUAL AO DIA', value: '7' },
    //   { label: 'PERCENTUAL AO MÊS', value: '8' }
    // ];

    this.tpMultaOptions = [
      { label: 'ISENTO', value: 'ISENTO' },
      { label: 'VALOR', value: 'VALOR' },
      { label: 'PERCENTUAL', value: 'PERCENTUAL' }
    ];

    this.tpJurosOptions = [
      { label: 'ISENTO', value: 'ISENTO' },
      { label: 'VALOR AO DIA', value: 'VALOR_DIA' },
      { label: 'PERCENTUAL AO MÊS', value: 'PERCENTUAL_MES' }
    ];
  }

  salvar() {
    this.blocked = true;

    // validar
    if (this.paymentLink.descricao === undefined || this.paymentLink.descricao === null || 0 === this.paymentLink.descricao.trim().length) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Informe o produto ou serviço que deseja vender.'
      });
      this.descricaoField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    let valorSplit = this.valor.split(',');
    if (this.valor === undefined || this.valor === null || valorSplit.length > 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço inválido.'
      });
      this.precoField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (valorSplit.length === 2 && valorSplit[1].length > 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço com mais de 2 digitos decimais.'
      });
      this.precoField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    this.paymentLink.valorUnitario = parseFloat(this.valor.replace(',', '.'));
    if (this.paymentLink.valorUnitario === undefined || this.paymentLink.valorUnitario === null || isNaN(this.paymentLink.valorUnitario)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço inválido.'
      });
      this.precoField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.paymentLink.valorUnitario < 5.00 ) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço mínimo R$ 5,00.'
      });
      this.precoField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    if (!this.paymentLink.ativarBoleto && !this.paymentLink.ativarCartao) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Nenhuma forma de recebimento ativada.'
      });
      this.blocked = false;
      return;
    }

    if (this.paymentLink.ativarBoleto) {

      if (this.paymentLink.tpMulta === '3') {
        this.valorMulta = '0,00';
        this.paymentLink.vlrMulta = 0.00;
      } else {
        if (this.valorMulta === undefined || this.valorMulta === null) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Valor Multa Inválido!',
            detail: 'Informe o valor da Multa.'
          });
          this.valorMulta = '0,00';
          this.paymentLink.vlrMulta = 0.00;
          this.blocked = false;
          return;
        }
        let cValorMulta = 0;
        for (const c of this.valorMulta.toString().split('')) {
          if (c === ',') {
            cValorMulta++;
          }
          if (cValorMulta > 1) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Valor Multa Inválido!',
              detail: 'Apenas um vírgula é permitida.'
            });
            this.valorMulta = '0,00';
            this.paymentLink.vlrMulta = 0.00;
            this.blocked = false;
            return;
          }
        }
        this.paymentLink.vlrMulta = Number(this.valorMulta.toString().replace(',', '.'));
        if (this.paymentLink.vlrMulta <= 0.00) {
          this.valorMulta = '0,00';
          this.paymentLink.vlrMulta = 0.00;
          this.blocked = false;
          return;
        }
      }

      if (this.paymentLink.tpJuros === '5') {
        this.valorJuros = '0,00';
        this.paymentLink.vlrJuros = 0.00;
      } else {
        if (this.valorJuros === undefined || this.valorJuros === null) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Valor Juros Inválido!',
            detail: 'Informe o valor do Juros.'
          });
          this.valorJuros = '0,00';
          this.paymentLink.vlrJuros = 0.00;
          this.blocked = false;
          return;
        }
        let cValorJuros = 0;
        for (const c of this.valorJuros.toString().split('')) {
          if (c === ',') {
            cValorJuros++;
          }
          if (cValorJuros > 1) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Valor Juros Inválido!',
              detail: 'Apenas um vírgula é permitida.'
            });
            this.valorJuros = '0,00';
            this.paymentLink.vlrJuros = 0.00;
            this.blocked = false;
            return;
          }
        }
        this.paymentLink.vlrJuros = Number(this.valorJuros.toString().replace(',', '.'));
        if (this.paymentLink.vlrJuros <= 0.00) {
          this.valorJuros = '0,00';
          this.paymentLink.vlrJuros = 0.00;
          this.blocked = false;
          return;
        }
      }

    } else {
      this.paymentLink.tpMulta = '3';
      this.paymentLink.vlrMulta = 0.00;
      this.paymentLink.tpJuros = '5';
      this.paymentLink.vlrJuros = 0.00;
    }

    console.log("Salvar link:");
    console.log(this.paymentLink);
    // Cria ou atualiza
    if (this.paymentLink.id) {
      this.atualizar();
    } else {
      this.criar();
    }

  }

  criar() {
    this.paymentLinkService.criar(this.paymentLink).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso!',
          detail: 'Link de pagamento criado com sucesso.'
        });
        this.paymentLinkService.linkEvent.emit();
        this.blocked = false;
      },
      error => {
        console.log('Error :', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao criar link de pagamento!',
          detail: 'Erro ao criar link de pagamento.'
        });
        this.descricaoField.nativeElement.focus();
        this.blocked = false;
      }
    );
  }

  atualizar() {
    this.paymentLinkService.atualizar(this.paymentLink).subscribe(
      data => {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso!',
          detail: 'Link de pagamento atualizado com sucesso.'
        });
        this.paymentLinkService.linkEvent.emit();
        this.blocked = false;
      },
      error => {
        console.log('Error:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao atualizar conta!',
          detail: 'Erro ao atualizar conta.'
        });
        this.blocked = false;
      }
    );
  }

  copyLinkReduzido() {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.getMelhorLink(this.paymentLink));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);

    this.messageService.add({
      severity: 'success',
      summary: 'Link copiado com sucesso!',
      detail: 'Link copiado para área de transferência.'
    });
  }

  changeValor() {
    console.log('changeValor');
    let valorSplit = this.valor.split(',');
    if (this.valor === undefined || this.valor === null || valorSplit.length > 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço inválido.'
      });
      this.precoField.nativeElement.focus();
      return;
    }
    if (valorSplit.length === 2 && valorSplit[1].length > 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço com mais de 2 digitos decimais.'
      });
      this.precoField.nativeElement.focus();
      return;
    }
    this.paymentLink.valorUnitario = parseFloat(this.valor.replace(',', '.'));
    if (this.paymentLink.valorUnitario === undefined || this.paymentLink.valorUnitario === null || isNaN(this.paymentLink.valorUnitario)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço inválido.'
      });
      this.precoField.nativeElement.focus();
      return;
    }

    this.parcelasOptions = [];
    if (this.paymentLink.valorUnitario >= 10) {
      this.parcelasOptions.push({ label: 'Não, somente a vista.', value: 1 });
      for (let i = 2; i <= 12; i++) {
        if (this.paymentLink.valorUnitario / i < 5.00) {
          if (this.paymentLink.parcelamentoCartao >= i) {
            this.paymentLink.parcelamentoCartao = i - 1;
          }
          break;
        }
        this.parcelasOptions.push({ label: `No máximo, ${i} parcelas.`, value: i });
      }
    } else {
      this.paymentLink.parcelamentoCartao = 1;
    }
  }

  selectTpMulta() {
    if (this.paymentLink.tpMulta === '3') {
      this.valorMulta = '0,00';
      this.paymentLink.vlrMulta = 0.00;
    }
  }

  selectTpJuros() {
    if (this.paymentLink.tpJuros === '5') {
      this.valorJuros = '0,00';
      this.paymentLink.vlrJuros = 0.00;
    }
  }

  selectAtivarBoleto() {
    console.log('this.paymentLink.ativarBoleto', this.paymentLink.ativarBoleto);
    if (!this.paymentLink.ativarBoleto) {
      this.paymentLink.tpMulta = '3';
      this.paymentLink.vlrMulta = 0.00;
      this.paymentLink.tpJuros = '5';
      this.paymentLink.vlrJuros = 0.00;
      this.valorMulta = '0,00';
      this.valorJuros = '0,00';
    }
  }

  getMelhorLink(paymentLink: PaymentLink) {
    return paymentLink.linkReduzido ? paymentLink.linkReduzido : paymentLink.link;
  }

}
