export class Conta {

  id: string;
  banco: string;
  agencia: string;
  conta: string;
  titular: string;
  cpfCnpj: string;
  empresaId: string;

  constructor(empresaId: string) {
    this.empresaId = empresaId;
  }

}
