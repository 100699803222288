import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-trocar-senha',
  templateUrl: './trocar-senha.component.html',
  styleUrls: ['./trocar-senha.component.css']
})
export class TrocarSenhaComponent implements OnInit {

  senhaAtual: string;
  novaSenha: string;
  confirmarSenha: string;

  blocked = false;

  @ViewChild('senhaAtualField', { static: true }) senhaAtualField: ElementRef;
  @ViewChild('novaSenhaField', { static: true }) novaSenhaField: ElementRef;
  @ViewChild('confirmarSenhaField', { static: true }) confirmarSenhaField: ElementRef;

  constructor(
    private authService: AuthService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    console.log('Trocar Senha Init');
    console.log('Trocar Senha Init | this.authService.senhaAtual:', this.authService.senhaAtual);
    this.authService.trocarSenhaFocusEvent.subscribe(() => {
      console.log('Trocar Senha Focus Event | this.authService.senhaAtual:', this.authService.senhaAtual);
      this.reset();
    });
  }

  trocarSenha() {
    this.blocked = true;

    console.log('Trocar Senha trocarSenha() | this.authService.senhaAtual:', this.authService.senhaAtual);

    if (this.senhaAtual === undefined) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao trocar senha!',
        detail: 'Digite a senha atual.'
      });
      this.reset();
      return;
    }
    if (this.senhaAtual !== this.authService.senhaAtual) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao trocar senha!',
        detail: 'Senha atual inválida.'
      });
      this.reset();
      return;
    }

    if (this.novaSenha === undefined) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao trocar senha!',
        detail: 'Digite uma nova senha.'
      });
      this.novaSenha = undefined;
      this.confirmarSenha = undefined;
      this.novaSenhaField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.novaSenha.length < 4) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao trocar senha!',
        detail: 'Digite uma nova senha com no mínimo 5 caracteres.'
      });
      this.novaSenha = undefined;
      this.confirmarSenha = undefined;
      this.novaSenhaField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    if (this.confirmarSenha === undefined) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao trocar senha!',
        detail: 'Confirme uma nova senha.'
      });
      this.confirmarSenha = undefined;
      this.confirmarSenhaField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.confirmarSenha.length < 4) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao trocar senha!',
        detail: 'Confirme uma nova senha com no mínimo 5 caracteres.'
      });
      this.confirmarSenha = undefined;
      this.confirmarSenhaField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    if (this.novaSenha !== this.confirmarSenha) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao trocar senha!',
        detail: 'Confirmação de senha inválida.'
      });
      this.confirmarSenha = undefined;
      this.confirmarSenhaField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    this.authService.alterarSenha(this.novaSenha).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Senha alterada com sucesso!',
          detail: 'Senha alterada com sucesso.'
        });
        this.authService.senhaAlteradaEvent.emit();
        this.blocked = false;
      },
      error => {
        console.log('error:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Falha ao alterar senha!',
          detail: 'A senha não foi alterada. Tente novamente.'
        });
        this.blocked = false;
      },
      () => this.reset()
    );

  }

  reset() {
    this.senhaAtual = undefined;
    this.novaSenha = undefined;
    this.confirmarSenha = undefined;
    this.blocked = false;
    this.senhaAtualField.nativeElement.focus();
  }

}
