import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';

import { Pedido } from '../pedido.model';
import { AuthService } from 'src/app/seguranca/auth.service';
import { PedidoService } from '../pedido.service';
import { TotalGroupDTO } from '../total-group-dto.model';
import { PedidoFilter } from '../pedido-filter';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { LicencaService } from 'src/app/licenca/licenca.service';
import { Licenca } from 'src/app/licenca/licenca.model';
import { PedidoDTO } from '../pedido-dto';

@Component({
  selector: 'app-pedido-consulta',
  templateUrl: './pedido-consulta.component.html',
  styleUrls: ['./pedido-consulta.component.css']
})
export class PedidoConsultaComponent implements OnInit {

  jwtPayload: any;
  exibirPedido = false;
  exibirDependentes = false;
  pedidoSelecionado: Pedido;
  licencaSelecionada: Licenca;
  pedidosDTO: PedidoDTO[] = [];
  pedidoFilter: PedidoFilter;
  empresasOptions: any[];
  licencasOptions: any[];

  pedidoRoot: Pedido;
  pedidosDependentes: PedidoDTO[];

  totalPendente$: Observable<TotalGroupDTO>;
  totalPago$: Observable<TotalGroupDTO>;
  totalDisponivel: number;
  totalCancelado$: Observable<TotalGroupDTO>;

  blocked = true;

  exibirOptions: any[];
  licencas: Licenca[];

  tableRows = 10;

  resumo: any;

  dashboardLicencaId: string;

  constructor(
    private messageService: MessageService,
    private empresaService: EmpresaService,
    private licencaService: LicencaService,
    private pedidoService: PedidoService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.exibirOptions = [
      { label: 'Exibir: Todos', value: 0 },
      { label: 'Exibir: 10 linhas por página', value: 10 },
      { label: 'Exibir: 20 linhas por página', value: 20 },
      { label: 'Exibir: 50 linhas por página', value: 50 },
      { label: 'Exibir: 100 linhas por página', value: 100 },
      { label: 'Exibir: 200 linhas por página', value: 200 },
      { label: 'Exibir: 500 linhas por página', value: 500 },
      { label: 'Exibir: 1000 linhas por página', value: 1000 }
    ];

    this.jwtPayload = this.authService.getJwtPayload();

    this.pedidoFilter = new PedidoFilter(this.jwtPayload.empresaId);

    this.empresaService.getEmpresas().subscribe(
      result => {
        this.empresasOptions = [];
        result.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
        this.blocked = false;
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );
  }

  buscarLicencas() {
    this.resumo = {
      qtd: 0,
      total: 0,
      formas: []
    }
    this.pedidoFilter.licencaId = undefined;
    this.pedidosDTO = [];
    let moedas = [];
    this.licencaService.buscarPorEmpresa(this.pedidoFilter.empresaId).subscribe(
      result => {
        this.licencas = result;
        this.licencasOptions = [];

        result.forEach(licenca => {
          this.licencasOptions.push(
            { label: `${licenca.contrato} [${licenca.moeda}]`, value: licenca.id, moeda: licenca.moeda }
          );
          if (!moedas.includes(licenca.moeda)) {
            moedas.push(licenca.moeda);
          }
          if (this.pedidoFilter.licencaId === undefined) {
            this.pedidoFilter.licencaId = licenca.id;
            this.pedidoFilter.moeda = licenca.moeda;
          }
        });
      },
      err => {
        console.log('Error:', err);
        this.blocked = false;
      },
      () => {
        if (this.licencasOptions.length > 1) {
          if (moedas.length > 1) {
            moedas.forEach(moeda => {
              this.licencasOptions.push(
                { label: `Todos - ${moeda}`, value: moeda }
              );
            });
          } else {
            this.licencasOptions.push(
              { label: 'Todos', value: moedas[0] }
            );
          }
        }

        // this.pedidoFilter.initRangeDates();
        // this.buscar();
        this.loadDashboards();

        this.blocked = false;
      }
    );
  }

  selecionarLicenca() {
    this.licencas.forEach(licenca => {
      if (this.pedidoFilter.licencaId && this.pedidoFilter.licencaId.length === 3) {
        this.pedidoFilter.moeda = this.pedidoFilter.licencaId;
      } else if (this.pedidoFilter.licencaId === licenca.id) {
        this.pedidoFilter.moeda = licenca.moeda;
      }
    });
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

  buscar() {
    this.pedidosDTO = [];
    // Verifica filtros obrigatórios
    if (!this.pedidoFilter.isFilterSelected()) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Nenhum filtro selecionado !',
        detail: 'Selecione pelo menos um dos filtros: pedido, período, situações, forma pagto, intituições, comprador ou autorização.'
      });
      return;
    }

    this.blocked = true;
    if (this.pedidoFilter.licencaId.length === 3) {
      this.pedidoFilter.licencaId = undefined;
    }
    console.log('PedidoFilter', this.pedidoFilter);

    this.pedidoService.getPedidosDTO(this.pedidoFilter).subscribe(
      pedidosDTO => {
        this.pedidosDTO = pedidosDTO;
        if (this.pedidosDTO.length == 0) {
          this.messageService.add({
            severity: 'success',
            summary: 'Consulta concluída !',
            detail: 'Nenhum registro encontrado.'
          });
        }
        if (!this.pedidoFilter.licencaId) {
          if (this.pedidoFilter.moeda) {
            this.pedidoFilter.licencaId = this.pedidoFilter.moeda;
          } else if (this.licencas && this.licencas[0] && this.licencas[0].id) {
            this.pedidoFilter.licencaId = this.licencas[0].id;
          }
        }
      },
      err => {
        console.log('Error:', err);
        this.blocked = false;
      },
      () => {

        this.pedidoFilter.clear();

        this.resumo = {
          qtd: 0,
          total: 0,
          formas: []
        }
        for (const p of this.pedidosDTO) {
          let forma = undefined;
          for (const f of this.resumo.formas) {
            if (f.forma === p.formaPagamento) {
              forma = f;
              break;
            }
          }
          if (forma) {
            forma.qtd++;
            forma.total += p.totalPedido;
          } else {
            this.resumo.formas.push({
              forma: p.formaPagamento,
              qtd: 1,
              total: p.totalPedido
            });
          }
          this.resumo.qtd++;
          this.resumo.total += p.totalPedido;
        }
        this.loadDashboards();
        this.blocked = false;
      }
    );
  }

  loadDashboards() {
    if (this.dashboardLicencaId != this.pedidoFilter.licencaId) {
      this.totalPendente$ = this.pedidoService.getTotalPendentePorLicencaId(this.pedidoFilter.licencaId);

      this.totalPago$ = this.pedidoService.getTotalPagoPorLicencaId(this.pedidoFilter.licencaId);

      this.pedidoService.getDisponivel(this.pedidoFilter.licencaId).subscribe(
        disponivel => this.totalDisponivel = disponivel
      );

      this.totalCancelado$ = this.pedidoService.getTotalCanceladoPorLicencaId(this.pedidoFilter.licencaId);

      this.dashboardLicencaId = this.pedidoFilter.licencaId;
    }
  }

  onRowSelect(pedidoDTO: PedidoDTO) {
    this.exibirDependentes = false;
    if (this.hasPermission('PEDIDO_VISUALIZAR')) {
      this.pedidoService.getPedido(pedidoDTO.id).subscribe(
        pedido => {
          this.pedidoSelecionado = pedido;
          console.log('PedidoSelecionado', pedido);
          for (const l of this.licencas) {
            if (l.id === pedido.licencaId) {
              this.licencaSelecionada = l;
              break;
            }
          }
          this.exibirPedido = true;
        }
      );
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Permissão Negada!',
        detail: 'Você não possui permissão para visualizar detalhes do pedido.'
      });
    }
  }

  onPedidoRootSelect(pedido: Pedido) {
    this.exibirDependentes = false;
    this.pedidoSelecionado = pedido;
    console.log('PedidoSelecionado', pedido);
    for (const l of this.licencas) {
      if (l.id === pedido.licencaId) {
        this.licencaSelecionada = l;
        break;
      }
    }
    this.exibirPedido = true;
  }

  updatePedido(event) {
    for (let i = 0; i < this.pedidosDTO.length; i++) {
      if (this.pedidosDTO[i].id == event.id) {
        this.pedidosDTO[i].compradorNomeCompleto = event.compradorNomeCompleto;
        break;
      }
    }
  }

  showPedidosDependentes(event) {
    this.pedidoRoot = event.pedido;
    this.pedidosDependentes = event.pedidos;
    this.exibirDependentes = true;
  }

  fecharDependentes() {
    this.exibirDependentes = false;
  }

}

// pedido.pedidoNumero < 189497
