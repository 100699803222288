<div class="pgw--container">

    <h1>Conciliação</h1>

    <div class="ui-g" style="margin-top: 20px; margin-bottom: 50px;" *ngIf="hasPermission('CONCILIACAO_CONSULTAR')">
        <div *ngIf="tab === 'CONSULTAR'">
            <div class="ui-g-12 ui-g-nopad">
                <div class="ui-g-12 ui-fluid" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
                    <p-dropdown [options]="empresasOptions" [(ngModel)]="conciliacaoFilter.empresaId"
                        (onChange)="buscarLicencas()" filter="true" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
            </div>

            <div class="ui-g-12 ui-md-5 ui-lg-3 ui-fluid">
                <p-dropdown [options]="licencasOptions" [(ngModel)]="conciliacaoFilter.licencaId" pTooltip="Contratos"
                    tooltipPosition="top">
                </p-dropdown>
            </div>

            <div class="ui-g-12 ui-md-5 ui-lg-2 ui-fluid">
                <p-calendar [(ngModel)]="conciliacaoFilter.rangeDates" selectionMode="range" [readonlyInput]="true"
                    dateFormat="dd/mm/yy" placeholder="Período" pTooltip="Data de Início ou Período"
                    tooltipPosition="top">
                </p-calendar>
            </div>

            <div class="ui-g-12 ui-md-1 ui-lg-1">
                <button pButton type="button" icon="pi pi-search" (click)="buscar()"></button>
            </div>

            <div class="ui-g-12">
                <button pButton label="Nova Conciliação" icon="pi pi-plus" class="ui-button-success" (click)="criar()"
                    *ngIf="hasPermission('CONCILIACAO_CONCILIAR')"></button>
            </div>

            <div class="ui-g-12 ui-fluid">
                <p-table [value]="conciliacoes" [responsive]="true" selectionMode="single"
                    (onRowSelect)="onRowSelect($event.data)">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 120px;">Data/Hora</th>
                            <th>Descrição</th>
                            <th style="width: 100px;">Valor</th>
                            <th style="width: 70px;" *ngIf="hasPermission('CONCILIACAO_EXCLUIR')">Excluir</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-conciliacao>
                        <tr [pSelectableRow]="conciliacao">
                            <td class="col-center">{{ conciliacao.dtHr | date:'dd/MM/yyyy HH:mm:ss' }}</td>
                            <td class="col-left">{{ conciliacao.descricao }}</td>
                            <td class="valores" *ngIf="moeda === 'BRL'">
                                {{ conciliacao.valor | currency:'BRL':'symbol':'0.2-2':'pt' }}
                            </td>
                            <td class="valores" *ngIf="moeda === 'USD'">
                                {{ conciliacao.valor | currency:'USD':'symbol':'0.2-2':'en' }}
                            </td>
                            <td class="col-center" *ngIf="hasPermission('CONCILIACAO_EXCLUIR')">
                                <p-button icon="pi pi-trash" (click)="excluir(conciliacao.id)"
                                    title="Excluir Conciliação"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td [attr.colspan]="hasPermission('CONCILIACAO_EXCLUIR') ? 4 : 3">
                                Nenhuma conciliação criada!
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div *ngIf="tab === 'CADASTRAR'">

        </div>
    </div>
</div>
