<div class="pgw--container">
  <div class="ui-g">

    <div class="ui-g-12">
      <button pButton type="button" (click)="fechar()" label="Voltar" icon="pi pi-step-backward"></button>
    </div>


    <div class="ui-g-12">
      <h1>Recorrência</h1>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Data/Hora Adesão:
      </div>
      <div>
        {{ recorrencia.dtHr | date:'dd/MM/yyyy - HH:mm:ss' }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Número Pedido:
      </div>
      <div>
        {{ recorrencia.pedidoNumero }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Forma Pagamento:
      </div>
      <div>
        {{ recorrencia.formaPagamento }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Periodicidade:
      </div>
      <div>
        {{ recorrencia.periodicidade }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Próximo Vencimento:
      </div>
      <div>
        {{ recorrencia.proximoVencimento | date:'dd/MM/yyyy' }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Valor:
      </div>
      <div>
        {{ recorrencia.valor | currency:'BRL':'symbol':'0.2-2':'pt' }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Status:
      </div>
      <div>
        {{ recorrencia.status }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Data/Hora Cancelamento:
      </div>
      <div>
        {{ recorrencia.cancelDtHr | date:'dd/MM/yyyy - HH:mm:ss' }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-8">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Cancelado por:
      </div>
      <div>
        {{ recorrencia.cancelUserName }}
      </div>
    </div>

    <div class="ui-g-12" *ngIf="recorrencia.status !== 'CANCELADO'">
      <button pButton type="submit" (click)="cancelarRecorrencia()" label="Cancelar Recorrência" icon="fas fa-times"
        class="ui-button-danger"></button>
    </div>

    <div class="ui-g-12">
      <h2>Cliente</h2>
    </div>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Nome:
      </div>
      <div>
        {{ recorrencia.comprador.nome }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Sobrenome:
      </div>
      <div>
        {{ recorrencia.comprador.sobreNome }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        {{ recorrencia.comprador.tipoDoc }}:
      </div>
      <div>
        {{ recorrencia.comprador.numeroDoc }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Data de Nascimento:
      </div>
      <div>
        {{ recorrencia.comprador.dataNascimento }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Celular:
      </div>
      <div>
        {{ recorrencia.comprador.dddCelular }} {{ recorrencia.comprador.numeroCelular }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Telefone:
      </div>
      <div>
        {{ recorrencia.comprador.dddTelefone }} {{ recorrencia.comprador.numeroTelefone }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        E-mail:
      </div>
      <div>
        {{ recorrencia.comprador.email }}
      </div>
    </div>

    <div class="ui-g-12">
      <h2>Endereço</h2>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-6">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Logradouro:
      </div>
      <div>
        {{ recorrencia.comprador.logradouro }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Número:
      </div>
      <div>
        {{ recorrencia.comprador.numero }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Complemento:
      </div>
      <div>
        {{ recorrencia.comprador.complemento }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Bairro:
      </div>
      <div>
        {{ recorrencia.comprador.bairro }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Cidade:
      </div>
      <div>
        {{ recorrencia.comprador.cidade }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        UF:
      </div>
      <div>
        {{ recorrencia.comprador.uf }}
      </div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-lg-3">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
        Cep:
      </div>
      <div>
        {{ recorrencia.comprador.cep }}
      </div>
    </div>

    <div class="ui-g-12">
      <h2>Pedidos</h2>
    </div>

    <div class="ui-g-12 ui-fluid">
      <p-table [value]="pedidosDTO" [responsive]="true"
        selectionMode="single" (onRowSelect)="onRowSelect($event.data)">
        <ng-template pTemplate="caption">
          Pedidos encontrados: {{ pedidosDTO.length }}
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 100px;">Data</th>
            <th style="width: 100px;">Pedido</th>
            <th class="col-left">Comprador</th>
            <th style="width: 110px;">Forma Pgto.</th>
            <th style="width: 200px;">Situação</th>
            <th style="width: 120px;" class="col-right">Total</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pedidoDTO>
          <tr [pSelectableRow]="pedidoDTO">
            <td class="col-center">{{ pedidoDTO.dtHr | date:'dd/MM/yyyy' }}</td>
            <td class="col-center">
              {{ pedidoDTO.pedidoNumero }}
              <span class="pgw--ref">{{pedidoDTO.origem}}</span>
            </td>
            <td class="col-left">
              {{ pedidoDTO.compradorNomeCompleto }}
              <span class="pgw--ref">{{ pedidoDTO.contrato }} |
                {{ pedidoDTO.environment === 'production' ? 'Produção' : pedidoDTO.environment === 'sandbox' ? 'Teste' :
                'Log' }}
              </span>
            </td>
            <td class="col-center">
              {{ pedidoDTO.formaPagamento }}
              <span class="pgw--ref" *ngIf="pedidoDTO.recorrente">recorrente</span>
              <span class="pgw--ref" *ngIf="!pedidoDTO.recorrente && pedidoDTO.qtdParcela == 1">a vista</span>
              <span class="pgw--ref" *ngIf="!pedidoDTO.recorrente && pedidoDTO.qtdParcela > 1">{{pedidoDTO.qtdParcela}}
                parcelas</span>
            </td>
            <td class="col-center" appPagseguroStatus>{{ pedidoDTO.pagseguroStatus }}</td>
            <td class="col-right">{{ pedidoDTO.totalPedido | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
          </tr>
        </ng-template>

      </p-table>
    </div>


    <div class="ui-g-12">
      <button pButton type="button" (click)="fechar()" label="Voltar" icon="pi pi-step-backward"></button>
    </div>

  </div>

</div>

<p-confirmDialog [style]="{width: '450px'}" acceptLabel='Sim' rejectLabel='Não' key="positionDialog" position="center" [baseZIndex]="10000">
</p-confirmDialog>

<p-blockUI [blocked]="blocked" [baseZIndex]="10000">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>


