<header>
  <div class="pgw--container">

    <div class="ui-g">

      <div class="ui-g-3 ui-md-3 ui-lg-2 ui-fluid">
        <img src="../../assets/images/logo.png" alt="Logo PGW Payments" class="pgw--logo" />
      </div>

      <div class="ui-g-9 ui-md-9 ui-lg-10 ui-fluid" *ngIf="jwtPayload && !jwtPayload.redefinirSenha">
        <div class="ui-g-10 ui-md-8 ui-lg-8 ui-fluid">
          <span style="display: block; font-weight: bold; color:white;">{{ jwtPayload.razaoSocial
            }}</span>
          <span style="display: block; font-size: 0.7em; color:white;">{{ jwtPayload.usuarioNome
            }}</span>
        </div>
        <div class="ui-g-2 ui-md-4 ui-lg-4 ui-fluid pgw--text-right">
          <i class="pi pi-bars menu-bars" (click)="exibirMenu = !exibirMenu"></i>
        </div>
      </div>

    </div>

  </div>
</header>

<nav>
  <p-sidebar [(visible)]="exibirMenu" position="right" [style]="{width:'24em'}">
    <h1>Menu</h1>

    <ul class="navbar-menu">
      <li class="navbar-menu-item" routerLinkActive="ativo">
        <i class="pi pi-home">
          <a routerLink="/home" (click)="exibirMenu=false">Home</a>
        </i>
      </li>

      <li [hidden]="!menu.boleto" class="navbar-menu-item" routerLinkActive="ativo">
        <i class="fas fa-barcode">
          <a routerLink="/boletos/emitir" (click)="exibirMenu=false">Boletos Emissão</a>
        </i>
      </li>

      <li [hidden]="!menu.boleto" class="navbar-menu-item" routerLinkActive="ativo">
        <i class="fas fa-barcode">
          <a routerLink="/boletos" (click)="exibirMenu=false">Boletos Fluxo</a>
        </i>
      </li>

      <li [hidden]="!menu.recorrente" class="navbar-menu-item" routerLinkActive="ativo">
        <i class="fas fa-chart-line">
          <a routerLink="/recorrencia" (click)="exibirMenu=false">Recorrência</a>
        </i>
      </li>

      <li [hidden]="!menu.conciliacao" class="navbar-menu-item" routerLinkActive="ativo">
        <i class="fas fa-check">
          <a routerLink="/conciliacao" (click)="exibirMenu=false">Conciliação</a>
        </i>
      </li>

      <li [hidden]="!menu.deposito" class="navbar-menu-item" routerLinkActive="ativo">
        <i class="pi pi-money-bill">
          <a routerLink="/depositos" (click)="exibirMenu=false">Depósitos</a>
        </i>
      </li>

      <li [hidden]="!menu.estatistica" class="navbar-menu-item" routerLinkActive="ativo">
        <i class="fas fa-chart-pie">
          <a routerLink="/estatistica" (click)="exibirMenu=false">Estatísticas</a>
        </i>
      </li>

      <li [hidden]="!menu.extrato" class="navbar-menu-item" routerLinkActive="ativo">
        <i class="fas fa-file-invoice-dollar">
          <a routerLink="/extrato" (click)="exibirMenu=false">Extrato</a>
        </i>
      </li>

      <li [hidden]="!menu.link" class="navbar-menu-item" routerLinkActive="ativo">
        <i class="fas fa-link"><a routerLink="/links" (click)="exibirMenu=false">Link Pagamento</a>
        </i>
      </li>

      <li [hidden]="!menu.plano" class="navbar-menu-item" routerLinkActive="ativo">
        <i class="fas fa-link"><a routerLink="/links/recorrente" (click)="exibirMenu=false">Link Recorrente</a>
        </i>
      </li>

      <li [hidden]="!menu.pedido" class="navbar-menu-item" routerLinkActive="ativo">
        <i class="pi pi-shopping-cart">
          <a routerLink="/pedidos" (click)="exibirMenu=false">Pedidos</a>
        </i>
      </li>

      <li [hidden]="!menu.saque" class="navbar-menu-item" routerLinkActive="ativo">
        <i class="fas fa-hand-holding-usd">
          <a routerLink="/saque" (click)="exibirMenu=false">Saque</a>
        </i>
      </li>

      <li [hidden]="!menu.config" class="navbar-menu-item" routerLinkActive="ativo">
        <i class="fas fa-cog">
          <a routerLink="/configuracoes" (click)="exibirMenu=false">Configurações</a>
        </i>
      </li>

      <li class="navbar-menu-item">
        <i class="fas fa-key">
          <a href="javascript:void(0);" (click)="trocarSenha()">
            Trocar Senha
          </a>
        </i>
      </li>

      <li class="navbar-menu-item logout" routerLinkActive="ativo">
        <i class="pi pi-sign-out">
          <a href="#" (click)="logout()">Logout</a>
        </i>
      </li>

    </ul>

    <span class="version">v1.2.11</span>

  </p-sidebar>

  <p-sidebar [(visible)]="exibirTrocarSenha" position="right" styleClass="ui-sidebar-md">

    <app-trocar-senha></app-trocar-senha>

  </p-sidebar>

</nav>
