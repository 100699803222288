<form autocomplete="off" (ngSubmit)="trocarSenha();">
  <div class="ui-g ui-fluid">

    <div class="ui-g-12 margin-bottom-20">
      <h1>Trocar Senha</h1>
    </div>

    <div class="ui-g-12 margin-bottom-20">
      <span class="ui-float-label">
        <input id="alterarSenhaAtual" type="password" pPassword required name="senhaAtual" [(ngModel)]="senhaAtual"
          [feedback]="false" #senhaAtualField>
        <label for="alterarSenhaAtual">Senha Atual</label>
      </span>
    </div>

    <div class="ui-g-12 margin-bottom-20">
      <span class="ui-float-label">
        <input id="alterarNovaSenha" type="password" pPassword required name="novaSenha" [(ngModel)]="novaSenha"
          promptLabel="Digite sua nova senha" weakLabel="Fraca" mediumLabel="Média" strongLabel="Forte" #novaSenhaField>
        <label for="alterarNovaSenha">Nova Senha</label>
      </span>
    </div>

    <div class="ui-g-12 margin-bottom-20">
      <span class="ui-float-label">
        <input id="alterarConfirmarSenha" type="password" pPassword required name="confirmarSenha" [(ngModel)]="confirmarSenha"
          promptLabel="Confirme sua nova senha" weakLabel="Fraca" mediumLabel="Média" strongLabel="Forte" #confirmarSenhaField>
        <label for="alterarConfirmarSenha">Confirmar Nova Senha</label>
      </span>
    </div>

    <div class="ui-g-12">
      <button pButton type="submit" label="Trocar Senha" icon="pi pi-sign-in"></button>
    </div>

  </div>
</form>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>
