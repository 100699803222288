import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RecorrenciaCancelDTO } from './recorrencia-cancel-dto.model';
import { RecorrenciaDTO } from './recorrencia-dto.model';
import { RecorrenciaFilter } from './recorrencia-filter';
import { Recorrencia } from './recorrencia.model';

@Injectable({
  providedIn: 'root'
})
export class RecorrenteConsultaService {

  constructor(private http: HttpClient) { }

  buscar(recorrenciaFilter: RecorrenciaFilter): Observable<RecorrenciaDTO[]> {
    const url = `${environment.apiUrl}/recorrencias`;
    return this.http.get<RecorrenciaDTO[]>(url, { params: recorrenciaFilter.getSearchParams() });
  }

  buscarPorId(recorrenciaId: string): Observable<Recorrencia> {
    const url = `${environment.apiUrl}/recorrencias/${recorrenciaId}`;
    return this.http.get<Recorrencia>(url);
  }

  canelarRecorrencia(recorrenciaCancel: RecorrenciaCancelDTO): Observable<Recorrencia> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<Recorrencia>(`${environment.apiUrl}/recorrencias/${recorrenciaCancel.recorrenciaId}/cancelar`, recorrenciaCancel, { headers });
  }

}
