import { BoletoVencto } from './boleto-vencto.model';
import { Customer } from './customer.model';

export class Boleto {

  constructor() {
    this.customer = new Customer();
    this.vencimentos = new Array();
    this.tpMulta = 'ISENTO';
    this.vlrMulta = 0.00;
    this.tpJuros = 'ISENTO';
    this.vlrJuros = 0.00;
  }

  licencaId: string;
  usuarioId: string;
  descricaoGeral: string;
  customer: Customer;
  vencimentos: BoletoVencto[];

  tpMulta: string;
  vlrMulta: number;

  tpJuros: string;
  vlrJuros: number;

}
