<div class="pgw--container pgw--form-login">

  <form autocomplete="off" (ngSubmit)="login()">
    <div class="ui-g ui-fluid">

      <div class="ui-g-12 margin-bottom-10">
        <h1>Acesso</h1>
      </div>

      <div class="ui-g-12 margin-bottom-10">
        <span class="ui-float-label">
          <input id="loginEmail" #loginEmail pInputText required name="email" [(ngModel)]="user.email" autofocus>
          <label for="loginEmail">E-mail</label>
        </span>
      </div>

      <div class="ui-g-12 margin-bottom-10">
        <span class="ui-float-label margin-bottom-10">
          <input id="loginSenha" #loginSenha type="password" pPassword required name="senha" [(ngModel)]="user.senha"
            [feedback]="false">
          <label for="loginSenha">Senha</label>
        </span>
        <a href="javascript:void(0);" (click)="recuperarSenha()">Esqueceu sua senha?</a>
      </div>

      <div class="ui-g-12 margin-bottom-10 ui-fluid" *ngIf="reCaptcha">
        <p-captcha size="large" [siteKey]="reCaptchaSite" (onResponse)="showResponse($event)">
        </p-captcha>
      </div>

      <div class="ui-g-12">
        <button pButton type="submit" label="Entrar" icon="pi pi-sign-in"></button>
      </div>

    </div>
  </form>
</div>

<p-sidebar [(visible)]="exibirRedefinirSenha" position="right" styleClass="ui-sidebar-md">
  <app-redefinir-senha></app-redefinir-senha>
</p-sidebar>

<p-sidebar [(visible)]="exibirRecuperarSenha" position="right" styleClass="ui-sidebar-md" (onHide)="loginFocus()">
  <app-recuperar-senha></app-recuperar-senha>
</p-sidebar>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>
