<div class="pgw--container">

  <div class="ui-g">

    <div class="ui-g-12">
      <h1>Solicitação de Saque
        <a href="javascript:void(0);" (click)="verTutorial()" [hidden]="exibirTutorial">
          <i class="fas fa-question-circle"></i>ajuda</a>
      </h1>
    </div>

    <div class="ui-g-12 pgw--text-right" *ngIf="exibirTutorial">
      <a href="javascript:void(0);" (click)="fecharTutorial()"><i class="fas fa-times"></i>fechar</a>
      <video width="100%" controls autoplay>
        <source src="https://app.pgwpay.com.br/repo/video/SaqueTutorial.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="ui-g-12 ui-g-nopad">
      <div class="ui-g-12 ui-fluid" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
        <p-dropdown [options]="empresasOptions" [(ngModel)]="empresaId" (onChange)="buscarLicencas()" filter="true" resetFilterOnHide="true">
        </p-dropdown>
      </div>
    </div>

    <div class="ui-g-12 ui-fluid">
      <p-dropdown [options]="licencasOptions" [(ngModel)]="licencaId" pTooltip="Contratos" tooltipPosition="top"
        (onChange)="buscar()">
      </p-dropdown>
    </div>

  </div>

  <div class="ui-g" *ngIf="tab == 'CONTAS' && hasPermission('SAQUE_CONSULTAR') && hasPermission('SAQUE_SOLICITAR')">

    <div class="ui-g-12 ui-md-3 ui-fluid">
      <label id="totalDisponivel">Valor Disponível:</label>
      <div *ngIf="moeda === 'USD'" class="valores">{{ totalDisponivel | currency:'USD':'symbol':'0.2-2':'en' }}</div>
      <div *ngIf="moeda === 'BRL'" class="valores">{{ totalDisponivel | currency:'BRL':'symbol':'0.2-2':'pt' }}</div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-fluid">
      <label id="totalBloqueado">Valor Bloqueado:</label>
      <div *ngIf="moeda === 'USD'" class="valores">{{ totalBloqueado | currency:'USD':'symbol':'0.2-2':'en' }}</div>
      <div *ngIf="moeda === 'BRL'" class="valores">{{ totalBloqueado | currency:'BRL':'symbol':'0.2-2':'pt' }}</div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-fluid">
      <label id="totalLiberado">Valor Liberado:</label>
      <div *ngIf="moeda === 'USD'" class="valores">{{ totalLiberado | currency:'USD':'symbol':'0.2-2':'en' }}</div>
      <div *ngIf="moeda === 'BRL'" class="valores">{{ totalLiberado | currency:'BRL':'symbol':'0.2-2':'pt' }}</div>
    </div>

    <div class="ui-g-12 ui-md-3 ui-fluid">
      <label id="totalSaque">Valor Solicitado:</label>
      <input pInputText id="totalSaque" name="totalSaque" [(ngModel)]="totalSaque" [pKeyFilter]="regExValor" />
    </div>

    <div class="ui-g-12">
      <p-table [value]="contas" [paginator]="false" [responsive]="true">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 100px;">Banco</th>
            <th style="width: 100px;">Agência</th>
            <th style="width: 210px;">Conta</th>
            <th>Titular</th>
            <th style="width: 190px;">Cpf/Cnpj</th>
            <th class="col-center" style="width: 70px;">Opção</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-conta>
          <tr [pSelectableRow]="conta">
            <td class="col-center">{{ conta.banco }}</td>
            <td class="col-center">{{ conta.agencia }}</td>
            <td class="col-center">{{ conta.conta }}</td>
            <td class="col-center">{{ conta.titular }}</td>
            <td class="col-center">{{ conta.cpfCnpj }}</td>
            <td class="col-center">
              <p-radioButton name="contaSelecionada" [value]="conta.id" [(ngModel)]="selectedContaId"></p-radioButton>
            </td>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="6">
              Nenhuma conta cadastrada!
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="ui-g-6">
      <button pButton type="button" class="ui-button-info" label="Voltar" (click)="tab = 'PEDIDOS'"></button>
    </div>
    <div class="ui-g-6 col-right">
      <button pButton type="button" class="ui-button-info" label="Confirmar Saque" (click)="confirmar()"></button>
    </div>
  </div>

  <div class="ui-g" *ngIf="tab == 'CONFIRMAR' && hasPermission('SAQUE_CONSULTAR') && hasPermission('SAQUE_SOLICITAR')">
    <div class="ui-g-12">
      <p-message severity="success" text="Resgate solicitado com sucesso!"></p-message>
    </div>
    <div class="ui-g-12">
      <button pButton type="button" class="ui-button-info" label="Novo Saque" (click)="novaSolicitacao()"></button>
    </div>
  </div>

</div>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>

<p-confirmDialog [style]="{width: '450px'}" acceptLabel='Sim' rejectLabel='Não' key="positionDialog" position="center" [baseZIndex]="10000">
</p-confirmDialog>
