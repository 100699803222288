import { Component, OnInit } from '@angular/core';

import { Empresa } from '../empresa.model';

@Component({
  selector: 'app-empresa-cadastro',
  templateUrl: './empresa-cadastro.component.html',
  styleUrls: ['./empresa-cadastro.component.css']
})
export class EmpresaCadastroComponent implements OnInit {

  empresa: Empresa;
  confirmarSenha: string;

  constructor() { }

  ngOnInit() {
    this.empresa = new Empresa();
  }

  salvar() {
  }

}
