import { Component, OnInit, Input } from '@angular/core';
import { Conta } from '../conta.model';
import { ContaService } from '../conta.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-conta-cadastro',
  templateUrl: './conta-cadastro.component.html',
  styleUrls: ['./conta-cadastro.component.css']
})
export class ContaCadastroComponent implements OnInit {

  @Input() conta: Conta;

  constructor(
    private contaService: ContaService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
  }

  salvar() {
    if (this.conta.id) {
      this.atualizar();
    } else {
      this.criar();
    }
  }

  criar() {
    this.contaService.criar(this.conta).subscribe(
      data => {
        this.contaService.contaEvent.emit();
      },
      error => {
        console.log('Error:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao criar conta!',
          detail: 'Erro ao criar conta.'
        });
      }
    );
  }

  atualizar() {
    this.contaService.atualizar(this.conta).subscribe(
      data => {
        this.contaService.contaEvent.emit();
      },
      error => {
        console.log('Error:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao atualizar conta!',
          detail: 'Erro ao atualizar conta.'
        });
      }
    );
  }

}
