import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { TreeNode, MessageService } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';

import { Grupo } from '../grupo.model';
import { GrupoService } from '../grupo.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-grupo-cadastro',
  templateUrl: './grupo-cadastro.component.html',
  styleUrls: ['./grupo-cadastro.component.css']
})
export class GrupoCadastroComponent implements OnInit {

  @Input() grupo: Grupo;

  permissionsNode: TreeNode[];
  selectedPermissionsNode: TreeNode[];
  blocked: boolean;

  @ViewChild('grupoField', { static: true }) grupoField: ElementRef;
  @ViewChild('permissoesField', { static: true }) permissoesField: MultiSelect;

  constructor(
    private authService: AuthService,
    private grupoService: GrupoService,
    private messageService: MessageService
  ) { }

  salvar() {
    this.blocked = true;
    // Validações
    if (this.grupo.grupo === undefined) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Grupo inválido!',
        detail: 'Informe uma descrição para o grupo.'
      });
      this.grupoField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.grupo.grupo.length < 3) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Grupo Inválido!',
        detail: 'Informe uma descrição para o grupo com no minímo 3 caracteres.'
      });
      this.grupoField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.selectedPermissionsNode === undefined || this.selectedPermissionsNode.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Permissões inválida!',
        detail: 'Selecione as permissões para o grupo.'
      });
      this.permissoesField.focus = true;
      this.blocked = false;
      return;
    }
    // Prepara permissões para gravar
    this.grupo.permissoes = [];
    this.selectedPermissionsNode.forEach(
      node => {
        const keys = node.data.split('_');
        let base = '';
        keys.forEach(key => {
          base += base.length > 0 ? `_${key}` : key;
          if (!this.grupo.permissoes.includes(base)) {
            this.grupo.permissoes.push(base);
          }
        });
      }
    );
    // Cria ou atualiza
    if (this.grupo.id) {
      this.atualizar();
    } else {
      this.criar();
    }
  }

  criar() {
    // Valida Grupo duplicado na inclusão
    this.grupoService.buscarPorEmpresa(this.authService.jwtPayload.empresaId).subscribe(
      data => {
        for (let i = 0, len = data.length; i < len; i++) {
          if (this.grupo.grupo === data[i].grupo) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Grupo Inválido!',
              detail: 'O grupo informado já foi cadastrado.'
            });
            this.grupo.grupo = undefined;
            this.grupoField.nativeElement.focus();
            this.blocked = false;
            return;
          }
        }
        // Cria o grupo
        this.grupoService.criar(this.grupo).subscribe(
          () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso!',
              detail: 'Grupo criado com sucesso.'
            });
            this.grupoService.grupoEvent.emit();
            this.blocked = false;
          },
          error => {
            console.log('Error ao criar grupo:', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Erro ao criar grupo!',
              detail: 'Erro ao criar grupo.'
            });
            this.grupoField.nativeElement.focus();
            this.blocked = false;
          }
        );
      },
      error => {
        console.log('Erro ao validar grupos existentes na inclusão:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao validar grupo!',
          detail: 'Erro ao validar grupos existentes.'
        });
        this.grupoField.nativeElement.focus();
        this.blocked = false;
        return;
      }
    );
  }

  atualizar() {
    // Valida Grupo duplicado na atualização
    this.grupoService.buscarPorEmpresa(this.authService.jwtPayload.empresaId).subscribe(
      data => {
        for (let i = 0, len = data.length; i < len; i++) {
          if (this.grupo.grupo === data[i].grupo && this.grupo.id !== data[i].id) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Grupo Inválido!',
              detail: 'O grupo informado já existe.'
            });
            this.grupo.grupo = undefined;
            this.grupoField.nativeElement.focus();
            this.blocked = false;
            return;
          }
        }
        // Atualiza o grupo
        this.grupoService.atualizar(this.grupo).subscribe(
          () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso!',
              detail: 'Grupo atualizado com sucesso.'
            });
            this.grupoService.grupoEvent.emit();
            this.blocked = false;
          },
          error => {
            console.log('Error ao atualizar grupo:', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Erro ao atualizar grupo!',
              detail: 'Erro ao atualizar grupo.'
            });
            this.grupoField.nativeElement.focus();
            this.blocked = false;
          }
        );
      },
      error => {
        console.log('Erro ao validar grupos existentes na atualização:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao validar grupo!',
          detail: 'Erro ao validar grupos existentes.'
        });
        this.grupoField.nativeElement.focus();
        this.blocked = false;
        return;
      }
    );
  }

  ngOnInit() {
    this.blocked = false;
    this.grupoField.nativeElement.focus();
    this.initPermissions();
    if (this.grupo.id) {
      this.selectedPermissionsNode = [];
      this.permissionsNode.forEach(
        node => {
          if (this.grupo.permissoes.includes(node.data)) {
            this.selectedPermissionsNode.push(node);
          }
          if (node.children) {
            this.selectedChildren(node.children);
          }
        }
      );
    } else {
      this.selectedPermissionsNode = [];
    }
  }

  private selectedChildren(nodes: TreeNode[]) {
    nodes.forEach(
      node => {
        if (this.grupo.permissoes.includes(node.data)) {
          this.selectedPermissionsNode.push(node);
        }
        if (node.children) {
          this.selectedChildren(node.children);
        }
      }
    );
  }

  private initPermissions() {
    this.permissionsNode = [
      {
        label: 'Home',
        data: 'HOME',
        icon: 'pi pi-home',
        children: [
          {
            label: 'Pedidos (Total)',
            data: 'HOME_PEDIDO-TOT',
            icon: 'fa fa-eye'
          },
          {
            label: 'Depósitos (Total)',
            data: 'HOME_DEPOSITO-TOT',
            icon: 'fa fa-eye'
          },
          {
            label: 'Reembolsos (Total)',
            data: 'HOME_REEMBOLSO-TOT',
            icon: 'fa fa-eye'
          },
          {
            label: 'Fluxo de Caixa (Gráfico)',
            data: 'HOME_FLUXO-LINE',
            icon: 'fa fa-eye'
          },
          {
            label: 'Pedidos (Gráfico)',
            data: 'HOME_PEDIDO-PIE',
            icon: 'fa fa-eye'
          },
          {
            label: 'Formas de Pagamento (Gráfico)',
            data: 'HOME_FORMA-PIE',
            icon: 'fa fa-eye'
          },
          {
            label: 'Débitos por Banco (Gráfico)',
            data: 'HOME_DEBITO-PIE',
            icon: 'fa fa-eye'
          },
          {
            label: 'Cartões por Bandeira (Gráfico)',
            data: 'HOME_BANDEIRA-PIE',
            icon: 'fa fa-eye'
          },
          {
            label: 'Depósitos (Gráfico)',
            data: 'HOME_DEPOSITO-BAR',
            icon: 'fa fa-eye'
          },
          {
            label: 'Produtos (Gráfico)',
            data: 'HOME_PRODUTO-CHART',
            icon: 'fa fa-eye'
          },
          {
            label: 'Clientes (Gráfico)',
            data: 'HOME_CLIENTE-CHART',
            icon: 'fa fa-eye'
          },
          {
            label: 'Reembolsos (Gráfico)',
            data: 'HOME_REEMBOLSO-CHART',
            icon: 'fa fa-eye'
          }
        ]
      },
      {
        label: 'Estatisticas',
        data: 'EST',
        icon: 'fa fa-chart-pie',
        children: [
          {
            label: 'Vendas',
            data: 'EST_VENDAS',
            icon: 'pi pi-shopping-cart'
          }
        ]
      },
      {
        label: 'Pagamentos',
        data: 'PG',
        icon: 'fa fa-file-invoice',
        children: [
          {
            label: 'Boleto Avulso',
            data: 'PG_BOLETO_AVULSO',
            icon: 'fa fa-barcode'
          }
        ]
      },
      {
        label: 'Boleto Fluxo',
        data: 'BOLETO_FLUXO',
        icon: 'fa fa-barcode',
        children: [
          {
            label: 'Consultar',
            data: 'BOLETO_FLUXO_CONSULTAR',
            icon: 'pi pi-search'
          },
          {
            label: 'Visualizar Comprador',
            data: 'BOLETO_FLUXO_COMPRADOR',
            icon: 'pi pi-user'
          },
          {
            label: 'Editar Nome Comprador',
            data: 'BOLETO_FLUXO_COMPRADOR_NOME',
            icon: 'pi pi-user-edit'
          },
          {
            label: 'Baixar Boleto',
            data: 'BOLETO_FLUXO_DOWNLOAD',
            icon: 'pi pi-download'
          }
        ]
      },
      {
        label: 'Boleto Recorrente',
        data: 'BOLETO_RECORRENTE',
        icon: 'fa fa-barcode',
        children: [
          {
            label: 'Consultar',
            data: 'BOLETO_RECORRENTE_CONSULTAR',
            icon: 'pi pi-search'
          },
          {
            label: 'Visualizar Comprador',
            data: 'BOLETO_RECORRENTE_COMPRADOR',
            icon: 'pi pi-user'
          },
          {
            label: 'Editar Nome Comprador',
            data: 'BOLETO_RECORRENTE_COMPRADOR_NOME',
            icon: 'pi pi-user-edit'
          },
          {
            label: 'Baixar Boleto',
            data: 'BOLETO_RECORRENTE_DOWNLOAD',
            icon: 'pi pi-download'
          }
        ]
      },
      {
        label: 'Extrato',
        data: 'EXTRATO',
        icon: 'fa fa-file-invoice-dollar',
        children: [
          {
            label: 'Consultar',
            data: 'EXTRATO_CONSULTAR',
            icon: 'pi pi-search'
          },
          {
            label: 'Visualizar Comprador',
            data: 'EXTRATO_COMPRADOR',
            icon: 'pi pi-user'
          },
          {
            label: 'Editar Nome Comprador',
            data: 'EXTRATO_COMPRADOR_NOME',
            icon: 'pi pi-user-edit'
          },
          {
            label: 'Baixar Boleto',
            data: 'EXTRATO_DOWNLOAD',
            icon: 'pi pi-download'
          },
          {
            label: 'Efetuar Transferência',
            data: 'EXTRATO_TRANSFER',
            icon: 'fas fa-exchange-alt'
          }
        ]
      },
      {
        label: 'Conciliação',
        data: 'CONCILIACAO',
        icon: 'fas fa-check',
        children: [
          {
            label: 'Consultar',
            data: 'CONCILIACAO_CONSULTAR',
            icon: 'pi pi-search'
          },
          {
            label: 'Conciliar',
            data: 'CONCILIACAO_CONCILIAR',
            icon: 'fas fa-check'
          },
          {
            label: 'Excluir',
            data: 'CONCILIACAO_EXCLUIR',
            icon: 'pi pi-trash'
          }
        ]
      },
      {
        label: 'Saque',
        data: 'SAQUE',
        icon: 'fa fa-hand-holding-usd',
        children: [
          {
            label: 'Consultar',
            data: 'SAQUE_CONSULTAR',
            icon: 'pi pi-search'
          },
          {
            label: 'Solicitar',
            data: 'SAQUE_SOLICITAR',
            icon: 'fa fa-hand-holding'
          }
        ]
      },
      {
        label: 'Depósito',
        data: 'DEPOSITO',
        icon: 'pi pi-money-bill',
        children: [
          {
            label: 'Consultar',
            data: 'DEPOSITO_CONSULTAR',
            icon: 'pi pi-search'
          },
          {
            label: 'Visualizar Detalhe',
            data: 'DEPOSITO_VISUALIZAR',
            icon: 'fa fa-eye'
          }
        ]
      },
      {
        label: 'Pedidos',
        data: 'PEDIDO',
        icon: 'pi pi-shopping-cart',
        children: [
          {
            label: 'Consultar',
            data: 'PEDIDO_CONSULTAR',
            icon: 'pi pi-search'
          },
          {
            label: 'Visualizar Detalhe',
            data: 'PEDIDO_VISUALIZAR',
            icon: 'fa fa-eye'
          },
          {
            label: 'Visualizar Comprador',
            data: 'PEDIDO_COMPRADOR',
            icon: 'pi pi-user'
          },
          {
            label: 'Editar Nome Comprador',
            data: 'PEDIDO_COMPRADOR_NOME',
            icon: 'pi pi-user-edit'
          },
          {
            label: 'Baixar Boleto',
            data: 'PEDIDO_DOWNLOAD',
            icon: 'pi pi-download'
          },
          {
            label: 'Totalizações',
            data: 'PEDIDO_TOTAL',
            icon: 'fa fa-hand-holding-usd'
          }
        ]
      },
      {
        label: 'Recorrente',
        data: 'RECORRENTE',
        icon: 'fa fa-chart-line',
        children: [
          {
            label: 'Boleto',
            data: 'RECORRENTE_BOLETO',
            icon: 'fa fa-barcode'
          }
        ]
      },
      {
        label: 'Link Pagamento',
        data: 'LINK',
        icon: 'fa fa-link',
        children: [
          {
            label: 'Criar',
            data: 'LINK_CRIAR',
            icon: 'pi pi-plus'
          },
          {
            label: 'Consultar',
            data: 'LINK_CONSULTAR',
            icon: 'pi pi-search'
          },
          {
            label: 'Editar',
            data: 'LINK_EDITAR',
            icon: 'pi pi-pencil'
          },
          {
            label: 'Excluir',
            data: 'LINK_EXCLUIR',
            icon: 'pi pi-trash'
          }
        ]
      },
      {
        label: 'Link Recorrente',
        data: 'LINK_RECORRENTE',
        icon: 'fa fa-link',
        children: [
          {
            label: 'Criar',
            data: 'LINK_RECORRENTE_CRIAR',
            icon: 'pi pi-plus'
          },
          {
            label: 'Consultar',
            data: 'LINK_RECORRENTE_CONSULTAR',
            icon: 'pi pi-search'
          },
          {
            label: 'Editar',
            data: 'LINK_RECORRENTE_EDITAR',
            icon: 'pi pi-pencil'
          },
          {
            label: 'Excluir',
            data: 'LINK_RECORRENTE_EXCLUIR',
            icon: 'pi pi-trash'
          }
        ]
      },
      {
        label: 'Configurações',
        data: 'CONFIG',
        icon: 'fa fa-cog',
        children: [
          {
            label: 'Cadastro',
            data: 'CONFIG_CADASTRO',
            icon: 'fa fa-building',
            children: [
              {
                label: 'Consultar',
                data: 'CONFIG_CADASTRO_CONSULTAR',
                icon: 'pi pi-search',
              },
              {
                label: 'Editar',
                data: 'CONFIG_CADASTRO_EDITAR',
                icon: 'pi pi-pencil',
              }
            ]
          },
          {
            label: 'Contas',
            data: 'CONFIG_CONTA',
            icon: 'fa fa-file-invoice-dollar',
            children: [
              {
                label: 'Criar',
                data: 'CONFIG_CONTA_CRIAR',
                icon: 'pi pi-plus'
              },
              {
                label: 'Consultar',
                data: 'CONFIG_CONTA_CONSULTAR',
                icon: 'pi pi-search'
              },
              {
                label: 'Editar',
                data: 'CONFIG_CONTA_EDITAR',
                icon: 'pi pi-pencil'
              },
              {
                label: 'Excluir',
                data: 'CONFIG_CONTA_EXCLUIR',
                icon: 'pi pi-trash'
              }
            ]
          },
          {
            label: 'Terminais Virtuais',
            data: 'CONFIG_TERMINAL',
            icon: 'fa fa-tv',
            children: [
              {
                label: 'Criar',
                data: 'CONFIG_TERMINAL_CRIAR',
                icon: 'pi pi-plus'
              },
              {
                label: 'Consultar',
                data: 'CONFIG_TERMINAL_CONSULTAR',
                icon: 'pi pi-search'
              },
              {
                label: 'Editar',
                data: 'CONFIG_TERMINAL_EDITAR',
                icon: 'pi pi-pencil'
              },
              {
                label: 'Excluir',
                data: 'CONFIG_TERMINAL_EXCLUIR',
                icon: 'pi pi-trash'
              }
            ]
          },
          {
            label: 'Usuários',
            data: 'CONFIG_USUARIO',
            icon: 'pi pi-user',
            children: [
              {
                label: 'Criar',
                data: 'CONFIG_USUARIO_CRIAR',
                icon: 'pi pi-plus'
              },
              {
                label: 'Consultar',
                data: 'CONFIG_USUARIO_CONSULTAR',
                icon: 'pi pi-search'
              },
              {
                label: 'Editar',
                data: 'CONFIG_USUARIO_EDITAR',
                icon: 'pi pi-pencil'
              },
              {
                label: 'Excluir',
                data: 'CONFIG_USUARIO_EXCLUIR',
                icon: 'pi pi-trash'
              },
              {
                label: 'Redefinir Senha',
                data: 'CONFIG_USUARIO_RESET',
                icon: 'fa fa-undo-alt'
              }
            ]
          },
          {
            label: 'Grupos',
            data: 'CONFIG_GRUPO',
            icon: 'pi pi-users',
            children: [
              {
                label: 'Criar',
                data: 'CONFIG_GRUPO_CRIAR',
                icon: 'pi pi-plus'
              },
              {
                label: 'Consultar',
                data: 'CONFIG_GRUPO_CONSULTAR',
                icon: 'pi pi-search'
              },
              {
                label: 'Editar',
                data: 'CONFIG_GRUPO_EDITAR',
                icon: 'pi pi-pencil'
              },
              {
                label: 'Excluir',
                data: 'CONFIG_GRUPO_EXCLUIR',
                icon: 'pi pi-trash'
              }
            ]
          }
        ]
      }
    ];
  }

}
