import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Pedido } from './pedido.model';
import { TotalGroupDTO } from './total-group-dto.model';
import { TotalDayDTO } from './total-day-dto.model';
import { GroupFormaDTO } from './group-forma-dto.model';
import { PedidoFilter } from './pedido-filter';
import { PedidoDTO } from './pedido-dto';
import { BoletoDTO } from '../boleto/boleto-dto';

@Injectable({
  providedIn: 'root'
})
export class PedidoService {

  constructor(private http: HttpClient) { }

  getPedidos(pedidoFilter: PedidoFilter): Observable<Pedido[]> {
    const url = `${environment.apiUrl}/pedidos`;
    return this.http.get<Pedido[]>(url, { params: pedidoFilter.getSearchParams() });
  }

  getPedidosDTO(pedidoFilter: PedidoFilter): Observable<PedidoDTO[]> {
    const url = `${environment.apiUrl}/pedidos/dto`;
    return this.http.get<PedidoDTO[]>(url, { params: pedidoFilter.getSearchParams() });
  }

  // getPedidosDTOByAssinaturaId(assinaturaId: string): Observable<PedidoDTO[]> {
  //   const url = `${environment.apiUrl}/pedidos/dto/${assinaturaId}`;
  //   return this.http.get<PedidoDTO[]>(url);
  // }

  getPedidosRecorrentes(pedidoFilter: PedidoFilter): Observable<PedidoDTO[]> {
    const url = `${environment.apiUrl}/pedidos/recorrentes`;
    return this.http.get<PedidoDTO[]>(url, { params: pedidoFilter.getSearchParams() });
  }

  getPedidosDependentes(parentId: string): Observable<PedidoDTO[]> {
    const url = `${environment.apiUrl}/pedidos/${parentId}/dependentes`;
    return this.http.get<PedidoDTO[]>(url);
  }

  getPedido(pedidoId: string): Observable<Pedido> {
    const url = `${environment.apiUrl}/pedidos/${pedidoId}`;
    return this.http.get<Pedido>(url);
  }

  getTotalPendentePorLicencaId(licencaId: string): Observable<TotalGroupDTO> {
    const url = `${environment.apiUrl}/pedidos/total/${licencaId}/1`;
    return this.http.get<TotalGroupDTO>(url);
  }

  getTotalPagoPorLicencaId(licencaId: string): Observable<TotalGroupDTO> {
    const url = `${environment.apiUrl}/pedidos/total/${licencaId}/3`;
    return this.http.get<TotalGroupDTO>(url);
  }

  getTotalCanceladoPorLicencaId(licencaId: string): Observable<TotalGroupDTO> {
    const url = `${environment.apiUrl}/pedidos/total/${licencaId}/7`;
    return this.http.get<TotalGroupDTO>(url);
  }

  getEstatisticaVendas(licencaId: string): Observable<TotalGroupDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/licenca/${licencaId}/status/0,1,2,3,4,5,6,7,8,9,10,11,13`;
    return this.http.get<TotalGroupDTO[]>(url);
  }

  getEstatisticaFluxo(licencaId: string): Observable<TotalDayDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/dia/licenca/${licencaId}/status/1,3,4,7,13`;
    return this.http.get<TotalDayDTO[]>(url);
  }

  getVendasDia(pedidoFilter: PedidoFilter): Observable<TotalDayDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/dia`;
    return this.http.get<TotalDayDTO[]>(url, { params: pedidoFilter.getSearchParams() });
  }

  getVendasSemana(pedidoFilter: PedidoFilter): Observable<TotalDayDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/semana`;
    return this.http.get<TotalDayDTO[]>(url, { params: pedidoFilter.getSearchParams() });
  }

  getVendasHora(pedidoFilter: PedidoFilter): Observable<TotalDayDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/hora`;
    return this.http.get<TotalDayDTO[]>(url, { params: pedidoFilter.getSearchParams() });
  }

  getEstatisticaForma(licencaId: string): Observable<GroupFormaDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/forma/licenca/${licencaId}`;
    return this.http.get<GroupFormaDTO[]>(url);
  }

  getEstatisticaBandeiras(licencaId: string): Observable<GroupFormaDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/bandeiras/licenca/${licencaId}`;
    return this.http.get<GroupFormaDTO[]>(url);
  }

  getEstatisticaDebito(licencaId: string): Observable<GroupFormaDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/debito/licenca/${licencaId}`;
    return this.http.get<GroupFormaDTO[]>(url);
  }

  getEstatisticaProdutos(licencaId: string): Observable<GroupFormaDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/produtos/licenca/${licencaId}`;
    return this.http.get<GroupFormaDTO[]>(url);
  }

  getDashboardClientes(licencaId: string): Observable<GroupFormaDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/clientes/licenca/${licencaId}`;
    return this.http.get<GroupFormaDTO[]>(url);
  }

  getTotalPedidosPorLicenca(licencaId: string): Observable<number> {
    const url = `${environment.apiUrl}/pedidos/total/${licencaId}`;
    return this.http.get<number>(url);
  }

  //Deprecated
  getTotalDepositosPorEmpresa(empresaId: string): Observable<number> {
    const url = `${environment.apiUrl}/pedidos/depositos/empresa/${empresaId}`;
    return this.http.get<number>(url);
  }

  getTotalDepositosPorLicenca(licencaId: string): Observable<number> {
    const url = `${environment.apiUrl}/pedidos/depositos/licenca/${licencaId}`;
    return this.http.get<number>(url);
  }

  getTotalReembolsosPorEmpresa(empresaId: string): Observable<number> {
    const url = `${environment.apiUrl}/pedidos/total/reembolsos/${empresaId}`;
    return this.http.get<number>(url);
  }

  getReembolsos(empresaId: string): Observable<any[]> {
    const url = `${environment.apiUrl}/pedidos/reembolsos/${empresaId}`;
    return this.http.get<any[]>(url);
  }

  getDisponivel(licencaId: string): Observable<number> {
    const url = `${environment.apiUrl}/lancamentos/disponivel/${licencaId}`;
    return this.http.get<number>(url);
  }

  getPendente(licencaId: string): Observable<number> {
    const url = `${environment.apiUrl}/saques/pendente/${licencaId}`;
    return this.http.get<number>(url);
  }

  cancelarAssinatura(pedidoId: string): Observable<Pedido> {
    const url = `${environment.apiUrl}/pedidos/${pedidoId}/recorrente/cancelar`;
    return this.http.get<Pedido>(url);
  }

  getBoletosDTO(pedidoFilter: PedidoFilter): Observable<BoletoDTO[]> {
    const url = `${environment.apiUrl}/pedidos/boletos/dto`;
    return this.http.get<BoletoDTO[]>(url, { params: pedidoFilter.getSearchParams() });
  }

  atualizarNomeComprador(pedidoId: string, name: string): Observable<void> {
    const url = `${environment.apiUrl}/pedidos/${pedidoId}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8;'
    });
    const body = `name=${name}`;
    console.log('Update Name Comprador Body:', body);
    return this.http.patch<void>(url, body, { headers });
  }

  estornar(pedidoId: string): Observable<void> {
    const url = `${environment.apiUrl}/pedidos/${pedidoId}/estornar`;
    return this.http.put<void>(url, {}, {});
  }

}
