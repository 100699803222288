<form autocomplete="off" (ngSubmit)="recuperarSenha();">
  
  <div class="ui-g ui-fluid">

    <div class="ui-g-12 margin-bottom-20">
      <h1>Recuperar Senha</h1>
    </div>

    <div class="ui-g-12 margin-bottom-20">
      <span class="ui-float-label">
        <input id="recuperarEmail" pInputText required name="email" [(ngModel)]="email" #emailField>
        <label for="recuperarEmail">E-mail</label>
      </span>
    </div>

    <div class="ui-g-12">
      <button pButton type="submit" label="Recuperar Senha" icon="pi pi-sign-in"></button>
    </div>

  </div>

</form>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>