<form (ngSubmit)="salvar()">

    <div class="ui-g">

        <div class="ui-g-12">
            <h1>Link de Pagamento</h1>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
            <label for="licencaId">Informe o contrato</label>
            <p-dropdown id="licencaId" name="licencaId" [options]="licencasOptions" [(ngModel)]="paymentLink.licencaId"
                #linkContrato></p-dropdown>
        </div>


        <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
            <label for="descricao">O que deseja vender?</label>
            <textarea pInputTextarea id="descricao" name="descricao" [rows]="4" [(ngModel)]="paymentLink.descricao"
                maxlength="100" #linkDescricao>
            </textarea>
            <span class="char-limit">{{ paymentLink.descricao.length }} / 100</span>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
            <label for="preco">Qual o preço?</label>
            <input pInputText id="preco" name="preco" [(ngModel)]="valor" maxlength="20" [pKeyFilter]="regExValor"
                (blur)="changeValor()" onFocus="this.select();" #linkPreco>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
            <label for="ativarBoleto">Permite receber com boleto?</label>
            <p-inputSwitch id="ativarBoleto" name="ativarBoleto" [(ngModel)]="paymentLink.ativarBoleto"
                (onChange)="selectAtivarBoleto()"></p-inputSwitch>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid" *ngIf="paymentLink.ativarBoleto">
            <label for="preco">Em quantos dias vence o boleto?</label>
            <input pInputText id="diasVenctoBoleto" name="diasVenctoBoleto" [(ngModel)]="paymentLink.diasVenctoBoleto"
                maxlength="2" pKeyFilter="pint" #diasVenctoBoleto>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid" *ngIf="paymentLink.ativarBoleto">
            <label for="tpMulta">Tipo de Multa *</label>
            <p-dropdown id="tpMulta" name="tpMulta" [options]="tpMultaOptions" [(ngModel)]="paymentLink.tpMulta"
                (onChange)="selectTpMulta()">
            </p-dropdown>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid" *ngIf="paymentLink.ativarBoleto && paymentLink.tpMulta !== '3'">
            <label for="valorMulta">Valor/Percentual de Multa</label>
            <input pInputText id="valorMulta" name="valorMulta" [(ngModel)]="valorMulta" maxlength="8"
                [pKeyFilter]="regExValor" placeholder="0,00" onFocus="this.select();">
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid" *ngIf="paymentLink.ativarBoleto">
            <label for="tpJuros">Tipo de Juros *</label>
            <p-dropdown id="tpJuros" name="tpJuros" [options]="tpJurosOptions" [(ngModel)]="paymentLink.tpJuros"
                (onChange)="selectTpJuros()">
            </p-dropdown>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid" *ngIf="paymentLink.ativarBoleto && paymentLink.tpJuros !== '5'">
            <label for="valorJuros">Valor/Percentual de Juros</label>
            <input pInputText id="valorJuros" name="valorJuros" [(ngModel)]="valorJuros" maxlength="8"
                [pKeyFilter]="regExValor" placeholder="0,00" onFocus="this.select();">
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
            <label for="ativarCartao">Permite receber com cartão?</label>
            <p-inputSwitch id="ativarCartao" name="ativarCartao" [(ngModel)]="paymentLink.ativarCartao"></p-inputSwitch>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid" *ngIf="parcelasOptions.length > 1 && paymentLink.ativarCartao">
            <label for="parcelamentoCartao">Permite parcelamento no cartão?</label>
            <p-dropdown id="parcelamentoCartao" name="parcelamentoCartao" [options]="parcelasOptions"
                [(ngModel)]="paymentLink.parcelamentoCartao" #parcelamentoCartaoElRef></p-dropdown>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid" *ngIf="parcelasOptions.length > 1 && paymentLink.ativarCartao">
            <label for="parcelamentoJuros">Incluir juros no parcelamento?</label>
            <p-inputSwitch id="parcelamentoJuros" name="parcelamentoJuros" [(ngModel)]="paymentLink.parcelamentoJuros">
            </p-inputSwitch>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
            <label for="ativo">Link ativo?</label>
            <p-inputSwitch id="ativo" name="ativo" [(ngModel)]="paymentLink.ativo"></p-inputSwitch>
        </div>

        <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="getMelhorLink(paymentLink)">
            <label for="linkReduzido">Link:</label>
            <span class="paymentLink" id="linkReduzido">{{ getMelhorLink(paymentLink) }}</span>
            <button pButton type="button" label="copiar" icon="far fa-copy" class="ui-button-rounded ui-button-success"
                (click)="copyLinkReduzido()"></button>
        </div>

        <div class="ui-g-12">
            <p-button type="submit" label="Salvar" icon="pi pi-save"></p-button>
        </div>

    </div>

</form>

<p-blockUI [blocked]="blocked">
    <p-progressSpinner class="spinner-center">
    </p-progressSpinner>
</p-blockUI>
