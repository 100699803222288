<form (ngSubmit)="salvar()">

  <div class="ui-g">

    <div class="ui-g-12">
      <h1>Usuário</h1>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="nome">Nome</label>
      <input pInputText id="nome" name="nome" [(ngModel)]="usuario.nome" maxlength="120" #usuarioNome>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="apelido">Apelido</label>
      <input pInputText id="apelido" name="apelido" [(ngModel)]="usuario.apelido" maxlength="20" #usuarioApelido>
    </div>

    <div class="ui-g-3 ui-md-3 ui-lg-2 ui-fluid">
      <label for="ddd">DDD</label>
      <input pInputText pKeyFilter="pint" id="ddd" name="ddd" [(ngModel)]="usuario.ddd" maxlength="2" #usuarioDdd >
    </div>

    <div class="ui-g-9 ui-md-9 ui-lg-10 ui-fluid">
      <label for="celular">Celular</label>
      <input pInputText pKeyFilter="pint" id="celular" name="celular" [(ngModel)]="usuario.celular" maxlength="9" #usuarioCelular>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="grupos">Grupos</label>
      <p-multiSelect id="grupos" [options]="grupos" name="grupos" [(ngModel)]="usuario.grupos" defaultLabel="Selecione" #usuarioGrupos>
      </p-multiSelect>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="email">E-mail</label>
      <input input pInputText pKeyFilter="email" id="email" name="email" [(ngModel)]="usuario.email" maxlength="80" #usuarioEmail>
    </div>

    <div class="ui-g-12">
      <p-button type="submit" label="Salvar" icon="pi pi-save"></p-button>
    </div>

  </div>

</form>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>
