<div class="pgw--container">

  <div class="ui-g">

    <div class="ui-g-12">
      <h1>Depósitos</h1>
    </div>

    <div class="ui-g-12 ui-g-nopad">
      <div class="ui-g-12 ui-fluid" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
        <p-dropdown [options]="empresasOptions" [(ngModel)]="saqueFilter.empresaId" (onChange)="buscarLicencas()" filter="true" resetFilterOnHide="true">
        </p-dropdown>
      </div>
    </div>

  </div>

  <div class="ui-g" *ngIf="hasPermission('DEPOSITO_CONSULTAR')">

    <div class="ui-g-12 ui-md-6 ui-lg-9 ui-fluid">
      <p-dropdown [options]="licencasOptions" [(ngModel)]="saqueFilter.licencaId" pTooltip="Contratos"
        tooltipPosition="top" (onChange)="buscar()">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-5 ui-lg-2 ui-fluid">
      <p-calendar [(ngModel)]="saqueFilter.rangeDates" selectionMode="range" [readonlyInput]="true"
        dateFormat="dd/mm/yy" placeholder="Período" pTooltip="Data de Início ou Período" tooltipPosition="top">
      </p-calendar>
    </div>

    <div class="ui-g-12 ui-md-1 ui-lg-1">
      <button pButton type="button" icon="pi pi-search" (click)="buscar()"></button>
    </div>

    <div class="ui-g-12 ui-fluid">

      <p-table [value]="saques" [paginator]="false" [responsive]="true" selectionMode="single"
        (onRowSelect)="onRowSelect($event.data)">
        <ng-template pTemplate="header">
          <tr>
            <th class="pgw--text-center" style="width: 120px;">Dt./Hr. Solicitação</th>
            <th class="pgw--text-center" style="width: 120px;">Dt./Hr. Liquidação</th>
            <th class="pgw--text-center" style="width: 120px;">Status</th>
            <th class="pgw--text-center">Conta</th>
            <th class="pgw--text-right" style="width: 120px;">Valor</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-saque>
          <tr [pSelectableRow]="saque">
            <td class="pgw--text-center">{{ saque.dtHrSolicitacao | date:'dd/MM/yyyy HH:mm:ss' }}</td>

            <td class="pgw--text-center">{{ saque.dtHrLiquidacao | date:'dd/MM/yyyy HH:mm:ss' }}</td>

            <td class="pgw--text-center">{{ saque.status }}</td>

            <td class="pgw--text-center">
              <span style="display: block;">{{ saque.banco }} | {{ saque.agencia }} | {{ saque.conta }}</span>
              <span style="display: block; color: #888;">{{ saque.titular }}</span>
            </td>

            <td class="pgw--text-right" *ngIf="saqueFilter.moeda === 'BRL'">
              {{ saque.valor | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="pgw--text-right" *ngIf="saqueFilter.moeda === 'USD'">
              {{ saque.valor | currency:'USD':'symbol':'0.2-2':'en' }}</td>

          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>

            <td class="pgw--text-left" colspan="3">
              Solicitações de Depósito: {{ qtdDepositos }}
            </td>

            <td class="pgw--text-right">
              Total Liquidado
            </td>

            <td class="pgw--text-right" *ngIf="saqueFilter.moeda === 'BRL'">
              {{ totalDepositos | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="pgw--text-right" *ngIf="saqueFilter.moeda === 'USD'">
              {{ totalDepositos | currency:'USD':'symbol':'0.2-2':'en' }}</td>

          </tr>
        </ng-template>
      </p-table>
    </div>

    <nav *ngIf="exibirSaque">
      <p-sidebar [(visible)]="exibirSaque" [fullScreen]="true">
        <div class="pgw--container">
          <form (ngSubmit)="salvar()">
            <div class="ui-g">

              <div class="ui-g-12">
                <button pButton type="button" (click)="exibirSaque = false" label="Voltar"
                  icon="pi pi-step-backward"></button>
              </div>

              <div class="ui-g-12">
                <h1>Solicitação de Saque</h1>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Data/Hora Solicitação:
                </div>
                <div>
                  {{ saqueSelecionado.dtHrSolicitacao | date:'dd/MM/yyyy - HH:mm:ss' }}
                </div>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Data/Hora Agendamento:
                </div>
                <div style="min-height: 15px;">
                  {{ saqueSelecionado.dtHrAgendamento | date:'dd/MM/yyyy - HH:mm:ss' }}
                </div>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Data Previsão:
                </div>
                <p-calendar [(ngModel)]="dtPrevisao" dateFormat="dd/mm/yy" name="dtPrevisao" [showIcon]="true"
                  placeholder="Selecione uma data" [disabled]="saqueSelecionado.status === 'LIQUIDADO' || !pgw">
                </p-calendar>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Data/Hora Liquidação:
                </div>
                <div style="min-height: 15px;">
                  {{ saqueSelecionado.dtHrLiquidacao | date:'dd/MM/yyyy - HH:mm:ss' }}
                </div>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Data/Hora Cancelamento:
                </div>
                <div style="min-height: 15px;">
                  {{ saqueSelecionado.dtHrCancelamento | date:'dd/MM/yyyy - HH:mm:ss' }}
                </div>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Status:
                </div>
                <div style="min-height: 15px;">
                  {{ saqueSelecionado.status }}
                </div>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-6 ui-fluid">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Histórico:
                </div>
                <input pInputText id="historico" name="historico" [(ngModel)]="saqueSelecionado.historico"
                  [disabled]="saqueSelecionado.status == 'LIQUIDADO' || !pgw" />
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-4 ui-fluid">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Autorização:
                </div>
                <input pInputText id="autorizacao" name="autorizacao" [(ngModel)]="saqueSelecionado.autorizacao"
                  [disabled]="saqueSelecionado.status == 'LIQUIDADO' || !pgw" />
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Forma de Pagamento:
                </div>
                <p-dropdown [options]="formasPagtoOptions" id="formaPagamento" name="formaPagamento"
                  [(ngModel)]="saqueSelecionado.formaPagamento"
                  [disabled]="saqueSelecionado.status == 'LIQUIDADO' || !pgw"></p-dropdown>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Banco:
                </div>
                <div style="min-height: 15px;">
                  {{ saqueSelecionado.banco }}
                </div>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Agência:
                </div>
                <div style="min-height: 15px;">
                  {{ saqueSelecionado.agencia }}
                </div>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Conta:
                </div>
                <div style="min-height: 15px;">
                  {{ saqueSelecionado.conta }}
                </div>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Titular:
                </div>
                <div style="min-height: 15px;">
                  {{ saqueSelecionado.titular }}
                </div>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  CPF/CNPJ:
                </div>
                <div style="min-height: 15px;">
                  {{ saqueSelecionado.cpfCnpj }}
                </div>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Moeda:
                </div>
                <div style="min-height: 15px;">
                  {{ saqueSelecionado.moeda }}
                </div>
              </div>

              <div class="ui-g-12">
                <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px; margin-top: 10px;">
                  Valor Solicitado:
                </div>
                <div *ngIf="saqueSelecionado.moeda === 'BRL'"
                  style="min-height: 20px; font-weight: bold; font-size: 1.2em;">
                  {{ saqueSelecionado.valor | currency:'BRL':'symbol':'0.2-2':'pt' }}</div>
                <div *ngIf="saqueSelecionado.moeda === 'USD'"
                  style="min-height: 20px; font-weight: bold; font-size: 1.2em;">
                  {{ saqueSelecionado.valor | currency:'USD':'symbol':'0.2-2':'en' }}</div>
              </div>

            </div>
            <div class="ui-g mt-20" *ngIf="pgw">

              <div class="ui-g-12 ui-md-12 ui-lg-6 ui-fluid"
                *ngIf="saqueSelecionado.status === 'PENDENTE' || saqueSelecionado.status === 'AGENDADO'">
                <p-dropdown [options]="bancosOrigemOptions" [(ngModel)]="saqueSelecionado.bancoOrigem"
                  name="bancoOrigem">
                </p-dropdown>
              </div>

              <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid"
                *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com') && (saqueSelecionado.status === 'PENDENTE' || saqueSelecionado.status === 'AGENDADO')">
                <button pButton type="button" (click)="historicoPadrao()" label="Hist. Padrão" icon="pi pi-clone"
                  class="ui-button-primary"></button>
              </div>
              <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid"
                *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com') && (saqueSelecionado.status === 'PENDENTE' || saqueSelecionado.status === 'AGENDADO')">
                <button pButton type="submit" (click)="action='LIQUIDAR'" label="Liquidar" icon="pi pi-money-bill"
                  class="ui-button-success"></button>
              </div>
              <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid"
                *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com') && (saqueSelecionado.status === 'PENDENTE' || saqueSelecionado.status === 'AGENDADO')">
                <button pButton type="submit" (click)="action='EXCLUIR'" label="Excluir" icon="fas fa-trash-alt"
                  class="ui-button-danger"></button>
              </div>

            </div>
          </form>

          <div class="ui-g">
            <div class="ui-g-12" style="margin-top: 20px;">
              <button pButton type="button" (click)="exibirSaque = false" label="Voltar"
                icon="pi pi-step-backward"></button>
            </div>
          </div>
        </div>
      </p-sidebar>
    </nav>

    <nav *ngIf="exibirPedido">
      <p-sidebar [(visible)]="exibirPedido" position="left" styleClass="ui-sidebar-md">

        <app-pedido-detalhe origem="SAQUE" [pedido]="pedidoSelecionado" [licenca]="licencaSelecionada"></app-pedido-detalhe>

      </p-sidebar>
    </nav>
  </div>
</div>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>
