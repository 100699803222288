import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Saque } from './saque.model';
import { SaqueFilter } from './saque-filter';
import { Pedido } from '../pedido/pedido.model';
import { PedidoDTO } from '../pedido/pedido-dto';
import { PedidoFilter } from '../pedido/pedido-filter';
import { TotalDayDTO } from '../pedido/total-day-dto.model';

@Injectable({
  providedIn: 'root'
})
export class SaqueService {

  saqueEvent = new EventEmitter();

  constructor(private http: HttpClient) { }

  solicitar(saque: Saque): Observable<Saque> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<Saque>(`${environment.apiUrl}/saques`, saque, { headers });
  }

  atualizar(saque: Saque): Observable<Saque> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<Saque>(`${environment.apiUrl}/saques`, saque, { headers });
  }

  excluir(saqueId: string): Observable<void> {
    const url = `${environment.apiUrl}/saques/${saqueId}`;
    return this.http.delete<void>(url);
  }

  getDepositos(saqueFilter: SaqueFilter): Observable<Saque[]> {
    const url = `${environment.apiUrl}/saques`;
    return this.http.get<Saque[]>(url, { params: saqueFilter.getSearchParams() });
  }

  getDeposito(saqueId: string): Observable<Saque> {
    const url = `${environment.apiUrl}/saques/${saqueId}`;
    return this.http.get<Saque>(url);
  }

  getPedidosDTOPorSaqueId(saqueId: string): Observable<PedidoDTO[]> {
    const url = `${environment.apiUrl}/saques/pedidos/${saqueId}`;
    return this.http.get<PedidoDTO[]>(url);
  }

  getPedidosDTO(pedidoFilter: PedidoFilter): Observable<PedidoDTO[]> {
    const url = `${environment.apiUrl}/saques/pedidos`;
    return this.http.get<PedidoDTO[]>(url, { params: pedidoFilter.getSearchParams() });
  }


  getDepositosDia(saqueFilter: SaqueFilter): Observable<TotalDayDTO[]> {
    const url = `${environment.apiUrl}/saques/group/dia`;
    return this.http.get<TotalDayDTO[]>(url, { params: saqueFilter.getSearchParams() });
  }
}
