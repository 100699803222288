<div class="pgw--container">

  <div class="ui-g">

    <div class="ui-g-12 border-botton">
      <h1>Links de Pagamento Recorrente</h1>
    </div>

  </div>

  <div class="ui-g" style="padding: 20px 0px;">

    <div class="ui-g-12 ui-fluid" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
      <p-dropdown [options]="empresasOptions" [(ngModel)]="recorrenteLinkFilter.empresaId" (onChange)="buscarLicencas()" filter="true" resetFilterOnHide="true" >
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-fluid">
      <p-dropdown [options]="licencasOptions" [(ngModel)]="recorrenteLinkFilter.licencaId" pTooltip="Contratos"
        tooltipPosition="top" (onChange)="buscar()">
      </p-dropdown>
    </div>

    <div class="ui-g-12">
      <button pButton label="Criar Link" icon="pi pi-plus" class="ui-button-primary" (click)="criar()"
        *ngIf="hasPermission('LINK_RECORRENTE_CRIAR')"></button>
    </div>

    <div class="ui-g-12 ui-fluid">
      <p-table [value]="recorrenteLinks" [responsive]="true" selectionMode="single"
        (onRowSelect)="onRowSelect($event.data)">
        <ng-template pTemplate="header">
          <tr>
            <th>Produto / Serviço</th>
            <th style="width: 120px;">Periodicidade</th>
            <th style="width: 120px;">Link</th>
            <th style="width: 70px;">Ativo</th>
            <th style="width: 100px;">Preço</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-recorrenteLink>
          <tr [pSelectableRow]="recorrenteLink">
            <td class="pgw--text-left">{{ recorrenteLink.descricao }}</td>
            <td class="pgw--text-center">{{ recorrenteLink.periodicidade }}</td>
            <td class="pgw--text-center">
              <button pButton type="button" label="copiar" icon="far fa-copy"
                class="ui-button-rounded ui-button-primary" (click)="copy(getMelhorLink(recorrenteLink))"></button>
              <a *ngIf="false" href="javascript:void(0);" (click)="copy(getMelhorLink(recorrenteLink))">copiar link</a>
            </td>
            <td class="pgw--text-center">{{ recorrenteLink.ativo ? 'SIM' : 'NÃO' }}</td>
            <td class="pgw--text-right">{{ recorrenteLink.valorUnitario | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
          </tr>
        </ng-template>

      </p-table>
    </div>

  </div>

</div>

<nav *ngIf="exibirLink">
  <p-sidebar [(visible)]="exibirLink" position="left" styleClass="ui-sidebar-md">
    <app-recorrente-link-cadastro [recorrenteLink]="recorrenteLink" [licencasOptions]="licencasOptions" [editLock]="editLock"></app-recorrente-link-cadastro>
  </p-sidebar>
</nav>
