import { Injectable, EventEmitter } from '@angular/core';
import { Grupo } from './grupo.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GrupoService {

  grupoEvent = new EventEmitter();

  constructor(private http: HttpClient) { }

  criar(grupo: Grupo): Observable<Grupo> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<Grupo>(`${environment.apiUrl}/grupos`, grupo, { headers });
  }

  atualizar(grupo: Grupo): Observable<Grupo> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<Grupo>(`${environment.apiUrl}/grupos`, grupo, { headers });
  }

  excluir(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/grupos/${id}`);
  }

  buscarPorEmpresa(empresaId: string): Observable<Grupo[]> {
    return this.http.get<Grupo[]>(`${environment.apiUrl}/grupos/${empresaId}`);
  }

}
