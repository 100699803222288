<div class="ui-g">

  <div class="ui-g-12">
    <button pButton label="Criar Grupo" icon="pi pi-plus" class="ui-button-success" (click)="criar()"
      *ngIf="hasPermission('CONFIG_GRUPO_CRIAR')"></button>
  </div>

  <div class="ui-g-12 ui-fluid" *ngIf="hasPermission('CONFIG_GRUPO_CONSULTAR')">
    <p-table [value]="grupos" [paginator]="true" [rows]="10" [responsive]="true" selectionMode="single"
      (onRowSelect)="onRowSelect($event.data)">
      <ng-template pTemplate="header">
        <tr>
          <th>Grupo</th>
          <th style="width: 70px;" *ngIf="hasPermission('CONFIG_GRUPO_EXCLUIR')">Excluir</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-grupo>
        <tr [pSelectableRow]="grupo">
          <td class="col-center">{{ grupo.grupo }}</td>
          <td class="col-center" *ngIf="hasPermission('CONFIG_GRUPO_EXCLUIR')">
            <p-button icon="pi pi-trash" (click)="excluir(grupo.id)" title="Excluir Usuário"></p-button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="2">
            Nenhum grupo cadastrado!
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

</div>

<nav *ngIf="exibir">
  <p-sidebar [(visible)]="exibir" position="left" styleClass="ui-sidebar-md">
    <app-grupo-cadastro [grupo]="grupo"></app-grupo-cadastro>
  </p-sidebar>
</nav>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>
