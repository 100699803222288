import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { JwtModule } from '@auth0/angular-jwt';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { PasswordModule } from 'primeng/password';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { MessageService, ConfirmationService } from 'primeng/api';
import { KeyFilterModule } from 'primeng/keyfilter';
import { PanelModule } from 'primeng/panel';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { TreeModule } from 'primeng/tree';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TooltipModule } from 'primeng/tooltip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CaptchaModule } from 'primeng/captcha';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageModule } from 'primeng/message';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopoComponent } from './topo/topo.component';
import { EmpresaCadastroComponent } from './empresa/empresa-cadastro/empresa-cadastro.component';
import { LoginComponent } from './seguranca/login/login.component';
import { LicencaConsultaComponent } from './licenca/licenca-consulta/licenca-consulta.component';
import { LicencaCadastroComponent } from './licenca/licenca-cadastro/licenca-cadastro.component';
import { PedidoConsultaComponent } from './pedido/pedido-consulta/pedido-consulta.component';
import { HomeComponent } from './home/home.component';
import { PagseguroStatusDirective } from './pagseguro-status.directive';
import { PedidoDetalheComponent } from './pedido/pedido-detalhe/pedido-detalhe.component';
import { SaqueSolicitacaoComponent } from './saque/saque-solicitacao/saque-solicitacao.component';
import { BoletoEmissaoComponent } from './boleto/boleto-emissao/boleto-emissao.component';
import { BoletoSuccessComponent } from './boleto/boleto-success/boleto-success.component';
import { RedefinirSenhaComponent } from './seguranca/redefinir-senha/redefinir-senha.component';
import { UsuarioConsultaComponent } from './seguranca/usuario-consulta/usuario-consulta.component';
import { UsuarioCadastroComponent } from './seguranca/usuario-cadastro/usuario-cadastro.component';
import { ConfiguracoesComponent } from './configuracoes/configuracoes.component';
import { GrupoConsultaComponent } from './seguranca/grupo-consulta/grupo-consulta.component';
import { GrupoCadastroComponent } from './seguranca/grupo-cadastro/grupo-cadastro.component';
import { ContaConsultaComponent } from './empresa/conta-consulta/conta-consulta.component';
import { ContaCadastroComponent } from './empresa/conta-cadastro/conta-cadastro.component';
import { TrocarSenhaComponent } from './seguranca/trocar-senha/trocar-senha.component';
import { RecuperarSenhaComponent } from './seguranca/recuperar-senha/recuperar-senha.component';
import { ExtratoConsultaComponent } from './extrato/extrato-consulta/extrato-consulta.component';
import { SaqueConsultaComponent } from './saque/saque-consulta/saque-consulta.component';
import { EstatisticaHomeComponent } from './estatistica/estatistica-home/estatistica-home.component';
import { EstatisticaVendasComponent } from './estatistica/estatistica-vendas/estatistica-vendas.component';
import { EstatisticaDepositosComponent } from './estatistica/estatistica-depositos/estatistica-depositos.component';
import { environment } from 'src/environments/environment';
import { CompradorConsultaComponent } from './comprador-consulta/comprador-consulta.component';
import { PedidoDependentesComponent } from './pedido/pedido-dependentes/pedido-dependentes.component';
import { BoletoRecorrenteComponent } from './boleto/boleto-recorrente/boleto-recorrente.component';
import { LinkConsultaComponent } from './link/link-consulta/link-consulta.component';
import { LinkCadastroComponent } from './link/link-cadastro/link-cadastro.component';
import { FooterComponent } from './footer/footer.component';
import { EstatisticaClientesComponent } from './estatistica/estatistica-clientes/estatistica-clientes.component';
import { EstatisticaDisponivelComponent } from './estatistica/estatistica-disponivel/estatistica-disponivelcomponent';
import { EstatisticaProdutosComponent } from './estatistica/estatistica-produtos/estatistica-produtos.component';
import { EstatisticaProjetadoComponent } from './estatistica/estatistica-projetado/estatistica-projetado.component';
import { PedidoAntecipacaoComponent } from './pedido/pedido-antecipacao/pedido-antecipacao.component';
import { BoletoItauComponent } from './boleto/boleto-itau/boleto-itau.component';
import { BoletoConsultaComponent } from './boleto/boleto-consulta/boleto-consulta.component';
import { ConciliacaoConsultaComponent } from './conciliacao/conciliacao-consulta/conciliacao-consulta.component';
import { ConciliacaoCadastroComponent } from './conciliacao/conciliacao-cadastro/conciliacao-cadastro.component';
import { LoginValidarComponent } from './seguranca/login-validar/login-validar.component';
import { ExtratoTransferComponent } from './extrato/extrato-transfer/extrato-transfer.component';
import { RecorrenteLinkConsultaComponent } from './recorrente/recorrente-link-consulta/recorrente-link-consulta.component';
import { RecorrenteLinkCadastroComponent } from './recorrente/recorrente-link-cadastro/recorrente-link-cadastro.component';
import { RecorrenteConsultaComponent } from './recorrente/recorrente-consulta/recorrente-consulta.component';
import { RecorrenteDetalheComponent } from './recorrente/recorrente-detalhe/recorrente-detalhe.component';

registerLocaleData(localePt);

export function tokenGetter() {
  return localStorage.getItem('access_token');
}
@NgModule({
  declarations: [
    AppComponent,
    TopoComponent,
    EmpresaCadastroComponent,
    LoginComponent,
    LicencaConsultaComponent,
    LicencaCadastroComponent,
    PedidoConsultaComponent,
    HomeComponent,
    PagseguroStatusDirective,
    PedidoDetalheComponent,
    SaqueSolicitacaoComponent,
    BoletoEmissaoComponent,
    BoletoSuccessComponent,
    RedefinirSenhaComponent,
    UsuarioConsultaComponent,
    UsuarioCadastroComponent,
    ConfiguracoesComponent,
    GrupoConsultaComponent,
    GrupoCadastroComponent,
    ContaConsultaComponent,
    ContaCadastroComponent,
    TrocarSenhaComponent,
    RecuperarSenhaComponent,
    ExtratoConsultaComponent,
    SaqueConsultaComponent,
    EstatisticaHomeComponent,
    EstatisticaVendasComponent,
    EstatisticaDepositosComponent,
    CompradorConsultaComponent,
    PedidoDependentesComponent,
    BoletoRecorrenteComponent,
    LinkConsultaComponent,
    LinkCadastroComponent,
    FooterComponent,
    EstatisticaClientesComponent,
    EstatisticaDisponivelComponent,
    EstatisticaProdutosComponent,
    EstatisticaProjetadoComponent,
    PedidoAntecipacaoComponent,
    BoletoItauComponent,
    BoletoConsultaComponent,
    ConciliacaoConsultaComponent,
    ConciliacaoCadastroComponent,
    LoginValidarComponent,
    ExtratoTransferComponent,
    RecorrenteLinkConsultaComponent,
    RecorrenteLinkCadastroComponent,
    RecorrenteConsultaComponent,
    RecorrenteDetalheComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,

    JwtModule.forRoot({
      config: {
        tokenGetter,
        // whitelistedDomains: environment.whitelistedDomains,
        // blacklistedRoutes: environment.blacklistedRoutes
        allowedDomains: environment.whitelistedDomains,
        disallowedRoutes: environment.blacklistedRoutes
      }
    }),
    ButtonModule,
    TableModule,
    InputTextModule,
    PasswordModule,
    SidebarModule,
    ToastModule,
    CardModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    KeyFilterModule,
    PanelModule,
    DynamicDialogModule,
    FieldsetModule,
    TabViewModule,
    TreeModule,
    CheckboxModule,
    RadioButtonModule,
    TooltipModule,
    ConfirmDialogModule,
    InputSwitchModule,
    InputTextareaModule,
    InputNumberModule,
    SelectButtonModule,
    CaptchaModule,
    BlockUIModule,
    ProgressSpinnerModule,
    MessageModule,
    InputMaskModule,

    AppRoutingModule
  ],
  providers: [MessageService, ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
