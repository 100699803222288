import { Customer } from './customer.model';

export class BoletoVencto {

  vencimento: Date;
  valor: number;
  descricaoEspecifica: string;
  pedidoNumero: number;
  autorizacao: string;
  link: string;
  status: string;
  error: string;

}
