<div class="ui-g">

  <div class="ui-g-12 ui-g-nopad">
    <div class="ui-g-12 ui-fluid" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
      <p-dropdown [options]="empresasOptions" [(ngModel)]="pedidoFilter.empresaId" (onChange)="buscarLicencas()" filter="true" resetFilterOnHide="true">
      </p-dropdown>
    </div>
  </div>

  <div class="ui-g-12 ui-md-7 ui-lg-8 ui-fluid">
    <p-dropdown [options]="licencasOptions" [(ngModel)]="pedidoFilter.licencaId" pTooltip="Contratos"
      tooltipPosition="top">
    </p-dropdown>
  </div>

  <div class="ui-g-10 ui-md-4 ui-lg-3 ui-fluid">
    <p-calendar [(ngModel)]="pedidoFilter.rangeDates" selectionMode="range" [readonlyInput]="true" dateFormat="dd/mm/yy"
      placeholder="Período" pTooltip="Data de Início ou Período" tooltipPosition="top">
    </p-calendar>
  </div>

  <div class="ui-g-2 ui-md-1 ui-lg-1">
    <button pButton type="button" icon="pi pi-search" (click)="buscar()"></button>
  </div>

  <div class="ui-g-12">
    <div [hidden]="chartDiaOptions.series[0].data.length == 0 || !hasPermission('HOME_FLUXO-LINE')">
      <div id="chart-vendas-dia" class="dash-box"></div>
    </div>
    <div *ngIf="chartDiaOptions.series[0].data.length == 0 || !hasPermission('HOME_FLUXO-LINE')">
      <div class="dash-box">
        <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
        <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
      </div>
    </div>
  </div>

  <div class="ui-g-12">
    <div [hidden]="chartSemanaOptions.series[0].data.length == 0 || !hasPermission('HOME_FLUXO-LINE')">
      <div id="chart-vendas-semana" class="dash-box"></div>
    </div>
    <div *ngIf="chartSemanaOptions.series[0].data.length == 0 || !hasPermission('HOME_FLUXO-LINE')">
      <div class="dash-box">
        <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
        <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
      </div>
    </div>
  </div>

  <div class="ui-g-12">
    <div [hidden]="chartHoraOptions.series[0].data.length == 0 || !hasPermission('HOME_FLUXO-LINE')">
      <div id="chart-vendas-hora" class="dash-box"></div>
    </div>
    <div *ngIf="chartHoraOptions.series[0].data.length == 0 || !hasPermission('HOME_FLUXO-LINE')">
      <div class="dash-box">
        <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
        <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
      </div>
    </div>
  </div>

</div>
