import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { MessageService } from 'primeng/api';

import { BoletoAvulso } from '../boleto-avulso.model';
import { LicencaService } from 'src/app/licenca/licenca.service';
import { AuthService } from 'src/app/seguranca/auth.service';
import { BoletoService } from '../boleto.service';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { Comprador } from '../comprador.model';
import { CompradorService } from '../comprador.service';

@Component({
  selector: 'app-boleto-emissao',
  templateUrl: './boleto-emissao.component.html',
  styleUrls: ['./boleto-emissao.component.css']
})
export class BoletoEmissaoComponent implements OnInit {

  jwtPayload: any;
  empresaId: any;
  empresasOptions: any[];
  boleto: BoletoAvulso;
  licencas: any[];
  types: any[];
  recorrenteOptions: any[];
  recorrenteVigenciaOptions: any[];
  recorrenteVigenciaMesOptions: any[];
  recorrenteVigenciaAnoOptions: any[];
  minDate: Date;
  maxDate: Date;
  exibirConsultaComprador = false;
  exibirTutorial = false;
  blocked = false;

  regExValor: RegExp = /[0123456789,]/;

  @ViewChild('number', { static: true }) numberField: ElementRef;

  constructor(
    private router: Router,
    private empresaService: EmpresaService,
    private compradorService: CompradorService,
    private licencaService: LicencaService,
    private boletoService: BoletoService,
    private authService: AuthService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.jwtPayload = this.authService.getJwtPayload();
    this.empresaId = this.jwtPayload.empresaId;
    this.boleto = new BoletoAvulso();
    this.boleto.usuarioId = this.jwtPayload.usuarioId;
    this.boleto.customer.document.type = 'CPF';
    this.types = [
      { label: 'CPF', value: 'CPF' },
      { label: 'CNPJ', value: 'CNPJ' }
    ];
    this.recorrenteOptions = [
      { label: 'NÃO', value: false },
      { label: 'SIM', value: true }
    ];
    this.recorrenteVigenciaOptions = [
      { label: 'INDETERMINADA', value: false },
      { label: 'ATÉ', value: true }
    ];
    this.recorrenteVigenciaMesOptions = [
      { label: '01', value: 1 },
      { label: '02', value: 2 },
      { label: '03', value: 3 },
      { label: '04', value: 4 },
      { label: '05', value: 5 },
      { label: '06', value: 6 },
      { label: '07', value: 7 },
      { label: '08', value: 8 },
      { label: '09', value: 9 },
      { label: '10', value: 10 },
      { label: '11', value: 11 },
      { label: '12', value: 12 }
    ];
    let ano = new Date().getFullYear();
    this.recorrenteVigenciaAnoOptions = [];
    for (let i = 0; i < 15; i++) {
      this.recorrenteVigenciaAnoOptions.push(
        { label: `${ano}`, value: ano++ }
      );
    }

    this.empresaService.getEmpresas().subscribe(
      result => {
        this.empresasOptions = [];
        result.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
      }
    );

    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.boleto.firstDueDate = this.minDate;

    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 30);

    this.buscarLicencas();
  }

  buscarLicencas() {
    this.licencaService.buscarPorEmpresa(this.empresaId).subscribe(
      data => {
        this.licencas = [];
        this.boleto.licencaId = undefined;
        data.forEach(d => {
          if (d.moeda === 'BRL') {
            if (this.boleto.licencaId === undefined) {
              this.boleto.licencaId = d.id;
            }
            this.licencas.push({ label: d.contrato, value: d.id });
          }
        }
        );
      },
      err => {
        console.log('Error:', err);
      }
    );
  }

  changeRecorrente() {
    if (!this.boleto.recorrente) {
      this.boleto.recorrenteVigencia = false;
      this.boleto.recorrenteVigenciaMes = undefined;
      this.boleto.recorrenteVigenciaAno = undefined;
    }
  }

  changeRecorrenteVigencia() {
    if (this.boleto.recorrenteVigencia) {
      const now = new Date();
      this.boleto.recorrenteVigenciaMes = now.getMonth() + 1;
      this.boleto.recorrenteVigenciaAno = now.getFullYear();
    } else {
      this.boleto.recorrenteVigenciaMes = undefined;
      this.boleto.recorrenteVigenciaAno = undefined;
    }
  }

  buscarCep() {
    if (this.boleto.customer.address.postalCode && this.boleto.customer.address.postalCode.length === 8) {
      this.boletoService.buscarCep(this.boleto.customer.address.postalCode).subscribe(
        data => {
          if (data.erro) {
            this.resetAddress();
          } else {
            this.boleto.customer.address.street = data.logradouro;
            this.boleto.customer.address.number = undefined;
            this.boleto.customer.address.complement = data.complemento;
            this.boleto.customer.address.district = data.bairro;
            this.boleto.customer.address.city = data.localidade;
            this.boleto.customer.address.state = data.uf;
            this.numberField.nativeElement.focus();
          }
        },
        error => {
          console.log('Erro ao buscar cep:', error);
          this.resetAddress();
        }
      );
    } else {
      this.resetAddress();
    }
  }

  resetAddress() {
    this.boleto.customer.address.street = undefined;
    this.boleto.customer.address.number = undefined;
    this.boleto.customer.address.complement = undefined;
    this.boleto.customer.address.district = undefined;
    this.boleto.customer.address.city = undefined;
    this.boleto.customer.address.state = undefined;
  }

  buscarComprador() {
    const cpfCnpj = this.boleto.customer.document.value;
    if (cpfCnpj && cpfCnpj.length > 10) {
      this.compradorService.buscarComprador(this.empresaId, cpfCnpj).subscribe(
        comprador => this.compradorSelecionado(comprador),
        error => console.log('Comprador não encontrado!')
      );
    }
  }

  gerarBoleto() {
    this.blocked = true;

    // Validações
    if (this.boleto.firstDueDate === undefined || this.boleto.firstDueDate === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Data Vencimento Inválida!',
        detail: 'Informe uma data de vencimento.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.amount === undefined || this.boleto.amount === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Valor Inválido!',
        detail: 'Informe o valor do boleto.'
      });
      this.boleto.amount = undefined;
      this.blocked = false;
      return;
    }
    let count = 0;
    for (const c of this.boleto.amount.toString().split('')) {
      if (c === ',') {
        count++;
      }
      if (count > 1) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Vírgula Inválida!',
          detail: 'Apenas um vírgula é permitida.'
        });
        this.boleto.amount = undefined;
        this.blocked = false;
        return;
      }
    }
    const valor = Number(this.boleto.amount.toString().replace(',', '.'));
    if (valor < 5.00 || valor > 1000000.00) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Valor Inválido!',
        detail: 'Informe o valor entre R$ 5,00 e R$ 1.000.000,00.'
      });
      this.boleto.amount = undefined;
      this.blocked = false;
      return;
    }

    if (this.boleto.licencaId === undefined || this.boleto.licencaId === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Contrato Inválido!',
        detail: 'Informe um Contrato.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.recorrente && this.boleto.recorrenteVigencia) {
      let now = new Date();
      let mes = now.getMonth() + 1;
      let ano = now.getFullYear();
      if (this.boleto.recorrenteVigenciaAno === ano && this.boleto.recorrenteVigenciaMes <= mes) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Vigência Inválida!',
          detail: 'Informe um mês e ano de vigência futura.'
        });
        this.blocked = false;
        return;
      }
    } else {
      this.boleto.recorrenteVigenciaMes = undefined;
      this.boleto.recorrenteVigenciaAno = undefined;
    }

    if (this.boleto.description === undefined || this.boleto.description === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Descrição do Serviço/Produto Inválida!',
        detail: 'Informe a Descrição do Serviço/Produto.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.description.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Descrição do Serviço/Produto Inválida!',
        detail: 'Informe a Descrição do Serviço/Produto com no mínimo 2 caracteres.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.name === undefined || this.boleto.customer.name === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Nome Inválido!',
        detail: 'Informe um Nome.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.name.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Nome Inválido!',
        detail: 'Informe um Nome com no mínimo 2 caracteres.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.email === undefined || this.boleto.customer.email === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'E-mail Inválido!',
        detail: 'Informe um E-mail.'
      });
      this.blocked = false;
      return;
    }
    count = 0;
    for (const c of this.boleto.customer.email.split('')) {
      if (c === '@') {
        count++;
      }
    }
    if (count !== 1 || this.boleto.customer.email.length < 5 || !this.boleto.customer.email.includes('.')) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Email Inválido!',
        detail: 'Informe um E-mail válido.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.phone.areaCode === undefined || this.boleto.customer.phone.areaCode === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'DDD Inválido!',
        detail: 'Informe um DDD.'
      });
      this.blocked = false;
      return;
    }
    if (!this.isNumeric(this.boleto.customer.phone.areaCode)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'DDD Inválido!',
        detail: 'Informe um DDD com apenas dígitos.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.phone.areaCode.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'DDD Inválido!',
        detail: 'Informe um DDD com 2 dígitos.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.phone.number === undefined || this.boleto.customer.phone.number === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Celular Inválido!',
        detail: 'Informe um Celular.'
      });
      this.blocked = false;
      return;
    }
    if (!this.isNumeric(this.boleto.customer.phone.number)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Celular Inválido!',
        detail: 'Informe um Celular com apenas dígitos.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.phone.number.length < 8) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Celular Inválido!',
        detail: 'Informe um Celular com no mínimo 8 dígitos.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.document.value === undefined || this.boleto.customer.document.value === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Documento Inválido!',
        detail: 'Informe um Documento.'
      });
      this.blocked = false;
      return;
    }
    if (!this.isNumeric(this.boleto.customer.document.value)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Documento Inválido!',
        detail: 'Informe um Documento com apenas dígitos.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.document.type === 'CPF' && this.boleto.customer.document.value.length !== 11) {
      this.messageService.add({
        severity: 'warn',
        summary: 'CPF Inválido!',
        detail: 'Informe o CPF com 11 dígitos.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.document.type === 'CNPJ' && this.boleto.customer.document.value.length !== 14) {
      this.messageService.add({
        severity: 'warn',
        summary: 'CNPJ Inválido!',
        detail: 'Informe o CNPJ com 14 dígitos.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.address.postalCode === undefined || this.boleto.customer.address.postalCode === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'CEP Inválido!',
        detail: 'Informe um CEP.'
      });
      this.blocked = false;
      return;
    }
    if (!this.isNumeric(this.boleto.customer.address.postalCode)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'CEP Inválido!',
        detail: 'Informe um CEP com apenas dígitos.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.address.postalCode.length !== 8) {
      this.messageService.add({
        severity: 'warn',
        summary: 'CEP Inválido!',
        detail: 'Informe um CEP com 8 dígitos.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.address.street === undefined || this.boleto.customer.address.street === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Logradouro Inválido!',
        detail: 'Informe um Logradouro.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.address.street.length < 3) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Logradouro Inválido!',
        detail: 'Informe um Logradouro com no mínimo 3 caracteres.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.address.number === undefined || this.boleto.customer.address.number === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Número Inválido!',
        detail: 'Informe um Número.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.address.number.length < 1) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Número Inválido!',
        detail: 'Informe um Número com no mínimo 1 caracter.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.address.district === undefined || this.boleto.customer.address.district === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Complemento/Bairro Inválido!',
        detail: 'Informe um Complemento/Bairro.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.address.district.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Complemento/Bairro Inválido!',
        detail: 'Informe um Complemento/Bairro com no mínimo 2 caracteres.'
      });
      this.blocked = false;
      return;
    }


    if (this.boleto.customer.address.city === undefined || this.boleto.customer.address.city === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Cidade Inválida!',
        detail: 'Informe uma Cidade.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.address.city.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Cidade Inválida!',
        detail: 'Informe uma Cidade com no mínimo 2 caracteres.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.address.state === undefined || this.boleto.customer.address.state === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Estado Inválido!',
        detail: 'Informe uma Cidade.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.address.state.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Estado Inválido!',
        detail: 'Informe um Estado com 2 letras.'
      });
      this.blocked = false;
      return;
    }

    // Emissão do Boleto
    this.boletoService.emitirBoletoAvulso(this.boleto).subscribe(
      data => {
        this.router.navigate(['boletos', 'success']);
        this.blocked = false;
      },
      error => {
        console.log('Boleto Avulso - Error:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Falha na emissão do boleto!',
          detail: 'A emissão do boleto falhou. Revise os dados e tente novamente.'
        });
        this.blocked = false;
      }
    );
  }

  private isNumeric(str: string): boolean {
    const er = /^[0-9]+$/;
    return (er.test(str));
  }

  // Consulta Comprador

  abrirConsultaComprador() {
    this.exibirConsultaComprador = true;
  }

  compradorSelecionado(comprador: Comprador) {
    this.exibirConsultaComprador = false;
    this.boleto.customer.document.type = comprador.tipoDoc;
    this.boleto.customer.document.value = comprador.cpfCnpj;
    this.boleto.customer.name = comprador.nome;
    this.boleto.customer.email = comprador.email;
    this.boleto.customer.phone.areaCode = comprador.dddCelular;
    this.boleto.customer.phone.number = comprador.celular;
    this.boleto.customer.address.postalCode = comprador.cep;
    this.boleto.customer.address.street = comprador.logradouro;
    this.boleto.customer.address.number = comprador.numero;
    this.boleto.customer.address.complement = comprador.complemento;
    this.boleto.customer.address.district = comprador.bairro;
    this.boleto.customer.address.city = comprador.cidade;
    this.boleto.customer.address.state = comprador.uf;
    this.messageService.add({
      severity: 'success',
      summary: `${comprador.tipoDoc}: ${comprador.cpfCnpj} encontrado!`,
      detail: 'Cadastro preenchido automaticamente. Favor conferir os dados.'
    });
  }

  fecharConsultaComprador() {
    this.exibirConsultaComprador = false;
  }

  verTutorial() {
    this.exibirTutorial = true;
  }
  fecharTutorial() {
    this.exibirTutorial = false;
  }
}
