import {
  Directive, ElementRef, AfterViewChecked
} from '@angular/core';

@Directive({
  selector: '[appPagseguroStatus]'
})
export class PagseguroStatusDirective implements AfterViewChecked {

  constructor(private elementRef: ElementRef) { }

  ngAfterViewChecked() {
    if (this.elementRef.nativeElement.textContent) {
      switch (this.elementRef.nativeElement.textContent.trim()) {
        case '0':
          this.elementRef.nativeElement.textContent = 'Pedido efetuado';
          this.elementRef.nativeElement.style.color = 'olive';
          break;
        case '1':
          this.elementRef.nativeElement.textContent = 'Aguardando pagamento';
          this.elementRef.nativeElement.style.color = 'gray';
          break;
        case '2':
          this.elementRef.nativeElement.textContent = 'Em análise';
          this.elementRef.nativeElement.style.color = 'chocolate';
          break;
        case '3':
          this.elementRef.nativeElement.textContent = 'Paga';
          this.elementRef.nativeElement.style.color = '#0f9b0f';
          break;
        case '4':
          this.elementRef.nativeElement.textContent = 'Disponível';
          this.elementRef.nativeElement.style.color = 'blue';
          break;
        case '5':
          this.elementRef.nativeElement.textContent = 'Em disputa';
          this.elementRef.nativeElement.style.color = 'orange';
          break;
        case '6':
          this.elementRef.nativeElement.textContent = 'Devolvida';
          this.elementRef.nativeElement.style.color = 'purple';
          break;
        case '7':
          this.elementRef.nativeElement.textContent = 'Cancelada';
          this.elementRef.nativeElement.style.color = 'red';
          break;
        case '8':
          this.elementRef.nativeElement.textContent = 'Debitado';
          this.elementRef.nativeElement.style.color = 'limegreen';
          break;
        case '9':
          this.elementRef.nativeElement.textContent = 'Retenção temporária';
          this.elementRef.nativeElement.style.color = 'brown';
          break;
        case '10':
          this.elementRef.nativeElement.textContent = 'Resgate';
          this.elementRef.nativeElement.style.color = 'midnightblue';
          break;
        case '11':
          this.elementRef.nativeElement.textContent = 'Liquidado';
          this.elementRef.nativeElement.style.color = '#c55a11';
          break;
        case '12':
          this.elementRef.nativeElement.textContent = 'Pedido falhou';
          this.elementRef.nativeElement.style.color = '#c70039';
          break;
        case '13':
          this.elementRef.nativeElement.textContent = 'Antecipado';
          this.elementRef.nativeElement.style.color = '#541743';
          break;
      }
    }
  }

}
