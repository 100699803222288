<form autocomplete="off" (ngSubmit)="redefinirSenha();">
  <div class="ui-g ui-fluid">

    <div class="ui-g-12 margin-bottom-20">
      <h1>Redefinir Senha</h1>
    </div>

    <div class="ui-g-12 margin-bottom-20">
      <span class="ui-float-label">
        <input id="redefinirSenhaAtual" type="password" pPassword required name="senhaAtual" [(ngModel)]="senhaAtual"
          [feedback]="false" #senhaAtualField>
        <label for="redefinirSenhaAtual">Senha Atual</label>
      </span>
    </div>

    <div class="ui-g-12 margin-bottom-20">
      <span class="ui-float-label">
        <input id="redefinirNovaSenha" type="password" pPassword required name="novaSenha" [(ngModel)]="novaSenha"
          promptLabel="Digite sua nova senha" weakLabel="Fraca" mediumLabel="Média" strongLabel="Forte"
          #novaSenhaField />
        <label for="redefinirNovaSenha">Nova Senha</label>
      </span>
    </div>

    <div class="ui-g-12 margin-bottom-20">
      <span class="ui-float-label">
        <input id="redefinirConfirmarSenha" type="password" pPassword required name="confirmarSenha"
          [(ngModel)]="confirmarSenha" promptLabel="Confirme sua nova senha" weakLabel="Fraca" mediumLabel="Média"
          strongLabel="Forte" #confirmarSenhaField />
        <label for="redefinirConfirmarSenha">Confirmar Nova Senha</label>
      </span>
    </div>

    <div class="ui-g-12">
      <button pButton type="submit" label="Redefinir Senha" icon="pi pi-sign-in"></button>
    </div>

  </div>
</form>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>
