export class Saque {

  id: string;
  dtHrSolicitacao: string;
  dtHrAgendamento: string;
  dtPrevisao: string;
  dtHrLiquidacao: string;
  dtHrCancelamento: string;
  valor: number;
  status: string;
  historico: string;
  autorizacao: string;
  formaPagamento: string;
  moeda: string;
  empresaId: string;
  licencaId: string;
  pedidosId: string[];

  contaId: string;
  banco: string;
  agencia: string;
  conta: string;
  titular: string;
  cpfCnpj: string;
  
  bancoOrigem: number;

constructor(empresaId: string) {
    this.empresaId = empresaId;
    this.pedidosId = [];
  }

}
