<div class="pgw--container">

  <div class="ui-g" style="padding: 20px 0px;">

    <div class="ui-g-12 ui-fluid" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
      <p-dropdown [options]="empresasOptions" [(ngModel)]="empresaId" (onChange)="buscarLicencas()" filter="true" resetFilterOnHide="true"></p-dropdown>
    </div>

    <div class="ui-g-12 ui-fluid">
      <p-dropdown [options]="licencasOptions" [(ngModel)]="licencaId" pTooltip="Contratos" tooltipPosition="top"
        (onChange)="paintChart()">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-4" [hidden]="!hasPermission('HOME_PEDIDO-TOT')">
      <p-panel>
        <p-header>
          <span *ngIf="hasPermission('PEDIDO')"><a routerLink="/pedidos">Pedidos (últimos 30 dias)</a></span>
          <span *ngIf="!hasPermission('PEDIDO')">Pedidos (últimos 30 dias)</span>
        </p-header>
        <div #dashPedidos class="dash-box" style="display: table;" [hidden]="!hasPermission('HOME_PEDIDO-TOT')">
          <span class="dash-box-valor" *ngIf="totalPedidos$ | async as tPedidos else loadingPedidos">
            <span *ngIf="moeda === 'USD'">{{ tPedidos | currency:'USD':'symbol':'0.2-2':'en' }}</span>
            <span *ngIf="moeda === 'BRL'">{{ tPedidos | currency:'BRL':'symbol':'0.2-2':'pt' }}</span>
          </span>
          <ng-template #loadingPedidos>
            <span class="dash-box-valor">
              <span *ngIf="moeda === 'USD'">$0.00</span>
              <span *ngIf="moeda === 'BRL'">R$ 0,00</span>
            </span>
          </ng-template>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-4" *ngIf="!hasPermission('HOME_PEDIDO-TOT')">
      <p-panel>
        <p-header>
          <span *ngIf="hasPermission('PEDIDO')"><a routerLink="/pedidos">Pedidos (últimos 30 dias)</a></span>
          <span *ngIf="!hasPermission('PEDIDO')">Pedidos (últimos 30 dias)</span>
        </p-header>
        <div>
          <div class="dash-box">
            <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
            <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-4" [hidden]="!hasPermission('HOME_DEPOSITO-TOT')">
      <p-panel>
        <p-header>
          <span *ngIf="hasPermission('DEPOSITO')"><a routerLink="/depositos">Depósitos (últimos 30 dias)</a></span>
          <span *ngIf="!hasPermission('DEPOSITO')">Depósitos (últimos 30 dias)</span>
        </p-header>
        <div #dashDepositos class="dash-box" style="display: table;" [hidden]="!hasPermission('HOME_DEPOSITO-TOT')">
          <span class="dash-box-valor" *ngIf="totalDepositos$ | async as tDepositos else loadingDepositos">
            <span *ngIf="moeda === 'USD'">{{ tDepositos | currency:'USD':'symbol':'0.2-2':'en' }}</span>
            <span *ngIf="moeda === 'BRL'">{{ tDepositos | currency:'BRL':'symbol':'0.2-2':'pt' }}</span>
          </span>
          <ng-template #loadingDepositos>
            <span class="dash-box-valor">
              <span *ngIf="moeda === 'USD'">$0.00</span>
              <span *ngIf="moeda === 'BRL'">R$ 0,00</span>
            </span>
          </ng-template>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-4" *ngIf="!hasPermission('HOME_DEPOSITO-TOT')">
      <p-panel>
        <p-header>
          <span *ngIf="hasPermission('DEPOSITO')"><a routerLink="/depositos">Depósitos (últimos 30 dias)</a></span>
          <span *ngIf="!hasPermission('DEPOSITO')">Depósitos (últimos 30 dias)</span>
        </p-header>
        <div>
          <div class="dash-box">
            <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
            <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-4" [hidden]="!hasPermission('HOME_REEMBOLSO-TOT')">
      <p-panel>
        <p-header>
          <span>Reembolsos (últimos 30 dias)</span>
        </p-header>
        <div #dashReembolsos class="dash-box" style="display: table;" [hidden]="!hasPermission('HOME_REEMBOLSO-TOT')">
          <span class="dash-box-valor" *ngIf="totalReembolsos$ | async as tReembolsos else loadingReembolsos">
            <span *ngIf="moeda === 'USD'">{{ tReembolsos | currency:'USD':'symbol':'0.2-2':'en' }}</span>
            <span *ngIf="moeda === 'BRL'">{{ tReembolsos | currency:'BRL':'symbol':'0.2-2':'pt' }}</span>
          </span>
          <ng-template #loadingReembolsos>
            <span class="dash-box-valor">
              <span *ngIf="moeda === 'USD'">$0.00</span>
              <span *ngIf="moeda === 'BRL'">R$ 0,00</span>
            </span>
          </ng-template>
        </div>
      </p-panel>
    </div>
    <div class="ui-g-12 ui-md-4 ui-lg-4" *ngIf="!hasPermission('HOME_REEMBOLSO-TOT')">
      <p-panel>
        <p-header>
          <span>Reembolsos</span>
        </p-header>
        <div>
          <div class="dash-box">
            <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
            <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-8">
      <p-panel>
        <p-header>
          <span>Fluxo de Caixa (últimos 30 dias)</span>
        </p-header>
        <div [hidden]="lineFluxoOptions.series[0].data.length == 0 || !hasPermission('HOME_FLUXO-LINE')">
          <div id="chartFluxo" class="dash-box">
          </div>
        </div>
        <div *ngIf="lineFluxoOptions.series[0].data.length == 0 || !hasPermission('HOME_FLUXO-LINE')">
          <div class="dash-box">
            <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
            <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-4">
      <p-panel>
        <p-header>
          <span *ngIf="hasPermission('PEDIDO')"><a routerLink="/pedidos">Pedidos (últimos 30 dias)</a></span>
          <span *ngIf="!hasPermission('PEDIDO')">Pedidos (últimos 30 dias)</span>
        </p-header>
        <div [hidden]="pieStatusOptions.series[0].data.length == 0 || !hasPermission('HOME_PEDIDO-PIE')">
          <div id="chartStatus" class="dash-box">
          </div>
        </div>
        <div *ngIf="pieStatusOptions.series[0].data.length == 0 || !hasPermission('HOME_PEDIDO-PIE')">
          <div class="dash-box">
            <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
            <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-4">
      <p-panel>
        <p-header>
          <span>Formas de Pagamento (últimos 30 dias)</span>
        </p-header>
        <div [hidden]="pieFormaOptions.series[0].data.length == 0 || !hasPermission('HOME_FORMA-PIE')">
          <div id="chartForma" class="dash-box">
          </div>
        </div>
        <div *ngIf="pieFormaOptions.series[0].data.length == 0 || !hasPermission('HOME_FORMA-PIE')">
          <div class="dash-box">
            <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
            <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-4">
      <p-panel>
        <p-header>
          <span>Cartões por Bandeira (últimos 30 dias)</span>
        </p-header>
        <div [hidden]="pieBandeirasOptions.series[0].data.length == 0 || !hasPermission('HOME_BANDEIRA-PIE')">
          <div id="chartBandeiras" class="dash-box">
          </div>
        </div>
        <div *ngIf="pieBandeirasOptions.series[0].data.length == 0 || !hasPermission('HOME_BANDEIRA-PIE')">
          <div class="dash-box">
            <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
            <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-4">
      <p-panel>
        <p-header>
          <span>Débitos por Bandeira (últimos 30 dias)</span>
        </p-header>
        <div [hidden]="pieDebitoOptions.series[0].data.length == 0 || !hasPermission('HOME_DEBITO-PIE')">
          <div id="chartDebito" class="dash-box">
          </div>
        </div>
        <div *ngIf="pieDebitoOptions.series[0].data.length == 0 || !hasPermission('HOME_DEBITO-PIE')">
          <div class="dash-box">
            <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
            <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-4">
      <p-panel>
        <p-header>
          <span *ngIf="hasPermission('DEPOSITO')"><a routerLink="/depositos">Depósitos (últimos 30 dias)</a></span>
          <span *ngIf="!hasPermission('DEPOSITO')">Depósitos (últimos 30 dias)</span>
        </p-header>
        <div [hidden]="barDepositosOptions.series[0].data.length == 0 || !hasPermission('HOME_DEPOSITO-BAR')">
          <div id="barDepositos" class="dash-box">
          </div>
        </div>
        <div *ngIf="barDepositosOptions.series[0].data.length == 0 || !hasPermission('HOME_DEPOSITO-BAR')">
          <div class="dash-box">
            <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
            <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-4">
      <p-panel>
        <p-header>
          <span *ngIf="hasPermission('HOME_PRODUTO-CHART')"><a routerLink="/estatistica/produtos">Produtos (Top 5 nos últimos 30 dias)</a></span>
          <span *ngIf="!hasPermission('HOME_PRODUTO-CHART')">Produtos (Top 5 nos últimos 30 dias)</span>
        </p-header>
        <div [hidden]="barProdutosOptions.series[0].data.length == 0 || !hasPermission('HOME_PRODUTO-CHART')">
          <div id="chartProdutos" class="dash-box">
          </div>
        </div>
        <div *ngIf="barProdutosOptions.series[0].data.length == 0 || !hasPermission('HOME_PRODUTO-CHART')">
          <div class="dash-box">
            <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
            <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-4">
      <p-panel>
        <p-header>
          <span *ngIf="hasPermission('HOME_CLIENTE-CHART')"><a routerLink="/estatistica/clientes">Clientes (Top 5)</a></span>
          <span *ngIf="!hasPermission('HOME_CLIENTE-CHART')">Clientes (Top 5)</span>
        </p-header>
        <div [hidden]="barClientesOptions.series[0].data.length == 0 || !hasPermission('HOME_CLIENTE-CHART')">
          <div id="chartClientes" class="dash-box">
          </div>
        </div>
        <div *ngIf="barClientesOptions.series[0].data.length == 0 || !hasPermission('HOME_CLIENTE-CHART')">
          <div class="dash-box">
            <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
            <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
          </div>
        </div>
      </p-panel>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-4">
      <p-panel>
        <p-header>
          <span>Reembolsos (últimos 30 dias)</span>
        </p-header>
        <div [hidden]="barReembolsosOptions.series[0].data.length == 0 || !hasPermission('HOME_REEMBOLSO-CHART')">
          <div id="barReembolsos" class="dash-box">
          </div>
        </div>
        <div *ngIf="barReembolsosOptions.series[0].data.length == 0 || !hasPermission('HOME_REEMBOLSO-CHART')">
          <div class="dash-box">
            <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
            <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
          </div>
        </div>
      </p-panel>
    </div>


  </div>

</div>
