<form (ngSubmit)="transfer()">

  <div class="ui-g">

    <div class="ui-g-12">
      <h1>Transferência</h1>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12">
      <label for="contaOrigem">Conta de Origem:</label>
      <span id="contaOrigem" style="margin-left: 6px;">{{ licencaSelecionada.contrato }} [{{ licencaSelecionada.moeda
        }}]</span>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="destino">Conta de Destino:</label>
      <p-dropdown id="destino" name="destino" [options]="licencasOptions" [(ngModel)]="transferDTO.destino"
        (onChange)="selecionarDestino()" #linkContrato></p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="valor">Valor:</label>
      <input pInputText id="valor" name="valor" [(ngModel)]="valor" maxlength="20" [pKeyFilter]="regExValor"
        onFocus="this.select();" #transferValor>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid" *ngIf="cambioEnabled">
      <label for="cambio">Câmbio:</label>
      <input pInputText id="cambio" name="cambio" [(ngModel)]="cambio" maxlength="20" [pKeyFilter]="regExValor"
        onFocus="this.select();" #transferCambio>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid" *ngIf="cambioEnabled && licencaSelecionada.moeda == 'BRL'">
      <label for="data">Data:</label>
      <p-inputMask id="data" name="data" [(ngModel)]="transferDTO.data" mask="99/99/9999" placeholder="dd/mm/aaaa"
        slotChar="dd/mm/aaaa"></p-inputMask>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid" *ngIf="cambioEnabled && licencaSelecionada.moeda == 'BRL'">
      <label for="pedido">Pedido:</label>
      <p-inputNumber id="pedido" name="pedido" [(ngModel)]="transferDTO.pedido" [useGrouping]="false"
        maxlength="10"></p-inputNumber>
    </div>

    <div class="ui-g-12" style="margin-top: 10px;">
      <p-button type="submit" label="Transferir" icon="fas fa-exchange-alt"></p-button>
    </div>

  </div>

</form>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>
