<form (ngSubmit)="salvar()">

  <div class="ui-g">

    <div class="ui-g-12">
      <h1>Contrato</h1>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="identificador">Identificador</label>
      <input pInputText id="identificador" name="id" [(ngModel)]="licenca.id" disabled />
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="titulo">Título</label>
      <input pInputText id="titulo" name="titulo" [(ngModel)]="licenca.titulo"/>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="descricao">Descrição</label>
      <input pInputText id="descricao" name="descricao" [(ngModel)]="licenca.descricao"/>
    </div>

    <div class="ui-g-12">
      <p-button type="submit" label="Salvar" icon="pi pi-save"></p-button>
    </div>

  </div>

</form>
