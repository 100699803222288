import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { LicencaService } from 'src/app/licenca/licenca.service';
import { AuthService } from 'src/app/seguranca/auth.service';
import { BoletoVencto } from '../boleto-vencto.model';
import { Boleto } from '../boleto.model';
import { BoletoService } from '../boleto.service';
import { Comprador } from '../comprador.model';
import { CompradorService } from '../comprador.service';

@Component({
  selector: 'app-boleto-itau',
  templateUrl: './boleto-itau.component.html',
  styleUrls: ['./boleto-itau.component.css']
})
export class BoletoItauComponent implements OnInit {

  jwtPayload: any;
  empresaId: any;
  empresasOptions: any[];
  boleto: Boleto;
  licencas: any[];
  types: any[];
  minDate: Date;
  maxDate: Date;
  exibirConsultaComprador = false;
  exibirTutorial = false;
  blocked = false;
  vencimento: Date;
  valor: string;
  descricaoEspecifica: string;

  tpMultaOptions: any[];
  tpJurosOptions: any[];
  valorMulta: string;
  valorJuros: string;

  regExValor: RegExp = /[0123456789,]/;

  @ViewChild('number', { static: true }) numberField: ElementRef;

  constructor(
    private router: Router,
    private empresaService: EmpresaService,
    private compradorService: CompradorService,
    private licencaService: LicencaService,
    private boletoService: BoletoService,
    private authService: AuthService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.jwtPayload = this.authService.getJwtPayload();
    this.empresaId = this.jwtPayload.empresaId;
    this.boleto = new Boleto();
    this.boleto.usuarioId = this.jwtPayload.usuarioId;
    this.boleto.customer.document.type = 'CPF';
    this.types = [
      { label: 'CPF', value: 'CPF' },
      { label: 'CNPJ', value: 'CNPJ' }
    ];

    this.tpMultaOptions = [
      { label: 'ISENTO', value: 'ISENTO' },
      { label: 'VALOR', value: 'VALOR' },
      { label: 'PERCENTUAL', value: 'PERCENTUAL' }
    ];
    this.valorMulta = '0,00';

    this.tpJurosOptions = [
      { label: 'ISENTO', value: 'ISENTO' },
      { label: 'VALOR AO DIA', value: 'VALOR_DIA' },
      { label: 'PERCENTUAL AO MÊS', value: 'PERCENTUAL_MES' }
    ];
    this.valorJuros = '0,00';

    // this.tpMultaOptions = [
    //   { label: 'ISENTO', value: '3' },
    //   { label: 'VALOR', value: '1' },
    //   { label: 'PERCENTUAL', value: '2' }
    // ];
    // this.valorMulta = '0,00';

    // this.tpJurosOptions = [
    //   { label: 'ISENTO', value: '5' },
    //   { label: 'VALOR AO DIA', value: '6' },
    //   { label: 'PERCENTUAL AO DIA', value: '7' },
    //   { label: 'PERCENTUAL AO MÊS', value: '8' }
    // ];
    // this.valorJuros = '0,00';

    this.empresaService.getEmpresas().subscribe(
      result => {
        this.empresasOptions = [];
        result.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );

    this.minDate = new Date();

    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.vencimento = new Date();
    this.vencimento.setDate(this.vencimento.getDate() + 1);
  }

  buscarLicencas() {
    this.licencaService.buscarPorEmpresa(this.empresaId).subscribe(
      data => {
        this.licencas = [];
        this.boleto.licencaId = undefined;
        data.forEach(d => {
          if (d.moeda === 'BRL') {
            if (this.boleto.licencaId === undefined) {
              this.boleto.licencaId = d.id;
            }
            this.licencas.push({ label: d.contrato, value: d.id });
          }
        }
        );
      },
      err => {
        console.log('Error:', err);
      }
    );
  }

  adicionarVencimento() {
    this.blocked = true;

    if (this.vencimento === undefined || this.vencimento === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Data Vencimento Inválida!',
        detail: 'Informe uma data de vencimento.'
      });
      this.blocked = false;
      return;
    }

    if (this.valor === undefined || this.valor === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Valor Boleto Inválido!',
        detail: 'Informe o valor do boleto.'
      });
      this.valor = undefined;
      this.blocked = false;
      return;
    }
    let cValor = 0;
    for (const c of this.valor.toString().split('')) {
      if (c === ',') {
        cValor++;
      }
      if (cValor > 1) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Valor Boleto Inválido!',
          detail: 'Apenas um vírgula é permitida.'
        });
        this.valor = undefined;
        this.blocked = false;
        return;
      }
    }
    const valor = Number(this.valor.toString().replace(',', '.'));
    if (valor < 5.00 || valor > 1000000.00) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Valor Boleto Inválido!',
        detail: 'Informe o valor entre R$ 5,00 e R$ 1.000.000,00.'
      });
      this.valor = undefined;
      this.blocked = false;
      return;
    }

    let vencimento = new BoletoVencto();
    vencimento.vencimento = new Date(this.vencimento.getTime());
    vencimento.valor = valor;
    vencimento.descricaoEspecifica = this.descricaoEspecifica;
    this.boleto.vencimentos.push(vencimento);
    this.ordenarVencimentos();

    this.descricaoEspecifica = undefined;

    this.messageService.add({
      severity: 'success',
      summary: 'Vencimento adicionado!',
      detail: 'Vencimento adicionado com sucesso.'
    });

    this.blocked = false;
    console.log('this.boleto.vencimentos', this.boleto.vencimentos);
  }

  removerVencimento(index: number) {
    if (index > -1) {
      this.boleto.vencimentos.splice(index, 1);
      this.messageService.add({
        severity: 'success',
        summary: 'Vencimento removido!',
        detail: 'Vencimento removido com sucesso.'
      });
      this.ordenarVencimentos();
    }
    console.log('this.boleto.vencimentos', this.boleto.vencimentos);
  }

  ordenarVencimentos() {
    if (this.boleto.vencimentos && this.boleto.vencimentos.length > 1) {
      this.boleto.vencimentos.sort(function (a, b) {
        if (a.vencimento > b.vencimento) {
          return 1;
        }
        if (a.vencimento < b.vencimento) {
          return -1;
        }
        return 0;
      });
    }
  }

  buscarCep() {
    if (this.boleto.customer.address.postalCode && this.boleto.customer.address.postalCode.length === 8) {
      this.boletoService.buscarCep(this.boleto.customer.address.postalCode).subscribe(
        data => {
          if (data.erro) {
            this.resetAddress();
          } else {
            this.boleto.customer.address.street = data.logradouro;
            this.boleto.customer.address.number = undefined;
            this.boleto.customer.address.complement = data.complemento;
            this.boleto.customer.address.district = data.bairro;
            this.boleto.customer.address.city = data.localidade;
            this.boleto.customer.address.state = data.uf;
            this.numberField.nativeElement.focus();
          }
        },
        error => {
          console.log('Erro ao buscar cep:', error);
          this.resetAddress();
        }
      );
    } else {
      this.resetAddress();
    }
  }

  resetAddress() {
    this.boleto.customer.address.street = undefined;
    this.boleto.customer.address.number = undefined;
    this.boleto.customer.address.complement = undefined;
    this.boleto.customer.address.district = undefined;
    this.boleto.customer.address.city = undefined;
    this.boleto.customer.address.state = undefined;
  }

  buscarComprador() {
    const cpfCnpj = this.boleto.customer.document.value;
    if (cpfCnpj && cpfCnpj.length > 10) {
      this.compradorService.buscarComprador(this.empresaId, cpfCnpj).subscribe(
        comprador => this.compradorSelecionado(comprador),
        error => console.log('Comprador não encontrado!')
      );
    }
  }

  // Consulta Comprador

  abrirConsultaComprador() {
    this.exibirConsultaComprador = true;
  }

  compradorSelecionado(comprador: Comprador) {
    this.exibirConsultaComprador = false;
    this.boleto.customer.document.type = comprador.tipoDoc;
    this.boleto.customer.document.value = comprador.cpfCnpj;
    this.boleto.customer.name = comprador.nome;
    this.boleto.customer.email = comprador.email;
    this.boleto.customer.phone.areaCode = comprador.dddCelular;
    this.boleto.customer.phone.number = comprador.celular;
    this.boleto.customer.address.postalCode = comprador.cep;
    this.boleto.customer.address.street = comprador.logradouro;
    this.boleto.customer.address.number = comprador.numero;
    this.boleto.customer.address.complement = comprador.complemento;
    this.boleto.customer.address.district = comprador.bairro;
    this.boleto.customer.address.city = comprador.cidade;
    this.boleto.customer.address.state = comprador.uf;
    this.messageService.add({
      severity: 'success',
      summary: `${comprador.tipoDoc}: ${comprador.cpfCnpj} encontrado!`,
      detail: 'Cadastro preenchido automaticamente. Favor conferir os dados.'
    });
  }

  fecharConsultaComprador() {
    this.exibirConsultaComprador = false;
  }

  verTutorial() {
    this.exibirTutorial = true;
  }
  fecharTutorial() {
    this.exibirTutorial = false;
  }

  selectTpMulta() {
    if (this.boleto.tpMulta === 'ISENTO') {
      this.valorMulta = '0,00';
      this.boleto.vlrMulta = 0.00;
    }
  }

  selectTpJuros() {
    if (this.boleto.tpJuros === 'ISENTO') {
      this.valorJuros = '0,00';
      this.boleto.vlrJuros = 0.00;
    }
  }

  gerarBoleto() {
    console.log('gerar boleto');
    this.blocked = true;

    // Validações
    if (this.boleto.vencimentos === undefined || this.boleto.vencimentos === null || this.boleto.vencimentos.length < 1) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Vencimento Inválido!',
        detail: 'Adicione um vencimento.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.licencaId === undefined || this.boleto.licencaId === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Contrato Inválido!',
        detail: 'Informe um Contrato.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.descricaoGeral === undefined || this.boleto.descricaoGeral === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Descrição geral do serviço/produto Inválida!',
        detail: 'Informe a Descrição do Serviço/Produto.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.descricaoGeral.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Descrição geral do serviço/produto Inválida!',
        detail: 'Informe a Descrição do Serviço/Produto com no mínimo 2 caracteres.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.tpMulta === 'ISENTO') {
      this.valorMulta = '0,00';
      this.boleto.vlrMulta = 0.00;
    } else {
      if (this.valorMulta === undefined || this.valorMulta === null) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Valor Multa Inválido!',
          detail: 'Informe o valor da Multa.'
        });
        this.valorMulta = '0,00';
        this.boleto.vlrMulta = 0.00;
        this.blocked = false;
        return;
      }
      let cValorMulta = 0;
      for (const c of this.valorMulta.toString().split('')) {
        if (c === ',') {
          cValorMulta++;
        }
        if (cValorMulta > 1) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Valor Multa Inválido!',
            detail: 'Apenas uma vírgula é permitida.'
          });
          this.valorMulta = '0,00';
          this.boleto.vlrMulta = 0.00;
          this.blocked = false;
          return;
        }
      }
      this.boleto.vlrMulta = Number(this.valorMulta.toString().replace(',', '.'));
      if (this.boleto.vlrMulta <= 0.00) {
        this.valorMulta = '0,00';
        this.boleto.vlrMulta = 0.00;
        this.blocked = false;
        return;
      }
    }

    if (this.boleto.tpJuros === 'ISENTO') {
      this.valorJuros = '0,00';
      this.boleto.vlrJuros = 0.00;
    } else {
      if (this.valorJuros === undefined || this.valorJuros === null) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Valor Juros Inválido!',
          detail: 'Informe o valor do Juros.'
        });
        this.valorJuros = '0,00';
        this.boleto.vlrJuros = 0.00;
        this.blocked = false;
        return;
      }
      let cValorJuros = 0;
      for (const c of this.valorJuros.toString().split('')) {
        if (c === ',') {
          cValorJuros++;
        }
        if (cValorJuros > 1) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Valor Juros Inválido!',
            detail: 'Apenas uma vírgula é permitida.'
          });
          this.valorJuros = '0,00';
          this.boleto.vlrJuros = 0.00;
          this.blocked = false;
          return;
        }
      }
      this.boleto.vlrJuros = Number(this.valorJuros.toString().replace(',', '.'));
      if (this.boleto.vlrJuros <= 0.00) {
        this.valorJuros = '0,00';
        this.boleto.vlrJuros = 0.00;
        this.blocked = false;
        return;
      }
    }

    if (this.boleto.customer.name === undefined || this.boleto.customer.name === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Nome Inválido!',
        detail: 'Informe um Nome.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.name.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Nome Inválido!',
        detail: 'Informe um Nome com no mínimo 2 caracteres.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.email === undefined || this.boleto.customer.email === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'E-mail Inválido!',
        detail: 'Informe um E-mail.'
      });
      this.blocked = false;
      return;
    }
    let cEmail = 0;
    for (const c of this.boleto.customer.email.split('')) {
      if (c === '@') {
        cEmail++;
      }
    }
    if (cEmail !== 1 || this.boleto.customer.email.length < 5 || !this.boleto.customer.email.includes('.')) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Email Inválido!',
        detail: 'Informe um E-mail válido.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.phone.areaCode === undefined || this.boleto.customer.phone.areaCode === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'DDD Inválido!',
        detail: 'Informe um DDD.'
      });
      this.blocked = false;
      return;
    }
    if (!this.isNumeric(this.boleto.customer.phone.areaCode)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'DDD Inválido!',
        detail: 'Informe um DDD com apenas dígitos.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.phone.areaCode.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'DDD Inválido!',
        detail: 'Informe um DDD com 2 dígitos.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.phone.number === undefined || this.boleto.customer.phone.number === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Celular Inválido!',
        detail: 'Informe um Celular.'
      });
      this.blocked = false;
      return;
    }
    if (!this.isNumeric(this.boleto.customer.phone.number)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Celular Inválido!',
        detail: 'Informe um Celular com apenas dígitos.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.phone.number.length < 8) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Celular Inválido!',
        detail: 'Informe um Celular com no mínimo 8 dígitos.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.document.value === undefined || this.boleto.customer.document.value === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Documento Inválido!',
        detail: 'Informe um Documento.'
      });
      this.blocked = false;
      return;
    }
    if (!this.isNumeric(this.boleto.customer.document.value)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Documento Inválido!',
        detail: 'Informe um Documento com apenas dígitos.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.document.type === 'CPF' && this.boleto.customer.document.value.length !== 11) {
      this.messageService.add({
        severity: 'warn',
        summary: 'CPF Inválido!',
        detail: 'Informe o CPF com 11 dígitos.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.document.type === 'CNPJ' && this.boleto.customer.document.value.length !== 14) {
      this.messageService.add({
        severity: 'warn',
        summary: 'CNPJ Inválido!',
        detail: 'Informe o CNPJ com 14 dígitos.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.address.postalCode === undefined || this.boleto.customer.address.postalCode === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'CEP Inválido!',
        detail: 'Informe um CEP.'
      });
      this.blocked = false;
      return;
    }
    if (!this.isNumeric(this.boleto.customer.address.postalCode)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'CEP Inválido!',
        detail: 'Informe um CEP com apenas dígitos.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.address.postalCode.length !== 8) {
      this.messageService.add({
        severity: 'warn',
        summary: 'CEP Inválido!',
        detail: 'Informe um CEP com 8 dígitos.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.address.street === undefined || this.boleto.customer.address.street === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Logradouro Inválido!',
        detail: 'Informe um Logradouro.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.address.street.length < 3) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Logradouro Inválido!',
        detail: 'Informe um Logradouro com no mínimo 3 caracteres.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.address.number === undefined || this.boleto.customer.address.number === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Número Inválido!',
        detail: 'Informe um Número.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.address.number.length < 1) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Número Inválido!',
        detail: 'Informe um Número com no mínimo 1 caracter.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.address.district === undefined || this.boleto.customer.address.district === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Complemento/Bairro Inválido!',
        detail: 'Informe um Complemento/Bairro.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.address.district.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Complemento/Bairro Inválido!',
        detail: 'Informe um Complemento/Bairro com no mínimo 2 caracteres.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.address.city === undefined || this.boleto.customer.address.city === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Cidade Inválida!',
        detail: 'Informe uma Cidade.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.address.city.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Cidade Inválida!',
        detail: 'Informe uma Cidade com no mínimo 2 caracteres.'
      });
      this.blocked = false;
      return;
    }

    if (this.boleto.customer.address.state === undefined || this.boleto.customer.address.state === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Estado Inválido!',
        detail: 'Informe uma Cidade.'
      });
      this.blocked = false;
      return;
    }
    if (this.boleto.customer.address.state.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Estado Inválido!',
        detail: 'Informe um Estado com 2 letras.'
      });
      this.blocked = false;
      return;
    }

    console.log('Emitir boleto');

    // Emissão do Boleto
    this.boletoService.emitirBoleto(this.boleto).subscribe(
      data => {
        console.log('data', data);
        this.router.navigate(['boletos', 'success'], { state: { data } });
        this.blocked = false;
      },
      error => {
        console.log('Emissão de Boleto - Error:', error);
        if (error.error.type === 'CEP') {
          this.messageService.add({
            severity: 'warn',
            summary: 'CEP inválido!',
            detail: 'A emissão do boleto falhou. Revise os dados do CEP.'
          });
        } else if (error.error.type === 'CPF') {
          this.messageService.add({
            severity: 'warn',
            summary: 'CPF inválido!',
            detail: 'A emissão do boleto falhou. Revise os dados do CPF.'
          });
        } else if (error.error.type === 'MAIL') {
          this.messageService.add({
            severity: 'warn',
            summary: 'E-mail inválido!',
            detail: 'A emissão do boleto falhou. Revise os dados do E-mail.'
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Falha na emissão do boleto!',
            detail: 'A emissão do boleto falhou. Revise os dados e tente novamente.'
          });
        }
        this.blocked = false;
      }
    );
  }

  private isNumeric(str: string): boolean {
    const er = /^[0-9]+$/;
    return (er.test(str));
  }

}
