import { Component, OnInit } from '@angular/core';
import { Usuario } from '../usuario.model';
import { UsuarioService } from '../usuario.service';
import { AuthService } from '../auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-usuario-consulta',
  templateUrl: './usuario-consulta.component.html',
  styleUrls: ['./usuario-consulta.component.css']
})
export class UsuarioConsultaComponent implements OnInit {

  jwtPayload: any;
  exibir = false;
  usuario: Usuario;
  usuarios: Usuario[] = [];
  blocked: boolean;

  constructor(
    private usuarioService: UsuarioService,
    private messageService: MessageService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.blocked = false;
    this.jwtPayload = this.authService.getJwtPayload();
    this.buscar();
    this.usuarioService.usuarioEvent.subscribe(
      () => {
        this.buscar();
        this.exibir = false;
      }
    );
  }

  criar() {
    this.exibir = true;
    this.usuario = new Usuario(this.jwtPayload.empresaId);
  }

  resetSenha(id: string) {
    this.blocked = true;
    this.authService.reiniciarSenha(id).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Senha reinicializada com sucesso!',
          detail: 'Uma senha temporária foi enviada para o e-mail do usuário.'
        });
        this.blocked = false;
      },
      error => {
        console.log('Error:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Falha ao reiniciar senha!',
          detail: 'Falha ao reiniciar senha do usuário.'
        });
        this.blocked = false;
      }
    );
  }

  excluir(id: string) {
    this.blocked = true;
    this.usuarioService.excluir(id).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Usuário excluído!',
          detail: 'Usuário excluído com sucesso.'
        });
        this.buscar();
        this.blocked = false;
      },
      error => {
        console.log('Error:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Falha ao excluir usuário!',
          detail: 'Erro ao excluir usuário.'
        });
        this.usuarios = [];
        this.blocked = false;
      }
    );
  }

  onRowSelect(usuario) {
    if (this.hasPermission('CONFIG_USUARIO_EDITAR')) {
      this.usuario = usuario;
      this.exibir = true;
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Permissão Negada!',
        detail: 'Você não possui permissão para editar Usuários.'
      });
    }
  }

  buscar() {
    this.usuarioService.buscarPorEmpresa(this.jwtPayload.empresaId).subscribe(
      result => this.usuarios = result,
      err => {
        console.log('Error:', err);
        this.messageService.add({
          severity: 'error',
          summary: 'Falha ao buscar usuários!',
          detail: 'Erro ao buscar usuários.'
        });
      }
    );
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

}
