import { Component, OnInit } from '@angular/core';

import { EmpresaService } from '../../empresa/empresa.service';
import { AuthService } from '../../seguranca/auth.service';
import { ExtratoService } from '../extrato.service';
import { LancamentoDTO } from '../lancamento-dto.model';
import { ExtratoFilter } from '../extrato-filter';
import { Licenca } from '../../licenca/licenca.model';
import { LicencaService } from '../../licenca/licenca.service';
import { Pedido } from '../../pedido/pedido.model';
import { MessageService } from 'primeng/api';
import { PedidoService } from '../../pedido/pedido.service';
import { Saque } from '../../saque/saque.model';
import { SaqueService } from '../../saque/saque.service';
import { PedidoDTO } from '../../pedido/pedido-dto';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-extrato-consulta',
  templateUrl: './extrato-consulta.component.html',
  styleUrls: ['./extrato-consulta.component.css']
})
export class ExtratoConsultaComponent implements OnInit {

  jwtPayload: any;

  empresasOptions: any[];
  licencasOptions: any[];
  licencasDestinoOptions: any[];
  licencas: Licenca[];
  moeda: string;
  maxDate: Date;

  extratoFilter: ExtratoFilter;
  lancamentos: LancamentoDTO[];

  exibirSaque = false;
  exibirPedido = false;
  exibirTransfer = false;

  saqueSelecionado: Saque;
  pedidoSelecionado: Pedido;
  pedidosDTOSelecionado: PedidoDTO[];

  licencaSelecionada: Licenca;

  csvQuery: string;

  qtdCredito = 0;
  qtdDebito = 0;
  totalCredito = 0.00;
  totalDebito = 0.00;
  totalSaldo = 0.00;

  resumo: any;

  constructor(
    private empresaService: EmpresaService,
    private licencaService: LicencaService,
    private pedidoService: PedidoService,
    private saqueService: SaqueService,
    private extratoService: ExtratoService,
    private messageService: MessageService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.jwtPayload = this.authService.getJwtPayload();

    this.extratoFilter = new ExtratoFilter(this.jwtPayload.empresaId);

    this.extratoService.transferEvent.subscribe(
      () => {
        this.buscar();
        this.exibirTransfer = false;
      }
    );

    this.empresaService.getEmpresas().subscribe(
      result => {
        this.empresasOptions = [];
        result.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );
  }

  buscarLicencas() {
    this.extratoFilter.licencaId = undefined;
    // PGW e FERRARO
    if (this.jwtPayload.usuarioEmail.includes('@pgwpay.com') || this.jwtPayload.empresaId === '602af0fb0ec03147413623ba') {
      this.maxDate = null;
    } else {
      this.maxDate = new Date();
    }
    this.licencaService.buscarPorEmpresa(this.extratoFilter.empresaId).subscribe(
      result => {
        this.licencas = result;
        this.licencasOptions = [];
        result.forEach(licenca => {
          this.licencasOptions.push(
            { label: `${licenca.contrato} [${licenca.moeda}]`, value: licenca.id, moeda: licenca.moeda }
          );
          if (this.extratoFilter.licencaId === undefined) {
            this.extratoFilter.licencaId = licenca.id;
            this.moeda = licenca.moeda;
          }
        });
      },
      err => console.log('Error:', err),
      () => this.buscar()
    );
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

  buscar() {
    this.lancamentos = [];
    this.qtdCredito = 0;
    this.qtdDebito = 0;
    this.totalCredito = 0.00;
    this.totalDebito = 0.00;
    this.totalSaldo = 0.00;

    this.licencas.forEach(l => {
      if (this.extratoFilter.licencaId === l.id) {
        this.moeda = l.moeda;
      }
    });

    this.csvQuery = `${environment.apiUrl}/public/extrato/csv?licencaId=${this.extratoFilter.licencaId}`;
    if (this.extratoFilter.rangeDates !== undefined && this.extratoFilter.rangeDates[0] != null) {
      this.csvQuery += '&dtInicio='.concat(this.extratoFilter.rangeDates[0].toISOString().substr(0, 10));
    }
    if (this.extratoFilter.rangeDates !== undefined && this.extratoFilter.rangeDates[1] != null) {
      this.csvQuery += '&dtFim='.concat(this.extratoFilter.rangeDates[1].toISOString().substr(0, 10));
    }

    this.extratoService.getLancamentos(this.extratoFilter).subscribe(
      data => {
        this.lancamentos = data;
        data.forEach(l => {
          if (l.credito !== 0.00) {
            this.qtdCredito++;
            this.totalCredito += (l.credito);
          }
          if (l.debito !== 0.00) {
            this.qtdDebito++;
            this.totalDebito += (l.debito);
          }
          this.totalSaldo = l.saldo;
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.extratoFilter.clear();

        console.log('lancamentos:', this.lancamentos);
        this.resumo = {
          qtd: 0,
          total: 0,
          formas: []
        }
        for (const l of this.lancamentos) {
          if (l.credito == 0) {
            continue;
          }
          let forma = undefined;
          for (const f of this.resumo.formas) {
            if (f.forma === l.tipo) {
              forma = f;
              break;
            }
          }
          if (forma) {
            forma.qtd++;
            forma.total += l.credito;
          } else {
            this.resumo.formas.push({
              forma: l.tipo,
              qtd: 1,
              total: l.credito
            });
          }
          this.resumo.qtd++;
          this.resumo.total += l.credito;
        }

      }
    );
  }

  onRowSelectLancamento(lancamento: LancamentoDTO) {
    if (lancamento.pedidoId) {
      if (this.hasPermission('PEDIDO_VISUALIZAR')) {
        this.pedidoService.getPedido(lancamento.pedidoId).subscribe(
          pedido => {
            this.pedidoSelecionado = pedido;
            this.exibirPedido = true;
            for (const l of this.licencas) {
              if (l.id === pedido.licencaId) {
                this.licencaSelecionada = l;
                break;
              }
            }
          }
        );
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: 'Permissão Negada!',
          detail: 'Você não possui permissão para visualizar detalhes do pedido.'
        });
      }
    }


    if (lancamento.saqueId) {
      if (this.hasPermission('PEDIDO_CONSULTAR')) {
        this.saqueService.getDeposito(lancamento.saqueId).subscribe(
          saque => {
            this.saqueSelecionado = saque;
            this.saqueService.getPedidosDTOPorSaqueId(lancamento.saqueId).subscribe(
              pedidosDTO => {
                this.pedidosDTOSelecionado = pedidosDTO;
                this.exibirSaque = true;
              }
            );
          }
        );
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: 'Permissão Negada!',
          detail: 'Você não possui permissão para consultar pedidos.'
        });
      }
    }
  }

  wrap(str: string) {
    if (str && str.length > 33) {
      return str.substr(0, 33) + " " + str.substr(33);
    } else {
      return str;
    }
  }

  updatePedido(event) {
    for (let i = 0; i < this.lancamentos.length; i++) {
      if (this.lancamentos[i].pedidoId == event.id) {
        this.lancamentos[i].comprador = event.compradorNomeCompleto;
        break;
      }
    }
  }

  transfer() {
    for (const l of this.licencas) {
      if (l.id === this.extratoFilter.licencaId) {
        this.licencaSelecionada = l;
        break;
      }
    }

    this.licencasDestinoOptions = [];
    for (const licencaOptional of this.licencasOptions) {
      if (licencaOptional.value === this.extratoFilter.licencaId) {
        continue;
      }
      for (const l of this.licencas) {
        if (licencaOptional.value === l.id) {
          // 1 - Mesma moeda
          if (l.moeda === this.licencaSelecionada.moeda) {
            this.licencasDestinoOptions.push(licencaOptional);
          }
          // 2 - Moeda origem BRL para moeda destino diferente de BRL
          if (this.licencaSelecionada.moeda === 'BRL' && l.moeda !== 'BRL') {
            this.licencasDestinoOptions.push(licencaOptional);
          }
          // 3 - Moeda origem diferente de BRL para moeda destino BRL
          if (this.licencaSelecionada.moeda !== 'BRL' && l.moeda === 'BRL') {
            this.licencasDestinoOptions.push(licencaOptional);
          }
        }
      }
    }

    this.exibirTransfer = true;
  }

  isTransferEnable() {
    // Somete PGW
    if (this.jwtPayload.empresaId != '5d5ffff07396f1033f599e0d') {
      return false;
    }

    for (const l of this.licencas) {
      if (l.id === this.extratoFilter.licencaId) {
        this.licencaSelecionada = l;
        break;
      }
    }

    let c = 0;
    for (const licencaOptional of this.licencasOptions) {
      if (licencaOptional.value === this.extratoFilter.licencaId) {
        continue;
      }
      for (const l of this.licencas) {
        if (licencaOptional.value === l.id) {
          // 1 - Mesma moeda
          if (l.moeda === this.licencaSelecionada.moeda) {
            c++;
          }
          // 2 - Moeda origem BRL para moeda destino diferente de BRL
          if (this.licencaSelecionada.moeda === 'BRL' && l.moeda !== 'BRL') {
            c++;
          }
          // 3 - Moeda origem diferente de BRL para moeda destino BRL
          if (this.licencaSelecionada.moeda !== 'BRL' && l.moeda === 'BRL') {
            c++;
          }
        }
      }
    }

    if (c > 0 && this.totalSaldo && this.totalSaldo > 0.00 && this.hasPermission('EXTRATO_TRANSFER')) {
      return true;
    } else {
      return false;
    }
  }

}
