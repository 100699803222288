<div class="pgw--container">

  <div class="ui-g" style="padding: 20px 0px;">

    <div class="ui-g-12">
      <h1>Estatísticas</h1>
    </div>

    <div class="ui-g-12">

      <p-tabView>

        <p-tabPanel header="Vendas" [selected]="tab === 'vendas'">
          <app-estatistica-vendas></app-estatistica-vendas>
        </p-tabPanel>

        <p-tabPanel header="Depósitos" [selected]="tab === 'depositos'">
            <app-estatistica-depositos></app-estatistica-depositos>
        </p-tabPanel>

        <p-tabPanel header="Disponível" [selected]="tab === 'disponivel'">
          <app-estatistica-disponivel></app-estatistica-disponivel>
        </p-tabPanel>

        <p-tabPanel header="Clientes" [selected]="tab === 'clientes'">
          <app-estatistica-clientes></app-estatistica-clientes>
        </p-tabPanel>
        
        <p-tabPanel header="Produtos" [selected]="tab === 'produtos'">
          <app-estatistica-produtos></app-estatistica-produtos>
        </p-tabPanel>

      </p-tabView>

    </div>

  </div>

</div>
