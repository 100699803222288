import { Component, OnInit } from '@angular/core';

import * as Highcharts from 'highcharts';

import { PedidoFilter } from 'src/app/pedido/pedido-filter';
import { AuthService } from 'src/app/seguranca/auth.service';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { LicencaService } from 'src/app/licenca/licenca.service';
import { PedidoService } from 'src/app/pedido/pedido.service';

declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);


@Component({
  selector: 'app-estatistica-vendas',
  templateUrl: './estatistica-vendas.component.html',
  styleUrls: ['./estatistica-vendas.component.css']
})
export class EstatisticaVendasComponent implements OnInit {

  jwtPayload: any;
  empresasOptions: any[];
  licencasOptions: any[];
  pedidoFilter: PedidoFilter;

  public chartDiaOptions: any = {
    title: {
      text: 'Dia do Mês',
      x: 0 // center
    },
    subtitle: {
      text: 'Monetário',
      x: 0
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e %b'
      }
    },
    yAxis: {
      title: {
        text: 'Valor'
      },
      min: 0,
      plotLines: [{
        value: 0,
        width: 1,
        color: '#808080'
      }]
    },
    tooltip: {
      valuePrefix: '$'
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      borderWidth: 0
    },
    series: [
      {
        name: 'Pendente',
        data: [],
        pointInterval: 24 * 3600 * 1000 // one day
      },
      {
        name: 'Pago',
        data: [],
        pointInterval: 24 * 3600 * 1000 // one day
      },
      {
        name: 'Disponível',
        data: [],
        pointInterval: 24 * 3600 * 1000 // one day
      },
      {
        name: 'Cancelado',
        data: [],
        pointInterval: 24 * 3600 * 1000 // one day
      },
      {
        name: 'Antecipado',
        data: [],
        pointInterval: 24 * 3600 * 1000 // one day
      }
    ]
  };

  public chartSemanaOptions: any = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Dia da Semana',
      x: 0
    },
    subtitle: {
      text: 'Monetário',
      x: 0
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: [
        'Domingo',
        'Segunda Feira',
        'Terça Feira',
        'Quarta Feira',
        'Quinta Feira',
        'Sexta Feira',
        'Sábado'
      ]
    },
    yAxis: {
      title: {
        text: 'Valor'
      },
      min: 0,
      plotLines: [{
        value: 0,
        width: 1,
        color: '#808080'
      }]
    },
    tooltip: {
      valuePrefix: '$'
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      borderWidth: 0
    },
    series: [
      { name: 'Pendente', data: [] },
      { name: 'Pago', data: [] },
      { name: 'Disponível', data: [] },
      { name: 'Cancelado', data: [] },
      { name: 'Antecipado', data: [] }
    ]
  };

  public chartHoraOptions: any = {
    title: {
      text: 'Hora do Dia',
      x: 0 // center
    },
    subtitle: {
      text: 'Monetário',
      x: 0
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        hour: '%H:%M'
      }
    },
    yAxis: {
      title: {
        text: 'Valor'
      },
      min: 0,
      plotLines: [{
        value: 0,
        width: 1,
        color: '#808080'
      }]
    },
    tooltip: {
      valuePrefix: '$',
      xDateFormat: '%H:%M',
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      borderWidth: 0
    },
    series: [
      {
        name: 'Pendente',
        data: [],
        pointInterval: 3600 * 1000 // one day
      },
      {
        name: 'Pago',
        data: [],
        pointInterval: 3600 * 1000 // one day
      },
      {
        name: 'Disponível',
        data: [],
        pointInterval: 3600 * 1000 // one day
      },
      {
        name: 'Cancelado',
        data: [],
        pointInterval: 3600 * 1000 // one day
      },
      {
        name: 'Antecipado',
        data: [],
        pointInterval: 3600 * 1000 // one day
      }
    ]
  };

  constructor(
    private authService: AuthService,
    private empresaService: EmpresaService,
    private licencaService: LicencaService,
    private pedidoService: PedidoService
  ) { }

  ngOnInit() {
    this.jwtPayload = this.authService.getJwtPayload();

    this.pedidoFilter = new PedidoFilter(this.jwtPayload.empresaId);
    this.pedidoFilter.moeda = undefined;
    this.pedidoFilter.origem = undefined;

    this.empresaService.getEmpresas().subscribe(
      empresas => {
        this.empresasOptions = [];
        empresas.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );
  }

  buscarLicencas() {
    this.pedidoFilter.licencaId = undefined;
    this.licencaService.buscarPorEmpresa(this.pedidoFilter.empresaId).subscribe(
      licencas => {
        this.licencasOptions = [];
        licencas.forEach(licenca => {
          this.licencasOptions.push(
            { label: `${licenca.contrato} [${licenca.moeda}]`, value: licenca.id, moeda: licenca.moeda }
          );
          if (this.pedidoFilter.licencaId === undefined) {
            this.pedidoFilter.licencaId = licenca.id;
          }
        });
      },
      err => console.log('Error:', err)
    );
  }

  buscar() {
    this.pedidoFilter.situacoesOptions = [1, 3, 4, 7];
    this.pedidoService.getVendasDia(this.pedidoFilter).subscribe(
      result => {
        this.chartDiaOptions.series[0].data = [];
        this.chartDiaOptions.series[1].data = [];
        this.chartDiaOptions.series[2].data = [];
        this.chartDiaOptions.series[3].data = [];
        this.chartDiaOptions.series[4].data = [];
        if (result.length > 0) {
          const dtInit = Date.parse(result[0].dt);
          this.chartDiaOptions.series[0].pointStart = dtInit;
          this.chartDiaOptions.series[1].pointStart = dtInit;
          this.chartDiaOptions.series[2].pointStart = dtInit;
          this.chartDiaOptions.series[3].pointStart = dtInit;
          this.chartDiaOptions.series[4].pointStart = dtInit;

          for (const value of result) {
            this.chartDiaOptions.series[0].data.push(value.totalPendente);
            this.chartDiaOptions.series[1].data.push(value.totalPago);
            this.chartDiaOptions.series[2].data.push(value.totalDisponivel);
            this.chartDiaOptions.series[3].data.push(value.totalCancelado);
            this.chartDiaOptions.series[4].data.push(value.totalLiquidado);
          }
        }
        Highcharts.chart('chart-vendas-dia', this.chartDiaOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );

    this.pedidoService.getVendasSemana(this.pedidoFilter).subscribe(
      result => {
        this.chartSemanaOptions.series[0].data = [];
        this.chartSemanaOptions.series[1].data = [];
        this.chartSemanaOptions.series[2].data = [];
        this.chartSemanaOptions.series[3].data = [];
        this.chartSemanaOptions.series[4].data = [];
        if (result.length > 0) {
          for (const value of result) {
            this.chartSemanaOptions.series[0].data.push(value.totalPendente);
            this.chartSemanaOptions.series[1].data.push(value.totalPago);
            this.chartSemanaOptions.series[2].data.push(value.totalDisponivel);
            this.chartSemanaOptions.series[3].data.push(value.totalCancelado);
            this.chartSemanaOptions.series[4].data.push(value.totalLiquidado);
          }
        }
        Highcharts.chart('chart-vendas-semana', this.chartSemanaOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );

    this.pedidoService.getVendasHora(this.pedidoFilter).subscribe(
      result => {
        console.log('Vendas Hora:', result);
        this.chartHoraOptions.series[0].data = [];
        this.chartHoraOptions.series[1].data = [];
        this.chartHoraOptions.series[2].data = [];
        this.chartHoraOptions.series[3].data = [];
        this.chartHoraOptions.series[4].data = [];
        if (result.length > 0) {
          const dtInit = Date.UTC(0, 0, 0, 0, 0, 0);
          this.chartHoraOptions.series[0].pointStart = dtInit;
          this.chartHoraOptions.series[1].pointStart = dtInit;
          this.chartHoraOptions.series[2].pointStart = dtInit;
          this.chartHoraOptions.series[3].pointStart = dtInit;
          this.chartHoraOptions.series[4].pointStart = dtInit;

          for (const value of result) {
            this.chartHoraOptions.series[0].data.push(value.totalPendente);
            this.chartHoraOptions.series[1].data.push(value.totalPago);
            this.chartHoraOptions.series[2].data.push(value.totalDisponivel);
            this.chartHoraOptions.series[3].data.push(value.totalCancelado);
            this.chartHoraOptions.series[4].data.push(value.totalLiquidado);
          }
        }
        Highcharts.chart('chart-vendas-hora', this.chartHoraOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );

  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

}
