<div class="ui-g">

    <div class="ui-g-12 ui-g-nopad">
        <div class="ui-g-12 ui-fluid" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
            <p-dropdown [options]="empresasOptions" [(ngModel)]="pedidoFilter.empresaId" (onChange)="buscarLicencas()" filter="true" resetFilterOnHide="true">
            </p-dropdown>
        </div>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-6 ui-fluid">
        <p-dropdown [options]="licencasOptions" [(ngModel)]="pedidoFilter.licencaId" pTooltip="Contratos"
            tooltipPosition="top">
        </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-5 ui-lg-3 ui-fluid">
        <p-calendar [(ngModel)]="pedidoFilter.rangeDates" selectionMode="range" [readonlyInput]="true"
            dateFormat="dd/mm/yy" placeholder="Período" pTooltip="Data de Início ou Período" tooltipPosition="top">
        </p-calendar>
    </div>

    <div class="ui-g-10 ui-md-5 ui-lg-2 ui-fluid">
        <p-dropdown [options]="pedidoFilter.topOptions" [(ngModel)]="pedidoFilter.limit" pTooltip="Listagem"
            tooltipPosition="top">
        </p-dropdown>
    </div>

    <div class="ui-g-2 ui-md-1 ui-lg-1">
        <button pButton type="button" icon="pi pi-search" (click)="buscar()"></button>
    </div>

    <div class="ui-g-12">
        <div [hidden]="barProdutosMonetarioOptions.series[0].data.length == 0 || !hasPermission('HOME_CLIENTE-CHART')">
            <div id="chart-produtos-monetario" class="dash-box" style="overflow: unset;">
            </div>
        </div>
        <div *ngIf="barProdutosMonetarioOptions.series[0].data.length == 0 || !hasPermission('HOME_CLIENTE-CHART')">
            <div class="dash-box" style="height: 250px;">
                <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
                <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
            </div>
        </div>
    </div>

    <div class="ui-g-12">
        <div [hidden]="barProdutosQuantitativoOptions.series[0].data.length == 0 || !hasPermission('HOME_CLIENTE-CHART')">
            <div id="chart-produtos-quantitativo" class="dash-box" style="overflow: unset;">
            </div>
        </div>
        <div *ngIf="barProdutosQuantitativoOptions.series[0].data.length == 0 || !hasPermission('HOME_CLIENTE-CHART')">
            <div class="dash-box" style="height: 250px;">
                <img src="../assets/images/vazio.png" style="margin-top: 30px;" />
                <p style="color: #bfbfbf;">Nenhuma transação neste período.</p>
            </div>
        </div>
    </div>

</div>
