import { Injectable, EventEmitter } from '@angular/core';
import { Conta } from './conta.model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContaService {

  contaEvent = new EventEmitter();

  constructor(private http: HttpClient) { }

  criar(conta: Conta): Observable<Conta> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<Conta>(`${environment.apiUrl}/contas`, conta, { headers });
  }

  atualizar(conta: Conta): Observable<Conta> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<Conta>(`${environment.apiUrl}/contas`, conta, { headers });
  }

  excluir(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/contas/${id}`);
  }

  buscarPorEmpresa(empresaId: string): Observable<Conta[]> {
    return this.http.get<Conta[]>(`${environment.apiUrl}/contas/${empresaId}`);
  }

}
