import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/seguranca/auth.service';
import { PedidoService } from 'src/app/pedido/pedido.service';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { Conta } from 'src/app/empresa/conta.model';
import { ContaService } from 'src/app/empresa/conta.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Saque } from '../saque.model';
import { SaqueService } from '../saque.service';
import { LicencaService } from 'src/app/licenca/licenca.service';
import { Licenca } from 'src/app/licenca/licenca.model';
import { formatCurrency } from '@angular/common';

@Component({
  selector: 'app-saque-solicitacao',
  templateUrl: './saque-solicitacao.component.html',
  styleUrls: ['./saque-solicitacao.component.css']
})
export class SaqueSolicitacaoComponent implements OnInit {

  jwtPayload: any;
  empresasOptions: any[];
  licencasOptions: any[];
  licencas: Licenca[];
  empresaId: string;
  licencaId: string;
  moeda: string;
  tab: string;
  regExValor: RegExp = /[0123456789,]/;
  totalDisponivel = 0.00;
  totalBloqueado = 0.00;
  totalLiberado = 0.00;
  totalSaque: string;
  qtdSaque = 0;
  contas: Conta[] = [];
  selectedContaId: string;
  licencaSelecionada: Licenca;
  exibirTutorial = false;
  blocked: boolean;


  constructor(
    private messageService: MessageService,
    private empresaService: EmpresaService,
    private licencaService: LicencaService,
    private pedidoService: PedidoService,
    private contaService: ContaService,
    private saqueService: SaqueService,
    private authService: AuthService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit() {
    this.blocked = false;
    this.jwtPayload = this.authService.getJwtPayload();
    this.empresaId = this.jwtPayload.empresaId;
    this.tab = 'CONTAS';

    this.empresaService.getEmpresas().subscribe(
      empresas => {
        this.empresasOptions = [];
        empresas.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
          if (this.empresaId === undefined) {
            this.empresaId = empresa.id;
          }
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );
  }

  buscarLicencas() {
    this.licencaId = undefined;
    this.licencaService.buscarPorEmpresa(this.empresaId).subscribe(
      licencas => {
        this.licencas = licencas;
        this.licencasOptions = [];
        licencas.forEach(licenca => {
          this.licencasOptions.push(
            { label: `${licenca.contrato} [${licenca.moeda}]`, value: licenca.id, moeda: licenca.moeda }
          );
          if (this.licencaId === undefined) {
            this.licencaId = licenca.id;
            this.setMoeda(licenca.moeda);
          }
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.buscar();
      }
    );

    this.contaService.buscarPorEmpresa(this.empresaId).subscribe(
      contas => {
        console.log('Contas:', contas);
        this.contas = contas;
      },
      error => {
        console.log('Error:', error);
        this.contas = [];
      }
    );
  }

  buscar() {
    for (const licenca of this.licencas) {
      if (licenca.id === this.licencaId) {
        this.setMoeda(licenca.moeda);
        break;
      }
    }

    this.totalDisponivel = 0.00;
    this.totalBloqueado = 0.00;
    this.totalLiberado = 0.00;

    console.log('buscar():', this.licencaId);
    this.pedidoService.getDisponivel(this.licencaId).subscribe(
      disponivel => {
        this.totalDisponivel = disponivel;


        this.pedidoService.getPendente(this.licencaId).subscribe(
          pendente => {
            this.totalBloqueado = pendente;
            this.totalLiberado = this.totalDisponivel - this.totalBloqueado;
            this.totalSaque = this.totalLiberado.toFixed(2);
            if (this.moeda === 'BRL') {
              this.totalSaque = this.totalSaque.replace('.', ',');
            }
          });
      }
    );

    this.selectedContaId = undefined;
  }

  confirmar() {
    this.blocked = true;

    // Valida conta
    if (this.selectedContaId === undefined) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Selecione a conta para resgate.'
      });
      this.blocked = false;
      return;
    }

    if (this.totalSaque === undefined || this.totalSaque === null || 0 === this.totalSaque.trim().length) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Informe o valor solicitado.'
      });
      this.blocked = false;
      return;
    }

    const saque = new Saque(this.empresaId);

    if (this.moeda === 'BRL') {
      saque.valor = parseFloat(this.totalSaque.replace(',', '.'));
    } else {
      saque.valor = parseFloat(this.totalSaque);
    }
    if (saque.valor > this.totalLiberado) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Valor solicitado maior que o liberado.'
      });
      this.blocked = false;
      return;
    }
    if (saque.valor === undefined || saque.valor === null || saque.valor == NaN || saque.valor <= 0.00) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Valor solicitado inálido.'
      });
      this.blocked = false;
      return;
    }
    if (saque.moeda === 'BRL' && saque.valor <= 10.00) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Valor mínimo para resgate é R$ 10.00.'
      });
      this.blocked = false;
      return;
    }
    saque.moeda = this.moeda;
    saque.licencaId = this.licencaId;
    // saque.pedidosId = this.selectedPedidos;
    saque.contaId = this.selectedContaId;
    for (const conta of this.contas) {
      if (conta.id === this.selectedContaId) {
        saque.banco = conta.banco;
        saque.agencia = conta.agencia;
        saque.conta = conta.conta;
        saque.titular = conta.titular;
        saque.cpfCnpj = conta.cpfCnpj;
        break;
      }
    }
    console.log('confirm')
    this.blocked = false;
    let v = formatCurrency(saque.valor, 'pt-br', 'R$');
    this.confirmationService.confirm({
      message: `Confirma a solicitação do saque de ${v} ?<table><tbody><tr><th>Banco:</th><td>${saque.banco}</td></tr><tr><th>Agência:</th><td>${saque.agencia}</td></tr><tr><th>Conta:</th><td>${saque.conta}</td></tr><tr><th>Titular:</th><td>${saque.titular}</td></tr><tr><th>Cpf/Cnpj:</th><td>${saque.cpfCnpj}</td></tr></tbody></table>`,
      header: 'Solicitação de saque',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.blocked = true;
        this.saqueService.solicitar(saque).subscribe(
          data => {
            this.tab = 'CONFIRMAR';
            this.selectedContaId = undefined;
            this.blocked = false;
          },
          error => {
            console.log('Error :', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Erro ao criar saque!',
              detail: 'Erro ao criar saque.'
            });
            this.selectedContaId = undefined;
            this.blocked = false;
          }
        );
      },
      reject: () => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Saque não confirmado!',
          detail: 'Saque não confirmado pelo usuário.'
        });
        this.blocked = false;
      },
      key: "positionDialog"
    });

  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

  setMoeda(moeda: string) {
    this.moeda = moeda;
    if (moeda === 'BRL') {
      this.regExValor = /[0123456789,]/;
    } else {
      this.regExValor = /[0123456789.]/;
    }
  }

  novaSolicitacao() {
    this.ngOnInit();
  }

  verTutorial() {
    this.exibirTutorial = true;
  }
  fecharTutorial() {
    this.exibirTutorial = false;
  }

}
