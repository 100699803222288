import { Component, OnInit } from '@angular/core';

import { MessageService } from 'primeng/api';

import { AuthService } from 'src/app/seguranca/auth.service';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { LicencaService } from 'src/app/licenca/licenca.service';
import { Licenca } from 'src/app/licenca/licenca.model';
import { PedidoFilter } from 'src/app/pedido/pedido-filter';
import { PedidoDTO } from 'src/app/pedido/pedido-dto';
import { PedidoService } from 'src/app/pedido/pedido.service';
import { Pedido } from 'src/app/pedido/pedido.model';

@Component({
  selector: 'app-boleto-recorrente',
  templateUrl: './boleto-recorrente.component.html',
  styleUrls: ['./boleto-recorrente.component.css']
})
export class BoletoRecorrenteComponent implements OnInit {

  jwtPayload: any;
  exibirPedido = false;
  exibirDependentes = false;
  pedidoSelecionado: Pedido;
  licencaSelecionada: Licenca;
  empresasOptions: any[];
  licencasOptions: any[];
  licencas: Licenca[];

  pedidoRoot: Pedido;
  pedidosDependentes: PedidoDTO[];

  pedidosDTO: PedidoDTO[] = [];
  pedidoFilter: PedidoFilter;

  constructor(
    private messageService: MessageService,
    private empresaService: EmpresaService,
    private licencaService: LicencaService,
    private pedidoService: PedidoService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.jwtPayload = this.authService.getJwtPayload();
    this.pedidoFilter = new PedidoFilter(this.jwtPayload.empresaId);
    this.pedidoFilter.origem = undefined;
    this.pedidoFilter.recorrente = true;
    this.pedidoFilter.recorrenteRoot = true;
    this.pedidoFilter.recorrenteAtivo = true;

    this.empresaService.getEmpresas().subscribe(
      result => {
        this.empresasOptions = [];
        result.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );
  }

  buscarLicencas() {
    this.pedidoFilter.licencaId = undefined;
    let moedas = [];
    this.licencaService.buscarPorEmpresa(this.pedidoFilter.empresaId).subscribe(
      result => {
        this.licencas = result;
        this.licencasOptions = [];

        result.forEach(licenca => {
          this.licencasOptions.push(
            { label: licenca.contrato, value: licenca.id }
          );
          if (!moedas.includes(licenca.moeda)) {
            moedas.push(licenca.moeda);
          }
          if (this.pedidoFilter.licencaId === undefined) {
            this.pedidoFilter.licencaId = licenca.id;
            this.pedidoFilter.moeda = licenca.moeda;
          }
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        if (this.licencasOptions.length > 1) {
          if (moedas.length > 1) {
            moedas.forEach(moeda => {
              this.licencasOptions.push(
                { label: `Todos - ${moeda}`, value: moeda }
              );
            });
          } else {
            this.licencasOptions.push(
              { label: 'Todos', value: 'ALL' }
            );
          }
        }
        this.buscar();
      }
    );
  }

  selecionarLicenca() {
    this.licencas.forEach(licenca => {
      if (this.pedidoFilter.licencaId && this.pedidoFilter.licencaId.length === 3) {
        this.pedidoFilter.moeda = this.pedidoFilter.licencaId;
      } else if (this.pedidoFilter.licencaId === licenca.id) {
        this.pedidoFilter.moeda = licenca.moeda;
      }
    });
  }

  buscar() {
    console.log('Pedido Filter', this.pedidoFilter);

    if (this.pedidoFilter.licencaId.length === 3) {
      this.pedidoFilter.licencaId = undefined;
    }

    this.pedidosDTO = [];
    this.pedidoService.getPedidosRecorrentes(this.pedidoFilter).subscribe(
      pedidosDTO => {
        this.pedidosDTO = pedidosDTO;
        if (!this.pedidoFilter.licencaId) {
          if (this.pedidoFilter.moeda) {
            this.pedidoFilter.licencaId = this.pedidoFilter.moeda;
          } else if (this.licencas && this.licencas[0] && this.licencas[0].id) {
            this.pedidoFilter.licencaId = this.licencas[0].id;
          }
        }
        console.log('PedidosDTO', this.pedidosDTO);
      },
      err => {
        console.log('Error:', err);
      }
    );
  }

  onRowSelect(pedidoDTO: PedidoDTO) {
    this.exibirDependentes = false;
    if (this.hasPermission('PEDIDO_VISUALIZAR')) {
      this.pedidoService.getPedido(pedidoDTO.id).subscribe(
        pedido => {
          this.pedidoSelecionado = pedido;
          console.log('PedidoSelecionado', pedido);
          for (const l of this.licencas) {
            if (l.id === pedido.licencaId) {
              this.licencaSelecionada = l;
              break;
            }
          }
          this.exibirPedido = true;
        }
      );
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Permissão Negada!',
        detail: 'Você não possui permissão para visualizar detalhes do pedido.'
      });
    }
  }

  onPedidoRootSelect(pedido: Pedido) {
    this.exibirDependentes = false;
    this.pedidoSelecionado = pedido;
    console.log('PedidoSelecionado', pedido);
    for (const l of this.licencas) {
      if (l.id === pedido.licencaId) {
        this.licencaSelecionada = l;
        break;
      }
    }
    this.exibirPedido = true;
  }

  showPedidosDependentes(event) {
    this.pedidoRoot = event.pedido;
    this.pedidosDependentes = event.pedidos;
    this.exibirDependentes = true;
  }

  fecharDependentes() {
    this.exibirDependentes = false;
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }
}
