<div class="ui-g">

  <div class="ui-g-12">
    <button pButton label="Criar Contrato" icon="pi pi-plus" class="ui-button-success"
      (click)="criar()" *ngIf="hasPermission('CONFIG_TERMINAL_CRIAR')"></button>
  </div>

  <div class="ui-g-12 ui-fluid" *ngIf="hasPermission('CONFIG_TERMINAL_CONSULTAR')">
    <p-table [value]="licencas" [paginator]="true" [rows]="10" [responsive]="true" selectionMode="single"
      (onRowSelect)="onRowSelect($event.data)">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 230px;">Identificador</th>
          <th>Título</th>
          <th style="width: 70px;" *ngIf="hasPermission('CONFIG_TERMINAL_EXCLUIR')">
            Excluir
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-licenca>
        <tr [pSelectableRow]="licenca">
          <td class="col-center">{{ licenca.id }}</td>
          <td class="col-center">{{ licenca.titulo }}</td>
          <td class="col-center" *ngIf="hasPermission('CONFIG_TERMINAL_EXCLUIR')">
            <p-button icon="pi pi-trash" (click)="excluir(licenca.id)" title="Excluir Contrato"></p-button>
          </td>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="3">
            Nenhum contrato cadastrado!
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

</div>

<nav *ngIf="exibir">
  <p-sidebar [(visible)]="exibir" position="left" styleClass="ui-sidebar-md">
    <app-licenca-cadastro [licenca]="licenca"></app-licenca-cadastro>
  </p-sidebar>
</nav>
