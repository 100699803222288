import { Grupo } from './grupo.model';

export class Usuario {

  id: string;
  empresaId: string;
  nome: string;
  apelido: string;
  ddd: string;
  celular: string;
  email: string;
  senha: string;

  grupos: Grupo[];

  constructor(empresaId: string) {
    this.empresaId = empresaId;
    this.grupos = [];
  }

}
