<div class="pgw--container">

  <h1>Extrato</h1>

  <div class="ui-g" style="margin-top: 20px; margin-bottom: 50px;" *ngIf="hasPermission('EXTRATO_CONSULTAR')">

    <div class="ui-g-12 ui-g-nopad">
      <div class="ui-g-12 ui-fluid" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
        <p-dropdown [options]="empresasOptions" [(ngModel)]="extratoFilter.empresaId" (onChange)="buscarLicencas()"
          filter="true" resetFilterOnHide="true">
        </p-dropdown>
      </div>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-9 ui-fluid">
      <p-dropdown [options]="licencasOptions" [(ngModel)]="extratoFilter.licencaId" pTooltip="Contratos"
        tooltipPosition="top">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-5 ui-lg-2 ui-fluid">
      <p-calendar [(ngModel)]="extratoFilter.rangeDates" selectionMode="range" [readonlyInput]="true"
        [maxDate]="maxDate" dateFormat="dd/mm/yy" placeholder="Período" pTooltip="Data de Início ou Período"
        tooltipPosition="top">
      </p-calendar>
    </div>

    <div class="ui-g-12 ui-md-1 ui-lg-1">
      <button pButton type="button" icon="pi pi-search" (click)="buscar()"></button>
    </div>

    <div class="ui-g-12 ui-fluid">
      <p-table [value]="lancamentos" [responsive]="true" selectionMode="single"
        (onRowSelect)="onRowSelectLancamento($event.data)">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 100px;">Data</th>
            <th style="width: 100px;">Pedido</th>
            <th>Histórico</th>
            <th style="width: 230px;">Autorização</th>
            <th style="width: 80px;">Tipo</th>
            <th style="width: 130px;" class="col-right">Crédito</th>
            <th style="width: 130px;" class="col-right">Débito</th>
            <th style="width: 130px;" class="col-right">Saldo</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-lancamento>
          <tr [pSelectableRow]="lancamento">

            <td class="col-center">{{ lancamento.disponivel | date:'dd/MM/yyyy' }}</td>
            <td class="col-center">{{ lancamento.pedidoNumero }}</td>
            <td>{{ lancamento.comprador }}</td>
            <td class="col-center" style="font-size: 10px;">{{ wrap(lancamento.autorizacao) }}</td>
            <td class="col-center">
              <span class="pgw--forma-pgto">{{ lancamento.tipo }}</span>
              <span class="pgw--ref">{{ lancamento.ref }}</span>
            </td>

            <td class="col-right" *ngIf="moeda === 'BRL' && lancamento.credito > 0.00">
              {{ lancamento.credito | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" *ngIf="moeda === 'USD' && lancamento.credito > 0.00">
              {{ lancamento.credito | currency:'USD':'symbol':'0.2-2':'en' }}</td>
            <td class="col-right" *ngIf="lancamento.credito == 0.00"></td>

            <td class="col-right" style="color: red;" *ngIf="moeda === 'BRL' && lancamento.debito > 0.00">
              {{ lancamento.debito | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" style="color: red;" *ngIf="moeda === 'USD' && lancamento.debito > 0.00">
              {{ lancamento.debito | currency:'USD':'symbol':'0.2-2':'en' }}</td>
            <td class="col-right" style="color: red;" *ngIf="lancamento.debito == 0.00"></td>

            <td class="col-right" *ngIf="lancamento.exibirSaldo && moeda === 'BRL'">
              {{ lancamento.saldo | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" *ngIf="lancamento.exibirSaldo && moeda === 'USD'">
              {{ lancamento.saldo | currency:'USD':'symbol':'0.2-2':'en' }}</td>
            <td class="col-right" *ngIf="!lancamento.exibirSaldo"></td>

          </tr>
        </ng-template>

        <ng-template pTemplate="footer">
          <tr>

            <td colspan="4">
              Lançamentos:
              {{ qtdCredito }} {{ qtdCredito > 1 ? 'Créditos' : 'Crédito'}} |
              {{ qtdDebito }} {{ qtdDebito > 1 ? 'Débitos' : 'Débito' }}
            </td>
            <td class="col-right">Total</td>

            <td class="col-right" *ngIf="moeda === 'BRL'">{{ totalCredito | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" *ngIf="moeda === 'USD'">{{ totalCredito | currency:'USD':'symbol':'0.2-2':'en' }}</td>

            <td class="col-right" style="color: red;" *ngIf="moeda == 'BRL'">
              {{ totalDebito | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" style="color: red;" *ngIf="moeda == 'USD'">
              {{ totalDebito | currency:'USD':'symbol':'0.2-2':'en' }}</td>

            <td class="col-right" *ngIf="moeda === 'BRL'">{{ totalSaldo | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" *ngIf="moeda === 'USD'">{{ totalSaldo | currency:'USD':'symbol':'0.2-2':'en' }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="ui-g-12" style="text-align: right;">
      <button pButton label="Transferir" icon="fas fa-exchange-alt" class="ui-button-primary" (click)="transfer()"
        *ngIf="isTransferEnable()"></button>
    </div>

    <div class="ui-g-12 ui-fluid" style="margin: 30px 0;">
      <p-table [value]="resumo.formas" *ngIf="resumo.formas.length > 0">

        <ng-template pTemplate="caption">
          Resumo
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th class="col-left">Forma Pagamento</th>
            <th class="col-right" style="width: 100px;">Qtd.</th>
            <th class="col-right" style="width: 120px;">Valor</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-forma>
          <tr>
            <td class="col-left">{{ forma.forma }}</td>
            <td class="col-right">{{ forma.qtd }}</td>
            <td class="col-right" *ngIf="moeda === 'BRL'">
              {{ forma.total | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" *ngIf="moeda === 'USD'">
              {{ forma.total | currency:'USD':'symbol':'0.2-2':'en' }}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer">
          <tr>
            <td class="col-left">Total</td>
            <td class="col-right">{{ resumo.qtd }}</td>
            <td class="col-right" *ngIf="moeda === 'BRL'">
              {{ resumo.total | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" *ngIf="moeda === 'USD'">
              {{ resumo.total | currency:'USD':'symbol':'0.2-2':'en' }}</td>
          </tr>
        </ng-template>

      </p-table>

    </div>

    <div class="ui-g-12" style="text-align: right;">
      <span>
        <a [href]="csvQuery" target="_blank" title="Baixe aqui o arquivo CSV"><i class="fa fa-file-csv"
            style="font-size: 3em;"></i></a>
      </span>
    </div>

  </div>

  <nav *ngIf="exibirPedido">
    <p-sidebar [(visible)]="exibirPedido" position="left" styleClass="ui-sidebar-md">

      <app-pedido-detalhe origem="EXTRATO" [pedido]="pedidoSelecionado" [licenca]="licencaSelecionada"
        (onUpdatePedido)="updatePedido($event)"></app-pedido-detalhe>

    </p-sidebar>
  </nav>

  <nav *ngIf="exibirTransfer">
    <p-sidebar [(visible)]="exibirTransfer" position="left" styleClass="ui-sidebar-md">

      <app-extrato-transfer [licencasOptions]="licencasDestinoOptions" [licencaSelecionada]="licencaSelecionada" [valorMax]="totalSaldo">
      </app-extrato-transfer>

    </p-sidebar>
  </nav>

  <nav *ngIf="exibirSaque">
    <p-sidebar [(visible)]="exibirSaque" [fullScreen]="true">
      <div class="pgw--container">
        <div class="ui-g">

          <div class="ui-g-12">
            <button pButton type="button" (click)="exibirSaque = false" label="Voltar"
              icon="pi pi-step-backward"></button>
          </div>

          <div class="ui-g-12">
            <h1>Depósito</h1>
          </div>

          <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Data/Hora Solicitação:
              </div>
              <div style="min-height: 15px;">
                {{ saqueSelecionado.dtHrSolicitacao | date:'dd/MM/yyyy - HH:mm:ss' }}
              </div>
            </div>
          </div>

          <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Data/Hora Agendamento:
              </div>
              <div style="min-height: 15px;">
                {{ saqueSelecionado.dtHrAgendamento | date:'dd/MM/yyyy - HH:mm:ss' }}
              </div>
            </div>
          </div>

          <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Data Previsão:
              </div>
              <div style="min-height: 15px;">
                {{ saqueSelecionado.dtPrevisao | date:'dd/MM/yyyy' }}
              </div>
            </div>
          </div>

          <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Data/Hora Liquidação:
              </div>
              <div style="min-height: 15px;">
                {{ saqueSelecionado.dtHrLiquidacao | date:'dd/MM/yyyy - HH:mm:ss' }}
              </div>
            </div>
          </div>

          <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Data/Hora Cancelamento:
              </div>
              <div style="min-height: 15px;">
                {{ saqueSelecionado.dtHrCancelamento | date:'dd/MM/yyyy - HH:mm:ss' }}
              </div>
            </div>
          </div>

          <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Status:
              </div>
              <div style="min-height: 15px;">
                {{ saqueSelecionado.status }}
              </div>
            </div>
          </div>

          <div class="ui-g-12 ui-md-4 ui-lg-6">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Histórico:
              </div>
              <div style="min-height: 15px;">
                {{ saqueSelecionado.historico }}
              </div>
            </div>
          </div>

          <div class="ui-g-12 ui-md-4 ui-lg-4">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Autorização:
              </div>
              <div style="min-height: 15px;">
                {{ saqueSelecionado.autorizacao }}
              </div>
            </div>
          </div>

          <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Forma de Pagamento:
              </div>
              <div style="min-height: 15px;">
                {{ saqueSelecionado.formaPagamento }}
              </div>
            </div>
          </div>

          <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Banco:
              </div>
              <div style="min-height: 15px;">
                {{ saqueSelecionado.banco }}
              </div>
            </div>
          </div>

          <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Agência:
              </div>
              <div style="min-height: 15px;">
                {{ saqueSelecionado.agencia }}
              </div>
            </div>
          </div>

          <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Conta:
              </div>
              <div style="min-height: 15px;">
                {{ saqueSelecionado.conta }}
              </div>
            </div>
          </div>

          <div class="ui-g-12 ui-md-8 ui-lg-4">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Moeda:
              </div>
              <div style="min-height: 15px;">
                {{ saqueSelecionado.moeda }}
              </div>
            </div>
          </div>

          <div class="ui-g-12 ui-md-4 ui-lg-2">
            <div style="margin-left: 15px; margin-top: 15px;">
              <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
                Valor Solicitado:
              </div>
              <div *ngIf="saqueSelecionado.moeda === 'BRL'" style="min-height: 15px;">
                {{ saqueSelecionado.valor | currency:'BRL':'symbol':'0.2-2':'pt' }}</div>
              <div *ngIf="saqueSelecionado.moeda === 'USD'" style="min-height: 15px;">
                {{ saqueSelecionado.valor | currency:'USD':'symbol':'0.2-2':'en' }}</div>
            </div>
          </div>

        </div>

        <div class="ui-g-12" style="margin-top: 20px;">
          <button pButton type="button" (click)="exibirSaque = false" label="Voltar"
            icon="pi pi-step-backward"></button>
        </div>

      </div>
    </p-sidebar>
  </nav>

</div>
