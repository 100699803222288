<div class="pgw--container">

  <div class="ui-g div-total" *ngIf="hasPermission('PEDIDO_TOTAL')">

    <div class="ui-g-2 div-pendente">
      <div *ngIf="totalPendente$ | async as tPendente else loadingPendente">
        <div class="div-pendente-total" *ngIf="pedidoFilter.moeda === 'USD'">
          {{ tPendente.total | currency:'USD':'symbol':'0.2-2':'en' }}</div>
        <div class="div-pendente-total" *ngIf="pedidoFilter.moeda === 'BRL'">
          {{ tPendente.total | currency:'BRL':'symbol':'0.2-2':'pt' }}</div>
        <div class="div-pendente-qtd">Pendente: {{ tPendente.qtd }}</div>
        <div class="div-pendente-legenda">(Últimos 30 dias)</div>
      </div>
      <ng-template #loadingPendente>
        <div>carregando...</div>
      </ng-template>
    </div>

    <div class="ui-g-4 div-pago">
      <div *ngIf="totalPago$ | async as tPago else loadingPago">
        <div class="div-pago-total" *ngIf="pedidoFilter.moeda === 'USD'">
          {{ tPago.total | currency:'USD':'symbol':'0.2-2':'en' }}</div>
        <div class="div-pago-total" *ngIf="pedidoFilter.moeda === 'BRL'">
          {{ tPago.total | currency:'BRL':'symbol':'0.2-2':'pt' }}</div>
        <div class="div-pago-qtd">Pago: {{ tPago.qtd }}</div>
        <div class="div-pago-legenda">(Últimos 30 dias)</div>
      </div>
      <ng-template #loadingPendente>
        <div>carregando...</div>
      </ng-template>
    </div>

    <div class="ui-g-4 div-disponivel">
      <div class="div-disponivel-total" *ngIf="pedidoFilter.moeda === 'USD'">
        {{ totalDisponivel | currency:'USD':'symbol':'0.2-2':'en' }}</div>
      <div class="div-disponivel-total" *ngIf="pedidoFilter.moeda === 'BRL'">
        {{ totalDisponivel | currency:'BRL':'symbol':'0.2-2':'pt' }}</div>
      <div class="div-disponivel-qtd">Disponível</div>
    </div>

    <div class="ui-g-2 div-cancelado">
      <div *ngIf="totalCancelado$ | async as tCancelado else loadingCancelado">
        <div class="div-cancelado-total" *ngIf="pedidoFilter.moeda === 'USD'">
          {{ tCancelado.total | currency:'USD':'symbol':'0.2-2':'en' }}</div>
        <div class="div-cancelado-total" *ngIf="pedidoFilter.moeda === 'BRL'">
          {{ tCancelado.total | currency:'BRL':'symbol':'0.2-2':'pt' }}</div>
        <div class="div-cancelado-qtd">Cancelado: {{ tCancelado.qtd }}</div>
        <div class="div-cancelado-legenda">(Últimos 30 dias)</div>
      </div>
      <ng-template #loadingCancelado>
        <div>carregando...</div>
      </ng-template>
    </div>

  </div>

  <div class="ui-g" style="margin-top: 20px;" *ngIf="hasPermission('PEDIDO_CONSULTAR')">

    <div class="ui-g-12 ui-g-nopad" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
      <div class="ui-g-12 ui-fluid">
        <p-dropdown [options]="empresasOptions" [(ngModel)]="pedidoFilter.empresaId" (onChange)="buscarLicencas()"
          filter="true" resetFilterOnHide="true">
        </p-dropdown>
      </div>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <input pInputText [(ngModel)]="pedidoFilter.pedidoNumero" placeholder="Pedido" pTooltip="Número do Pedido"
        tooltipPosition="top" />
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-calendar [(ngModel)]="pedidoFilter.rangeDates" selectionMode="range" [readonlyInput]="true"
        dateFormat="dd/mm/yy" placeholder="Período" pTooltip="Data de Início ou Período" tooltipPosition="top">
      </p-calendar>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-4 ui-fluid">
      <input pInputText [(ngModel)]="pedidoFilter.comprador" placeholder="Comprador" pTooltip="Nome do Comprador"
        tooltipPosition="top" />
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-4 ui-fluid">
      <input pInputText [(ngModel)]="pedidoFilter.autorizacao" placeholder="Autorização" pTooltip="Autorização"
        tooltipPosition="top" />
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-multiSelect [options]="pedidoFilter.situacoesOptions" [(ngModel)]="pedidoFilter.selectedSituacoes"
        defaultLabel="Situações" pTooltip="Situação" tooltipPosition="top">
        <ng-template let-item pTemplate="item">
          <div class="item-multi-selected" [style.color]="item.rgb">
            {{item.label}}</div>
        </ng-template>
      </p-multiSelect>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-multiSelect [options]="pedidoFilter.formasPagtoOptions" [(ngModel)]="pedidoFilter.selectedFormasPagto"
        defaultLabel="Formas Pagto." pTooltip="Forma de Pagamento" tooltipPosition="top"></p-multiSelect>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-multiSelect [options]="pedidoFilter.instituicoesOptions" [(ngModel)]="pedidoFilter.selectedInstituicoes"
        defaultLabel="Instituições" pTooltip="Instituição Financeira" tooltipPosition="top"></p-multiSelect>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-dropdown [options]="pedidoFilter.origemOptions" [(ngModel)]="pedidoFilter.origem" pTooltip="Origem"
        tooltipPosition="top">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-dropdown [options]="pedidoFilter.recorrenteOptions" [(ngModel)]="pedidoFilter.recorrente"
        pTooltip="Pagamento Recorrente" tooltipPosition="top">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-dropdown [options]="pedidoFilter.environmentOptions" [(ngModel)]="pedidoFilter.environment"
        pTooltip="Modo de Operação" tooltipPosition="top">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-7 ui-lg-11 ui-fluid">
      <p-dropdown [options]="licencasOptions" [(ngModel)]="pedidoFilter.licencaId" pTooltip="Contratos"
        tooltipPosition="top" (onChange)="selecionarLicenca()">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-1 ui-lg-1">
      <button pButton type="button" icon="pi pi-search" (click)="buscar()"></button>
    </div>

    <div class="ui-g-12 ui-fluid">
      <p-table [value]="pedidosDTO" [paginator]="tableRows > 0" [rows]="tableRows" [responsive]="true"
        selectionMode="single" (onRowSelect)="onRowSelect($event.data)">
        <ng-template pTemplate="caption">
          Pedidos encontrados: {{ pedidosDTO.length }}
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 100px;">Data</th>
            <th style="width: 100px;">Pedido</th>
            <th class="col-left">Comprador</th>
            <th style="width: 110px;">Forma Pgto.</th>
            <th style="width: 200px;">Situação</th>
            <th style="width: 120px;" class="col-right">Total</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-pedidoDTO>
          <tr [pSelectableRow]="pedidoDTO">
            <td class="col-center">{{ pedidoDTO.dtHr | date:'dd/MM/yyyy' }}</td>
            <td class="col-center">
              {{ pedidoDTO.pedidoNumero }}
              <span class="pgw--ref">{{pedidoDTO.origem}}</span>
            </td>
            <td class="col-left">
              {{ pedidoDTO.compradorNomeCompleto }}
              <span class="pgw--ref">{{ pedidoDTO.contrato }} |
                {{ pedidoDTO.environment === 'production' ? 'Produção' : pedidoDTO.environment === 'sandbox' ? 'Teste' :
                'Log' }}
              </span>
            </td>
            <td class="col-center">
              {{ pedidoDTO.formaPagamento }}
              <span class="pgw--ref" *ngIf="pedidoDTO.recorrente">recorrente</span>
              <span class="pgw--ref" *ngIf="!pedidoDTO.recorrente && pedidoDTO.qtdParcela == 1">a vista</span>
              <span class="pgw--ref" *ngIf="!pedidoDTO.recorrente && pedidoDTO.qtdParcela > 1">{{pedidoDTO.qtdParcela}}
                parcelas</span>
            </td>
            <td class="col-center" appPagseguroStatus>{{ pedidoDTO.pagseguroStatus }}</td>
            <td class="col-right" *ngIf="pedidoFilter.moeda === 'BRL'">
              {{ pedidoDTO.totalPedido | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" *ngIf="pedidoFilter.moeda === 'USD'">
              {{ pedidoDTO.totalPedido | currency:'USD':'symbol':'0.2-2':'en' }}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="summary" class="col-center">
          <p-dropdown [options]="exibirOptions" [(ngModel)]="tableRows">
          </p-dropdown>
        </ng-template>
      </p-table>
    </div>

    <div class="ui-g-12 ui-fluid" style="margin: 30px 0;">
      <p-table [value]="resumo.formas" *ngIf="resumo.formas.length > 0">

        <ng-template pTemplate="caption">
          Resumo
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th class="col-left">Forma Pagamento</th>
            <th class="col-right" style="width: 100px;">Qtd.</th>
            <th class="col-right" style="width: 120px;">Valor</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-forma>
          <tr>
            <td class="col-left">{{ forma.forma }}</td>
            <td class="col-right">{{ forma.qtd }}</td>
            <td class="col-right" *ngIf="pedidoFilter.moeda === 'BRL'">
              {{ forma.total | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" *ngIf="pedidoFilter.moeda === 'USD'">
              {{ forma.total | currency:'USD':'symbol':'0.2-2':'en' }}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer">
          <tr>
            <td class="col-left">Total</td>
            <td class="col-right">{{ resumo.qtd }}</td>
            <td class="col-right" *ngIf="pedidoFilter.moeda === 'BRL'">
              {{ resumo.total | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" *ngIf="pedidoFilter.moeda === 'USD'">
              {{ resumo.total | currency:'USD':'symbol':'0.2-2':'en' }}</td>
          </tr>
        </ng-template>

      </p-table>

    </div>

    <nav *ngIf="exibirPedido">
      <p-sidebar [(visible)]="exibirPedido" position="left" styleClass="ui-sidebar-md">

        <app-pedido-detalhe origem="PEDIDO" [pedido]="pedidoSelecionado" [licenca]="licencaSelecionada"
          (onUpdatePedido)="updatePedido($event)" (onPedidosDependentes)="showPedidosDependentes($event)">
        </app-pedido-detalhe>

      </p-sidebar>
    </nav>

    <div *ngIf="exibirDependentes">
      <p-sidebar [(visible)]="exibirDependentes" [fullScreen]="true" [baseZIndex]="9000">
        <app-pedido-dependentes [pedido]="pedidoRoot" [pedidos]="pedidosDependentes" (fechar)="fecharDependentes()"
          (pedidoDependenteSelecionado)="onRowSelect($event)" (pedidoOrigemSelecionado)="onPedidoRootSelect($event)">
        </app-pedido-dependentes>
      </p-sidebar>
    </div>

  </div>
</div>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>
