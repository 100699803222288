import { HttpParams } from '@angular/common/http';

export class RecorrenciaFilter {

  empresaId: string;
  licencaId: string;
  pedidoNumero: string;
  cpf: string;

  constructor(empresaId: string) {
    this.empresaId = empresaId;
  }

  getSearchParams() {
    let params = new HttpParams();

    if (this.empresaId !== undefined) {
      params = params.set('empresaId', this.empresaId);
    }

    if (this.licencaId !== undefined) {
      params = params.set('licencaId', this.licencaId);
    }

    if (this.pedidoNumero !== undefined) {
      params = params.set('pedidoNumero', this.pedidoNumero);
    }

    if (this.cpf !== undefined) {
      params = params.set('cpf', this.cpf);
    }

    return params;
  }

}
