export class Empresa {

  id: string;
  dtHr: Date;
  type: string;
  razaoSocial: string;
  nomeFantasia: string;
  cnpj: string;
  email: string;
  ddd: string;
  telefone: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  uf: string;
  pais: string;
  cep: string;
  usuario: string;
  senha: string;
  logo: string;

}
