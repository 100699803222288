import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { MessageService } from 'primeng/api';

import { Login } from '../login.model';
import { AuthService } from '../auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @ViewChild('loginEmail', { static: true }) emailField: ElementRef;

  user: Login;
  exibirRedefinirSenha = false;
  exibirRecuperarSenha = false;

  reCaptchaSite: string;
  reCaptcha: boolean;

  verified: boolean;

  blocked: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService
  ) {
    this.authService.carregarToken();
  }

  ngOnInit() {
    console.log('login init');
    this.reCaptchaSite = environment.reCaptchaSite;
    this.reCaptcha = false;
    this.blocked = false;

    this.user = new Login();
    this.authService.senhaRecuperadaEvent.subscribe(() => {
      this.exibirRecuperarSenha = false;
      this.emailField.nativeElement.focus();
    });
    this.emailField.nativeElement.focus();
  }

  showResponse(event) {
    console.log('Environment:', environment.production ? 'production' : 'development');
    console.log('reCapcha event:', event);
    this.authService.reCaptcha(event.response).subscribe(
      response => {
        const json = JSON.parse(response.body);
        console.log('reCapcha success:', json);

        this.verified = json.success;
        console.log('reCapcha verified:', this.verified);
      },
      error => {
        console.log('reCapcha error:', error);
        this.verified = false;
      }
    );
  }

  onExpire(event) {
    this.verified = false;
    console.log('expire', event);
  }

  login() {
    this.blocked = true;

    if (this.reCaptcha && !this.verified) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Verificação de robô!',
        detail: 'Confirme que você não é um robô.'
      });
      this.reCaptcha = true;
      this.blocked = false;
      return;
    }

    console.log('User:', this.user);
    this.authService.login(this.user).subscribe(
      response => {
        this.authService.token = response.access_token;
        this.authService.senhaAtual = this.user.senha;
        if (response.redefinirSenha) {
          console.log('Login | this.authService.senhaAtual:', this.authService.senhaAtual);
          this.user = new Login();
          this.exibirRedefinirSenha = true;
          this.authService.redefinirSenhaFocusEvent.emit();
        } else {
          this.router.navigate(['login', 'validar']);
        }
        this.reCaptcha = false;
        this.blocked = false;
      },
      error => {
        console.log('error:', error);
        this.user = new Login();
        localStorage.removeItem('access_token');
        this.authService.setJwtPayload(undefined);
        this.emailField.nativeElement.focus();
        this.messageService.add({
          severity: 'error',
          summary: 'Falha na autenticação!',
          detail: 'Usuário inexistente ou senha inválida.'
        });
        this.reCaptcha = true;
        this.blocked = false;
      }
    );
  }

  recuperarSenha() {
    this.exibirRecuperarSenha = true;
    this.authService.recuperarSenhaFocusEvent.emit();
  }

  loginFocus() {
    this.emailField.nativeElement.focus();
  }

}

// reCAPTCHA v2: pgwpay.com.br

// CHAVE DE SITE
// 6LcSuswZAAAAAE4S6N-o8kr1l2cSRTb90YEAe01S

// CHAVE SECRETA
// 6LcSuswZAAAAAK7jIiXfbi1ZlRpJRnALd-KdnhJ8

// reCAPTCHA v3: localhost

// CHAVE DE SITE
// 6LeVpPYcAAAAAJxbGbLXCjdmi-917G8TDn6WWNJa

// CHAVE SECRETA
// 6LeVpPYcAAAAACNgPNPGQzn2hM010lycIET-82lm

// reCAPTCHA v2: localhost

// CHAVE DE SITE
// 6LfdzvYcAAAAAGpeA_ocJ3nRGa5iSCnExtgwgu7b

// CHAVE SECRETA
// 6LfdzvYcAAAAADyc6M1gNiLmJAxSFb1dXVD13oAC


