import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './seguranca/login/login.component';
import { LoginValidarComponent } from './seguranca/login-validar/login-validar.component';
import { EmpresaCadastroComponent } from './empresa/empresa-cadastro/empresa-cadastro.component';
import { HomeComponent } from './home/home.component';
import { PedidoConsultaComponent } from './pedido/pedido-consulta/pedido-consulta.component';
import { BoletoSuccessComponent } from './boleto/boleto-success/boleto-success.component';
import { RedefinirSenhaComponent } from './seguranca/redefinir-senha/redefinir-senha.component';
import { ConfiguracoesComponent } from './configuracoes/configuracoes.component';
import { ExtratoConsultaComponent } from './extrato/extrato-consulta/extrato-consulta.component';
import { SaqueSolicitacaoComponent } from './saque/saque-solicitacao/saque-solicitacao.component';
import { SaqueConsultaComponent } from './saque/saque-consulta/saque-consulta.component';
import { EstatisticaHomeComponent } from './estatistica/estatistica-home/estatistica-home.component';
import { BoletoRecorrenteComponent } from './boleto/boleto-recorrente/boleto-recorrente.component';
import { LinkConsultaComponent } from './link/link-consulta/link-consulta.component';
import { BoletoItauComponent } from './boleto/boleto-itau/boleto-itau.component';
import { BoletoConsultaComponent } from './boleto/boleto-consulta/boleto-consulta.component';
import { ConciliacaoConsultaComponent } from './conciliacao/conciliacao-consulta/conciliacao-consulta.component';
import { RecorrenteLinkConsultaComponent } from './recorrente/recorrente-link-consulta/recorrente-link-consulta.component';
import { RecorrenteConsultaComponent } from './recorrente/recorrente-consulta/recorrente-consulta.component';

const routes: Routes = [

  { path: 'redefinir/senha', component: RedefinirSenhaComponent },
  { path: 'empresa', component: EmpresaCadastroComponent },
  { path: 'home', component: HomeComponent },
  { path: 'conciliacao', component: ConciliacaoConsultaComponent },
  { path: 'estatistica', component: EstatisticaHomeComponent },
  { path: 'estatistica/:tab', component: EstatisticaHomeComponent },
  { path: 'extrato', component: ExtratoConsultaComponent },
  { path: 'saque', component: SaqueSolicitacaoComponent },
  { path: 'depositos', component: SaqueConsultaComponent },
  { path: 'boletos', component: BoletoConsultaComponent },
  { path: 'boletos/emitir', component: BoletoItauComponent },
  { path: 'boletos/success', component: BoletoSuccessComponent },
  { path: 'recorrencia', component: RecorrenteConsultaComponent },
  { path: 'pedidos', component: PedidoConsultaComponent },
  { path: 'configuracoes', component: ConfiguracoesComponent },
  { path: 'links', component: LinkConsultaComponent },
  { path: 'links/recorrente', component: RecorrenteLinkConsultaComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/validar', component: LoginValidarComponent },
  { path: '', component: LoginComponent }
  // { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
