import { HttpParams } from '@angular/common/http';

export class ExtratoFilter {

  empresaId: string;
  licencaId: string;

  pedidoId: string;
  pedidoNumero: string;
  comprador: string;
  rangeDates: Date[];

  constructor(empresaId: string) {
    this.empresaId = empresaId;
  }

  initRangeDates() {
    const startDate = new Date(new Date().setDate(new Date().getDate() - 1));
    this.rangeDates = [];
    this.rangeDates.push(startDate);
    this.rangeDates.push(null);
  }

  clear() {
    this.pedidoId = undefined;
    this.pedidoNumero = undefined;
    this.comprador = undefined;
    this.rangeDates = undefined;
  }

  getSearchParams() {
    let params = new HttpParams();

    if (this.empresaId !== undefined) {
      params = params.set('empresaId', this.empresaId);
    }

    if (this.pedidoId !== undefined) {
      params = params.set('pedidoId', this.pedidoId);
    }

    if (this.licencaId !== undefined) {
      params = params.set('licencaId', this.licencaId);
    }

    if (this.pedidoNumero !== undefined) {
      params = params.set('pedidoNumero', this.pedidoNumero);
    }

    if (this.comprador !== undefined) {
      params = params.set('comprador', this.comprador);
    }

    if (this.rangeDates !== undefined && this.rangeDates[0] != null) {
      const dtStr = this.rangeDates[0].toISOString().substr(0, 10);
      params = params.set('dtInicio', dtStr);
    }

    if (this.rangeDates !== undefined && this.rangeDates[1] != null) {
      const dtStr = this.rangeDates[1].toISOString().substr(0, 10);
      params = params.set('dtFim', dtStr);
    }

    return params;
  }

}
