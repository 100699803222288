import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ExtratoFilter } from './extrato-filter';
import { LancamentoDTO } from './lancamento-dto.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TotalDayDTO } from '../pedido/total-day-dto.model';
import { TransferDTO } from './transfer-dto.model';

@Injectable({
  providedIn: 'root'
})
export class ExtratoService {

  transferEvent = new EventEmitter();

  constructor(private http: HttpClient) { }

  getLancamentos(extratoFilter: ExtratoFilter): Observable<LancamentoDTO[]> {
    const url = `${environment.apiUrl}/lancamentos`;
    return this.http.get<LancamentoDTO[]>(url, { params: extratoFilter.getSearchParams() });
  }

  getCreditosDia(extratoFilter: ExtratoFilter): Observable<TotalDayDTO[]> {
    const url = `${environment.apiUrl}/lancamentos/group/dia`;
    return this.http.get<TotalDayDTO[]>(url, { params: extratoFilter.getSearchParams() });
  }

  transfer(transferDTO: TransferDTO): Observable<TransferDTO> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<TransferDTO>(`${environment.apiUrl}/lancamentos/transfer`, transferDTO, { headers });
  }

}
