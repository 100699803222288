<div class="footer">
    <div class="pgw--container">
        <article class="footer_content">
            <a href="https://www.pgwpay.com.br" target="_blank">
                <img src="https://app.pgwpay.com.br/assets/images/logo-footer20.svg" height="40px" />
            </a>
            <p>www.pgwpay.com.br</p>
            <p>Rua Aurora, 817 | 8 andar | São Paulo | SP</p>
            <p>© 2019 | Todos os direitos reservados a PGW PAYMENTS INTERNET LTDA.</p>
            <p>CNPJ: 33.392.629/0001-83</p>
            <p>
                <a href="https://www.facebook.com/PGWPayments" target="_blank"><i class="fab fa-facebook-square"></i></a>
                <a href="https://www.linkedin.com/company/pgw-pay" target="_blank"><i class="fab fa-linkedin"></i></a>
                <a href="https://www.instagram.com/pgwpayments" target="_blank"><i class="fab fa-instagram"></i></a>
            </p>
        </article>
    </div>
</div>
