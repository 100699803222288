import { Injectable, EventEmitter } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { PaymentLink } from './payment-link.model';
import { environment } from 'src/environments/environment';
import { PaymentLinkFilter } from './payment-link-filter';

@Injectable({
  providedIn: 'root'
})
export class PaymentLinkService {

  linkEvent = new EventEmitter();

  constructor(private http: HttpClient) {
  }

  buscar(paymentLinkFilter: PaymentLinkFilter): Observable<PaymentLink[]> {
    const url = `${environment.apiUrl}/links`;
    return this.http.get<PaymentLink[]>(url, { params: paymentLinkFilter.getSearchParams() });
  }

  criar(paymentLink: PaymentLink): Observable<PaymentLink> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<PaymentLink>(`${environment.apiUrl}/links`, paymentLink, { headers });
  }

  atualizar(paymentLink: PaymentLink): Observable<PaymentLink> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<PaymentLink>(`${environment.apiUrl}/links`, paymentLink, { headers });
  }

}

