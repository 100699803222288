import { Component } from '@angular/core';

import { AuthService } from '../seguranca/auth.service';

@Component({
  selector: 'app-configuracoes',
  templateUrl: './configuracoes.component.html',
  styleUrls: ['./configuracoes.component.css']
})
export class ConfiguracoesComponent {

  constructor(private authService: AuthService) { }

  hasPermission(permission: string) {
    return this.authService.jwtPayload.authorities.includes(permission);
  }

}
