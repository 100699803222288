import { Injectable, EventEmitter, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { RecorrenteLink } from './recorrente-link.model';
import { environment } from 'src/environments/environment';
import { RecorrenteLinkFilter } from './recorrente-link-filter';

@Injectable({
  providedIn: 'root'
})
export class RecorrenteLinkService {

  linkEvent = new EventEmitter();

  constructor(private http: HttpClient) { }

  buscar(recorrenteLinkFilter: RecorrenteLinkFilter): Observable<RecorrenteLink[]> {
    const url = `${environment.apiUrl}/recorrente/links`;
    return this.http.get<RecorrenteLink[]>(url, { params: recorrenteLinkFilter.getSearchParams() });
  }

  criar(recorrenteLink: RecorrenteLink): Observable<RecorrenteLink> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<RecorrenteLink>(`${environment.apiUrl}/recorrente/links`, recorrenteLink, { headers });
  }

  atualizar(recorrenteLink: RecorrenteLink): Observable<RecorrenteLink> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<RecorrenteLink>(`${environment.apiUrl}/recorrente/links`, recorrenteLink, { headers });
  }

}
