import { Component, OnInit } from '@angular/core';
import { Grupo } from '../grupo.model';
import { GrupoService } from '../grupo.service';
import { AuthService } from '../auth.service';
import { MessageService } from 'primeng/api';
import { UsuarioService } from '../usuario.service';

@Component({
  selector: 'app-grupo-consulta',
  templateUrl: './grupo-consulta.component.html',
  styleUrls: ['./grupo-consulta.component.css']
})
export class GrupoConsultaComponent implements OnInit {

  jwtPayload: any;
  exibir = false;
  grupo: Grupo;
  grupos: Grupo[] = [];
  blocked: boolean;

  constructor(
    private usuarioService: UsuarioService,
    private grupoService: GrupoService,
    private messageService: MessageService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.blocked = false;
    this.jwtPayload = this.authService.getJwtPayload();
    this.buscar();
    this.grupoService.grupoEvent.subscribe(
      () => {
        this.buscar();
        this.exibir = false;
      }
    );
  }

  criar() {
    this.grupo = new Grupo(this.jwtPayload.empresaId);
    this.exibir = true;
  }

  excluir(idGrupo: string) {
    this.blocked = true;
    this.usuarioService.buscarPorEmpresaAndGrupo(this.jwtPayload.empresaId, idGrupo).subscribe(
      data => {
        if (data !== undefined && data.length > 0) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Exclusão não permitida!',
            detail: 'Grupo utilizado por algum usuário.'
          });
          this.blocked = false;
          return;
        }
        this.grupoService.excluir(idGrupo).subscribe(
          () => {
            this.messageService.add({
              severity: 'success',
              summary: 'Sucesso!',
              detail: 'Grupo excluido com sucesso.'
            });
            this.buscar();
            this.blocked = false;
          },
          error => {
            console.log('Erro ao excluir grupo:', error);
            this.messageService.add({
              severity: 'error',
              summary: 'Erro!',
              detail: 'Erro ao excluir grupo.'
            });
            this.grupos = [];
            this.blocked = false;
          }
        );
      },
      err => {
        console.log('Error ao validar exclusão de grupo:', err);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro!',
          detail: 'Error ao validar exclusão de grupo.'
        });
        this.blocked = false;
        return;
      }
    );
  }

  onRowSelect(grupo: Grupo) {
    if (this.hasPermission('CONFIG_GRUPO_EDITAR')) {
      this.grupo = grupo;
      this.exibir = true;
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Permissão Negada!',
        detail: 'Você não possui permissão para editar Grupos.'
      });
    }
  }

  buscar() {
    this.grupoService.buscarPorEmpresa(this.jwtPayload.empresaId).subscribe(
      result => this.grupos = result,
      err => {
        console.log('Error:', err);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro!',
          detail: 'Error ao buscar grupos.'
        });
      }
    );
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

}
