import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from 'src/environments/environment';
import { Login } from './login.model';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  senhaAtual: string;
  token: string;
  jwtPayload: any;

  loginEvent = new EventEmitter();
  trocarSenhaFocusEvent = new EventEmitter();
  senhaAlteradaEvent = new EventEmitter();
  senhaRecuperadaEvent = new EventEmitter();
  recuperarSenhaFocusEvent = new EventEmitter();
  redefinirSenhaFocusEvent = new EventEmitter();

  constructor(
    private router: Router,
    private http: HttpClient,
    private jwtHelper: JwtHelperService
  ) { }

  getJwtPayload() {
    if (this.jwtPayload && this.jwtPayload.usuarioId) {
      return this.jwtPayload;
    } else {
      this.router.navigate(['login']);
    }
  }

  setJwtPayload(jwtPayload: any) {
    this.jwtPayload = jwtPayload;
  }

  armazenarToken() {
    localStorage.setItem('access_token', this.token);
    this.jwtPayload = this.jwtHelper.decodeToken(this.token);
    this.loginEvent.emit(this.jwtPayload);
  }

  carregarToken() {
    const token = localStorage.getItem('access_token');
    if (token) {
      this.jwtPayload = this.jwtHelper.decodeToken(token);
    }
  }

  login(login: Login): Observable<any> {
    const url = `${environment.apiUrl}/oauth/token`;
    const body = 'username='.concat(login.email, '&password=', encodeURI(login.senha), '&grant_type=password');
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8;',
      Authorization: 'Basic YW5ndWxhcjpwZ3dwYXk='
    });
    return this.http.post<any>(url, body, { headers });
  }

  reCaptcha(response: string): Observable<any> {
    const url = `${environment.apiUrl}/public/recaptcha/verify`;
    const body = `response=${response}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.post<any>(url, body, { headers });
  }
  // reCaptcha(response: string): Observable<any> {
  //   const url = 'https://www.google.com/recaptcha/api/siteverify';
  //   const body = `secret=${environment.reCaptchaSecret}&response=${response}&remoteip=localhost`;
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Access-Control-Allow-Methods': 'POST',
  //     'Access-Control-Allow-Origin': '*'
  //   });
  //   return this.http.post<any>(url, body, { headers });
  // }

  alterarSenha(novaSenha: string): Observable<any> {
    const url = `${environment.apiUrl}/usuarios/${this.jwtPayload.usuarioId}/alterar/senha`;
    const body = `${novaSenha}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.put<any>(url, body, { headers });
  }

  redefinirSenha(novaSenha: string): Observable<any> {
    let jwt = this.jwtHelper.decodeToken(this.token);
    const url = `${environment.apiUrl}/usuarios/${jwt.usuarioId}/redefinir/senha`;
    console.log('usuarioId', jwt.usuarioId);
    const body = `${novaSenha}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8;',
      'Authorization': `Bearer ${this.token}`
    });
    return this.http.put<any>(url, body, { headers });
  }

  recuperarSenha(email: string): Observable<any> {
    const url = `${environment.apiUrl}/usuario/${email}/recuperar/senha`;
    return this.http.get<any>(url);
  }

  reiniciarSenha(usuarioId: string): Observable<any> {
    const url = `${environment.apiUrl}/usuarios/${usuarioId}/reiniciar/senha`;
    return this.http.get<any>(url);
  }

  logout() {
    this.jwtPayload = undefined;
    this.loginEvent.emit(undefined);
    localStorage.removeItem('access_token');
    this.router.navigate(['login']);
  }

  enviarCodigo(media: string): Observable<any> {
    let jwt = this.jwtHelper.decodeToken(this.token);
    const url = `${environment.apiUrl}/usuarios/${jwt.usuarioId}/validar/${media}`;
    console.log(url);
    console.log(this.token);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8;',
      'Authorization': `Bearer ${this.token}`
    });
    return this.http.get<any>(url, { headers });
  }

  validarCodigo(codigo: string): Observable<any> {
    let jwt = this.jwtHelper.decodeToken(this.token);
    const url = `${environment.apiUrl}/usuarios/${jwt.usuarioId}/validar`;
    console.log(url);
    console.log(this.token);
    const body = `code=${codigo}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8;',
      'Authorization': `Bearer ${this.token}`
    });
    return this.http.post<any>(url, body, { headers });
  }

}
