export class PaymentLink {
  id: string;
  empresaId: string;
  licencaId: string;
  descricao: string;
  valorUnitario: number;
  diasVenctoBoleto: number;
  parcelamentoCartao: number;
  parcelamentoJuros: boolean;
  ativarBoleto: boolean;
  ativarCartao: boolean;
  ativo: boolean;

  link: string;
  linkReduzido: string;

  tpMulta: string;
  vlrMulta: number;
  tpJuros: string;
  vlrJuros: number;

  constructor(empresaId: string) {
    this.empresaId = empresaId;
    this.valorUnitario = 0.00;
    this.diasVenctoBoleto = 1;
    this.parcelamentoCartao = 1;
    this.descricao = "";
    this.parcelamentoJuros = true;
    this.ativarBoleto = true;
    this.ativarCartao = true;
    this.ativo = true;
    this.tpMulta = '3';
    this.vlrMulta = 0.00;
    this.tpJuros = '5';
    this.vlrJuros = 0.00;
  }
}
