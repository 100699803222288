import { Component, OnInit } from '@angular/core';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { Licenca } from 'src/app/licenca/licenca.model';
import { LicencaService } from 'src/app/licenca/licenca.service';
import { AuthService } from 'src/app/seguranca/auth.service';
import { ConciliacaoFilter } from '../conciliacao-filter';
import { Conciliacao } from '../conciliacao.model';

@Component({
  selector: 'app-conciliacao-consulta',
  templateUrl: './conciliacao-consulta.component.html',
  styleUrls: ['./conciliacao-consulta.component.css']
})
export class ConciliacaoConsultaComponent implements OnInit {

  jwtPayload: any;

  empresasOptions: any[];
  licencasOptions: any[];
  licencas: Licenca[];
  moeda: string;
  tab: string;

  conciliacaoFilter: ConciliacaoFilter;

  conciliacao: Conciliacao;
  conciliacoes: Conciliacao[];


  constructor(
    private empresaService: EmpresaService,
    private licencaService: LicencaService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.jwtPayload = this.authService.getJwtPayload();

    this.conciliacaoFilter = new ConciliacaoFilter(this.jwtPayload.empresaId);

    this.moeda = "BRL";
    this.tab = "CONSULTAR";

    this.empresaService.getEmpresas().subscribe(
      result => {
        this.empresasOptions = [];
        result.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );
  }

  buscarLicencas() {
    this.conciliacaoFilter.licencaId = undefined;
    this.licencaService.buscarPorEmpresa(this.conciliacaoFilter.empresaId).subscribe(
      result => {
        this.licencas = result;
        this.licencasOptions = [];
        result.forEach(licenca => {
          this.licencasOptions.push(
            { label: licenca.contrato, value: licenca.id }
          );
          if (this.conciliacaoFilter.licencaId === undefined) {
            this.conciliacaoFilter.licencaId = licenca.id;
            this.moeda = licenca.moeda;
          }
        });
      },
      err => console.log('Error:', err),
      () => this.buscar()
    );
  }

  buscar() {

  }

  criar() {
    this.tab = "CADASTRAR"; 
    this.conciliacao = new Conciliacao();
    this.conciliacao.empresaId = this.conciliacaoFilter.empresaId;
    this.conciliacao.licencaId = this.conciliacaoFilter.licencaId;

  }
  
  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

}
