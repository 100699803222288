import { Component, OnInit } from '@angular/core';

import { AuthService } from '../seguranca/auth.service';

@Component({
  selector: 'app-topo',
  templateUrl: './topo.component.html',
  styleUrls: ['./topo.component.css']
})
export class TopoComponent implements OnInit {

  jwtPayload: any;

  exibirMenu = false;
  exibirTrocarSenha = false;

  menu = {
    estatistica: false,
    boleto: false,
    conciliacao: false,
    extrato: false,
    saque: false,
    deposito: false,
    pedido: false,
    recorrente: false,
    link: false,
    plano: false,
    config: false
  };

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.authService.loginEvent.subscribe(jwtPayload => {
      this.jwtPayload = jwtPayload;
      console.log('jwtPayload', this.jwtPayload);
      this.menu.estatistica = this.hasPermission('EST');
      this.menu.boleto = this.hasPermission('PG_BOLETO_AVULSO');
      this.menu.recorrente = this.hasPermission('RECORRENTE');
      // this.menu.conciliacao = this.hasPermission('CONCILIACAO');
      this.menu.extrato = this.hasPermission('EXTRATO');
      this.menu.saque = this.hasPermission('SAQUE');
      this.menu.deposito = this.hasPermission('DEPOSITO');
      this.menu.pedido = this.hasPermission('PEDIDO');
      this.menu.link = this.hasPermission('LINK');
      this.menu.plano = this.hasPermission('LINK_RECORRENTE');
      this.menu.config = this.hasPermission('CONFIG');

      this.menu.conciliacao = false;
      // if (!(this.jwtPayload.empresaId === '612eb9721889fa52f429a2a5' || this.jwtPayload.empresaId === '5d5ffff07396f1033f599e0d')) {
      //   this.menu.recorrente = false;
      // }
    });
    this.authService.senhaAlteradaEvent.subscribe(() => this.exibirTrocarSenha = false);
  }

  trocarSenha() {
    this.exibirMenu=false;
    this.exibirTrocarSenha=true;
    this.authService.trocarSenhaFocusEvent.emit();
    console.log('Topo Trocar Senha | this.authService.senhaAtual:', this.authService.senhaAtual);
  }

  logout() {
    this.jwtPayload = undefined;
    this.exibirMenu = false;
    this.authService.logout();
  }

  hasPermission(permission: string) {
    if (this.jwtPayload && this.jwtPayload.authorities) {
      return this.jwtPayload.authorities.includes(permission);
    } else {
      return false;
    }
  }

}
