import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { Licenca } from 'src/app/licenca/licenca.model';
import { LicencaService } from 'src/app/licenca/licenca.service';
import { PedidoDTO } from 'src/app/pedido/pedido-dto';
import { Pedido } from 'src/app/pedido/pedido.model';
import { PedidoService } from 'src/app/pedido/pedido.service';
import { AuthService } from 'src/app/seguranca/auth.service';
import { RecorrenciaDTO } from '../recorrencia-dto.model';
import { RecorrenciaFilter } from '../recorrencia-filter';
import { Recorrencia } from '../recorrencia.model';
import { RecorrenteConsultaService } from '../recorrente-consulta.service';

@Component({
  selector: 'app-recorrente-consulta',
  templateUrl: './recorrente-consulta.component.html',
  styleUrls: ['./recorrente-consulta.component.css']
})
export class RecorrenteConsultaComponent implements OnInit {

  jwtPayload: any;
  empresasOptions: any[];
  licencasOptions: any[];
  licencas: Licenca[];
  blocked = false;
  exibirRecorrencia = false;

  recorrencia: Recorrencia;
  recorrenciasDTO: RecorrenciaDTO[] = [];
  recorrenciaFilter: RecorrenciaFilter;

  exibirPedido = false;
  pedidoSelecionado: Pedido;
  licencaSelecionada: Licenca;

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private empresaService: EmpresaService,
    private licencaService: LicencaService,
    private pedidoService: PedidoService,
    private recorrenteConsultaService: RecorrenteConsultaService
  ) { }

  ngOnInit(): void {
    this.jwtPayload = this.authService.getJwtPayload();
    this.recorrenciaFilter = new RecorrenciaFilter(this.jwtPayload.empresaId);
    this.empresaService.getEmpresas().subscribe(
      result => {
        this.empresasOptions = [];
        result.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );
  }

  buscarLicencas() {
    this.recorrenciaFilter.licencaId = undefined;
    this.licencaService.buscarPorEmpresa(this.recorrenciaFilter.empresaId).subscribe(
      result => {
        this.licencas = result;
        this.licencasOptions = [];

        result.forEach(licenca => {
          this.licencasOptions.push(
            { label: `${licenca.contrato} [${licenca.moeda}]`, value: licenca.id, moeda: licenca.moeda }
          );
          if (this.recorrenciaFilter.licencaId === undefined) {
            this.recorrenciaFilter.licencaId = licenca.id;
          }
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        if (this.licencasOptions.length > 1) {
          this.licencasOptions.push(
            { label: 'Todos', value: 'ALL' }
          );
        }
        // this.buscar();
      }
    );
  }

  buscar() {
    this.blocked = true;
    console.log('Recorrencia Filter', this.recorrenciaFilter);

    if (this.recorrenciaFilter.licencaId === 'ALL') {
      this.recorrenciaFilter.licencaId = undefined;
    }

    if (!this.recorrenciaFilter.cpf && !this.recorrenciaFilter.pedidoNumero) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Filtro obrigatório!',
        detail: 'Informe o CPF ou o Número do Pedido.'
      });
      this.blocked = false;
      return;
    }

    console.log('Recorrencia Filter', this.recorrenciaFilter);

    this.recorrenteConsultaService.buscar(this.recorrenciaFilter).subscribe(
      result => {
        this.recorrenciasDTO = result;
        this.recorrenciaFilter.cpf = '';
        this.recorrenciaFilter.pedidoNumero = '';
        console.log('result', this.recorrenciasDTO);
        if (!this.recorrenciasDTO.length) {
          this.messageService.add({
            severity: 'success',
            summary: 'Consulta efetuada!',
            detail: 'Nenhum registro encontrado.'
          });
        }
        this.blocked = false;
      },
      error => {
        console.log('Error:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao consultar!',
          detail: 'Erro ao consultar recorrências.'
        });
        this.recorrenciasDTO = [];
        this.blocked = false;
      }
    );
  }

  onRowSelect(recorrenciaDTO: RecorrenciaDTO) {
    this.blocked = true;
    this.recorrenteConsultaService.buscarPorId(recorrenciaDTO.id).subscribe(
      result => {
        this.recorrencia = result;
        console.log('RecorrenciaSelecionada', this.recorrencia);
        this.exibirRecorrencia = true;

      },
      error => {
        console.log('Error:', error);
        this.messageService.add({
          severity: 'warn',
          summary: 'Falha ao consultar!',
          detail: 'Falha ao consultar detalhes da recorrências.'
        });
        this.recorrenciasDTO = [];
        this.blocked = false;
      }
    );
  }

  onUpdateCancel(recorrenciaId: string) {
    for (const r of this.recorrenciasDTO) {
      if (r.id === recorrenciaId) {
        r.status = 'CANCELADO';
        break;
      }
    }
  }

  onShowPedido(pedidoDTO: PedidoDTO) {
    this.blocked = true;
    if (this.hasPermission('PEDIDO_VISUALIZAR')) {
      this.pedidoService.getPedido(pedidoDTO.id).subscribe(
        pedido => {
          this.pedidoSelecionado = pedido;
          console.log('PedidoSelecionado', this.pedidoSelecionado);
          this.exibirPedido = true;
          for (const l of this.licencas) {
            if (l.id === pedido.licencaId) {
              this.licencaSelecionada = l;
              break;
            }
          }
          console.log('LicencaSelecionada', this.licencaSelecionada);
          this.blocked = false;
        },
        err => {
          console.log('Error:', err);
          this.messageService.add({
            severity: 'error',
            summary: 'Error ao consultar!',
            detail: 'Erro ao consultar detalhes do pedido.'
          });
          this.blocked = false;
        }
      );
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Permissão Negada!',
        detail: 'Você não possui permissão para visualizar detalhes do pedido.'
      });
      this.blocked = false;
    }
  }

  onRecorrenciaBlocked(blocked: boolean) {
    this.blocked = blocked;
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

}
