<div class="pgw--container">
    <form (ngSubmit)="buscarCompradores()">
        <div class="ui-g">

            <div class="ui-g-12">
                <button pButton type="button" (click)="fechar()" label="Voltar" icon="pi pi-step-backward"></button>
            </div>

            <div class="ui-g-12">
                <h1>Compradores</h1>
            </div>

            <div class="ui-g-12 ui-md-4 ui-lg-4 ui-fluid">
                <input pInputText name="nome" [(ngModel)]="compradorFilter.nome" placeholder="Nome" class="placeholder"
                    pTooltip="Buscar por nome" tooltipPosition="top" />
            </div>

            <div class="ui-g-12 ui-md-4 ui-lg-4 ui-fluid">
                <input pInputText name="email" [(ngModel)]="compradorFilter.email" placeholder="E-mail" class="placeholder"
                    pTooltip="Buscar por E-mail" tooltipPosition="top" pKeyFilter="email" />
            </div>

            <div class="ui-g-11 ui-md-3 ui-lg-3 ui-fluid">
                <input pInputText name="cpfCnpj" [(ngModel)]="compradorFilter.cpfCnpj" placeholder="CPF ou CNPJ" class="placeholder"
                    pTooltip="Buscar po CPF ou CNPJ" tooltipPosition="top" maxlength="14" pKeyFilter="pint" />
            </div>

            <div class="ui-g-1 ui-md-1 ui-lg-1">
                <button pButton type="submit" icon="pi pi-search"></button>
            </div>

            <div class="ui-g-12 ui-fluid">
                <p-table [value]="compradores" [paginator]="true" [rows]="10" [responsive]="true" selectionMode="single"
                    (onRowSelect)="onSelectComprador($event.data)">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 70px;">Tp.Doc.</th>
                            <th style="width: 120px;">Num.Doc.</th>
                            <th>Nome</th>
                            <th>E-mail</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-comprador>
                        <tr [pSelectableRow]="comprador">
                            <td class="col-center">{{ comprador.tipoDoc }}</td>
                            <td class="col-center">{{ comprador.cpfCnpj }}</td>
                            <td class="col-center">{{ comprador.nome }}</td>
                            <td class="col-center">{{ comprador.email }}</td>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td [attr.colspan]="4">
                                Nenhum comprador encontrado!
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>

            <div class="ui-g-12">
                <button pButton type="button" (click)="fechar()" label="Voltar" icon="pi pi-step-backward" style="margin: 30px 0;"></button>
            </div>

        </div>
    </form>
</div>