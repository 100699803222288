import { Component, OnInit } from '@angular/core';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { AuthService } from 'src/app/seguranca/auth.service';
import { Saque } from '../saque.model';
import { SaqueFilter } from '../saque-filter';
import { SaqueService } from '../saque.service';
import { MessageService } from 'primeng/api';
import { LicencaService } from 'src/app/licenca/licenca.service';
import { Licenca } from 'src/app/licenca/licenca.model';
import { PedidoDTO } from 'src/app/pedido/pedido-dto';
import { PedidoService } from 'src/app/pedido/pedido.service';
import { Pedido } from 'src/app/pedido/pedido.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-saque-consulta',
  templateUrl: './saque-consulta.component.html',
  styleUrls: ['./saque-consulta.component.css']
})
export class SaqueConsultaComponent implements OnInit {

  jwtPayload: any;
  empresaId: string;
  empresasOptions: any[];
  licencasOptions: any[];
  licencas: Licenca[];
  licencaSelecionada: Licenca;
  saques: Saque[];
  saqueSelecionado: Saque;
  pedidoSelecionado: Pedido;
  pedidosDTOSelecionado: PedidoDTO[];
  dtPrevisao: Date;
  action: string;
  saqueFilter: SaqueFilter;
  exibirSaque = false;
  exibirPedido = false;
  pgw = false;
  // qtdPedidos = 0;
  qtdDepositos = 0;
  totalDepositos = 0.00;

  blocked = false;

  bancosOrigemOptions: any[];
  formasPagtoOptions: any[];

  constructor(
    private empresaService: EmpresaService,
    private licencaService: LicencaService,
    private saqueService: SaqueService,
    private pedidoService: PedidoService,
    private messageService: MessageService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.jwtPayload = this.authService.getJwtPayload();
    this.saqueFilter = new SaqueFilter(this.jwtPayload.empresaId);
    this.pgw = this.jwtPayload.usuarioEmail.includes('@pgwpay.com');

    this.empresaService.getEmpresas().subscribe(
      empresas => {
        this.empresasOptions = [];
        empresas.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );

    this.saqueService.saqueEvent.subscribe(
      () => {
        this.buscar();
        this.exibirSaque = false;
      }
    );

    this.formasPagtoOptions = [
      { label: '---', value: undefined },
      { label: 'TED', value: 'TED' },
      { label: 'TRF', value: 'TRF' },
      { label: 'PIX', value: 'PIX' }
    ];

    this.bancosOrigemOptions = [
      { label: '--- Informe o banco de origem ---', value: undefined },
      { label: 'BANCO INTER', value: 305 },
      { label: 'BANCO ITAÚ', value: 286 },
      { label: 'BANCO BRADESCO', value: 287 },
      { label: 'PAG BANK', value: 302 }
    ];
  }

  buscarLicencas() {
    this.saqueFilter.licencaId = undefined;
    this.licencaService.buscarPorEmpresa(this.saqueFilter.empresaId).subscribe(
      licencas => {
        this.licencas = licencas;
        this.licencasOptions = [];
        licencas.forEach(licenca => {
          this.licencasOptions.push(
            { label: `${licenca.contrato} [${licenca.moeda}]`, value: licenca.id, moeda: licenca.moeda }
          );
          if (this.saqueFilter.licencaId === undefined) {
            this.saqueFilter.licencaId = licenca.id;
            this.saqueFilter.moeda = licenca.moeda;
          }
        });
      },
      err => console.log('Error:', err),
      () => this.buscar()
    );
  }

  buscar() {
    this.saques = [];
    for (const licenca of this.licencas) {
      if (licenca.id === this.saqueFilter.licencaId) {
        this.saqueFilter.moeda = licenca.moeda;
        break;
      }
    }
    this.saqueService.getDepositos(this.saqueFilter).subscribe(
      data => {
        this.saques = data;
        // this.qtdPedidos = 0;
        this.qtdDepositos = 0;
        this.totalDepositos = 0.00;
        this.saques.forEach(d => {
          if (d.status === 'LIQUIDADO') {
            // this.qtdPedidos += d.pedidosId.length;
            this.qtdDepositos++;
            this.totalDepositos += d.valor;
          }
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => this.saqueFilter.clear()
    );
  }

  onRowSelect(saque: Saque) {
    console.log('select deposito');
    if (this.hasPermission('DEPOSITO_VISUALIZAR')) {
      this.saqueSelecionado = saque;
      if (this.saqueSelecionado.dtPrevisao && this.saqueSelecionado.dtPrevisao.length >= 10) {
        const year = Number(this.saqueSelecionado.dtPrevisao.substr(0, 4));
        const month = Number(this.saqueSelecionado.dtPrevisao.substr(5, 2)) - 1;
        const day = Number(this.saqueSelecionado.dtPrevisao.substr(8, 2));
        this.dtPrevisao = new Date(year, month, day);
      }
      this.saqueService.getPedidosDTOPorSaqueId(saque.id).subscribe(
        pedidosDTO => {
          this.pedidosDTOSelecionado = pedidosDTO;
          this.exibirSaque = true;
          console.log('this.pedidosDTOSelecionado', this.pedidosDTOSelecionado);
        }
      );
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Permissão Negada!',
        detail: 'Você não possui permissão para visualizar detalhes do depósito.'
      });
    }
  }

  // onRowSelectPedido(pedidoDTO: PedidoDTO) {
  //   if (this.hasPermission('PEDIDO_VISUALIZAR')) {
  //     this.pedidoService.getPedido(pedidoDTO.id).subscribe(
  //       pedido => {
  //         this.pedidoSelecionado = pedido;
  //         this.exibirPedido = true;
  //         for (const l of this.licencas) {
  //           if (l.id === pedido.licencaId) {
  //             this.licencaSelecionada = l;
  //             break;
  //           }
  //         }
  //       }
  //     );
  //   } else {
  //     this.messageService.add({
  //       severity: 'warn',
  //       summary: 'Permissão Negada!',
  //       detail: 'Você não possui permissão para visualizar detalhes do pedido.'
  //     });
  //   }
  // }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

  salvar() {
    console.log('this.saqueSelecionado', this.saqueSelecionado);
    this.blocked = true;

    if (this.action === 'EXCLUIR') {
      this.excluir();
      this.blocked = false;
      return;
    }

    if (this.dtPrevisao === undefined) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Data Previsão Inválida!',
        detail: 'Digite a data prevista para liquidação.'
      });
      this.blocked = false;
      return;
    } else {
      this.saqueSelecionado.dtPrevisao = this.dtPrevisao.toISOString().substr(0, 10);
    }

    if (this.action === 'AGENDAR') {
      this.saqueSelecionado.status = 'AGENDADO';
    } else {
      if (this.saqueSelecionado.historico === undefined
        || this.saqueSelecionado.historico === null
        || this.saqueSelecionado.historico.trim().length < 3) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Histórico Inválido!',
          detail: 'Digite um histórico.'
        });
        this.blocked = false;
        return;
      } else {
        this.saqueSelecionado.historico = this.saqueSelecionado.historico.trim();
      }

      if (this.saqueSelecionado.autorizacao === undefined
        || this.saqueSelecionado.autorizacao === null
        || this.saqueSelecionado.autorizacao.trim().length < 3) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Autorização Inválida!',
          detail: 'Digite um código de autorização.'
        });
        this.blocked = false;
        return;
      } else {
        this.saqueSelecionado.autorizacao = this.saqueSelecionado.autorizacao.trim();
      }

      if (this.saqueSelecionado.formaPagamento === undefined
        || this.saqueSelecionado.formaPagamento === null) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Forma de Pagamento Inválida!',
          detail: 'Informe a Forma de Pagamento.'
        });
        this.blocked = false;
        return;
      }

      if (this.saqueSelecionado.bancoOrigem === undefined
        || this.saqueSelecionado.bancoOrigem === null) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Banco de Origem Inválido!',
          detail: 'Informe o banco de origem.'
        });
        this.blocked = false;
        return;
      }

      if (this.saqueSelecionado.banco !== 'PIX') {
        if (this.saqueSelecionado.banco === undefined
          || this.saqueSelecionado.banco === null
          || this.saqueSelecionado.banco.length < 3) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Banco Inválido!',
            detail: 'Atualize o banco no cadastro da conta bancária.'
          });
          this.blocked = false;
          return;
        }

        if (this.saqueSelecionado.agencia === undefined
          || this.saqueSelecionado.agencia === null
          || this.saqueSelecionado.agencia.length < 3) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Agência Inválida!',
            detail: 'Atualize a agência no cadastro da conta bancária.'
          });
          this.blocked = false;
          return;
        }

        if (this.saqueSelecionado.conta === undefined
          || this.saqueSelecionado.conta === null
          || this.saqueSelecionado.conta.length < 3) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Conta Inválida!',
            detail: 'Atualize a conta no cadastro da conta bancária.'
          });
          this.blocked = false;
          return;
        }

        if (this.saqueSelecionado.cpfCnpj === undefined
          || this.saqueSelecionado.cpfCnpj === null
          || this.saqueSelecionado.cpfCnpj.length < 11) {
          this.messageService.add({
            severity: 'warn',
            summary: 'CPF/CNPJ Inválido!',
            detail: 'Atualize o CPF ou CNPJ no cadastro da conta bancária.'
          });
          this.blocked = false;
          return;
        }
      }

      if (this.saqueSelecionado.titular === undefined
        || this.saqueSelecionado.titular === null
        || this.saqueSelecionado.titular.length < 3) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Titular Inválido!',
          detail: 'Atualize o titular no cadastro da conta bancária.'
        });
        this.blocked = false;
        return;
      }

      this.saqueSelecionado.status = 'LIQUIDADO';
    }

    this.saqueService.atualizar(this.saqueSelecionado).subscribe(
      data => {
        this.saqueService.saqueEvent.emit();
        this.blocked = false;
      },
      error => {
        console.log('Error :', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao atualizar saque!',
          detail: 'Erro ao atualizar saque.'
        });
        this.blocked = false;
      }
    );
  }

  excluir() {
    this.saqueService.excluir(this.saqueSelecionado.id).subscribe(
      () => {
        console.log('success: depósito excluído com sucesso.');
        this.messageService.add({
          severity: 'success',
          summary: 'Depósito excluído!',
          detail: 'Depósito excluído com sucesso.'
        });
        this.saqueService.saqueEvent.emit();
      },
      error => {
        console.log('Error :', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao excluir!',
          detail: 'Erro ao excluir depósito.'
        });
      }
    );
  }

  historicoPadrao() {
    console.log('titular', this.saqueSelecionado.titular);
    if (this.saqueSelecionado.titular == undefined || this.saqueSelecionado.titular == null || this.saqueSelecionado.titular.length < 1) {
      this.saqueSelecionado.historico = 'RESGATE SOLICITADO PELO CLIENTE';
    } else {
      this.saqueSelecionado.historico = this.saqueSelecionado.titular.toUpperCase();
    }
  }

}
