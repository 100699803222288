import { HttpParams } from '@angular/common/http';

export class CompradorFilter {
    empresaId: string;
    nome: string;
    email: string;
    cpfCnpj: string;

    constructor(empresaId: string) {
        this.empresaId = empresaId;
    }

    getSearchParams() {
        let params = new HttpParams();

        if (this.empresaId !== undefined) {
            params = params.set('empresaId', this.empresaId);
        }

        if (this.nome !== undefined) {
            params = params.set('nome', this.nome);
        }

        if (this.email !== undefined) {
            params = params.set('email', this.email);
        }

        if (this.cpfCnpj !== undefined) {
            params = params.set('cpfCnpj', this.cpfCnpj);
        }

        return params;
    }

}