<div class="ui-g" style="margin-bottom: 20px;" *ngIf="!toggle">

  <div class="ui-g-12">
    <h1 style="font-size: 2em; border-bottom: solid 1px; color: #655f5d">
      Pedido #{{ pedido.pedidoNumero }} - {{pedido.origem}}
    </h1>
  </div>

  <div class="ui-g-12">
    <p-card>
      <div *ngIf="pedido.moedaLoja == 'BRL'" style="font-size: 1.8em; font-weight: bold; color: #655f5d">
        {{ pedido.totalGeral | currency:'BRL':'symbol':'0.2-2':'pt' }}
      </div>
      <div *ngIf="pedido.moedaLoja == 'USD'" style="font-size: 1.8em; font-weight: bold; color: #655f5d">
        {{ pedido.totalWC | currency:'USD':'symbol':'0.2-2':'en' }}
      </div>
      <div style="font-size: 0.8em; font-weight: bold; color: #c2c4c7;">
        TOTAL
      </div>
    </p-card>
  </div>

  <div class="ui-g-12">
    <p-card>

      <div style="display: table; vertical-align: middle; width:100%;">
        <i class="pi pi-user"
          style="display: inline; font-size: 3em; font-weight: bold; color: #007ad9; margin-right: 10px;"></i>
        <span
          style="color: #655f5d; font-size: 0.8em; font-weight: bold; vertical-align: middle; display: table-cell; width: 100%;">
          {{ pedido.compradorNomeCompleto }}
        </span>
        <i class="pi pi-search" (click)="clickEvent()"
          *ngIf="(origem === 'PEDIDO' && hasPermission('PEDIDO_COMPRADOR')) || (origem === 'EXTRATO' && hasPermission('EXTRATO_COMPRADOR')) || (origem === 'BOLETO_FLUXO' && hasPermission('BOLETO_FLUXO_COMPRADOR')) || (origem === 'BOLETO_RECORRENTE' && hasPermission('BOLETO_RECORRENTE_COMPRADOR'))"
          style="vertical-align: middle; text-align: right; display: table-cell; color: #007ad9;"></i>
      </div>

    </p-card>
  </div>

  <div class="ui-g-12">
    <p-card>
      <div style="font-size: 1.5em; font-weight: bold; color: #655f5d">
        Situação
      </div>

      <ul>
        <li *ngFor="let log of pedido.statusLog"
          [hidden]="pedido.dtAntecipacao && log.dtHr > pedido.dtAntecipacao && log.status !== 13 && log.status !== 6"
          style="padding: 10px 0px;">
          <div appPagseguroStatus>{{ log.status }}</div>
          <div style="font-size: 0.8em; font-weight: bold; color: #c2c4c7; padding: 0.2em;">
            {{ log.dtHr | date:'dd/MM/yyyy - HH:mm:ss' }}
          </div>
        </li>
      </ul>

    </p-card>
  </div>

  <div class="ui-g-12">
    <p-card>
      <div style="font-size: 1.5em; font-weight: bold; color: #655f5d">
        Forma de recebimento
      </div>

      <i [ngClass]="pedido.formaPagamento == 'BOLETO' ? 'fa fa-barcode' : 'fa fa-credit-card'"
        style="font-size: 3em; font-weight: bold; color: #007ad9; padding: 15px;">

        <span
          style="color: #655f5d; font-size: 0.3em; vertical-align: middle; font-family: 'Comfortaa', 'Helvetica Neue', 'sans-serif'; padding: 0px 20px;">
          {{ pedido.formaPagamento }}
        </span>
        <span
          style="color: #c2c4c7; font-size: 0.3em; vertical-align: middle; font-family: 'Comfortaa', 'Helvetica Neue', 'sans-serif';">
          {{ pedido.qtdParcela }} X {{ pedido.vlrParcela | currency:'BRL':'symbol':'0.2-2':'pt' }}
        </span>
        <span
          *ngIf="pedido.formaPagamento === 'BOLETO' && getBoletoLink() && ((origem === 'PEDIDO' && hasPermission('PEDIDO_DOWNLOAD')) || (origem === 'EXTRATO' && hasPermission('EXTRATO_DOWNLOAD')) || (origem === 'BOLETO_FLUXO' && hasPermission('BOLETO_FLUXO_DOWNLOAD')) || (origem === 'BOLETO_RECORRENTE' && hasPermission('BOLETO_RECORRENTE_DOWNLOAD')))"
          style="font-size: 0.5em; padding: 0px 20px;">
          <a [href]="getBoletoLink()" target="_blank" title="Baixe aqui seu boleto"><i class="pi pi-download"></i></a>
        </span>

      </i>

      <div style="margin-left: 15px; margin-top: 15px;">
        <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
          Instituição:
        </div>
        <div>
          {{ pedido.instituicao }}
        </div>
      </div>

      <div style="margin-left: 15px; margin-top: 15px;"
        *ngIf="pedido.formaPagamento === 'CARTÃO' && pedido.cartaoBandeira">
        <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
          Bandeira:
        </div>
        <div>
          <span>{{ pedido.cartaoBandeira }}</span><span *ngIf="pedido.cardBin"> | {{ pedido.cardBin }}</span>
        </div>
      </div>

      <div style="margin-left: 15px; margin-top: 15px;" *ngIf="pedido.autorizacao">
        <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
          Código de Autorização:
        </div>
        <div>
          {{ pedido.autorizacao }}
        </div>
      </div>

      <div style="margin-left: 15px; margin-top: 15px;"
        *ngIf="pedido.formaPagamento === 'BOLETO' && getBoletoBarCode()">
        <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
          Código de Barras:
        </div>
        <div>
          {{ getBoletoBarCode() }}
        </div>
      </div>

      <div style="margin-left: 15px; margin-top: 15px;" *ngIf="pedido.valorTotalRecebimento > pedido.vlrParcela">
        <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
          Valor Multa/Juros:
        </div>
        <div>
          {{ (pedido.valorTotalRecebimento - pedido.vlrParcela) | currency:'BRL':'symbol':'0.2-2':'pt' }}
        </div>
      </div>

      <div style="margin-left: 15px; margin-top: 15px;" *ngIf="pedido.valorTotalRecebimento > 0.00">
        <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
          Valor Total Recebido:
        </div>
        <div>
          {{ pedido.valorTotalRecebimento | currency:'BRL':'symbol':'0.2-2':'pt' }}
        </div>
      </div>

      <div style="margin-left: 15px; margin-top: 15px;"
        *ngIf="pedido.formaPagamento === 'BOLETO' && getBoletoDtVencto()">
        <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
          Data de Vencimento:
        </div>
        <div>
          {{ getBoletoDtVencto() | date:'dd/MM/yyyy' }}
        </div>
      </div>

      <div style="margin-left: 15px; margin-top: 15px;" *ngIf="pedido.formaPagamento === 'BOLETO'">
        <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
          Recorrente:
        </div>
        <div>
          {{ pedido.recorrente ? 'SIM' : 'NÃO' }}
        </div>
      </div>

      <div style="margin-left: 15px; margin-top: 15px;"
        *ngIf="false && pedido.formaPagamento === 'BOLETO' && pedido.recorrente">
        <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
          Pedido de Origem:
        </div>
        <div *ngIf="pedido.pedidoNumero === pedido.pedidoNumeroOrigem">
          {{ pedido.pedidoNumeroOrigem }}
        </div>
        <div *ngIf="pedido.pedidoNumero !== pedido.pedidoNumeroOrigem">
          <span class="boleto-recorrente" (click)="pedidoOrigem()">{{ pedido.pedidoNumeroOrigem }}</span>
        </div>
      </div>

      <div style="margin-left: 15px; margin-top: 15px;"
        *ngIf="false && pedido.formaPagamento === 'BOLETO' && pedido.recorrente">
        <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
          Pedidos Dependentes:
        </div>
        <div *ngIf="pedido.recorrenteQtd === 0">
          0
        </div>
        <div *ngIf="pedido.recorrenteQtd > 0">
          <span class="boleto-recorrente" (click)="showPedidosDependentes()">{{ pedido.recorrenteQtd }}</span>
        </div>
      </div>

      <div style="margin-left: 15px; margin-top: 15px;"
        *ngIf="false && pedido.formaPagamento === 'BOLETO' && pedido.recorrente && !pedido.recorrenteDtCancel">
        <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
          Validade da Assinatura:
        </div>
        <div>
          <span *ngIf="!pedido.recorrenteVigencia">INDETERMINADA</span>
          <span *ngIf="pedido.recorrenteVigencia">{{ pedido.recorrenteVigencia | date:'dd/MM/yyyy' }}</span>
        </div>
      </div>

      <div style="margin-left: 15px; margin-top: 15px;"
        *ngIf="false && pedido.formaPagamento === 'BOLETO' && pedido.recorrente">
        <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
          Situação da Assinatura:
        </div>
        <div *ngIf="!pedido.recorrenteDtCancel" style="color: green">
          ATIVA
          <button pButton type="button" label="cancelar" class="ui-button-rounded ui-button-danger cancelar-assinatura"
            (click)="cancelarAssinatura()"></button>
          <p-confirmDialog [style]="{width: '50vw'}" key="positionDialog" position="left" [baseZIndex]="10000">
          </p-confirmDialog>
        </div>
        <div *ngIf="pedido.recorrenteDtCancel" style="color: red">
          Cancelada em {{ pedido.recorrenteDtCancel | date:'dd/MM/yyyy - HH:mm:ss' }}
        </div>
      </div>

    </p-card>
  </div>

  <div class="ui-g-12" *ngIf="pedido.parcelas.length > 0 && pedido.pagseguroStatus !== 13">
    <p-card>
      <div style="font-size: 1.5em; font-weight: bold; color: #655f5d">
        Parcelas
      </div>
      <div class="ui-g" *ngFor="let parcela of pedido.parcelas" style="padding: 5px 0px;">
        <div class="ui-g-3 pgw--text-center">
          <span style="color: #655f5d; ">
            {{ parcela.releaseDate | date:'dd/MM/yyyy' }}
          </span>
        </div>

        <div class="ui-g-2 pgw--text-right">
          <span style="color: #655f5d;">
            {{ parcela.installment }} / {{ pedido.parcelas.length }}
          </span>
        </div>

        <div class="ui-g-4 pgw--text-right" *ngIf="pedido.moedaLoja === 'BRL'">
          <span style="color: #655f5d;">
            {{ parcela.paymentAmount | currency:'BRL':'symbol':'0.2-2':'pt' }}
          </span>
        </div>
        <div class="ui-g-4 pgw--text-right" *ngIf="pedido.moedaLoja === 'USD'">
          <span style="color: #655f5d;">
            {{ parcela.paymentAmount | currency:'USD':'symbol':'0.2-2':'en' }}
          </span>
        </div>

        <div class="ui-g-3 pgw--text-center">
          <span style="color: #655f5d;">
            {{ parcela.status === 'OPENED' ? 'Aberta' : parcela.status === 'CANCELLED' ? 'Cancelada' : 'Liquidada' }}
          </span>
        </div>
      </div>

    </p-card>
  </div>

  <div class="ui-g-12" *ngIf="pedido.moedaLoja == 'BRL'">
    <p-card>
      <div style="font-size: 1.5em; font-weight: bold; color: #655f5d">
        Produtos
      </div>

      <div class="ui-g" *ngFor="let item of pedido.itensPedido" style="padding: 10px 0px;">
        <div class="ui-g-12 pgw--text-right">
          <span style="color: #655f5d; ">
            {{ item.produtoDescricao }}
          </span>
        </div>
        <div class="ui-g-2 pgw--text-right">
          <span style="color: #655f5d; font-weight: bold;">
            {{ item.produtoQuantidade }} X
          </span>
        </div>
        <div class="ui-g-5 pgw--text-right">
          <span style="color: #655f5d; font-weight: bold;">
            {{ item.produtoPreco | currency:'BRL':'symbol':'0.2-2':'pt' }}
          </span>
        </div>
        <div class="ui-g-5 pgw--text-right">
          <span style="color: #655f5d; font-weight: bold;">
            {{ item.produtoQuantidade * item.produtoPreco | currency:'BRL':'symbol':'0.2-2':'pt' }}
          </span>
        </div>
      </div>
      <div class="ui-g" style="border-top: solid 1px;">
        <div class="ui-g-6 text-bold">
          Subtotal
        </div>
        <div class="ui-g-6 pgw--text-right text-bold">
          {{ pedido.totalItens | currency:'BRL':'symbol':'0.2-2':'pt' }}
        </div>
        <div class="ui-g-6">
          Extras
        </div>
        <div class="ui-g-6 pgw--text-right">
          {{ pedido.extras | currency:'BRL':'symbol':'0.2-2':'pt' }}
        </div>
        <div class="ui-g-6">
          Impostos incidentes
        </div>
        <div class="ui-g-6 pgw--text-right">
          {{ pedido.iof + pedido.ir + pedido.despesa | currency:'BRL':'symbol':'0.2-2':'pt' }}
        </div>
      </div>
      <div class="ui-g" style="border-top: solid 1px;">
        <div class="ui-g-6 text-bold">
          Total Geral
        </div>
        <div class="ui-g-6 pgw--text-right text-bold">
          {{ pedido.totalGeral | currency:'BRL':'symbol':'0.2-2':'pt' }}
        </div>
      </div>

    </p-card>
  </div>

  <div class="ui-g-12" *ngIf="pedido.moedaLoja == 'USD'">
    <p-card>
      <div style="font-size: 1.5em; font-weight: bold; color: #655f5d">
        Produtos
      </div>

      <div class="ui-g" *ngFor="let item of pedido.itensPedido" style="padding: 10px 0px;">
        <div class="ui-g-12 pgw--text-right">
          <span style="color: #655f5d; ">
            {{ item.produtoDescricao }}
          </span>
        </div>
        <div class="ui-g-2 pgw--text-right">
          <span style="color: #655f5d; font-weight: bold;">
            {{ item.produtoQuantidade }} X
          </span>
        </div>
        <div class="ui-g-5 pgw--text-right">
          <span style="color: #655f5d; font-weight: bold;">
            {{ item.produtoPrecoBase | currency:'USD':'symbol':'0.2-2':'en' }}
          </span>
        </div>
        <div class="ui-g-5 pgw--text-right">
          <span style="color: #655f5d; font-weight: bold;">
            {{ item.produtoTotalBase | currency:'USD':'symbol':'0.2-2':'en' }}
          </span>
        </div>
      </div>
      <div class="ui-g" style="border-top: solid 1px;">
        <div class="ui-g-6 text-bold">
          Subtotal
        </div>
        <div class="ui-g-6 pgw--text-right text-bold">
          {{ pedido.subTotalWC | currency:'USD':'symbol':'0.2-2':'en' }}
        </div>
        <div class="ui-g-6">
          Extras
        </div>
        <div class="ui-g-6 pgw--text-right">
          {{ pedido.totalWC - pedido.subTotalWC | currency:'USD':'symbol':'0.2-2':'en' }}
        </div>
      </div>
      <div class="ui-g" style="border-top: solid 1px;">
        <div class="ui-g-6 text-bold">
          Total
        </div>
        <div class="ui-g-6 pgw--text-right text-bold">
          {{ pedido.totalWC | currency:'USD':'symbol':'0.2-2':'en' }}
        </div>
        <div class="ui-g-6" *ngIf="licenca.taxaResp === 'VENDEDOR'">
          Fee 3,5%
        </div>
        <div class="ui-g-6 pgw--text-right" *ngIf="licenca.taxaResp === 'VENDEDOR'">
          {{ getFeePGW() | currency:'USD':'symbol':'0.2-2':'en' }}
        </div>
      </div>
      <div class="ui-g" style="border-top: solid 1px;" *ngIf="licenca.taxaResp === 'VENDEDOR'">
        <div class="ui-g-6 text-bold">
          Total Líquido
        </div>
        <div class="ui-g-6 pgw--text-right text-bold">
          {{ getTotalLiquido() | currency:'USD':'symbol':'0.2-2':'en' }}
        </div>
      </div>

      <div class="ui-g" style="margin-top: 25px; border-top: solid 1px;">
        <div class="ui-g-6">
          Câmbio da Operação
        </div>
        <div class="ui-g-6 pgw--text-right">
          {{ pedido.cambio | currency:'BRL':'symbol':'0.2-2':'pt' }}
        </div>
      </div>

    </p-card>
  </div>

  <div class="ui-g-12">
    <p-card>
      <div style="font-size: 1.5em; font-weight: bold; color: #655f5d">
        Contrato
      </div>
      <div style="padding: 15px 10px 0px 10px;">{{ licenca.contrato }}</div>
      <div style="font-size: 0.8em; font-weight: bold; color: #c2c4c7; padding: 5px 10px 15px 10px;">
        {{ pedido.environment === 'production' ? 'Produção' : pedido.environment === 'sandbox' ? 'Teste' : 'Log' }}
      </div>
    </p-card>
  </div>

  <div class="ui-g-12"
    *ngIf="pedido.formaPagamento === 'CARTÃO' && (pedido.pagseguroStatus == 3 || pedido.pagseguroStatus == 4 || pedido.pagseguroStatus == 5 || pedido.pagseguroStatus == 13)">
    <button pButton type="submit" (click)="estornar()" label="Solicitar Estorno" icon="fas fa-times"
      class="ui-button-danger"></button>
  </div>

</div>

<div class="ui-g" style="margin-top: 20px; margin-bottom: 20px;" *ngIf="toggle">

  <div class="ui-g-12" style="border-bottom: solid 1px; padding-bottom: 15px; padding-top: 15px;">

    <div class="ui-g-12" style="border-bottom: solid 1px; margin-bottom: 15px;" *ngIf="!nameEdit">

      <div style="display: table; vertical-align: middle; width:100%; ">
        <i class="pi pi-angle-double-left" (click)="clickEvent()"
          style="display: inline; font-size: 3em; font-weight: bold; color: #007ad9; margin-right: 10px;"></i>

        <span style="color: #655f5d; font-weight: bold; vertical-align: middle; display: table-cell; width: 100%;">
          {{ pedido.compradorNomeCompleto }}
          <i class="pi pi-user-edit" (click)="nameEditEvent()"
            *ngIf="(origem === 'PEDIDO' && hasPermission('PEDIDO_COMPRADOR_NOME')) || (origem === 'EXTRATO' && hasPermission('EXTRATO_COMPRADOR_NOME')) || (origem === 'BOLETO_FLUXO' && hasPermission('BOLETO_FLUXO_COMPRADOR_NOME')) || (origem === 'BOLETO_RECORRENTE' && hasPermission('BOLETO_RECORRENTE_COMPRADOR_NOME'))"
            style="display: inline; font-weight: bold; color: #007ad9; margin-left: 10px;"></i>
        </span>
      </div>
    </div>

    <div class="ui-g-12" style="border-bottom: solid 1px; margin-bottom: 15px;" *ngIf="nameEdit">

      <i class="pi pi-angle-double-left" (click)="clickEvent()"
        style="display: inline; font-size: 3em; font-weight: bold; color: #007ad9; margin-right: 10px;"></i>

      <div class="ui-g-12 ui-fluid">
        <form (ngSubmit)="nameSaveEvent()">
          <div class="ui-inputgroup">
            <input type="text" name="nameValue" pInputText [(ngModel)]="nameValue">
            <button pButton type="submit" icon="pi pi-save" class="ui-button-warn"></button>
          </div>
        </form>
      </div>

    </div>

    <div style="margin-left: 15px; margin-top: 15px;">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold;  margin-bottom: 5px;">
        {{ pedido.compradorTipoDoc }}:
      </div>
      <div>
        {{ pedido.compradorCpfCnpj }}
      </div>
    </div>

    <div style="margin-left: 15px; margin-top: 15px;"
      *ngIf="pedido.compradorCompany && pedido.compradorCompany.length > 1">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
        Empresa:
      </div>
      <div>
        {{ pedido.compradorCompany }}
      </div>
    </div>

    <div style="margin-left: 15px; margin-top: 15px;">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
        E-mail:
      </div>
      <div>
        {{ pedido.compradorEmail }}
      </div>
    </div>

    <div style="margin-left: 15px; margin-top: 15px;">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;"
        [title]="pedido.compradorFoneCompleto">
        Telefone:
      </div>
      <div>
        {{ pedido.compradorDdd }} {{ pedido.compradorFone }}
      </div>
    </div>
    <div style="margin-left: 15px; margin-top: 15px;">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;"
        [title]="pedido.compradorCelularCompleto">
        Celular:
      </div>
      <div>
        {{ pedido.compradorDddCelular }} {{ pedido.compradorFoneCelular }}
      </div>
    </div>

    <div style="margin-left: 15px; margin-top: 15px; border-top: solid 1px; padding-top: 15px;">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
        Logradouro:
      </div>
      <div>
        {{ pedido.compradorEnderecoLogradouro }}
      </div>
    </div>

    <div style="margin-left: 15px; margin-top: 15px;">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
        Número:
      </div>
      <div>
        {{ pedido.compradorEnderecoNumero }}
      </div>
    </div>

    <div style="margin-left: 15px; margin-top: 15px;">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
        Complemento:
      </div>
      <div>
        {{ pedido.compradorEnderecoComplemento }}
      </div>
    </div>

    <div style="margin-left: 15px; margin-top: 15px;">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
        Bairro:
      </div>
      <div>
        {{ pedido.compradorEnderecoBairro }}
      </div>
    </div>

    <div style="margin-left: 15px; margin-top: 15px;">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
        Cidade:
      </div>
      <div>
        {{ pedido.compradorEnderecoCidade }}
      </div>
    </div>

    <div style="margin-left: 15px; margin-top: 15px;">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
        Estado:
      </div>
      <div>
        {{ pedido.compradorEnderecoUf }}
      </div>
    </div>

    <div style="margin-left: 15px; margin-top: 15px;">
      <div style="color: #655f5d; font-size: 0.8em; font-weight: bold; margin-bottom: 5px;">
        CEP:
      </div>
      <div>
        {{ pedido.compradorEnderecoCep }}
      </div>
    </div>
  </div>

</div>

<p-blockUI [blocked]="blocked" [baseZIndex]="10000">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>

<p-confirmDialog [style]="{width: '450px'}" acceptLabel='Sim' rejectLabel='Não' key="positionDialog" position="center" [baseZIndex]="10000">
</p-confirmDialog>
