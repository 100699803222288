<div class="pgw--container">

  <div class="ui-g">

    <div class="ui-g-12">
      <h1>Boletos Fluxo</h1>
    </div>

    <div class="ui-g-12 ui-g-nopad">
      <div class="ui-g-12 ui-fluid" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
        <p-dropdown [options]="empresasOptions" [(ngModel)]="pedidoFilter.empresaId" (onChange)="buscarLicencas()"
          filter="true" resetFilterOnHide="true">
        </p-dropdown>
      </div>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <input pInputText [(ngModel)]="pedidoFilter.pedidoNumero" placeholder="Pedido" pTooltip="Número do Pedido"
        tooltipPosition="top" />
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-calendar [(ngModel)]="pedidoFilter.rangeDates" selectionMode="range" [readonlyInput]="true"
        dateFormat="dd/mm/yy" placeholder="Período" pTooltip="Data de Início ou Período" tooltipPosition="top">
      </p-calendar>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-dropdown [options]="pedidoFilter.tpPeriodoOptions" [(ngModel)]="pedidoFilter.tpPeriodo"
        pTooltip="Tipo de Período" tooltipPosition="top">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-multiSelect [options]="pedidoFilter.situacoesOptions" [(ngModel)]="pedidoFilter.selectedSituacoes"
        defaultLabel="Situações" pTooltip="Situação" tooltipPosition="top">
        <ng-template let-item pTemplate="item">
          <div class="item-multi-selected" [style.color]="item.rgb">
            {{item.label}}</div>
        </ng-template>
      </p-multiSelect>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-multiSelect [options]="pedidoFilter.instituicoesOptions" [(ngModel)]="pedidoFilter.selectedInstituicoes"
        defaultLabel="Instituições" pTooltip="Instituição Financeira" tooltipPosition="top"></p-multiSelect>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-dropdown [options]="pedidoFilter.origemOptions" [(ngModel)]="pedidoFilter.origem" pTooltip="Origem"
        tooltipPosition="top">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-dropdown [options]="pedidoFilter.recorrenteOptions" [(ngModel)]="pedidoFilter.recorrente"
        pTooltip="Pagamento Recorrente" tooltipPosition="top">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
      <p-dropdown [options]="pedidoFilter.environmentOptions" [(ngModel)]="pedidoFilter.environment"
        pTooltip="Modo de Operação" tooltipPosition="top">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-4 ui-fluid">
      <input pInputText [(ngModel)]="pedidoFilter.comprador" placeholder="Comprador" pTooltip="Nome do Comprador"
        tooltipPosition="top" />
    </div>

    <div class="ui-g-12 ui-md-4 ui-lg-4 ui-fluid">
      <input pInputText [(ngModel)]="pedidoFilter.autorizacao" placeholder="Autorização" pTooltip="Autorização"
        tooltipPosition="top" />
    </div>

    <div class="ui-g-12 ui-md-7 ui-lg-11 ui-fluid">
      <p-dropdown [options]="licencasOptions" [(ngModel)]="pedidoFilter.licencaId" pTooltip="Contratos"
        tooltipPosition="top" (onChange)="selecionarLicenca()">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-1 ui-lg-1">
      <button pButton type="button" icon="pi pi-search" (click)="buscar()"></button>
    </div>

    <div class="ui-g-12 ui-fluid">
      <p-table [value]="boletosDTO" [responsive]="true" selectionMode="single" (onRowSelect)="onRowSelect($event.data)">
        <ng-template pTemplate="caption">
          Boletos encontrados: {{ boletosDTO.length }}
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 110px;">Emissão<i class="fas fa-sort-amount-down-alt ml-5"
                [style.color]="sort === 'E' ? '#222f3e' : '#8395a7'" (click)="sortEmissao()"></i></th>
            <th style="width: 100px;">Vencto.<i class="fas fa-sort-amount-down-alt ml-5"
                [style.color]="sort === 'V' ? '#222f3e' : '#8395a7'" (click)="sortVencto()"></i></th>
            <th style="width: 100px;">Pagto.<i class="fas fa-sort-amount-down-alt ml-5"
                [style.color]="sort === 'P' ? '#222f3e' : '#8395a7'" (click)="sortPagto()"></i></th>
            <th style="width: 70px;">Tipo</th>
            <th style="width: 90px;">Pedido</th>
            <th class="col-left">Comprador</th>
            <th style="width: 120px;">Situação</th>
            <th style="width: 110px;" class="col-right">Vlr.Boleto</th>
            <th style="width: 110px;" class="col-right">Vlr.Pago</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-boletoDTO>
          <tr [pSelectableRow]="boletoDTO">
            <td class="col-center">{{ boletoDTO.dtHr | date:'dd/MM/yyyy' }}</td>
            <td class="col-center">{{ boletoDTO.dtVencto | date:'dd/MM/yyyy' }}</td>
            <td class="col-center"
              [style.color]="boletoDTO.dtVencto > boletoDTO.dtPagto ? '#27ae60' : boletoDTO.dtVencto < boletoDTO.dtPagto ? '#d35400' : ''">
              {{ boletoDTO.dtPagto | date:'dd/MM/yyyy' }}
            </td>
            <td class="col-center">
              <span *ngIf="boletoDTO.dtPagto">
                <img *ngIf="boletoDTO.dtHrPagtoPix" class="pix-logo" src="../../../assets/images/logo-pix-icone-256.png"
                  [title]="boletoDTO.dtHrPagtoPix | date:'dd/MM/yyyy HH:mm:ss'" />
                <i *ngIf="!boletoDTO.dtHrPagtoPix" class="fas fa-barcode"></i>
              </span>
            </td>
            <td class="col-center">
              {{ boletoDTO.pedidoNumero }}
              <span class="pgw--ref">{{boletoDTO.origem}}</span>
            </td>
            <td class="col-left">
              {{ boletoDTO.compradorNomeCompleto }}
              <span class="pgw--ref">{{ boletoDTO.contrato }} |
                {{ boletoDTO.environment === 'production' ? 'Produção' : boletoDTO.environment ===
                'sandbox' ? 'Teste' : 'Log' }}
              </span>
            </td>
            <td class="col-center" appPagseguroStatus>{{ boletoDTO.pagseguroStatus }}</td>
            <td class="col-right" *ngIf="pedidoFilter.moeda === 'BRL'">
              {{ boletoDTO.totalPedido | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" *ngIf="pedidoFilter.moeda === 'USD'">
              {{ boletoDTO.totalPedido | currency:'USD':'symbol':'0.2-2':'en' }}</td>
            <td class="col-right">
              {{ boletoDTO.totalPago | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
          </tr>
        </ng-template>

      </p-table>
    </div>

    <div class="ui-g-12 ui-fluid" style="margin: 30px 0;">
      <p-table [value]="resumo.formas" *ngIf="resumo.formas.length > 0">

        <ng-template pTemplate="caption">
          Resumo
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th class="col-left">Situação</th>
            <th class="col-right" style="width: 100px;">Qtd.</th>
            <th class="col-right" style="width: 120px;">Vlr.Boleto</th>
            <th class="col-right" style="width: 120px;">Vlr.Pago</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-forma>
          <tr>
            <td class="col-left" appPagseguroStatus>{{ forma.forma }}</td>
            <td class="col-right">{{ forma.qtd }}</td>
            <td class="col-right" *ngIf="pedidoFilter.moeda === 'BRL'">
              {{ forma.total | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" *ngIf="pedidoFilter.moeda === 'USD'">
              {{ forma.total | currency:'USD':'symbol':'0.2-2':'en' }}</td>
            <td class="col-right">
              {{ forma.totalPago | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer">
          <tr>
            <td class="col-left">Total</td>
            <td class="col-right">{{ resumo.qtd }}</td>
            <td class="col-right" *ngIf="pedidoFilter.moeda === 'BRL'">
              {{ resumo.total | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-right" *ngIf="pedidoFilter.moeda === 'USD'">
              {{ resumo.total | currency:'USD':'symbol':'0.2-2':'en' }}</td>
            <td class="col-right">
              {{ resumo.totalPago | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
          </tr>
        </ng-template>

      </p-table>

    </div>

    <div class="ui-g-12" style="text-align: right;">
      <span>
        <a [href]="csvQuery" target="_blank" title="Baixe aqui o arquivo CSV"><i class="fa fa-file-csv"
            style="font-size: 3em;"></i></a>
      </span>
    </div>

  </div>

</div>

<nav *ngIf="exibirPedido">
  <p-sidebar [(visible)]="exibirPedido" position="left" styleClass="ui-sidebar-md">
    <app-pedido-detalhe origem="BOLETO_FLUXO" [pedido]="pedidoSelecionado" [licenca]="licencaSelecionada">
    </app-pedido-detalhe>
  </p-sidebar>
</nav>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>
