import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Empresa } from './empresa.model';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  constructor(private http: HttpClient) { }

  buscarPorId(empresaId: string): Observable<Empresa> {
    const url = `${environment.apiUrl}/empresas/${empresaId}`;
    return this.http.get<Empresa>(url);
  }
  
  getEmpresas(): Observable<Empresa[]> {
    const url = `${environment.apiUrl}/empresas`;
    return this.http.get<Empresa[]>(url);
  }
  
}
