<div class="pgw--container">
  <div class="ui-g">

    <div class="ui-g-12">
      <h1>Boleto(s) Gerado(s)</h1>
    </div>

    <div class="ui-g-12 ui-fluid">
      <p-table [value]="data.vencimentos" [responsive]="true">
        <ng-template pTemplate="header">
          <tr>
            <th class="col-center">Vencimento</th>
            <th class="col-center">Pedido</th>
            <th class="col-center">Autorizacao</th>
            <th class="col-center">Valor</th>
            <th class="col-center">Boleto</th>
            <th class="col-center">Status</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-vencimento>
          <tr>
            <td class="col-center">{{ vencimento.vencimento | date:'dd/MM/yyyy' }}</td>
            <td class="col-center">{{ vencimento.pedidoNumero }}</td>
            <td class="col-center">{{ vencimento.autorizacao }}</td>
            <td class="col-center">{{ vencimento.valor | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-center">
              <a [href]="vencimento.link" target="_blank" title="Baixe aqui seu boleto"><i class="fas fa-download"
                  *ngIf="vencimento.link"></i></a>
              <span class="status-danger" *ngIf="vencimento.status !== 'SUCCESS'">{{ vencimento.error }}</span>
            </td>
            <td class="col-center">
              <i class="fas fa-check status-success" *ngIf="vencimento.status === 'SUCCESS'"
                title="Boleto gerado com sucesso!"></i>
              <i class="fas fa-times status-danger" *ngIf="vencimento.status !== 'SUCCESS'" title="Boleto falhou!"></i>
            </td>
          </tr>
        </ng-template>

      </p-table>
    </div>

    <div class="ui-g-12">
      <button pButton label="Emitir Boletos" icon="fas fa-plus" class="ui-button-success"
        (click)="emitirBoletos()"></button>
    </div>

  </div>


</div>
