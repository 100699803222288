import { HttpParams } from '@angular/common/http';

export class SaqueFilter {

  empresaId: string;
  licencaId: string;

  moeda: string;

  rangeDates: Date[];
  rangeType: string;
  sort: string;


  constructor(empresaId: string) {
    this.empresaId = empresaId;
    this.rangeType = 'dtHrSolicitacao';
    this.moeda = 'USD';
    this.sort = 'DESC';
  }

  initRangeDates() {
    const startDate = new Date(new Date().setDate(new Date().getDate() - 1));
    this.rangeDates = [];
    this.rangeDates.push(startDate);
    this.rangeDates.push(null);
  }

  clear() {
    this.rangeDates = undefined;
  }

  getSearchParams() {
    let params = new HttpParams();

    if (this.empresaId !== undefined) {
      params = params.set('empresaId', this.empresaId);
    }

    if (this.licencaId !== undefined) {
      params = params.set('licencaId', this.licencaId);
    }

    if (this.moeda !== undefined) {
      params = params.set('moeda', this.moeda);
    }

    if (this.rangeDates !== undefined && this.rangeDates[0] != null) {
      const dtStr = this.rangeDates[0].toISOString().substr(0, 10);
      params = params.set('dtInicio', dtStr);
    }

    if (this.rangeDates !== undefined && this.rangeDates[1] != null) {
      const dtStr = this.rangeDates[1].toISOString().substr(0, 10);
      params = params.set('dtFim', dtStr);
    }

    if (this.rangeType !== undefined) {
      params = params.set('dtType', this.rangeType);
    }

    if (this.sort !== undefined) {
      params = params.set('sort', this.sort);
    }

    return params;
  }

}
