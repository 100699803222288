import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Pedido } from '../pedido.model';
import { PedidoDTO } from '../pedido-dto';

@Component({
  selector: 'app-pedido-dependentes',
  templateUrl: './pedido-dependentes.component.html',
  styleUrls: ['./pedido-dependentes.component.css']
})
export class PedidoDependentesComponent implements OnInit {

  @Input() pedido: Pedido;
  @Input() pedidos: PedidoDTO[];
  @Output() fechar = new EventEmitter();
  @Output() pedidoOrigemSelecionado = new EventEmitter();
  @Output() pedidoDependenteSelecionado = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClose() {
    this.fechar.emit();
  }

  onRowSelectPedido(pedidoDTO: PedidoDTO) {
    this.pedidoDependenteSelecionado.emit(pedidoDTO);
  }

  onPedidoOrigemSelected() {
    this.pedidoOrigemSelecionado.emit(this.pedido);
  }

}
