import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CompradorFilter } from '../boleto/comprador-filter';
import { CompradorService } from '../boleto/comprador.service';
import { Comprador } from '../boleto/comprador.model';

@Component({
  selector: 'app-comprador-consulta',
  templateUrl: './comprador-consulta.component.html',
  styleUrls: ['./comprador-consulta.component.css']
})
export class CompradorConsultaComponent implements OnInit {

  @Output() compradorSelecionado = new EventEmitter();
  @Output() fecharConsultaComprador = new EventEmitter();

  @Input() empresaId: string;

  compradores: Comprador[];
  compradorFilter: CompradorFilter;

  constructor(private compradorService: CompradorService) { }

  ngOnInit(): void {
    this.compradorFilter = new CompradorFilter(this.empresaId);
  }

  buscarCompradores() {
    this.compradorService.buscarCompradores(this.compradorFilter).subscribe(
      compradores => {
        this.compradores = compradores;
        this.compradorFilter.nome = undefined;
        this.compradorFilter.email = undefined;
        this.compradorFilter.cpfCnpj = undefined;
      }
    );
  }

  onSelectComprador(comprador: Comprador) {
    this.compradorSelecionado.emit(comprador);
  }

  fechar() {
    this.fecharConsultaComprador.emit();
  }

}
