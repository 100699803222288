import { Component, OnInit } from '@angular/core';
import { Conta } from '../conta.model';
import { ContaService } from '../conta.service';
import { AuthService } from 'src/app/seguranca/auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-conta-consulta',
  templateUrl: './conta-consulta.component.html',
  styleUrls: ['./conta-consulta.component.css']
})
export class ContaConsultaComponent implements OnInit {

  jwtPayload: any;
  exibir = false;
  conta: Conta;
  contas: Conta[] = [];

  constructor(
    private contaService: ContaService,
    private messageService: MessageService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.jwtPayload = this.authService.getJwtPayload();
    this.buscar();
    this.contaService.contaEvent.subscribe(
      () => {
        this.buscar();
        this.exibir = false;
      }
    );
  }

  criar() {
    this.conta = new Conta(this.jwtPayload.empresaId);
    this.exibir = true;
  }

  excluir(id: string) {
    this.contaService.excluir(id).subscribe(
      () => {
        this.buscar();
      },
      error => {
        console.log('Error:', error);
        this.contas = [];
      }
    );
  }

  onRowSelect(conta) {
    if (this.hasPermission('CONFIG_CONTA_EDITAR')) {
      this.conta = conta;
      this.exibir = true;
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Permissão Negada!',
        detail: 'Você não possui permissão para editar contas bancárias.'
      });
    }
  }

  buscar() {
    this.contaService.buscarPorEmpresa(this.jwtPayload.empresaId).subscribe(
      result => {
        this.contas = result;
      },
      error => {
        console.log('Error:', error);
        this.contas = [];
      }
    );
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

}
