import { Component, OnInit, Input } from '@angular/core';
import { Licenca } from '../licenca.model';
import { LicencaService } from '../licenca.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-licenca-cadastro',
  templateUrl: './licenca-cadastro.component.html',
  styleUrls: ['./licenca-cadastro.component.css']
})
export class LicencaCadastroComponent implements OnInit {

  @Input() licenca: Licenca;

  constructor(
    private licencaService: LicencaService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
  }

  salvar() {
    if (this.licenca.id) {
      this.atualizar();
    } else {
      this.criar();
    }
  }

  criar() {
    this.licencaService.criar(this.licenca).subscribe(
      data => {
        this.licencaService.licencaEvent.emit();
      },
      error => {
        console.log('Error :', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao criar licença!',
          detail: 'Erro ao criar licença.'
        });
      }
    );
  }

  atualizar() {
    this.licencaService.atualizar(this.licenca).subscribe(
      data => {
        this.licencaService.licencaEvent.emit();
      },
      error => {
        console.log('Error :', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao atualizar licença!',
          detail: 'Erro ao atualizar licença.'
        });
      }
    );
  }

}
