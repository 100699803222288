import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redefinir-senha',
  templateUrl: './redefinir-senha.component.html',
  styleUrls: ['./redefinir-senha.component.css']
})
export class RedefinirSenhaComponent implements OnInit {

  senhaAtual: string;
  novaSenha: string;
  confirmarSenha: string;

  blocked = false;

  @ViewChild('senhaAtualField', { static: true }) senhaAtualField: ElementRef;
  @ViewChild('novaSenhaField', { static: true }) novaSenhaField: ElementRef;
  @ViewChild('confirmarSenhaField', { static: true }) confirmarSenhaField: ElementRef;

  constructor(
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.authService.redefinirSenhaFocusEvent.subscribe(() => this.senhaAtualField.nativeElement.focus());
  }

  redefinirSenha() {

    this.blocked = true;

    if (this.senhaAtual === undefined) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao redefinir senha!',
        detail: 'Digite a senha atual.'
      });
      this.reset();
      return;
    }
    if (this.senhaAtual !== this.authService.senhaAtual) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao redefinir senha!',
        detail: 'Senha atual inválida.'
      });
      this.reset();
      return;
    }

    if (this.novaSenha === undefined) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao redefinir senha!',
        detail: 'Digite uma nova senha.'
      });
      this.novaSenha = undefined;
      this.confirmarSenha = undefined;
      this.novaSenhaField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.novaSenha.length < 5) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao redefinir senha!',
        detail: 'Digite uma nova senha com no mínimo 5 algarismos.'
      });
      this.novaSenha = undefined;
      this.confirmarSenha = undefined;
      this.novaSenhaField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    if (this.confirmarSenha === undefined) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao redefinir senha!',
        detail: 'Confirme a nova senha.'
      });
      this.confirmarSenha = undefined;
      this.confirmarSenhaField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.confirmarSenha.length < 5) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao redefinir senha!',
        detail: 'Confirme a nova senha com no mínimo 5 algarismos.'
      });
      this.confirmarSenha = undefined;
      this.confirmarSenhaField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.novaSenha !== this.confirmarSenha) {
      this.messageService.add({
        severity: 'error',
        summary: 'Falha ao redefinir senha!',
        detail: 'Confirmação de senha inválida.'
      });
      this.confirmarSenha = undefined;
      this.confirmarSenhaField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    console.log('Redefinir senha start');

    this.authService.redefinirSenha(this.novaSenha).subscribe(
      () => {
        console.log('Redefinir senha success');
        this.messageService.add({
          severity: 'success',
          summary: 'Senha redefinida com sucesso!',
          detail: 'Faça login novamente com sua nova senha.'
        });
        this.blocked = false;
        this.authService.logout();
      },
      error => {
        console.log('Redefinir senha error');
        console.log('error:', error);
        localStorage.removeItem('access_token');
        this.authService.setJwtPayload(undefined);
        this.messageService.add({
          severity: 'error',
          summary: 'Falha ao redefinir senha!',
          detail: 'Usuário inexistente ou senha inválida.'
        });
        this.blocked = false;
      },
      () => this.reset()
    );
  }

  reset() {
    this.senhaAtual = undefined;
    this.novaSenha = undefined;
    this.confirmarSenha = undefined;
    this.senhaAtualField.nativeElement.focus();
    this.blocked = false;
  }

}
