import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Comprador } from './comprador.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { CompradorFilter } from './comprador-filter';

@Injectable({
  providedIn: 'root'
})
export class CompradorService {

  constructor(private http: HttpClient) { }

  buscarComprador(empresaId: string, cpfCnpj: string): Observable<Comprador> {
    return this.http.get<Comprador>(`${environment.apiUrl}/compradores/${empresaId}/${cpfCnpj}`);
  }

  buscarCompradores(compradorFilter: CompradorFilter): Observable<Comprador[]> {
    const url = `${environment.apiUrl}/compradores`;
    return this.http.get<Comprador[]>(url, { params: compradorFilter.getSearchParams() });
  }
  
}
