<div class="pgw--container pgw--form-login">
  <form autocomplete="off" (ngSubmit)="enviar();">
    <div class="ui-g ui-fluid margin-bottom-30">

      <div class="ui-g-12">
        <h1>Validar</h1>
        <p>Escolha como quer receber o código de validação.</p>
      </div>

      <div class="ui-g-12">
        <p-radioButton name="meioEnvioCodigo" value="SMS" label="SMS" [(ngModel)]="meioEnvioCodigo"></p-radioButton>
        <p-radioButton name="meioEnvioCodigo" value="WHATSAPP" label="WhatsApp" [(ngModel)]="meioEnvioCodigo" *ngIf="false">
        </p-radioButton>
        <p-radioButton name="meioEnvioCodigo" value="EMAIL" label="E-mail" [(ngModel)]="meioEnvioCodigo">
        </p-radioButton>
      </div>

      <div class="ui-g-12">
        <button pButton type="submit" [label]="enviado ? 'Reenviar Código' : 'Enviar Código'"
          [icon]="enviado ? 'far fa-paper-plane' : 'fas fa-paper-plane'"
          [ngClass]="{'ui-button-secondary': enviado}"></button>
      </div>

    </div>
  </form>

  <form autocomplete="off" (ngSubmit)="validar();" *ngIf="enviado">
    <div class="ui-g ui-fluid">

      <div class="ui-g-12">
        <span class="ui-float-label">
          <input id="codigo" pInputText required name="codigo" [(ngModel)]="codigo" maxlength="6" pKeyFilter="pint"
            autofocus>
          <label for="codigo">Código de validação</label>
        </span>
      </div>

      <div class="ui-g-12">
        <button pButton type="submit" label="Validar" icon="fas fa-check" class="ui-button-success"></button>
      </div>

    </div>
  </form>
</div>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>
