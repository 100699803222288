import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PedidoFilter } from '../pedido/pedido-filter';
import { Observable } from 'rxjs';
import { GroupFormaDTO } from '../pedido/group-forma-dto.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EstatisticaService {

  constructor(private http: HttpClient) { }

  getEstatisticaClientesMonetario(pedidoFilter: PedidoFilter): Observable<GroupFormaDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/clientes/monetario`;
    return this.http.get<GroupFormaDTO[]>(url, { params: pedidoFilter.getSearchParams() });
  }
  
  getEstatisticaClientesQuantitativo(pedidoFilter: PedidoFilter): Observable<GroupFormaDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/clientes/quantitativo`;
    return this.http.get<GroupFormaDTO[]>(url, { params: pedidoFilter.getSearchParams() });
  }

  getEstatisticaProdutosMonetario(pedidoFilter: PedidoFilter): Observable<GroupFormaDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/produtos/monetario`;
    return this.http.get<GroupFormaDTO[]>(url, { params: pedidoFilter.getSearchParams() });
  }
  
  getEstatisticaProdutosQuantitativo(pedidoFilter: PedidoFilter): Observable<GroupFormaDTO[]> {
    const url = `${environment.apiUrl}/pedidos/group/produtos/quantitativo`;
    return this.http.get<GroupFormaDTO[]>(url, { params: pedidoFilter.getSearchParams() });
  }

}
