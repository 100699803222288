import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conciliacao-cadastro',
  templateUrl: './conciliacao-cadastro.component.html',
  styleUrls: ['./conciliacao-cadastro.component.css']
})
export class ConciliacaoCadastroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
