<div class="pgw--container">

  <div class="ui-g" style="padding-bottom: 50px;">

    <div class="ui-g-12">
      <h1>Configurações</h1>
    </div>

    <div class="ui-g-12" style="margin-bottom: 20px;" *ngIf="false && hasPermission('CONFIG_CADASTRO')">
      <p-fieldset legend="Cadastro" [toggleable]="true" [collapsed]="true">

      </p-fieldset>
    </div>

    <div class="ui-g-12" style="margin-bottom: 20px;" *ngIf="false && hasPermission('CONFIG_CONTA')">
      <p-fieldset legend="Contas" [toggleable]="true" [collapsed]="true">
        <app-conta-consulta></app-conta-consulta>
      </p-fieldset>
    </div>

    <div class="ui-g-12" style="margin-bottom: 20px;" *ngIf="false && hasPermission('CONFIG_TERMINAL')">
      <p-fieldset legend="Terminais" [toggleable]="true" [collapsed]="true">
        <app-licenca-consulta></app-licenca-consulta>
      </p-fieldset>
    </div>

    <div class="ui-g-12" style="margin-bottom: 20px;" *ngIf="hasPermission('CONFIG_USUARIO')">
      <p-fieldset legend="Usuários" [toggleable]="true" [collapsed]="true">
        <app-usuario-consulta></app-usuario-consulta>
      </p-fieldset>
    </div>

    <div class="ui-g-12" style="margin-bottom: 20px;" *ngIf="hasPermission('CONFIG_GRUPO')">
      <p-fieldset legend="Grupos" [toggleable]="true" [collapsed]="true">
        <app-grupo-consulta></app-grupo-consulta>
      </p-fieldset>
    </div>

  </div>

</div>
