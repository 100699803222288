export class Licenca {

  id: string;
  dtHr: Date;
  titulo: string;
  contrato: string;
  descricao: string;
  moeda: string;
  taxaResp: string;
  taxaAliq: number;
  classificacao: number;
  empresaId: string;

  constructor(empresaId: string) {
    this.empresaId = empresaId;
  }

}
