import { Document } from './document.model';
import { Address } from './Address.model';
import { Phone } from './phone.model';

export class Customer {

  constructor() {
    this.document = new Document();
    this.phone = new Phone();
    this.address = new Address();
  }

  name: string;
  email: string;
  document: Document;
  phone: Phone;
  address: Address;
}
