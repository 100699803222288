import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

import { MultiSelect } from 'primeng/multiselect';
import { MessageService } from 'primeng/api';

import { Usuario } from '../usuario.model';
import { UsuarioService } from '../usuario.service';
import { AuthService } from '../auth.service';
import { GrupoService } from '../grupo.service';

@Component({
  selector: 'app-usuario-cadastro',
  templateUrl: './usuario-cadastro.component.html',
  styleUrls: ['./usuario-cadastro.component.css']
})
export class UsuarioCadastroComponent implements OnInit {

  @Input() usuario: Usuario;

  jwtPayload: any;
  grupos: any[];
  confirmarSenha: string;
  blocked: boolean;

  @ViewChild('usuarioNome', { static: true }) nomeField: ElementRef;
  @ViewChild('usuarioApelido', { static: true }) apelidoField: ElementRef;
  @ViewChild('usuarioDdd', { static: true }) dddField: ElementRef;
  @ViewChild('usuarioCelular', { static: true }) celularField: ElementRef;
  @ViewChild('usuarioGrupos', { static: true }) gruposField: MultiSelect;
  @ViewChild('usuarioEmail', { static: true }) emailField: ElementRef;

  constructor(
    private authService: AuthService,
    private usuarioService: UsuarioService,
    private grupoService: GrupoService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.blocked = false;
    this.jwtPayload = this.authService.getJwtPayload();
    this.nomeField.nativeElement.focus();
    this.grupoService.buscarPorEmpresa(this.jwtPayload.empresaId).subscribe(
      result => {
        this.grupos = [];
        result.forEach(grupo => {
          this.grupos.push(
            { label: grupo.grupo, value: grupo.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      }
    );
  }

  salvar() {
    this.blocked = true;
    // Validações

    // Nome
    if (this.usuario.nome === undefined) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Nome inválido!',
        detail: 'Informe o nome do usuário.'
      });
      this.nomeField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.usuario.nome.length < 3) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Nome Curto!',
        detail: 'Informe o nome com no minímo 3 caracteres.'
      });
      this.nomeField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    // Apelido
    if (this.usuario.apelido === undefined) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Apelido inválido!',
        detail: 'Informe o apelido do usuário.'
      });
      this.apelidoField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.usuario.apelido.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Apelido Curto!',
        detail: 'Informe o apelido com no minímo 2 caracteres.'
      });
      this.apelidoField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    // DDD
    if (this.usuario.ddd === undefined) {
      this.messageService.add({
        severity: 'warn',
        summary: 'DDD inválido!',
        detail: 'Informe o DDD..'
      });
      this.dddField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.usuario.ddd.length < 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'DDD Curto!',
        detail: 'Informe o DDD com no minímo 2 algarismos.'
      });
      this.dddField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    // Celular
    if (this.usuario.celular === undefined) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Celular inválido!',
        detail: 'Informe o celular..'
      });
      this.celularField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.usuario.celular.length < 8) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Celular Curto!',
        detail: 'Informe o celular com no minímo 8 algarismos.'
      });
      this.celularField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    // Grupo(s)
    if (this.usuario.grupos === undefined || this.usuario.grupos.length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Grupos inválido!',
        detail: 'Selecione o(s) grupo(s).'
      });
      this.gruposField.focus = true;
      this.blocked = false;
      return;
    }

    // E-mail
    if (this.usuario.email === undefined) {
      this.messageService.add({
        severity: 'warn',
        summary: 'E-mail inválido!',
        detail: 'Informe o e-mail.'
      });
      this.emailField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.usuario.email.length < 5 || !this.usuario.email.includes('@') || !this.usuario.email.includes('.')) {
      this.messageService.add({
        severity: 'warn',
        summary: 'E-mail inválido!',
        detail: 'Informe um e-mail válido.'
      });
      this.emailField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    // Cria ou atualiza
    if (this.usuario.id) {
      this.atualizar();
    } else {
      this.criar();
    }
  }

  criar() {
    this.usuarioService.buscarPorEmail(this.usuario.email).subscribe(
      () => {
        this.messageService.add({
          severity: 'warn',
          summary: 'E-mail Inválido!',
          detail: 'E-mail já utilizado.'
        });
        this.emailField.nativeElement.focus();
        this.blocked = false;
        return;
      },
      () => this.usuarioService.criar(this.usuario).subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Sucesso!',
            detail: 'Usuário criado com sucesso.'
          });
          this.usuarioService.usuarioEvent.emit();
          this.blocked = false;
        },
        error => {
          console.log('Error :', error);
          this.messageService.add({
            severity: 'error',
            summary: 'Erro ao criar usuário!',
            detail: 'Erro ao criar usuário.'
          });
          this.nomeField.nativeElement.focus();
          this.blocked = false;
        }
      )
    );
  }

  atualizar() {
    this.usuarioService.buscarPorEmail(this.usuario.email).subscribe(
      data => {
        if (data.id === this.usuario.id) {
          this.update();
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'E-mail Inválido!',
            detail: 'E-mail já utilizado.'
          });
          this.emailField.nativeElement.focus();
          this.blocked = false;
        }
      },
      () => this.update()
    );
  }

  private update() {
    this.usuarioService.atualizar(this.usuario).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso!',
          detail: 'Usuário alterado com sucesso.'
        });
        this.usuarioService.usuarioEvent.emit();
        this.blocked = false;
      },
      error => {
        console.log('Error :', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao atualizar usuário!',
          detail: 'Erro ao atualizar usuário.'
        });
        this.nomeField.nativeElement.focus();
        this.blocked = false;
      }
    );
  }

}
