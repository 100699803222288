import { Component, OnInit, ElementRef, ViewChild, Input } from '@angular/core';

import { MessageService } from 'primeng/api';

import { RecorrenteLink } from '../recorrente-link.model';
import { RecorrenteLinkService } from '../recorrente-link.service';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'app-recorrente-link-cadastro',
  templateUrl: './recorrente-link-cadastro.component.html',
  styleUrls: ['./recorrente-link-cadastro.component.css']
})
export class RecorrenteLinkCadastroComponent implements OnInit {

  @Input() editLock: boolean;
  @Input() recorrenteLink: RecorrenteLink;
  @Input() licencasOptions: any[];

  @ViewChild('linkContrato', { static: true }) contratoField: ElementRef;
  @ViewChild('linkDescricao', { static: true }) descricaoField: ElementRef;
  @ViewChild('linkPreco', { static: true }) precoField: ElementRef;
  @ViewChild('textToCopy', { static: true }) textToCopyField: ElementRef;
  @ViewChild('parcelamentoCartaoElRef', { static: true }) parcelamentoCartaoField: Dropdown;

  regExValor: RegExp = /[0123456789,]/;
  valor: string;

  blocked: boolean;

  periodicidadeOptions: any[];

  tpMultaOptions: any[];
  tpJurosOptions: any[];
  valorMulta: string;
  valorJuros: string;

  constructor(
    private paymentLinkService: RecorrenteLinkService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.blocked = false;
    this.valor = this.recorrenteLink.valorUnitario.toFixed(2).replace('.', ',');
    this.valorMulta = this.recorrenteLink.vlrMulta.toFixed(2).replace('.', ',');
    this.valorJuros = this.recorrenteLink.vlrJuros.toFixed(2).replace('.', ',');
    this.changeValor();

    this.periodicidadeOptions = [
      { label: 'MENSAL', value: 'MENSAL' },
      { label: 'ANUAL', value: 'ANUAL' }
    ];

    this.tpMultaOptions = [
      { label: 'ISENTO', value: 'ISENTO' },
      { label: 'VALOR', value: 'VALOR' },
      { label: 'PERCENTUAL', value: 'PERCENTUAL' }
    ];

    this.tpJurosOptions = [
      { label: 'ISENTO', value: 'ISENTO' },
      { label: 'VALOR AO DIA', value: 'VALOR_DIA' },
      { label: 'PERCENTUAL AO MÊS', value: 'PERCENTUAL_MES' }
    ];
  }

  salvar() {
    this.blocked = true;

    // validar
    if (this.recorrenteLink.descricao === undefined || this.recorrenteLink.descricao === null || 0 === this.recorrenteLink.descricao.trim().length) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Informe o produto ou serviço que deseja vender.'
      });
      this.descricaoField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    let valorSplit = this.valor.split(',');
    if (this.valor === undefined || this.valor === null || valorSplit.length > 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço inválido.'
      });
      this.precoField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (valorSplit.length === 2 && valorSplit[1].length > 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço com mais de 2 digitos decimais.'
      });
      this.precoField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    this.recorrenteLink.valorUnitario = parseFloat(this.valor.replace(',', '.'));
    if (this.recorrenteLink.valorUnitario === undefined || this.recorrenteLink.valorUnitario === null || isNaN(this.recorrenteLink.valorUnitario)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço inválido.'
      });
      this.precoField.nativeElement.focus();
      this.blocked = false;
      return;
    }
    if (this.recorrenteLink.valorUnitario < 5.00 ) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço mínimo R$ 5,00.'
      });
      this.precoField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    if (!this.recorrenteLink.ativarBoleto && !this.recorrenteLink.ativarCartao) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Nenhuma forma de recebimento foi permitida.'
      });
      this.blocked = false;
      return;
    }

    if (this.recorrenteLink.ativarBoleto) {

      if (this.recorrenteLink.tpMulta === 'ISENTO') {
        this.valorMulta = '0,00';
        this.recorrenteLink.vlrMulta = 0.00;
      } else {
        if (this.valorMulta === undefined || this.valorMulta === null) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Valor Multa Inválido!',
            detail: 'Informe o valor da Multa.'
          });
          this.valorMulta = '0,00';
          this.recorrenteLink.vlrMulta = 0.00;
          this.blocked = false;
          return;
        }
        let cValorMulta = 0;
        for (const c of this.valorMulta.toString().split('')) {
          if (c === ',') {
            cValorMulta++;
          }
          if (cValorMulta > 1) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Valor Multa Inválido!',
              detail: 'Apenas um vírgula é permitida.'
            });
            this.valorMulta = '0,00';
            this.recorrenteLink.vlrMulta = 0.00;
            this.blocked = false;
            return;
          }
        }
        this.recorrenteLink.vlrMulta = Number(this.valorMulta.toString().replace(',', '.'));
        if (this.recorrenteLink.vlrMulta <= 0.00) {
          const tMulta = this.recorrenteLink.tpMulta === 'VALOR' ? 'valor' : "percentual";
          this.messageService.add({
            severity: 'warn',
            summary: 'Valor Multa Inválido!',
            detail: `Informe o ${tMulta} da Multa.`
          });
          this.valorMulta = '0,00';
          this.recorrenteLink.vlrMulta = 0.00;
          this.blocked = false;
          return;
        }
      }

      if (this.recorrenteLink.tpJuros === 'ISENTO') {
        this.valorJuros = '0,00';
        this.recorrenteLink.vlrJuros = 0.00;
      } else {
        if (this.valorJuros === undefined || this.valorJuros === null) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Valor Juros Inválido!',
            detail: 'Informe o valor do Juros.'
          });
          this.valorJuros = '0,00';
          this.recorrenteLink.vlrJuros = 0.00;
          this.blocked = false;
          return;
        }
        let cValorJuros = 0;
        for (const c of this.valorJuros.toString().split('')) {
          if (c === ',') {
            cValorJuros++;
          }
          if (cValorJuros > 1) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Valor Juros Inválido!',
              detail: 'Apenas um vírgula é permitida.'
            });
            this.valorJuros = '0,00';
            this.recorrenteLink.vlrJuros = 0.00;
            this.blocked = false;
            return;
          }
        }
        this.recorrenteLink.vlrJuros = Number(this.valorJuros.toString().replace(',', '.'));
        if (this.recorrenteLink.vlrJuros <= 0.00) {
          const tJuros = this.recorrenteLink.tpJuros === 'VALOR_DIA' ? 'valor' : "percentual";
          this.messageService.add({
            severity: 'warn',
            summary: 'Valor Juros Inválido!',
            detail: `Informe o ${tJuros} do Juros.`
          });
          this.valorJuros = '0,00';
          this.recorrenteLink.vlrJuros = 0.00;
          this.blocked = false;
          return;
        }
      }

    } else {
      this.recorrenteLink.tpMulta = 'ISENTO';
      this.recorrenteLink.vlrMulta = 0.00;
      this.recorrenteLink.tpJuros = 'ISENTO';
      this.recorrenteLink.vlrJuros = 0.00;
    }

    console.log("Salvar link:");
    console.log(this.recorrenteLink);
    // Cria ou atualiza
    if (this.recorrenteLink.id) {
      this.atualizar();
    } else {
      this.criar();
    }

  }

  criar() {
    this.paymentLinkService.criar(this.recorrenteLink).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso!',
          detail: 'Link de pagamento criado com sucesso.'
        });
        this.paymentLinkService.linkEvent.emit();
        this.blocked = false;
      },
      error => {
        console.log('Error :', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao criar link de pagamento!',
          detail: 'Erro ao criar link de pagamento.'
        });
        this.descricaoField.nativeElement.focus();
        this.blocked = false;
      }
    );
  }

  atualizar() {
    this.paymentLinkService.atualizar(this.recorrenteLink).subscribe(
      data => {
        this.messageService.add({
          severity: 'success',
          summary: 'Sucesso!',
          detail: 'Link de pagamento atualizado com sucesso.'
        });
        this.paymentLinkService.linkEvent.emit();
        this.blocked = false;
      },
      error => {
        console.log('Error:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao atualizar conta!',
          detail: 'Erro ao atualizar conta.'
        });
        this.blocked = false;
      }
    );
  }

  copyLink() {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.recorrenteLink.link);
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);

    this.messageService.add({
      severity: 'success',
      summary: 'Link copiado com sucesso!',
      detail: 'Link copiado para área de transferência.'
    });
  }

  copyLinkReduzido() {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', this.getMelhorLink(this.recorrenteLink));
      e.preventDefault();
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);

    this.messageService.add({
      severity: 'success',
      summary: 'Link copiado com sucesso!',
      detail: 'Link copiado para área de transferência.'
    });
  }

  changeValor() {
    console.log('changeValor');
    let valorSplit = this.valor.split(',');
    if (this.valor === undefined || this.valor === null || valorSplit.length > 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço inválido.'
      });
      this.precoField.nativeElement.focus();
      return;
    }
    if (valorSplit.length === 2 && valorSplit[1].length > 2) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço com mais de 2 digitos decimais.'
      });
      this.precoField.nativeElement.focus();
      return;
    }
    this.recorrenteLink.valorUnitario = parseFloat(this.valor.replace(',', '.'));
    if (this.recorrenteLink.valorUnitario === undefined || this.recorrenteLink.valorUnitario === null || isNaN(this.recorrenteLink.valorUnitario)) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Atenção!',
        detail: 'Preço inválido.'
      });
      this.precoField.nativeElement.focus();
      return;
    }
  }

  selectTpMulta() {
    if (this.recorrenteLink.tpMulta === 'ISENTO') {
      this.valorMulta = '0,00';
      this.recorrenteLink.vlrMulta = 0.00;
    }
  }

  selectTpJuros() {
    if (this.recorrenteLink.tpJuros === 'ISENTO') {
      this.valorJuros = '0,00';
      this.recorrenteLink.vlrJuros = 0.00;
    }
  }

  selectAtivarBoleto() {
    console.log('this.recorrenteLink.ativarBoleto', this.recorrenteLink.ativarBoleto);
    if (!this.recorrenteLink.ativarBoleto) {
      this.recorrenteLink.tpMulta = 'ISENTO';
      this.recorrenteLink.vlrMulta = 0.00;
      this.recorrenteLink.tpJuros = 'ISENTO';
      this.recorrenteLink.vlrJuros = 0.00;
      this.valorMulta = '0,00';
      this.valorJuros = '0,00';
    }
  }

  getMelhorLink(recorrenteLink: RecorrenteLink) {
    return recorrenteLink.linkReduzido ? recorrenteLink.linkReduzido : recorrenteLink.link;
  }

}
