<div class="pgw--container">

  <div class="ui-g">

    <div class="ui-g-12">
      <h1>Recorrência</h1>
    </div>

    <div class="ui-g-12 ui-g-nopad">

      <div class="ui-g-12 ui-fluid" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
        <p-dropdown [options]="empresasOptions" [(ngModel)]="recorrenciaFilter.empresaId" (onChange)="buscarLicencas()"
          filter="true" resetFilterOnHide="true">
        </p-dropdown>
      </div>

    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-7 ui-fluid">
      <p-dropdown [options]="licencasOptions" [(ngModel)]="recorrenciaFilter.licencaId" pTooltip="Contratos"
        tooltipPosition="top">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-6 ui-lg-2 ui-fluid">
      <p-inputMask [(ngModel)]="recorrenciaFilter.cpf" mask="999.999.999-99" pTooltip="CPF" tooltipPosition="top"
        placeholder="CPF">
      </p-inputMask>
    </div>

    <div class="ui-g-11 ui-md-5 ui-lg-2 ui-fluid">
      <input pInputText [(ngModel)]="recorrenciaFilter.pedidoNumero" placeholder="Pedido" pTooltip="Número do Pedido"
        tooltipPosition="top" />
    </div>

    <div class="ui-g-1 ui-md-1 ui-lg-1">
      <button pButton type="button" icon="pi pi-search" (click)="buscar()"></button>
    </div>

    <div class="ui-g-12 ui-fluid">
      <p-table [value]="recorrenciasDTO" [responsive]="true" selectionMode="single"
        (onRowSelect)="onRowSelect($event.data)">
        <ng-template pTemplate="caption">
          Recorrências encontradas: {{ recorrenciasDTO.length }}
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 110px;">Adesão</th>
            <th style="width: 70px;">Tipo</th>
            <th style="width: 90px;">Pedido</th>
            <th class="col-left">Comprador</th>
            <th style="width: 110px;" class="col-right">Valor</th>
            <th style="width: 70px;">Status</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-recorrenciaDTO>
          <tr [pSelectableRow]="recorrenciaDTO">
            <td class="col-center">{{ recorrenciaDTO.dtHr | date:'dd/MM/yyyy' }}</td>
            <td class="col-center">
              <i *ngIf="recorrenciaDTO.formaPagamento === 'BOLETO'" class="fas fa-barcode"
                style="font-size: 1.6rem; color: #000000;" pTooltip="BOLETO" tooltipPosition="top"></i>
              <i *ngIf="recorrenciaDTO.formaPagamento === 'CARTÃO'" class="fas fa-credit-card"
                style="font-size: 1.6rem; color: #007ad9;" pTooltip="CARTÃO" tooltipPosition="top"></i>
            </td>
            <td class="col-center">{{ recorrenciaDTO.pedidoNumero }}
              <span class="pgw--ref">
                {{ recorrenciaDTO.periodicidade }}
              </span>
            </td>
            <td class="col-left">{{ recorrenciaDTO.comprador.nome }} {{ recorrenciaDTO.comprador.sobreNome }}
              <span class="pgw--ref">
                {{ recorrenciaDTO.comprador.tipoDoc }} {{ recorrenciaDTO.comprador.numeroDoc }}
              </span>
            </td>
            <td class="col-right">{{ recorrenciaDTO.valor | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
            <td class="col-center">
              <i *ngIf="recorrenciaDTO.status === 'ATIVO'" class="fas fa-check"
                style="font-size: 1.6rem; color: #107d11;" pTooltip="ATIVO" tooltipPosition="top"></i>
              <i *ngIf="recorrenciaDTO.status === 'CANCELADO'" class="fas fa-times"
                style="font-size: 1.6rem; color: #c01120;" pTooltip="CANCELADO" tooltipPosition="top"></i>
            </td>
          </tr>
        </ng-template>

      </p-table>
    </div>

  </div>

  <nav *ngIf="exibirRecorrencia">
    <p-sidebar [(visible)]="exibirRecorrencia" [fullScreen]="true" [baseZIndex]="9000">
      <app-recorrente-detalhe [recorrencia]="recorrencia" (onClose)="exibirRecorrencia = false"
        (onCancel)="onUpdateCancel($event)" (onShowPedido)="onShowPedido($event)"
        (onRecorrenciaBlocked)="onRecorrenciaBlocked($event)">
      </app-recorrente-detalhe>
    </p-sidebar>
  </nav>

  <nav *ngIf="exibirPedido">
    <p-sidebar [(visible)]="exibirPedido" position="left" styleClass="ui-sidebar-md" [baseZIndex]="9001">
      <app-pedido-detalhe origem="PEDIDO" [pedido]="pedidoSelecionado" [licenca]="licencaSelecionada">
      </app-pedido-detalhe>
    </p-sidebar>
  </nav>

</div>


<p-blockUI [blocked]="blocked" [baseZIndex]="10000">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>
