<div class="pgw--container">
  <form (ngSubmit)="salvar()">

    <div class="ui-g">

      <div class="ui-g-12">
        <h1>Cadastro da Empresa</h1>
      </div>

      <div class="ui-g-12 ui-md-8 ui-lg-8 ui-fluid">
        <label for="razaoSocial">Razão Social</label>
        <input pInputText id="razaoSocial" name="razaoSocial" [(ngModel)]="empresa.razaoSocial" />
      </div>

      <div class="ui-g-12 ui-md-4 ui-lg-4 ui-fluid">
        <label for="nomeFantasia">Nome Fantasia</label>
        <input pInputText id="nomeFantasia" name="nomeFantasia" [(ngModel)]="empresa.nomeFantasia" />
      </div>

      <div class="ui-g-12 ui-md-4 ui-lg-4 ui-fluid">
        <label for="cnpj">CNPJ</label>
        <input pInputText id="cnpj" name="cnpj" [(ngModel)]="empresa.cnpj" />
      </div>

      <div class="ui-g-12 ui-md-4 ui-lg-4 ui-fluid">
        <label for="email">E-mail</label>
        <input pInputText id="email" name="email" [(ngModel)]=empresa.email />
      </div>

      <div class="ui-g-12 ui-md-1 ui-lg-1 ui-fluid">
        <label for="ddd">DDD</label>
        <input pInputText id="ddd" name="ddd" [(ngModel)]="empresa.ddd" />
      </div>

      <div class="ui-g-12 ui-md-3 ui-lg-3 ui-fluid">
        <label for="telefone">Telefone</label>
        <input pInputText id="telefone" name="telefone" [(ngModel)]="empresa.telefone" />
      </div>

      <div class="ui-g-12">
        <h4>Endereço</h4>
      </div>

      <div class="ui-g-12 ui-md-2 ui-lg-2 ui-fluid">
        <label for="cep">CEP</label>
        <input pInputText id="cep" name="cep" [(ngModel)]="empresa.cep" />
      </div>

      <div class="ui-g-12 ui-md-8 ui-lg-8 ui-fluid">
        <label for="logradouro">Logradouro</label>
        <input pInputText id="logradouro" name="logradouro" [(ngModel)]="empresa.logradouro" />
      </div>

      <div class="ui-g-12 ui-md-2 ui-lg-2 ui-fluid">
        <label for="numero">Número</label>
        <input pInputText id="numero" name="numero" [(ngModel)]="empresa.numero" />
      </div>

      <div class="ui-g-12 ui-md-3 ui-lg-3 ui-fluid">
        <label for="complemento">Complemento</label>
        <input pInputText id="complemento" name="complemento" [(ngModel)]="empresa.complemento" />
      </div>

      <div class="ui-g-12 ui-md-3 ui-lg-3 ui-fluid">
        <label for="bairro">Bairro</label>
        <input pInputText id="bairro" name="bairro" [(ngModel)]="empresa.bairro" />
      </div>

      <div class="ui-g-12 ui-md-3 ui-lg-3 ui-fluid">
        <label for="cidade">Cidade</label>
        <input pInputText id="cidade" name="cidade" [(ngModel)]="empresa.cidade" />
      </div>

      <div class="ui-g-12 ui-md-1 ui-lg-1 ui-fluid">
        <label for="uf">UF</label>
        <input pInputText id="uf" name="uf" [(ngModel)]="empresa.uf" />
      </div>

      <div class="ui-g-12 ui-md-2 ui-lg-2 ui-fluid">
        <label for="pais">País</label>
        <input pInputText id="pais" name="pais" [(ngModel)]="empresa.pais" />
      </div>

      <div class="ui-g-12">
        <p-button type="submit" label="Salvar" icon="pi pi-save"></p-button>
      </div>

    </div>

  </form>

</div>
