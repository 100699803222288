<form (ngSubmit)="salvar()">

  <div class="ui-g">

    <div class="ui-g-12">
      <h1>Link de Pagamento Recorrente</h1>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="licencaId">Informe o contrato</label>
      <p-dropdown id="licencaId" name="licencaId" [options]="licencasOptions" [(ngModel)]="recorrenteLink.licencaId" [disabled]="editLock"
        #linkContrato></p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="descricao">O que deseja vender?</label>
      <textarea pInputTextarea id="descricao" name="descricao" [rows]="4" [(ngModel)]="recorrenteLink.descricao"
        maxlength="100" [disabled]="editLock" #linkDescricao>
          </textarea>
      <span class="char-limit">{{ recorrenteLink.descricao.length }} / 100</span>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="preco">Qual o preço?</label>
      <input pInputText id="preco" name="preco" [(ngModel)]="valor" maxlength="20" [pKeyFilter]="regExValor"
        (blur)="changeValor()" onFocus="this.select();" [disabled]="editLock" #linkPreco>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="periodicidade">Qual a periodicidade da recorrência?</label>
      <p-dropdown id="periodicidade" name="periodicidade" [options]="periodicidadeOptions"
        [(ngModel)]="recorrenteLink.periodicidade" [disabled]="editLock">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="ativarBoleto">Permite receber com boleto?</label>
      <p-inputSwitch id="ativarBoleto" name="ativarBoleto" [(ngModel)]="recorrenteLink.ativarBoleto"
        (onChange)="selectAtivarBoleto()" [disabled]="editLock"></p-inputSwitch>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid" *ngIf="recorrenteLink.ativarBoleto">
      <label for="preco">Em quantos dias vence o boleto?</label>
      <input pInputText id="diasVenctoBoleto" name="diasVenctoBoleto" [(ngModel)]="recorrenteLink.diasVenctoBoleto"
        maxlength="2" pKeyFilter="pint" [disabled]="editLock" #diasVenctoBoleto>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid" *ngIf="recorrenteLink.ativarBoleto">
      <label for="tpMulta">Tipo de Multa *</label>
      <p-dropdown id="tpMulta" name="tpMulta" [options]="tpMultaOptions" [(ngModel)]="recorrenteLink.tpMulta"
        (onChange)="selectTpMulta()" [disabled]="editLock">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid"
      *ngIf="recorrenteLink.ativarBoleto && recorrenteLink.tpMulta !== '3'">
      <label for="valorMulta">Valor/Percentual de Multa</label>
      <input pInputText id="valorMulta" name="valorMulta" [(ngModel)]="valorMulta" maxlength="8"
        [pKeyFilter]="regExValor" placeholder="0,00" onFocus="this.select();" [disabled]="editLock">
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid" *ngIf="recorrenteLink.ativarBoleto">
      <label for="tpJuros">Tipo de Juros *</label>
      <p-dropdown id="tpJuros" name="tpJuros" [options]="tpJurosOptions" [(ngModel)]="recorrenteLink.tpJuros"
        (onChange)="selectTpJuros()" [disabled]="editLock">
      </p-dropdown>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid"
      *ngIf="recorrenteLink.ativarBoleto && recorrenteLink.tpJuros !== '5'">
      <label for="valorJuros">Valor/Percentual de Juros</label>
      <input pInputText id="valorJuros" name="valorJuros" [(ngModel)]="valorJuros" maxlength="8"
        [pKeyFilter]="regExValor" placeholder="0,00" onFocus="this.select();" [disabled]="editLock">
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="ativarCartao">Permite receber com cartão?</label>
      <p-inputSwitch id="ativarCartao" name="ativarCartao" [(ngModel)]="recorrenteLink.ativarCartao" [disabled]="editLock"></p-inputSwitch>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="ativo">Link ativo?</label>
      <p-inputSwitch id="ativo" name="ativo" [(ngModel)]="recorrenteLink.ativo" [disabled]="editLock"></p-inputSwitch>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12" *ngIf="getMelhorLink(recorrenteLink)">
      <label for="linkReduzido">Link:</label>
      <span class="paymentLink" id="linkReduzido">{{ getMelhorLink(recorrenteLink) }}</span>
      <button pButton type="button" label="copiar" icon="far fa-copy" class="ui-button-rounded ui-button-success"
        (click)="copyLinkReduzido()"></button>
    </div>

    <div class="ui-g-12">
      <p-button type="submit" label="Salvar" icon="pi pi-save" [disabled]="editLock"></p-button>
    </div>

  </div>

</form>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>
