<div class="ui-g">

  <div class="ui-g-12">
    <button pButton label="Criar Conta" icon="pi pi-plus" class="ui-button-success" (click)="criar()" *ngIf="hasPermission('CONFIG_CONTA_CRIAR')"></button>
  </div>

  <div class="ui-g-12 ui-fluid" *ngIf="hasPermission('CONFIG_CONTA_CONSULTAR')">
    <p-table [value]="contas" [paginator]="true" [rows]="10" [responsive]="true" selectionMode="single"
      (onRowSelect)="onRowSelect($event.data)">
      <ng-template pTemplate="header">
        <tr>
          <th>Banco</th>
          <th>Agência</th>
          <th>Conta</th>
          <th style="width: 70px;" *ngIf="hasPermission('CONFIG_CONTA_EXCLUIR')">
            Excluir
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-conta>
        <tr [pSelectableRow]="conta">
          <td class="col-center">{{ conta.banco }}</td>
          <td class="col-center">{{ conta.agencia }}</td>
          <td class="col-center">{{ conta.conta }}</td>
          <td class="col-center" *ngIf="hasPermission('CONFIG_CONTA_EXCLUIR')">
            <p-button icon="pi pi-trash" (click)="excluir(conta.id)" title="Excluir Conta"></p-button>
          </td>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="4">
            Nenhuma conta cadastrada!
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

</div>

<nav *ngIf="exibir">
  <p-sidebar [(visible)]="exibir" position="left" styleClass="ui-sidebar-md">
    <app-conta-cadastro [conta]="conta"></app-conta-cadastro>
  </p-sidebar>
</nav>
