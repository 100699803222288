import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.css']
})
export class RecuperarSenhaComponent implements OnInit {

  email: string;

  blocked: boolean;

  @ViewChild('emailField', { static: true }) emailField: ElementRef;

  constructor(
    private authService: AuthService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.blocked = false;
    this.authService.recuperarSenhaFocusEvent.subscribe(() => this.emailField.nativeElement.focus());
  }

  recuperarSenha() {
    this.blocked = true;

    if (this.email === undefined || this.email.length < 4) {
      this.messageService.add({
        severity: 'error',
        summary: 'Digite seu E-mail!',
        detail: 'Digite seu E-mail.'
      });
      this.email = undefined;
      this.emailField.nativeElement.focus();
      this.blocked = false;
      return;
    }

    this.authService.recuperarSenha(this.email).subscribe(
      () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Senha recuperada com sucesso!',
          detail: 'Uma senha temporária foi enviada para o seu e-mail.'
        });
        this.email = undefined;
        this.authService.senhaRecuperadaEvent.emit();
        this.blocked = false;
      },
      error => {
        console.log('error:', error);
        localStorage.removeItem('access_token');
        this.authService.setJwtPayload(undefined);
        this.messageService.add({
          severity: 'error',
          summary: 'Falha ao recuperar senha!',
          detail: 'Usuário inexistente.'
        });
        this.email = undefined;
        this.emailField.nativeElement.focus();
        this.blocked = false;
      }
    );
  }

}
