import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Licenca } from './licenca.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LicencaService {

  licencaEvent = new EventEmitter();

  constructor(private http: HttpClient) { }

  criar(licenca: Licenca): Observable<Licenca> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<Licenca>(`${environment.apiUrl}/licencas`, licenca, { headers });
  }

  atualizar(licenca: Licenca): Observable<Licenca> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.http.put<Licenca>(`${environment.apiUrl}/licencas`, licenca, { headers });
  }

  excluir(id: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/licencas/${id}`);
  }

  buscarPorEmpresa(empresaId: string): Observable<Licenca[]> {
    return this.http.get<Licenca[]>(`${environment.apiUrl}/licencas/empresa/${empresaId}`);
  }
  
  buscarPorId(id: string): Observable<Licenca> {
    return this.http.get<Licenca>(`${environment.apiUrl}/licencas/${id}`);
  }

}
