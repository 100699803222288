import { Component, OnInit } from '@angular/core';

import * as Highcharts from 'highcharts';

import { LicencaService } from 'src/app/licenca/licenca.service';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { AuthService } from 'src/app/seguranca/auth.service';
import { SaqueFilter } from 'src/app/saque/saque-filter';
import { SaqueService } from 'src/app/saque/saque.service';
import { Licenca } from 'src/app/licenca/licenca.model';

declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-estatistica-depositos',
  templateUrl: './estatistica-depositos.component.html',
  styleUrls: ['./estatistica-depositos.component.css']
})
export class EstatisticaDepositosComponent implements OnInit {

  jwtPayload: any;
  empresasOptions: any[];
  licencasOptions: any[];
  licencas: Licenca[];
  saqueFilter: SaqueFilter;

  public chartDiaOptions: any = {
    title: {
      text: 'Dia do Mês',
      x: 0 // center
    },
    subtitle: {
      text: 'Monetário',
      x: 0
    },
    credits: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e %b'
      }
    },
    yAxis: {
      title: {
        text: 'Valor'
      },
      min: 0,
      plotLines: [{
        value: 0,
        width: 1,
        color: '#808080'
      }]
    },
    tooltip: {
      valuePrefix: '$'
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      borderWidth: 0
    },
    series: [
      {
        name: 'Liquidado',
        data: [],
        pointInterval: 24 * 3600 * 1000 // one day
      }
    ]
  };

  public chartSemanaOptions: any = {
    chart: {
      type: 'column'
    },
    title: {
      text: 'Dia da Semana',
      x: 0
    },
    subtitle: {
      text: 'Monetário',
      x: 0
    },
    credits: {
      enabled: false
    },
    xAxis: {
      categories: [
        'Domingo',
        'Segunda Feira',
        'Terça Feira',
        'Quarta Feira',
        'Quinta Feira',
        'Sexta Feira',
        'Sábado'
      ]
    },
    yAxis: {
      title: {
        text: 'Valor'
      },
      min: 0,
      plotLines: [{
        value: 0,
        width: 1,
        color: '#808080'
      }]
    },
    tooltip: {
      valuePrefix: '$'
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      borderWidth: 0
    },
    series: [
      { name: 'Depósitos', data: [] }
    ]
  };

  constructor(
    private authService: AuthService,
    private empresaService: EmpresaService,
    private licencaService: LicencaService,
    private saqueService: SaqueService
  ) { }

  ngOnInit() {
    this.jwtPayload = this.authService.getJwtPayload();

    this.saqueFilter = new SaqueFilter(this.jwtPayload.empresaId);
    this.saqueFilter.rangeType = 'dtHrLiquidacao';
    this.saqueFilter.sort = 'ASC';
    this.saqueFilter.moeda = undefined;

    this.empresaService.getEmpresas().subscribe(
      empresas => {
        this.empresasOptions = [];
        empresas.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );

  }

  buscarLicencas() {
    this.saqueFilter.licencaId = undefined;
    this.licencaService.buscarPorEmpresa(this.saqueFilter.empresaId).subscribe(
      licencas => {
        this.licencas = licencas;
        this.licencasOptions = [];
        licencas.forEach(licenca => {
          this.licencasOptions.push(
            { label: licenca.contrato, value: licenca.id }
          );
          if (this.saqueFilter.licencaId === undefined) {
            this.saqueFilter.licencaId = licenca.id;
          }
        });
      },
      err => console.log('Error:', err)
    );
  }

  buscar() {
    this.licencas.forEach(
      licenca => {
        if (licenca.id === this.saqueFilter.licencaId) {
          this.saqueFilter.moeda = licenca.moeda;
        }
      }
    );

    this.saqueService.getDepositosDia(this.saqueFilter).subscribe(
      result => {
        console.log('result', result);
        this.chartDiaOptions.series[0].data = [];
        this.chartSemanaOptions.series[0].data = [];
        if (result.length > 0) {
          this.chartDiaOptions.tooltip.valuePrefix = this.saqueFilter.moeda === 'BRL' ? 'R$ ' : '$';
          this.chartSemanaOptions.tooltip.valuePrefix = this.saqueFilter.moeda === 'BRL' ? 'R$ ' : '$';
          this.chartSemanaOptions.series[0].data = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00];
          const d: number = parseInt(result[0].dt.substring(8), 10);
          const m: number = parseInt(result[0].dt.substring(5, 7), 10);
          const a: number = parseInt(result[0].dt.substring(0, 4), 10);
          const dtInit = Date.UTC(a, m - 1, d);
          this.chartDiaOptions.series[0].pointStart = dtInit;
          for (const value of result) {
            let dt = new Date(value.dt);
            console.log(dt.getDay(), value.dt, dt);
            this.chartSemanaOptions.series[0].data[dt.getUTCDay()] += value.totalLiquidado;
            this.chartDiaOptions.series[0].data.push(value.totalLiquidado);
          }
          console.log('week', this.chartSemanaOptions.series[0].data);
        }
        Highcharts.chart('chart-depositos-dia', this.chartDiaOptions);
        Highcharts.chart('chart-depositos-semana', this.chartSemanaOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

}
