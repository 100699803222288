import { Component, OnInit } from '@angular/core';

import { Licenca } from '../licenca.model';
import { AuthService } from 'src/app/seguranca/auth.service';
import { LicencaService } from '../licenca.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-licenca-consulta',
  templateUrl: './licenca-consulta.component.html',
  styleUrls: ['./licenca-consulta.component.css']
})
export class LicencaConsultaComponent implements OnInit {

  jwtPayload: any;
  exibir = false;
  licenca: Licenca;
  licencas: Licenca[] = [];

  constructor(
    private licencaService: LicencaService,
    private messageService: MessageService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.jwtPayload = this.authService.getJwtPayload();
    this.buscar();
    this.licencaService.licencaEvent.subscribe(
      () => {
        this.buscar();
        this.exibir = false;
      }
    );
  }

  criar() {
    this.licenca = new Licenca(this.jwtPayload.empresaId);
    this.exibir = true;
  }

  excluir(id: string) {
    this.licencaService.excluir(id).subscribe(
      () => {
        this.buscar();
      },
      error => {
        console.log('Error:', error);
        this.licencas = [];
      }
    );
  }

  onRowSelect(licenca) {
    if (this.hasPermission('CONFIG_TERMINAL_EDITAR')) {
      this.licenca = licenca;
      this.exibir = true;
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Permissão Negada!',
        detail: 'Você não possui permissão para editar Terminais Virtuais.'
      });
    }
  }

  buscar() {
    this.licencaService.buscarPorEmpresa(this.jwtPayload.empresaId).subscribe(
      result => this.licencas = result,
      err => console.log('Error:', err)
    );
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

}
