import { Component, OnInit } from '@angular/core';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { LicencaService } from 'src/app/licenca/licenca.service';
import { PedidoFilter } from 'src/app/pedido/pedido-filter';
import { PedidoService } from 'src/app/pedido/pedido.service';
import { BoletoDTO } from 'src/app/boleto/boleto-dto';
import { Licenca } from 'src/app/licenca/licenca.model';
import { AuthService } from 'src/app/seguranca/auth.service';
import { MessageService } from 'primeng/api';
import { Pedido } from 'src/app/pedido/pedido.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-boleto-consulta',
  templateUrl: './boleto-consulta.component.html',
  styleUrls: ['./boleto-consulta.component.css']
})
export class BoletoConsultaComponent implements OnInit {

  jwtPayload: any;
  exibirPedido = false;
  exibirDependentes = false;
  pedidoSelecionado: Pedido;
  licencaSelecionada: Licenca;
  empresasOptions: any[];
  licencasOptions: any[];
  licencas: Licenca[];

  pedidoFilter: PedidoFilter;
  boletoDTO: BoletoDTO;
  boletosDTO: BoletoDTO[] = [];

  resumo: any;

  csvQuery: string;

  sort: string;

  blocked = true;

  constructor(
    private messageService: MessageService,
    private empresaService: EmpresaService,
    private licencaService: LicencaService,
    private pedidoService: PedidoService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.jwtPayload = this.authService.getJwtPayload();

    this.pedidoFilter = new PedidoFilter(this.jwtPayload.empresaId);
    this.pedidoFilter.selectedFormasPagto.push('BOLETO');

    this.empresaService.getEmpresas().subscribe(
      result => {
        this.empresasOptions = [];
        result.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );
  }

  buscarLicencas() {
    this.resumo = {
      qtd: 0,
      total: 0,
      totalPago: 0,
      formas: []
    }
    this.pedidoFilter.licencaId = undefined;
    this.boletosDTO = [];
    let moedas = [];
    this.licencaService.buscarPorEmpresa(this.pedidoFilter.empresaId).subscribe(
      result => {
        this.licencas = result;
        this.licencasOptions = [];

        result.forEach(licenca => {
          this.licencasOptions.push(
            { label: `${licenca.contrato} [${licenca.moeda}]`, value: licenca.id, moeda: licenca.moeda }
          );
          if (!moedas.includes(licenca.moeda)) {
            moedas.push(licenca.moeda);
          }
          if (this.pedidoFilter.licencaId === undefined) {
            this.pedidoFilter.licencaId = licenca.id;
            this.pedidoFilter.moeda = licenca.moeda;
          }
        });
      },
      err => {
        console.log('Error:', err);
        this.blocked = false;
      },
      () => {
        if (this.licencasOptions.length > 1) {
          if (moedas.length > 1) {
            moedas.forEach(moeda => {
              this.licencasOptions.push(
                { label: `Todos - ${moeda}`, value: moeda }
              );
            });
          } else {
            this.licencasOptions.push(
              { label: 'Todos', value: moedas[0] }
            );
          }
        }

        this.blocked = false;
        // this.pedidoFilter.initDtVenctos();
        // this.buscar();
      }
    );
  }

  selecionarLicenca() {
    this.licencas.forEach(licenca => {
      if (this.pedidoFilter.licencaId && this.pedidoFilter.licencaId.length === 3) {
        this.pedidoFilter.moeda = this.pedidoFilter.licencaId;
      } else if (this.pedidoFilter.licencaId === licenca.id) {
        this.pedidoFilter.moeda = licenca.moeda;
      }
    });
  }

  buscar() {
    this.blocked = true;
    if (this.pedidoFilter.licencaId.length === 3) {
      this.pedidoFilter.licencaId = undefined;
    }
    this.csvQuery = `${environment.apiUrl}/public/boletos/csv?${this.pedidoFilter.getSearchParams()}`;
    this.sort = this.pedidoFilter.tpPeriodo;
    console.log('pedidoFilter', this.pedidoFilter);
    console.log('csvQuery', this.csvQuery);
    this.boletosDTO = [];
    this.pedidoService.getBoletosDTO(this.pedidoFilter).subscribe(
      boletosDTO => {
        this.boletosDTO = boletosDTO;
        console.log('BoletosDTO', boletosDTO);
        if (!this.pedidoFilter.licencaId) {
          if (this.pedidoFilter.moeda) {
            this.pedidoFilter.licencaId = this.pedidoFilter.moeda;
          } else if (this.licencas && this.licencas[0] && this.licencas[0].id) {
            this.pedidoFilter.licencaId = this.licencas[0].id;
          }
        }
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.pedidoFilter.clear();

        this.resumo = {
          qtd: 0,
          total: 0,
          totalPago: 0,
          formas: []
        }
        for (const p of this.boletosDTO) {
          let forma = undefined;
          for (const f of this.resumo.formas) {
            if (f.forma === p.pagseguroStatus) {
              forma = f;
              break;
            }
          }
          if (forma) {
            forma.qtd++;
            forma.total += p.totalPedido;
            forma.totalPago += p.totalPago;
          } else {
            this.resumo.formas.push({
              forma: p.pagseguroStatus,
              qtd: 1,
              total: p.totalPedido,
              totalPago: p.totalPago
            });
          }
          this.resumo.qtd++;
          this.resumo.total += p.totalPedido;
          this.resumo.totalPago += p.totalPago;
        }
        if (this.sort === 'E') {
          this.sortEmissao();
        }
        if (this.sort === 'P') {
          this.sortPagto();
        }
        this.blocked = false;
      }
    );
  }

  onRowSelect(boletoDTO: BoletoDTO) {
    this.exibirDependentes = false;
    if (this.hasPermission('PEDIDO_VISUALIZAR')) {
      this.pedidoService.getPedido(boletoDTO.id).subscribe(
        pedido => {
          this.pedidoSelecionado = pedido;
          console.log('PedidoSelecionado', pedido);
          for (const l of this.licencas) {
            if (l.id === pedido.licencaId) {
              this.licencaSelecionada = l;
              break;
            }
          }
          this.exibirPedido = true;
        }
      );
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Permissão Negada!',
        detail: 'Você não possui permissão para visualizar detalhes do pedido.'
      });
    }
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

  sortEmissao() {
    this.blocked = true;
    this.sort = 'E';
    this.boletosDTO.sort(function (b1, b2) {
      if (b1.dtHr > b2.dtHr) {
        return 1;
      }
      if (b1.dtHr < b2.dtHr) {
        return -1;
      }
      if (b1.pedidoNumero > b2.pedidoNumero) {
        return 1;
      }
      if (b1.pedidoNumero < b2.pedidoNumero) {
        return -1;
      }
      return 0;
    });
    this.blocked = false;
  }

  sortVencto() {
    this.blocked = true;
    this.sort = 'V';
    this.boletosDTO.sort(function (b1, b2) {
      if (b1.dtVencto > b2.dtVencto) {
        return 1;
      }
      if (b1.dtVencto < b2.dtVencto) {
        return -1;
      }
      if (b1.pedidoNumero > b2.pedidoNumero) {
        return 1;
      }
      if (b1.pedidoNumero < b2.pedidoNumero) {
        return -1;
      }
      return 0;
    });
    this.blocked = false;
  }

  sortPagto() {
    this.blocked = true;
    this.sort = 'P';
    const semData = this.boletosDTO.filter(b => !b.dtPagto).sort(function (b1, b2) {
      if (b1.pedidoNumero > b2.pedidoNumero) {
        return 1;
      }
      if (b1.pedidoNumero < b2.pedidoNumero) {
        return -1;
      }
      return 0;
    });

    const comData = this.boletosDTO.filter(b => b.dtPagto).sort(function (b1, b2) {
      if (b1.dtPagto > b2.dtPagto) {
        return 1;
      }
      if (b1.dtPagto < b2.dtPagto) {
        return -1;
      }
      if (b1.pedidoNumero > b2.pedidoNumero) {
        return 1;
      }
      if (b1.pedidoNumero < b2.pedidoNumero) {
        return -1;
      }
      return 0;
    });

    this.boletosDTO = [].concat(comData, semData);
    this.blocked = false;
  }

}
