import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BoletoService } from '../boleto.service';

@Component({
  selector: 'app-boleto-success',
  templateUrl: './boleto-success.component.html',
  styleUrls: ['./boleto-success.component.css']
})
export class BoletoSuccessComponent implements OnInit {

  data: any;

  constructor(
    private router: Router
  ) {
    const nav = this.router.getCurrentNavigation();
    this.data = nav.extras.state.data;
  }

  ngOnInit() {
    console.log('boleto sucess', this.data);
  }

  emitirBoletos() {
    this.router.navigate(['boletos', 'emitir']);
  }

}
