<form (ngSubmit)="salvar()">

  <div class="ui-g">

    <div class="ui-g-12">
      <h1>Grupo</h1>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="grupo">Grupo</label>
      <input pInputText id="grupo" name="grupo" [(ngModel)]="grupo.grupo" #grupoField/>
    </div>

    <div class="ui-g-12 ui-md-12 ui-lg-12 ui-fluid">
      <label for="nome">Permissões</label>
      <p-tree [value]="permissionsNode" selectionMode="checkbox" [(selection)]="selectedPermissionsNode" #permissoesField></p-tree>
    </div>

    <div class="ui-g-12">
      <p-button type="submit" label="Salvar" icon="pi pi-save" ></p-button>
    </div>

  </div>

</form>

<p-blockUI [blocked]="blocked">
  <p-progressSpinner class="spinner-center">
  </p-progressSpinner>
</p-blockUI>
