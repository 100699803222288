import { Component, OnInit } from '@angular/core';

import { MessageService } from 'primeng/api';

import { EmpresaService } from 'src/app/empresa/empresa.service';
import { AuthService } from 'src/app/seguranca/auth.service';
import { LicencaService } from 'src/app/licenca/licenca.service';
import { Licenca } from 'src/app/licenca/licenca.model';
import { RecorrenteLink } from '../recorrente-link.model';
import { RecorrenteLinkFilter } from '../recorrente-link-filter';
import { RecorrenteLinkService } from '../recorrente-link.service';

@Component({
  selector: 'app-recorrente-link-consulta',
  templateUrl: './recorrente-link-consulta.component.html',
  styleUrls: ['./recorrente-link-consulta.component.css']
})
export class RecorrenteLinkConsultaComponent implements OnInit {

  jwtPayload: any;
  moeda: string;
  exibirLink: boolean;
  editLock: boolean;

  empresasOptions: any[];

  licencas: Licenca[];
  licencasOptions: any[];

  recorrenteLinkFilter: RecorrenteLinkFilter;
  recorrenteLink: RecorrenteLink;
  recorrenteLinks: RecorrenteLink[];

  exibirTutorial = false;

  constructor(
    private authservice: AuthService,
    private empresaService: EmpresaService,
    private licencaService: LicencaService,
    private recorrenteLinkService: RecorrenteLinkService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.editLock = false;
    this.jwtPayload = this.authservice.getJwtPayload();
    this.recorrenteLinkFilter = new RecorrenteLinkFilter(this.jwtPayload.empresaId);

    this.recorrenteLinkService.linkEvent.subscribe(
      () => {
        this.buscar();
        this.exibirLink = false;
      }
    );

    this.empresaService.getEmpresas().subscribe(
      result => {
        this.empresasOptions = [];
        result.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );
  }

  buscarLicencas() {
    this.recorrenteLinkFilter.licencaId = undefined;
    this.licencaService.buscarPorEmpresa(this.recorrenteLinkFilter.empresaId).subscribe(
      licencas => {
        this.licencas = licencas;
        this.licencasOptions = [];
        licencas.forEach(licenca => {
          if (licenca.moeda === 'BRL') {
            this.licencasOptions.push(
              { label: licenca.contrato, value: licenca.id }
            );
            if (this.recorrenteLinkFilter.licencaId === undefined) {
              this.recorrenteLinkFilter.licencaId = licenca.id;
              this.moeda = licenca.moeda;
            }
          }
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.buscar();
      }
    );
  }

  buscar() {
    this.recorrenteLinkService.buscar(this.recorrenteLinkFilter).subscribe(
      result => {
        this.recorrenteLinks = result;
        console.log('RecorrenteLinks', this.recorrenteLinks);
      },
      error => {
        console.log('Error:', error);
        this.recorrenteLinks = [];
        this.messageService.add({
          severity: 'error',
          summary: 'Erro ao consultar!',
          detail: 'Erro ao consultar links.'
        });
      }
    );
  }

  onRowSelect(recorrenteLink: RecorrenteLink) {
    this.recorrenteLink = recorrenteLink;
    this.exibirLink = true;
    this.editLock = true;
  }

  criar() {
    if (!this.licencasOptions || this.licencasOptions.length < 1) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Contrato(s) inativo(s)!',
        detail: 'Empresa sem contrato ativo em Real.'
      });
      return;
    }
    this.editLock = false;
    this.exibirLink = true;
    this.recorrenteLink = new RecorrenteLink(this.recorrenteLinkFilter.empresaId);
    this.recorrenteLink.licencaId = this.recorrenteLinkFilter.licencaId;
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

  copy(link: string) {
    if (link) {
      let listener = (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', link);
        e.preventDefault();
      };
      document.addEventListener('copy', listener);
      document.execCommand('copy');
      document.removeEventListener('copy', listener);

      this.messageService.add({
        severity: 'success',
        summary: 'Link copiado com sucesso!',
        detail: 'Link copiado para área de transferência.'
      });
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Falha ao copiar!',
        detail: 'Link não encontrado.'
      });
    }
  }

  getMelhorLink(recorrenteLink: RecorrenteLink) {
    return recorrenteLink.linkReduzido ? recorrenteLink.linkReduzido : recorrenteLink.link;
  }

}
