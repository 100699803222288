export class Grupo {

  id: string;
  empresaId: string;
  grupo: string;
  permissoes: string[];

  constructor(empresaId: string) {
    this.empresaId = empresaId;
    this.permissoes = [];
  }

}
