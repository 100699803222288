import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import * as Highcharts from 'highcharts';

import { LicencaService } from 'src/app/licenca/licenca.service';
import { EmpresaService } from 'src/app/empresa/empresa.service';
import { AuthService } from 'src/app/seguranca/auth.service';
import { EstatisticaService } from 'src/app/estatistica/estatistica.service';
import { Licenca } from 'src/app/licenca/licenca.model';
import { PedidoFilter } from 'src/app/pedido/pedido-filter';

declare var require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

@Component({
  selector: 'app-estatistica-produtos',
  templateUrl: './estatistica-produtos.component.html',
  styleUrls: ['./estatistica-produtos.component.css']
})
export class EstatisticaProdutosComponent implements OnInit {
  jwtPayload: any;
  empresasOptions: any[];
  licencasOptions: any[];
  licencas: Licenca[];
  pedidoFilter: PedidoFilter;
  altura = 250;

  public barProdutosMonetarioOptions: any = {
    chart: {
      type: 'bar',
      height: 250
    },
    title: {
      text: 'Vendas por produto',
      x: 0
    },
    subtitle: {
      text: 'Monetário',
      x: 0
    },
    xAxis: {
      categories: [],
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      labels: {
        overflow: 'justify'
      }
    },
    tooltip: {
      valuePrefix: '$'
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false
        }
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top', x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      shadow: true,
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Total',
      data: []
    }]
  };
  
  public barProdutosQuantitativoOptions: any = {
    chart: {
      type: 'bar',
      height: 250
    },
    title: {
      text: 'Vendas por produto',
      x: 0
    },
    subtitle: {
      text: 'Quantitativo',
      x: 0
    },
    xAxis: {
      categories: [],
      title: {
        text: null
      }
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      labels: {
        overflow: 'justify'
      }
    },
    tooltip: {
      valuePrefix: ''
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false
        }
      }
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top', x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      shadow: true,
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Quantidade',
      data: []
    }]
  };

  constructor(
    private authService: AuthService,
    private empresaService: EmpresaService,
    private licencaService: LicencaService,
    private estatisticaService: EstatisticaService
  ) { }

  ngOnInit() {
    this.jwtPayload = this.authService.getJwtPayload();

    this.pedidoFilter = new PedidoFilter(this.jwtPayload.empresaId);
    this.pedidoFilter.environment = 'production';
    this.pedidoFilter.selectedSituacoes = [0, 1, 2, 3, 4, 5, 8, 9, 10, 11, 13];
    this.pedidoFilter.moeda = undefined;
    this.pedidoFilter.limit = 5;


    this.empresaService.getEmpresas().subscribe(
      empresas => {
        this.empresasOptions = [];
        empresas.forEach(empresa => {
          let name = empresa.nomeFantasia;
          if (empresa.type === 'PF') {
            name = empresa.razaoSocial + ' ' + name;
          } else {
            name = empresa.razaoSocial + ' | ' + name;
          }
          this.empresasOptions.push(
            { label: name.toUpperCase(), value: empresa.id }
          );
        });
      },
      err => {
        console.log('Error:', err);
      },
      () => {
        this.empresasOptions.sort(function (a, b) {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });
        this.buscarLicencas();
      }
    );

  }

  buscarLicencas() {
    this.pedidoFilter.licencaId = undefined;
    this.licencaService.buscarPorEmpresa(this.pedidoFilter.empresaId).subscribe(
      licencas => {
        this.licencas = licencas;
        this.licencasOptions = [];
        licencas.forEach(licenca => {
          this.licencasOptions.push(
            { label: licenca.contrato, value: licenca.id }
          );
          if (this.pedidoFilter.licencaId === undefined) {
            this.pedidoFilter.licencaId = licenca.id;
          }
        });
      },
      err => console.log('Error:', err)
    );
  }

  buscar() {
    this.licencas.forEach(
      licenca => {
        if (licenca.id === this.pedidoFilter.licencaId) {
          this.pedidoFilter.moeda = licenca.moeda;
        }
      }
    );

    this.estatisticaService.getEstatisticaProdutosMonetario(this.pedidoFilter).subscribe(
      result => {
        console.log('monetario', result);
        let h = 90;
        this.barProdutosMonetarioOptions.xAxis.categories = [];
        this.barProdutosMonetarioOptions.series[0].data = [];
        this.barProdutosMonetarioOptions.tooltip.valuePrefix = this.pedidoFilter.moeda === 'BRL' ? 'R$ ' : '$';
        for (const value of result) {
          this.barProdutosMonetarioOptions.xAxis.categories.push(value.forma);
          this.barProdutosMonetarioOptions.series[0].data.push(value.total);
          h += 30;
        }
        if (h > 250) {
          this.barProdutosMonetarioOptions.chart.height = h;
        } else {
          this.barProdutosMonetarioOptions.chart.height = 250;
        }
        Highcharts.chart('chart-produtos-monetario', this.barProdutosMonetarioOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );

    this.estatisticaService.getEstatisticaProdutosQuantitativo(this.pedidoFilter).subscribe(
      result => {
        console.log('quantitativo', result);
        let h = 90;
        this.barProdutosQuantitativoOptions.xAxis.categories = [];
        this.barProdutosQuantitativoOptions.series[0].data = [];
        for (const value of result) {
          this.barProdutosQuantitativoOptions.xAxis.categories.push(value.forma);
          this.barProdutosQuantitativoOptions.series[0].data.push(value.qtd);
          h += 30;
        }
        if (h > 250) {
          this.barProdutosQuantitativoOptions.chart.height = h;
        } else {
          this.barProdutosQuantitativoOptions.chart.height = 250;
        }
        Highcharts.chart('chart-produtos-quantitativo', this.barProdutosQuantitativoOptions);
      },
      err => {
        console.log('Error:', err);
      }
    );
  }

  hasPermission(permission: string) {
    return this.jwtPayload.authorities.includes(permission);
  }

}
