export class Conciliacao {

  id: string;
  dtHr: Date;
  empresaId: string;
  licencaId: string;
  descricao: number;
  valor: number;

}
