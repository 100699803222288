import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login-validar',
  templateUrl: './login-validar.component.html',
  styleUrls: ['./login-validar.component.css']
})
export class LoginValidarComponent implements OnInit {

  enviado: boolean;
  btnSendLabel: string;
  meioEnvioCodigo: string;
  codigo: string;
  blocked: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.blocked = false;
    this.enviado = false;
    this.meioEnvioCodigo = 'SMS';
  }

  enviar() {
    this.blocked = true;
    console.log('Login Enviar | this.authService.senhaAtual:', this.authService.senhaAtual);
    this.authService.enviarCodigo(this.meioEnvioCodigo).subscribe(
      response => {
        console.log('success', response);
        this.enviado = true;
        window.setTimeout(function () {
          document.getElementById('codigo').focus();
        }, 0);
        this.blocked = false;
      },
      error => {
        console.log('error:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Falha na validação!',
          detail: `${this.meioEnvioCodigo} FALHOU.`
        });
        this.blocked = false;
      }
    );
  }

  validar() {
    this.blocked = true;
    console.log('Login Validar | this.authService.senhaAtual:', this.authService.senhaAtual);
    if (this.codigo === undefined || this.codigo === null) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Código de Validação Obrigatório!',
        detail: 'Informe Código de Validação.'
      });
      document.getElementById('codigo').focus();
      this.blocked = false;
      return;
    }
    if (this.codigo.length < 6) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Código de Validação Inválido!',
        detail: 'Informe o Código de Validação com 6 dígitos.'
      });
      this.blocked = false;
      return;
    }

    this.authService.validarCodigo(this.codigo).subscribe(
      response => {
        console.log('success', response);
        this.authService.armazenarToken();
        this.router.navigate(['home']);
        this.blocked = false;
      },
      error => {
        console.log('error:', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Falha na autorização!',
          detail: 'Código inválido ou expirado.'
        });
        this.codigo = '';
        document.getElementById('codigo').focus();
        this.blocked = false;
      }
    );

  }

}
