import { HttpParams } from '@angular/common/http';

export class RecorrenteLinkFilter {

    empresaId: string;
    licencaId: string;

    constructor(empresaId: string) {
        this.empresaId = empresaId;
    }

    getSearchParams() {
        let params = new HttpParams();

        if (this.empresaId !== undefined) {
            params = params.set('empresaId', this.empresaId);
        }

        if (this.licencaId !== undefined) {
            params = params.set('licencaId', this.licencaId);
        }

        return params;
    }

}
