<div class="pgw--container">

    <div class="ui-g">

        <div class="ui-g-12">
            <h1>Boletos Recorrente</h1>
        </div>

        <div class="ui-g-12 ui-g-nopad">
            <div class="ui-g-12 ui-fluid" *ngIf="jwtPayload.usuarioEmail.includes('@pgwpay.com')">
                <p-dropdown [options]="empresasOptions" [(ngModel)]="pedidoFilter.empresaId"
                    (onChange)="buscarLicencas()" filter="true" resetFilterOnHide="true">
                </p-dropdown>
            </div>
        </div>

        <div class="ui-g-12 ui-md-4 ui-lg-3 ui-fluid">
            <p-dropdown [options]="licencasOptions" [(ngModel)]="pedidoFilter.licencaId" pTooltip="Contratos"
                tooltipPosition="top" (onChange)="selecionarLicenca()">
            </p-dropdown>
        </div>

        <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
            <p-multiSelect [options]="pedidoFilter.diasOptions" [(ngModel)]="pedidoFilter.selectedDias"
                defaultLabel="Dias" pTooltip="Dias do mês" tooltipPosition="top"></p-multiSelect>
        </div>

        <div class="ui-g-12 ui-md-4 ui-lg-2 ui-fluid">
            <p-dropdown [options]="pedidoFilter.situacaoOptions" [(ngModel)]="pedidoFilter.recorrenteAtivo"
                pTooltip="Situação da Assinatura" tooltipPosition="top"></p-dropdown>
        </div>

        <div class="ui-g-12 ui-md-1 ui-lg-1">
            <button pButton type="button" icon="pi pi-search" (click)="buscar()"></button>
        </div>

        <div class="ui-g-12 ui-fluid">
            <p-table [value]="pedidosDTO" [responsive]="true" selectionMode="single"
                (onRowSelect)="onRowSelect($event.data)">
                <ng-template pTemplate="caption">
                    Pedidos encontrados: {{ pedidosDTO.length }}
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 70px;">Dia</th>
                        <th style="width: 100px;">Pedido</th>
                        <th class="col-left">Comprador</th>
                        <th style="width: 110px;">Forma Pgto.</th>
                        <th style="width: 150px;">Situação</th>
                        <th style="width: 120px;" class="col-right">Total</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-pedidoDTO>
                    <tr [pSelectableRow]="pedidoDTO">
                        <td class="col-center">{{ pedidoDTO.dtHr | date:'dd' }}</td>
                        <td class="col-center">
                            {{ pedidoDTO.pedidoNumero }}
                            <span class="pgw--ref">{{pedidoDTO.origem}}</span>
                        </td>
                        <td class="col-left">
                            {{ pedidoDTO.compradorNomeCompleto }}
                            <span class="pgw--ref">{{ pedidoDTO.contrato }} |
                                {{ pedidoDTO.environment === 'production' ? 'Produção' : pedidoDTO.environment === 'sandbox' ? 'Teste' : 'Log' }}
                            </span>
                        </td>
                        <td class="col-center">
                            {{ pedidoDTO.formaPagamento }}
                            <span class="pgw--ref" *ngIf="pedidoDTO.recorrente">recorrente</span>
                            <span class="pgw--ref" *ngIf="!pedidoDTO.recorrente && pedidoDTO.qtdParcela == 1">a
                                vista</span>
                            <span class="pgw--ref"
                                *ngIf="!pedidoDTO.recorrente && pedidoDTO.qtdParcela > 1">{{pedidoDTO.qtdParcela}}
                                parcelas</span>
                        </td>
                        <td class="col-center">
                            <span *ngIf="!pedidoDTO.recorrenteDtCancel" style="color: green;">Ativo</span>
                            <span *ngIf="pedidoDTO.recorrenteDtCancel" style="color: red;">Cancelado</span>
                        </td>
                        <td class="col-right" *ngIf="pedidoFilter.moeda === 'BRL'">
                            {{ pedidoDTO.totalPedido | currency:'BRL':'symbol':'0.2-2':'pt' }}</td>
                        <td class="col-right" *ngIf="pedidoFilter.moeda === 'USD'">
                            {{ pedidoDTO.totalPedido | currency:'USD':'symbol':'0.2-2':'en' }}</td>
                    </tr>
                </ng-template>

            </p-table>
        </div>

    </div>

</div>

<nav *ngIf="exibirPedido">
    <p-sidebar [(visible)]="exibirPedido" position="left" styleClass="ui-sidebar-md">
        <app-pedido-detalhe origem="BOLETO_RECORRENTE" [pedido]="pedidoSelecionado" [licenca]="licencaSelecionada"
            (onPedidosDependentes)="showPedidosDependentes($event)"></app-pedido-detalhe>
    </p-sidebar>
</nav>

<div *ngIf="exibirDependentes">
    <p-sidebar [(visible)]="exibirDependentes" [fullScreen]="true" [baseZIndex]="9000">
        <app-pedido-dependentes [pedido]="pedidoRoot" [pedidos]="pedidosDependentes" (fechar)="fecharDependentes()"
            (pedidoDependenteSelecionado)="onRowSelect($event)" (pedidoOrigemSelecionado)="onPedidoRootSelect($event)">
        </app-pedido-dependentes>
    </p-sidebar>
</div>
